function timeConvert(seconds) {
	const hour = Math.floor(seconds / 3600);
	const minute = Math.floor((seconds - 3600 * hour) / 60);
	const sec = seconds - 3600 * hour - 60 * minute

	return `${hour}:${minute}:${sec}`;
}

const plotData = (timelines) => {

	let users = new Set();
	let dataset = [];
	let users_tl = {};
	const timeList = [];

	const params = new URLSearchParams(window.location.search);
	const movieId = params.get("movieId");
	
	if ("zoom_timelines" in timelines && timelines.zoom_timelines.length != 0) {
		for (let i = 0; i < timelines.zoom_timelines.length; i++) {
			users.add(timelines.zoom_timelines[i].name);
		};

		let y = users.size;
		users.forEach((a) => {
			users_tl[a] = {};
			users_tl[a]["y"] = y;
			users_tl[a]["data"] = [];
			y=y-1;
		});

		for (let i = 0; i < timelines.zoom_timelines.length; i++) {
			const _name = timelines.zoom_timelines[i].name

			users_tl[_name]["data"].push({
				x: timelines.zoom_timelines[i].time,
				y: users_tl[_name]["y"],
				link: "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/player?movieId="
					+ movieId + "&time=" + timelines.zoom_timelines[i].time,
			})

			// console.log(timelines.zoom_timelines[i].time)
			if (timelines.zoom_timelines[i].time % 1 == 0){
				timeList.push({
					x: timelines.zoom_timelines[i].time,
					label: timeConvert(timelines.zoom_timelines[i].time)
				})
			}
		}

		users.forEach((a) => {
			const eachDataset = {
				seriesname: a,
				data: users_tl[a]["data"]
			}
			dataset.push(eachDataset);
		});
	}


	// console.log(timeList)
	// console.log(happyList)

	const dataSource = {
		chart: {
			// subcaption: "Engagement Score",
			// yaxisname: "Score",
			// numvisibleplot: "70",
			// labeldisplay: "auto",
			// theme: "fusion",
			// caption: "Analysing Subsidies by Youth Population",
			// subcaption: "By province",
			// yaxisname: "Score",
			showYAxisValues:0,
			xAxisName:"Time",
			// syaxisname: "Subsidies % of Income",
			labeldisplay: "rotate",
			// snumbersuffix: "%",
			scrollheight: "10",
			numvisibleplot: "10",
			drawcrossline: "1",
			theme: "fusion",
			anchorRadius: "3",
			lineThickness: "1",
			lineAlpha:"100",
			yAxisMaxValue:users.size+1,
			showToolTipShadow:"1",
		},
		categories: [
			{
				category: timeList,
			},
		],
		dataset: dataset
	};

	return dataSource;
};

export default plotData;
