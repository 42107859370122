import { Auth, API } from "aws-amplify";

export async function getEngagementScores() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&type=avg&group_by=all";
	return await API.get(apiName, path);
}

export async function getEngagementScoresGroupByParticipant() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&type=avg&group_by=participant";
	return await API.get(apiName, path);
}

export async function getEngagementScoresPerMovie() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&type=avg&group_by=movie";
	return await API.get(apiName, path);
}

export async function getAvgEngagementScoresOfMovie(movie_id) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movie_id + "&type=avg";
	return await API.get(apiName, path);
}

export async function getAvgEngagementScoresOfParticipantGroupbyMovie(userId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&user_id=" + userId + "&type=avg&group_by=movie";
	return await API.get(apiName, path);
}

export async function getAvgEngagementScoresOfParticipant(userId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&user_id=" + userId + "&type=avg";
	return await API.get(apiName, path);
}

export async function getAvgEngagementScoresOfMovieGroupbyParticipant(movieId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&type=avg&group_by=participant";
	return await API.get(apiName, path);
}

export async function getTsEngagementScoresOfMovieOfParticipant(movieId, participant_id) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId +  "&participant_id=" + participant_id + "&type=timeseries";
	return await API.get(apiName, path);
}

export async function getTsEngagementScoresOfMovie(movieId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "EngagementScoresAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&type=timeseries";
	return await API.get(apiName, path);
}