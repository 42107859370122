import React, { useEffect, useState } from "react";
import "./CreateSectionModal.css";
import { AiOutlineClose } from "react-icons/ai";
import { getMovies } from "../../../../api/Movies";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  createMovieSection,
  addMoviesInSection,
} from "../../../../api/MovieSections";
import ClipLoader from "react-spinners/ClipLoader";

const CreateSectionModal = (props) => {
  const [sectionName, setSectionName] = useState(null);
  const [movieOptions, setMovieOptions] = useState([]);
  const [selectedMovies, setSelectedMovies] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const generateAutocompleteOptions = (rawData) => {
    let res = [];
    let length = rawData.length;
    for (let i = 0; i < length; i++) {
      let obj = {
        label: rawData[i].movie_id + " ( " + rawData[i].topic + " )",
        id: rawData[i].movie_id,
      };
      res.push(obj);
    }
    console.log("autocomplete options - ", res);
    return res;
  };
  const fetchMovies = async () => {
    let moviesRes = await getMovies();
    console.log("fetched movies in modal - ", moviesRes);
    setMovieOptions(generateAutocompleteOptions(moviesRes.movies));
  };

  const createSection = async (sectionName, movieList) => {
    setShowSpinner(true);
    let uniqueMovieList = [...new Set(movieList)];
    console.log(
      "creating section for sectionName and movieList - ",
      sectionName,
      uniqueMovieList
    );
    let sectionId;
    try {
      let response = await createMovieSection(sectionName);
      sectionId = response.section_id;
      console.log("response form createMovieSection - ", response);
    } catch (err) {
      setShowSpinner(false);

      console.log("error in creating new section - ", err);
      return;
    }
    try {
      let response2 = await addMoviesInSection(sectionId, uniqueMovieList);
      console.log("response form addMoviesInSection - ", response2);
    } catch (err) {
      setShowSpinner(false);

      console.log("error in adding movies to section - ", err);
    }
    setShowSpinner(false);
    props.toggleFunc();
  };

  useEffect(() => {
    fetchMovies();
  }, []);

  if (props.enabled === false) return null;

  return (
    <div className="section-modal-root">
      <div className="section-modal-content">
        <div className="section-modal-topbar">
          <span className="section-title-text section-modal-heading">
            Add Section
          </span>
          <AiOutlineClose size={36} onClick={props.toggleFunc} />
        </div>
        <div className="section-modal-sectionname">
          <span>Section Name:</span>
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={(event) => {
              setSectionName(event.target.value);
              // console.log("textfield event - ", event.target.value);
            }}
          />
        </div>
        <div className="section-modal-sectionname">
          <span>Add Movie ID:</span>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={movieOptions}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Movie" />}
            onSelect={(event) => {
              let curr = event.target.value;
              console.log("select event - ", event.target.value);
              let currId = curr.substring(0, curr.indexOf(" "));
              console.log("selected currID - ", currId);
              if (
                typeof event.target.value === "string" &&
                event.target.value.length > 5
              ) {
                let currId = curr.substring(0, curr.indexOf(" "));
                console.log("selected currID - ", currId);
                setSelectedMovies((prev) => [...prev, currId]);
                console.log("selected movies array - ", selectedMovies);
              }
            }}
          />
        </div>
        <div className="section-modal-padding">
          <div className="tag-bubble-container ">
            {[...new Set(selectedMovies)].map((movie) => {
              return (
                <div className="tag-bubble">
                  <span className="info-text-small"> {movie} </span>;
                </div>
              );
            })}
          </div>
        </div>
        <div className="section-modal-footer">
          <div
            className="yellow-big-button"
            onClick={() => {
              createSection(sectionName, selectedMovies);
            }}
          >
            {showSpinner ? (
              <ClipLoader loading={showSpinner} size={25} />
            ) : (
              "Create Section"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSectionModal;
