import React, { useEffect, useState } from "react";

import {
	getAllAlerts
} from "../../../api/Alerts";

import MaterialTable from "material-table";
import Card from "../../../components/Card";
import { Paper } from '@material-ui/core';

import tableIcons from "../../../assets/MaterialTableIcons";
import { dataset, options, columns } from "./helper";
import AddBox from "@material-ui/icons/AddBox";

const Tables = React.memo(props => {

	const [alerts, setAlerts] = useState([]);

	useEffect(() => {
		getAllAlerts()
			.then((response) => {
				// console.log(response)
				setAlerts(response.alert_def);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const data = dataset(alerts)

	return (
		<div className='col-md-11 p-4 mt-5'>
			<Card>
				<div className='mx-2'>
					<MaterialTable
						title="Alert Definition List"
						icons={tableIcons}
						columns={columns}
						data={data}
						options={options}
						components={{
							Container: props => <Paper {...props} elevation={0} />
						}}
						actions={[
							{
								icon: AddBox,
								tooltip: "Create a new alert",
								isFreeAction: true,
								onClick: () => {
									window.location.href =  "/alerts/create";
								}
							}
						]}
					/>
				</div>
			</Card>
		</div>
	);
});

export default Tables;