export const fetchSearchResultRequest = (request) => {
  console.log("fetchSearchResultRequest action called");
  return {
    type: "FETCH_SEARCH_RESULT_REQUEST",
    payload: request,
  };
};

export const fetchSearchResultSuccess = (data) => {
  return {
    type: "FETCH_SEARCH_RESULT_SUCCESS",
    payload: data,
  };
};

export const fetchSearchResultFailure = (error) => {
  return {
    type: "FETCH_SEARCH_RESULT_FAILURE",
    payload: error,
  };
};

export const fetchTagSuggestionsSuccess = (data) => {
  return {
    type: "FETCH_TAG_SUGGESTIONS_SUCCESS",
    payload: data,
  };
};

export const updateCustomPlay = (data) => {
  return {
    type: "UPDATE_CUSTOM_PLAY",
    payload: data,
  };
};

export const chart1 = (data) => {
  return {
    type: "UPDATE_CHART_1",
    payload: data,
  };
};

export const chart2 = (data) => {
  return {
    type: "UPDATE_CHART_2",
    payload: data,
  };
};

export const fetchHighlightsDataRequest = () => {
  return {
    type: "FETCH_HIGHLIGHTS_DATA_REQUEST",
  };
};

export const fetchHighlightsDataSuccess = (data) => {
  return {
    type: "FETCH_HIGHLIGHTS_DATA_SUCCESS",
    payload: data,
  };
};

export const fetchHighlightsDataFailure = () => {
  return {
    type: "FETCH_HIGHLIGHTS_DATA_FAILURE",
  };
};

export const updateTranscript = (data) => {
  return {
    type: "UPDATE_TRANSCRIPT_DATA",
    payload: data,
  };
};

export const transcriptChart = (data) => {
  return {
    type: "UPDATE_TRANSCRIPT_CHART",
    payload: data,
  };
};

export const resetSearchResult = () => {
  console.log("resetSearchResult action called");
  return {
    type: "RESET_SEARCH_RESULT",
    payload: [],
  };
};
