import React from "react";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import plotData from "./helper";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const EngagementScore = (props) => {
	const scores = props.dataset;
	const userId = props.userId;

	const dataSource = plotData(scores, userId);

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<div className='card h-100 text-center'>
			<div className='pt-3' style={style}>
				Engagement score
			</div>
			<div className='card-body'>
				<ReactFC
					type='scrollcolumn2d'
					width='100%'
					height='250'
					dataFormat='JSON'
					dataSource={dataSource}
				/>
			</div>
		</div>
	);
};

export default EngagementScore;
