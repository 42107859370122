import React, { useRef, useEffect, useState } from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import routes from "../../../utils/routes";
import Dropdown from "react-bootstrap/Dropdown";

import { CSVLink } from "react-csv";

import "./app.css";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);



const EmotionLine = (props) => {
	const [aggregateType, setAggregateType] = useState("mean");

	// useEffect(() => {
	// 	console.log(aggregateType);
	// 	getTsFaceEmotionsOfMovie(movieId, aggregateType)
	// 		.then((response) => {
	// 			// console.log(response)
	// 			setFaceEmotionTs({
	// 				faceEmotionTs: response
	// 			});
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// }, [aggregateType]);

	const fetchDoneRef = useRef();

	const userId = props.userId;

	const faceEmotionTs = props.faceEmotionTs;
	const dataSource = plotData(faceEmotionTs);

	// console.log(dataSource)
	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	const iconStyle = {
		fontSize: 25,
		color: "grey",
		cursor: "pointer",
	};

	const handleSelect = (e) => {
		if (e == "download") {
			fetchDoneRef.current.link.click()
		} else if (e == "mean") {
			setAggregateType("mean")
		} else if (e == "sum") {
			setAggregateType("sum")
		} else if (e == "std") {
			setAggregateType("std")
		}
	}

	return (
		<div className='card text-center'>
			<div class="row">
				<div className='pt-3 header-item' style={style}>
					<div className='header-title'> {"Facial Emotion Score -- " + aggregateType }</div>
					<div className='header-icon'>
						<Dropdown onSelect={handleSelect}>
							<Dropdown.Toggle variant="secondary" id="dropdown-basic">
							</Dropdown.Toggle>
							<Dropdown.Menu >
								<Dropdown.Item eventKey="download">Download csv</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>

			</div>
			<div className='card-body'>
				<ReactFC
					type="msline"
					width="100%"
					height="500"
					dataFormat="JSON"
					dataSource={dataSource}
				/>
			</div>

			{("face_emotions" in faceEmotionTs && faceEmotionTs.face_emotions.length) && <CSVLink
				data={faceEmotionTs.face_emotions}
				filename={"emotion_" + aggregateType + "_" + userId +  ".csv"} //ファイル名を指定
				ref={fetchDoneRef} // refを指定
				target="_blank"
			/>
			}

		</div>
	);
};

export default EmotionLine;
