import React, { useState, useEffect } from "react";
import "./shared_dashboard.css";
import { useHistory } from "react-router-dom";

import { Search } from "../Search";
import Popup from "reactjs-popup";
import Loading from "../../assets/loading.gif";
import delete_icon from "../../assets/delete_icon.svg";
import dashboarIcon from "../../assets/dashboardIcon.svg";

import Table from "./Table";
import {
  getShareAccounts,
  deleteShareAccounts,
  createShareAccounts,
  getSharedOrganizationList,
} from "../../api/ShareDashboard";

/************************
Old Organization List
************************/

// const OrganizationList = ({ org_name, selected }) => {
//   const header = selected
//     ? "organization-list organization-list-selected-background"
//     : "organization-list organization-list-not-selected-background";
//   return <div className={header}>{org_name}</div>;
// };

/************************
Old Navbar
************************/
// const SharedDashboardNavbar = () => {
//   return (
//     <div className="shared-dashboard-container-body-navbar">
//       <div className="shared-dashboard-container-body-navbar-items">
//         Tag Search
//       </div>
//     </div>
//   );
// };

const OrganizationList = () => {
  const theadData = ["Organization Name", "Email Id", "Dashboard Veiw"];
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});

  let history = useHistory();

  const tbodyData = [];

  useEffect(() => {
    getSharedOrganizationList()
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let id = 1;

  const createDataForTable = (item) => {
    tbodyData.push({
      id: id,
      items: [
        item["company_name"],
        item["email"],
        <img
          src={dashboarIcon}
          alt="Dashboard Icon"
          className="delete-icon"
          onClick={() => {
            setItem(item);
            history.push(
              `/share/search?company_name=${item.company_name}&sender_id=${item.sender_id}`
            );
          }}
        />,
      ],
    });
    id++;
  };

  data.forEach(createDataForTable);

  return (
    <div className="organization-list">
      <Table
        theadData={theadData}
        tbodyData={tbodyData}
        customClass="organization-list-table"
      />
    </div>
  );
};

const SharedOrganizationList = ({ open, closeModal }) => {
  const theadData = [
    "Organization Name",
    "Email Id",
    "Scope",
    "Resource Id",
    "Revoke Access",
  ];
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const [run, setRun] = useState("");

  //Delete Dialog Box
  const [openDeleteDialogBox, setOpenDeleteDialogBox] = useState(false);
  const closeDeleteDialogBox = () => {
    setOpenDeleteDialogBox(false);
  };

  const callApiAgain = (value) => {
    setRun(value);
  };

  const tbodyData = [];

  useEffect(() => {
    getShareAccounts()
      .then((response) => {
        console.log(response);
        setData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [run]);

  let id = 1;

  const createDataForTable = (item) => {
    console.log(item);
    tbodyData.push({
      id: id,
      items: [
        item["company_name"],
        item["email"],
        item["scope"],
        item["resource_id"],
        <img
          src={delete_icon}
          alt="Delete Icon"
          className="delete-icon"
          onClick={() => {
            setOpenDeleteDialogBox(true);
            setItem(item);
          }}
        />,
      ],
    });
    id++;
  };

  data.forEach(createDataForTable);

  return (
    <div className="organization-list">
      <Table
        theadData={theadData}
        tbodyData={tbodyData}
        customClass="organization-list-table"
      />
      <ShareDashboardPopup
        open={openDeleteDialogBox}
        closeModal={closeDeleteDialogBox}
        children={
          <DeleteDialogBoxContent
            close={closeDeleteDialogBox}
            item={item}
            runApi={callApiAgain}
          />
        }
      />
      <ShareDashboardPopup
        open={open}
        closeModal={closeModal}
        children={
          <CreateShareDialogBox close={closeModal} runApi={callApiAgain} />
        }
      />
    </div>
  );
};

const ShareDashboardPopup = ({ open, closeModal, children }) => {
  return (
    <Popup
      className="popup-content-shared-dashboard"
      open={open}
      closeOnDocumentClick={false}
      onClose={closeModal}
    >
      {children}
    </Popup>
  );
};

const CreateShareDialogBox = ({ close, runApi }) => {
  const [notSubmit, setNotSubmit] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [sending, setSending] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [sectionName, setSectionName] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setNotSubmit(false);
    const obj = {
      email_id: email,
      resource_id: sectionName,
      scope: "section",
    };
    //Submit the form
    createShareAccounts(obj)
      .then((res) => {
        setSending(false);
        setIsSuccess(true);
        runApi("Added");
      })
      .catch((err) => {
        console.log(err.response);
        setSending(false);
        setIsSuccess(false);
        if (
          err.response.data === "No account found related to this email id "
        ) {
          setErrorMessage(err.response.data);
        }
      });
  };
  const emailHandleChange = (event) => {
    setEmail(event.target.value);
  };

  const sectionNameHandleChange = (event) => {
    setSectionName(event.target.value);
  };

  return (
    <div className="shared-dashboard-popup-content">
      <div className="shared-dashboard-popup-content-close-btn" onClick={close}>
        X
      </div>
      <div className="shared-dashboard-popup-content-heading">
        Share Dashboard
      </div>
      {notSubmit ? (
        <form
          className="shared-dashboard-popup-content-body"
          onSubmit={handleSubmit}
        >
          <label>
            <div className="shared-dashboard-popup-content-body-label">
              Email Id
            </div>
            <input
              className="shared-dashboard-popup-content-body-input"
              type="text"
              value={email}
              onChange={emailHandleChange}
              placeholder="type here ..."
            />
          </label>
          <label>
            <div className="shared-dashboard-popup-content-body-label">
              Section Id
            </div>
            <input
              className="shared-dashboard-popup-content-body-input"
              type="text"
              value={sectionName}
              onChange={sectionNameHandleChange}
              placeholder="type here ..."
            />
          </label>
          <input
            className="shared-dashboard-popup-content-body-btn"
            type="submit"
            value="Submit"
          />
        </form>
      ) : sending ? (
        <div className="shared-dashboard-popup-content-success">
          <img src={Loading} alt="Loading!" />
        </div>
      ) : isSuccess ? (
        <div className="shared-dashboard-popup-content-success">
          Successfully Shared the Section ! ✅
        </div>
      ) : (
        <div className="shared-dashboard-popup-content-success">
          {errorMessage
            ? errorMessage
            : "Sorry, Could Not Share the Section ! ❌"}
        </div>
      )}
    </div>
  );
};

const DeleteDialogBoxContent = ({ close, item, runApi }) => {
  const [notSubmit, setNotSubmit] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [sending, setSending] = useState(true);

  const deleteItem = () => {
    setNotSubmit(false);
    setSending(true);
    deleteShareAccounts(item)
      .then((res) => {
        console.log(res);
        setSending(false);
        setIsSuccess(true);
        runApi("Deleted");
      })
      .catch((err) => {
        console.log(err);
        setSending(false);
        setIsSuccess(false);
      });
  };

  return (
    <div className="delete-dialog-box">
      <div className="shared-dashboard-popup-content-close-btn" onClick={close}>
        X
      </div>
      {notSubmit ? (
        <div>
          <div className="delete-dialog-box-heading">Confirm Delete !</div>
          <div className="delete-dialog-box-body">
            <div
              className="delete-dialog-box-delete-button"
              onClick={deleteItem}
            >
              Delete
            </div>
            <div className="delete-dialog-box-cancel-button" onClick={close}>
              Cancel
            </div>
          </div>
        </div>
      ) : sending ? (
        <div className="shared-dashboard-popup-content-success">
          <img src={Loading} alt="Loading!" />
        </div>
      ) : isSuccess ? (
        <div className="shared-dashboard-popup-content-success">
          Successfully Deleted the Section ! ✅
        </div>
      ) : (
        <div className="shared-dashboard-popup-content-success">
          Sorry, Could Not Delete it ! ❌
        </div>
      )}
    </div>
  );
};

const Shared_Dashboard = () => {
  const [changeSection, setChangeSection] = useState(false);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div className="shared-dashboard-container">
      <div className="shared-dashboard-container-header">
        {changeSection ? (
          <div>
            <div>
              <span
                className="shared-dashboard-container-header-sub-heading"
                onClick={() => {
                  setChangeSection(false);
                }}
              >
                Shared Dashboard
              </span>
              <p className="shared-dashboard-container-header-arrow">&gt;</p>
              <span className="shared-dashboard-container-header-heading">
                Share My Dashboard
              </span>
            </div>
          </div>
        ) : (
          <div className="shared-dashboard-container-header-heading">
            Shared Dashboards
          </div>
        )}
        {changeSection ? (
          <div
            className="shared-dashboard-container-header-button"
            onClick={() => {
              setOpen(true);
            }}
          >
            Share
          </div>
        ) : (
          <div
            className="shared-dashboard-container-header-button"
            onClick={() => {
              setChangeSection(true);
            }}
          >
            Share My Dashboard
          </div>
        )}
      </div>
      <div className="shared-dashboard-container-body">
        {/* <div className="shared-dashboard-container-body-list">
          <OrganizationList org_name="Organization_1" selected={true} />
          <OrganizationList org_name="Organization_2" selected={false} />
        </div>
        <div className="shared-dashboard-container-body-components">
          <ShareDashboardPopup open={open} closeModal={closeModal} />
          <div>
            <SharedDashboardNavbar />
          </div>
          <div className="shared-dashboard-container-body-component-loader">
            <Search showHeading={false} />
          </div>
        </div> */}
        {changeSection ? (
          <SharedOrganizationList open={open} closeModal={closeModal} />
        ) : (
          <OrganizationList />
        )}
      </div>
    </div>
  );
};

export default Shared_Dashboard;
