import React, { useState, useEffect } from "react";
import "./NotificationConfig.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { AiFillDelete } from "react-icons/ai";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { FaInfo } from "react-icons/fa";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";

import { create_alert_config } from "../../api/AlertConfig";

const emotions = [
  { label: "Happy", value: "happy" },
  { label: "Sad", value: "sad" },
  { label: "Fear", value: "fear" },
  { label: "Neutral", value: "neutral" },
  { label: "Surprise", value: "surprise" },
  { label: "Angry", value: "angry" },
  { label: "happy_increase", value: "corr_happy_increase" },
  { label: "sad_increase", value: "corr_sad_increase" },
  { label: "fear_increase", value: "corr_fear_increase" },
  { label: "neutral_increase", value: "corr_neutral_increase" },
  { label: "surprise_increase", value: "corr_surprise_increase" },
  { label: "angry_increase", value: "corr_angry_increase" },
  { label: "happy_decrease", value: "corr_happy_decrease" },
  { label: "sad_decrease", value: "corr_sad_decrease" },
  { label: "fear_decrease", value: "corr_fear_decrease" },
  { label: "neutral_decrease", value: "corr_neutral_decrease" },
  { label: "surprise_decrease", value: "corr_surprise_decrease" },
  { label: "angry_decrease", value: "corr_angry_decrease" },
  { label: "happy_flat", value: "corr_happy_flat" },
  { label: "sad_flat", value: "corr_sad_flat" },
  { label: "fear_flat", value: "corr_fear_flat" },
  { label: "neutral_flat", value: "corr_neutral_flat" },
  { label: "surprise_flat", value: "corr_surprise_flat" },
  { label: "angry_flat", value: "corr_angry_flat" },
];

const conditionOptions = [
  { label: "equal to", value: "equal_to" },
  { label: "less than", value: "less_than" },
  { label: "greater than", value: "greater_than" },
  { label: "in between", value: "in_between" },
];

const conditionOptionsCorr = [{ label: "exists", value: "exists" }];

const conditionRelOptions = [
  { label: "AND", value: "AND" },
  { label: "OR", value: "OR" },
];

const severityOptions = [
  {
    label: "None",
    value: 0,
    icon: (
      <div className="icons-wrapper-alt" style={{ backgroundColor: "green" }}>
        <MdOutlineNotificationsNone
          style={{ color: "white", fontSize: "14" }}
        />
      </div>
    ),
  },
  {
    label: "Information",
    value: 1,
    icon: (
      <div className="icons-wrapper-alt" style={{ backgroundColor: "#5F6CE1" }}>
        <FaInfo style={{ color: "white", fontSize: "14" }} />
      </div>
    ),
  },
  {
    label: "Warning",
    value: 2,
    icon: (
      <div className="icons-wrapper-alt" style={{ backgroundColor: "#EC8A87" }}>
        <BsExclamationTriangleFill style={{ color: "white", fontSize: "14" }} />
      </div>
    ),
  },
  {
    label: "Important",
    value: 3,
    icon: (
      <div className="icons-wrapper-alt" style={{ backgroundColor: "#F7CF6D" }}>
        <AiFillStar style={{ color: "white", fontSize: "14" }} />
      </div>
    ),
  },
];

const ColorButton = styled(Button)(({ theme }) => ({
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#F7CF6D",
  "&:hover": {
    backgroundColor: "#F7CF6D",
  },
}));

export default function CreateAlert({ account_id }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [conditions_rel, setConditions_rel] = useState("");
  const [severity, setSeverity] = useState(0);

  const [newAlert, setNewAlert] = useState([
    {
      when: "",
      condition: "",
      value1: 0,
      value2: 0,
    },
  ]);

  const delCondition = (index) => {
    if (index > -1) {
      var temp = newAlert;
      temp.splice(index, 1);
      setNewAlert([...temp]);
    }
  };

  const updateCondition = (index, label, value) => {
    var temp = newAlert;
    temp[index][label] = value;
    setNewAlert([...temp]);
  };

  const addCondition = () => {
    const temp = newAlert;
    temp.push({
      when: "",
      condition: "",
      value1: 0,
      value2: 0,
    });
    setNewAlert([...temp]);
  };

  const alertDef = (index) => {
    // console.log(item);
    // console.log(item.when);
    // console.log(index);
    // console.log(newAlert[index]);
    return (
      <div>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">When</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={newAlert[index]["when"]}
              label="When"
              onChange={(e) => {
                updateCondition(index, "when", e.target.value);
              }}
            >
              {emotions.map((item) => {
                return <MenuItem value={item.value}>{item.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small">Condition</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={newAlert[index].condition}
              label="Condition"
              onChange={(e) => {
                updateCondition(index, "condition", e.target.value);
              }}
            >
              {newAlert[index]["when"].split("_")[0] === "corr"
                ? conditionOptionsCorr.map((item) => {
                    return <MenuItem value={item.value}>{item.label}</MenuItem>;
                  })
                : conditionOptions.map((item) => {
                    return <MenuItem value={item.value}>{item.label}</MenuItem>;
                  })}
              {/* {conditionOptions.map((item) => {
                return <MenuItem value={item.value}>{item.label}</MenuItem>;
              })} */}
            </Select>
          </FormControl>
          {newAlert[index]["when"].split("_")[0] !== "corr" ? (
            <TextField
              id="outlined-size-small"
              variant="outlined"
              size="small"
              sx={{ width: "120px" }}
              value={newAlert[index].value1}
              onChange={(e) => {
                updateCondition(index, "value1", e.target.value);
              }}
              label="Value"
            />
          ) : null}
          {newAlert[index].condition === "in_between" ? (
            <>
              <p style={{ margin: "0" }}>and</p>
              <TextField
                id="outlined-size-small"
                variant="outlined"
                size="small"
                sx={{ width: "120px" }}
                value={newAlert[index].value2}
                onChange={(e) => {
                  updateCondition(index, "value2", e.target.value);
                }}
                label="Value"
              />
            </>
          ) : (
            <>
              <p style={{ margin: "0", visibility: "hidden" }}>and</p>
              <TextField
                id="outlined-size-small"
                variant="outlined"
                size="small"
                sx={{ width: "120px", visibility: "hidden" }}
                value={newAlert[index].value2}
                onChange={(e) => {
                  updateCondition(index, "value2", e.target.value);
                }}
                label="Value"
              />
            </>
          )}
          {index === 0 ? (
            <AiFillDelete
              style={{
                fontSize: "1.5rem",
                cursor: "pointer",
                visibility: "hidden",
              }}
              disabled
            />
          ) : (
            <AiFillDelete
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
              onClick={() => {
                delCondition(index);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const submitHelper = async () => {
    const result = await create_alert_config({
      alert_name: name,
      alert_description: description,
      severity: severity,
      conditions_rel: conditions_rel,
      alerts: newAlert,
      account_id: account_id,
    });

    if (result === "Successfully created") {
      alert("Successfully created");
    }

    // sender_id, alert_name, account_id, conditions_rel, alerts;
  };

  return (
    <div>
      <p className="creatreAlertHeading">Please select alert conditions</p>
      <p className="creatreAlertSubHeading">
        Based on the parameters you select, IBY will send you notification
        alerts
      </p>
      {/* <span>
          <span>Set alert for</span>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Age</InputLabel>
            <Select labelId="demo-select-small" id="demo-select-small">
              <MenuItem value={20}>Emotion Spike</MenuItem>
              <MenuItem value={30}>Analysis spike</MenuItem>
            </Select>
          </FormControl>
        </span> */}
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "1rem",
          marginBottom: "1.5rem",
        }}
      >
        <TextField
          id="outlined-size-small"
          variant="outlined"
          size="small"
          sx={{ marginTop: "1rem", width: "400px" }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          label="Name the alert"
        />
        <FormControl sx={{ minWidth: 180, marginTop: "1rem" }} size="small">
          <InputLabel id="demo-select-small">Add relation</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={conditions_rel}
            label="Add relation"
            MenuItem
            onChange={(e) => {
              setConditions_rel(e.target.value);
            }}
          >
            {conditionRelOptions.map((item) => {
              return <MenuItem value={item.value}>{item.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </span>

      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // marginTop: "1rem",
          marginBottom: "2rem",
        }}
      >
        <TextField
          id="outlined-size-small"
          variant="outlined"
          size="small"
          sx={{ width: "70%" }}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          label="Description"
        />
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="demo-select-small">Severity</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={severity}
            label="Severity"
            onChange={(e) => {
              // console.log(e.target.value);
              setSeverity(e.target.value);
            }}
          >
            {severityOptions.map((item) => {
              return (
                <MenuItem value={item.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.icon} {item.label}
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </span>

      {newAlert.map((item, index) => {
        return alertDef(index);
      })}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          onClick={() => {
            addCondition();
          }}
          sx={{ marginTop: "1rem" }}
        >
          Add condition
        </Button>
        <ColorButton
          variant="contained"
          onClick={() => {
            submitHelper();
          }}
          sx={{ marginTop: "1rem" }}
        >
          Submit
        </ColorButton>
      </div>
    </div>
  );
}
