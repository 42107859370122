import { Auth, API } from "aws-amplify";

export async function getMovieComment(
  resource_type,
  resource_id,
  commenter_id = ""
) {
  if (commenter_id === "") {
    commenter_id = (await Auth.currentUserInfo()).attributes.sub;
  }

  const apiName = "MovieComment";
  const path =
    "?commenter_id=" +
    commenter_id +
    "&resource_id=" +
    resource_id +
    "&resource_type=" +
    resource_type;

  return await API.get(apiName, path);
}

export async function deleteMovieComment(comment_id, commenter_id = "") {
  if (commenter_id === "") {
    commenter_id = (await Auth.currentUserInfo()).attributes.sub;
  }

  const apiName = "MovieComment";
  const path = "";
  const myInit = {
    body: {
      commenter_id: commenter_id,
      comment_id: comment_id,
    },
  };
  return await API.del(apiName, path, myInit);
}

export async function addMovieComment(
  resource_type,
  resource_id,
  comment,
  commenter_id = ""
) {
  if (commenter_id === "") {
    commenter_id = (await Auth.currentUserInfo()).attributes.sub;
  }

  const apiName = "MovieComment";
  const path = "";
  let myInit = {};

  myInit = {
    body: {
      resource_type: resource_type,
      resource_id: resource_id,
      comment: comment,
      commenter_id: commenter_id,
    },
  };

  console.log(myInit.body);
  return await API.post(apiName, path, myInit);
}
