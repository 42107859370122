import React, { useEffect, useState, useRef } from "react";

import ReactPlayer from "react-player";

import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ArrowClockwise,
} from "react-bootstrap-icons";
import { FaExpand } from "react-icons/fa";

const Movie = ({ movie }) => {
  const player = useRef(null);
  // const url = movie.movies.url;
  const url = movie.movies && movie.movies.url ? movie.movies.url : null;

  const [rate, setRate] = useState(1);

  const rateUp = () => {
    if (rate < 4) {
      setRate(rate + 1);
    }
  };

  const rateDown = () => {
    if (rate > 1) {
      setRate(rate - 1);
    }
  };

  const rateReset = () => {
    setRate(1);
  };

  return (
    <div>
      <ReactPlayer
        url={url}
        controls={true}
        playbackRate={rate}
        ref={player}
        width="100%"
        height="100%"
      />
      <ChevronDoubleLeft onClick={rateDown} />
      <ArrowClockwise onClick={rateReset} />
      <ChevronDoubleRight onClick={rateUp} />
      <span>{rate}</span>
      <span>x</span>
    </div>
  );
};

export default Movie;
