import React, { useState, useEffect, useRef } from "react";
import TranscriptV3viewHelper from "./TranscriptV3viewHelper";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { useSelector, useDispatch } from "react-redux";

import {
  getTranscriptKeywords,
  addTranscriptKeywords,
  deleteTranscriptKeyword,
} from "../../api/Transcripts";

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F7CF6D",
  "&:hover": {
    backgroundColor: "#F7CF6DCC",
    color: "black",
  },
}));

export default function TranscriptV3view({
  player,
  currData,
  setOpen,
  seekToTimestamp,
  sceneTimestamp,
  setSceneTimestamp,
  scrollToTop,
  tableid,
  movieId,
  setAddTagPopupOpen,
  commentTimestamp,
  setNewTimestamp,
  comments,
  playing,
  duration,
}) {
  const [keywords, setKeywords] = useState([]);
  // console.log(keywords);

  useEffect(async () => {
    setKeywords(await getTranscriptKeywords());
  }, []);

  const handleAddKeyword = () => {
    setKeywords([...keywords, { keyword: "" }]);
  };

  const handleUpdateBookmark = async (keyword, index, status) => {
    // console.log(keyword, index, status);
    if (status === true) {
      const result = await addTranscriptKeywords(keyword.keyword);
      // console.log(result);
      // var temp = keywords;
      setKeywords([
        ...keywords.slice(0, index),
        {
          // ...keywords[index],
          keyword: keyword.keyword,
          keyword_id: result,
        },
        ...keywords.slice(index + 1),
      ]);
    }
    if (status === false) {
      const result = await deleteTranscriptKeyword(keyword.keyword_id);
      const temp = { keyword: keyword.keyword };
      setKeywords([
        ...keywords.slice(0, index),
        temp,
        ...keywords.slice(index + 1),
      ]);
    }
  };

  const handleDeleteFromList = (index) => {
    setKeywords([...keywords.slice(0, index), ...keywords.slice(index + 1)]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {keywords.map((keyword, index) => {
        return (
          <TranscriptV3viewHelper
            key={index}
            comments={comments}
            seekToTimestamp={seekToTimestamp}
            keyword={keyword}
            index={index}
            handleUpdateBookmark={handleUpdateBookmark}
            handleDeleteFromList={handleDeleteFromList}
          />
        );
      })}

      <ColorButton
        onClick={() => handleAddKeyword()}
        variant="contained"
        disableElevation
        sx={{ marginTop: "2rem", width: "20rem", marginLeft: "0.5rem" }}
      >
        + Add keyword
      </ColorButton>
    </div>
  );
}
