import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

/*TODO:
[✅] Setup basic dev env
[  ] UI
[✅] Store
[  ] Test
[✅] Utils


MAKE YOUR CODE SUCH THAT IT IS EASILY EXTENDABLE

Misc:
[✅] routes
[✅] aws-exports
[✅] table schema
[✅] Api

UI:
[  ] Dashboard // Only graph is left
[✅] User
[✅] Zoom
[✅] Navigation Drawer
[✅] Settings


Store:
[✅] AppUsage
[✅] FaceEmotions
[✅] Users
[✅] ZoomMeetingReport
[✅] ZoomRecordings
[✅] ZoomUser
[✅] ZoomUsers



Test:
[  ]
[  ]
[  ]


*/
