function timeConvert(seconds) {
	const hour = Math.floor(seconds / 3600);
	const minute = Math.floor((seconds - 3600 * hour) / 60);
	const sec = seconds - 3600 * hour - 60 * minute

	return `${hour}:${minute}:${sec}`;
}

const plotData = (faceEmotions) => {

	const MAX_PLOT_NUM = 500;

	const timeList = []
	const neutralList = []
	const happyList = []
	const sadList = []
	const angryList = []
	const fearList = []
	const disgustList = []
	const surpriseList = []

	const params = new URLSearchParams(window.location.search);
	const movieId = params.get("movieId");

	// console.log(faceEmotions)
	
	if ("face_emotions" in faceEmotions && faceEmotions.face_emotions.length != 0) {
		// console.log(faceEmotions.face_emotions.length);

		let plotInterval = faceEmotions.face_emotions.length > MAX_PLOT_NUM ? Math.floor(faceEmotions.face_emotions.length / MAX_PLOT_NUM): 1;

		for (let i = 0; i < faceEmotions.face_emotions.length; i++) {
			const emotions = faceEmotions.face_emotions[i]

			if(emotions.time % plotInterval != 0){
				continue
			}

			const link =  "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/player?movieId=" + movieId + "&time=" + emotions.time;

			timeList.push({label: timeConvert(emotions.time)})
			neutralList.push({value: emotions.neutral, link:link});
			happyList.push({value: emotions.happy, link:link});
			sadList.push({value: emotions.sad, link:link});
			angryList.push({value: emotions.angry, link:link});
			fearList.push({value: emotions.fear, link:link});
			disgustList.push({value: emotions.disgust, link:link});
			surpriseList.push({value: emotions.surprise, link:link});
		};
	}

	// console.log(timeList)
	// console.log(happyList)

	const dataSource = {
		chart: {
			// subcaption: "Engagement Score",
			// yaxisname: "Score",
			// numvisibleplot: "70",
			// labeldisplay: "auto",
			// theme: "fusion",
			// caption: "Analysing Subsidies by Youth Population",
			// subcaption: "By province",
			yaxisname: "Score",
			xAxisName:"Time",
			// syaxisname: "Subsidies % of Income",
			labeldisplay: "rotate",
			// snumbersuffix: "%",
			scrollheight: "10",
			numvisibleplot: "10",
			drawcrossline: "1",
			theme: "fusion",
			anchorRadius: "3",
			lineThickness: "1",
			lineAlpha:"100",
			yAxisMaxValue:"100",
			showToolTipShadow:"1",
		},
		categories: [
			{
				category: timeList,
			},
		],
		dataset: [
			{
				seriesname: "Angry",
				data: angryList,
				color: "#45379b",
			},
			{
				seriesname: "Disgust",
				data: disgustList,
				color: "#934e9f",
			},
			{
				seriesname: "Fear",
				data: fearList,
				color: "#2097b7",
			},
			{
				seriesname: "Happy",
				data: happyList,
				color: "#f48f57",
			},
			{
				seriesname: "Sad",
				data: sadList,
				color: "#1d5fbf",
			},
			{
				seriesname: "Surprise",
				data: surpriseList,
				color: "#e66b9d",
			},
			{
				seriesname: "Neutral",
				data: neutralList,
				color: "#ffcd89",
			},
			
		],
	};

	return dataSource;
};

export default plotData;
