import React, { useState, useEffect } from "react";
import ActiveScoreColumn from "./ActiveScoreColumn";
import ScoreCard from "./ScoreCard";
import ActiveScoreHistgram from "./ActiveScoreHistgram";

const ActiveScores = (props) => {

	const dataset = props.dataset;
	const userId = props.userId;

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
    <section className="bar-chart-section">
      <span className="section-title-text">Active Score</span>
      <div className="row">
        <div className="col-md-12">
          <ActiveScoreColumn dataset={dataset} userId={userId} />
        </div>
        {/* <div className="col-md-4"> */}
          {/* <ActiveScoreHistgram dataset={dataset} userId={userId} /> */}
          {/* <ScoreCard dataset={dataset} userId={userId} /> */}
        {/* </div> */}
      </div>
    </section>
  );
};

export default ActiveScores;
