import React, { useState, useEffect } from "react";
import EmotionDonut from "./EmotionDonut";
import UserHeatMap from "./UserHeatMap";
import "./index.css";

const UserEmotions = (props) => {
  const [userId, setUserId] = useState("all");

  const dataset = props.dataset;
  const users = props.users;

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  // console.log(props)

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">User Emotions</span>
      <div className="heatmap-row-container">
        <div className="heatmap-chart-column-card">
          <UserHeatMap dataset={dataset} users={users} setUserId={setUserId} />
        </div>
        <div className="heatmap-chart-column-card">
          <EmotionDonut dataset={dataset} userId={userId} />
        </div>
      </div>
    </section>
  );
};

export default UserEmotions;
