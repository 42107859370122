import React from "react";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import plotData from "./helper";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme, TimeSeries);

const ValenceScore = (props) => {
	const faceEmotions = props.dataset;
	const userId = props.userId;

	const dataSource = plotData(faceEmotions,userId)

	
	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<div className='card text-center'>
			<div className='pt-3' style={style}>
				Valence Score
			</div>
			<div className='card-body'>
				<ReactFC {...dataSource} />
			</div>
		</div>
	);
};

export default ValenceScore;
