import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import "./app.css";

import {
	getNotesOfThePage, syncNotes, deleteNote
} from "../../api/Notes";

const CORLORS = ["#ffe1b4", "#FFF9D5", "#A5DEC8", "#FFF"];

const Notes = (parentRef) => {
	const [cards, setCards] = useState({});
	const [isSynced, setIsSynced] = useState(true);
	const [editMode, setEditMode] = useState({ key: "", w: 0, h: 0 });
	const [dragging, setDragging] = useState({ key: "", x: 0, y: 0 });

	useEffect(() => {
		const path = window.location.href;
		getNotesOfThePage(path)
			.then((response) => {
				// console.log(response);
				setCards(response.notes);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		if (!isSynced) {
			sysncToCloud()
			setIsSynced(true)
		}
	}, [isSynced]);

	// const update = (key, card) => setCards({ ...cards, [key]: card });

	const sysncToCloud = () => {
		const path = window.location.pathname + window.location.search;
		const url = new URL(window.location.href);
		const params = url.searchParams;
		const movieId = ('movieId' in params) ? params.get('movieId') : "";
		const userId = ('userId' in params) ? params.get('userId') : "";

		// console.log(cards);

		syncNotes(cards, path, movieId, userId)
			.then((response) => {
				console.log(response)
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const update = (key, card) => {
		setCards({ ...cards, [key]: card })
	};

	const removeKey = (obj, deleteKey) => {
		let clone = Object.assign({}, obj);
		delete clone[deleteKey];
		return clone;
	  }

	const remove = (key) => {
		const cardsAfeterDelete = removeKey(cards, key)
		setCards(cardsAfeterDelete)

		deleteNote(key)
			.then((response) => {
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const add = () => {
		const newPostKey = uuidv4();
		const t = "text here";
		const x = window.scrollX + Math.floor(Math.random() * (200 - 80) + 80);
		const y = window.scrollY + Math.floor(Math.random() * (200 - 80) + 80);
		const c = CORLORS[3];

		setCards({
			...cards,
			[newPostKey]: {
				t: t,
				x: x / parentRef.parentRef.current.offsetWidth,
				y: y,
				c: c,
			}
		})
	};

	const style = {
		margin: 0,
		top: 'auto',
		right: 20,
		bottom: 20,
		left: 'auto',
		position: 'fixed',
	};

	// console.log(parentRef)
	return (
		<div
			onDragEnd={(e) => {

				if (!dragging || !cards) return;
				console.log(e.pageX)
				console.log(parentRef.parentRef.current.offsetLeft)
				console.log(dragging.x)
				console.log(parentRef.parentRef.current.offsetWidth)
				console.log(((e.pageX - parentRef.parentRef.current.offsetLeft) - dragging.x) / parentRef.parentRef.current.offsetWidth)
				update(dragging.key, {
					...cards[dragging.key],
					x: ((e.pageX - parentRef.parentRef.current.offsetLeft) - dragging.x) / parentRef.parentRef.current.offsetWidth,
					y: (e.pageY - parentRef.parentRef.current.offsetTop) - dragging.y
				});
				setIsSynced(false)
			}}
		>
			{Object.keys(cards).map((key) => (
				
				<div
					key={key}
					style={{
						position: "absolute",
						top: cards[key].y + "px",
						left: (cards[key].x * parentRef.parentRef.current.offsetWidth) + "px",
						background: cards[key].c,
						zIndex: 10000000
					}}
					draggable={true}
					className="Card"
					// onDragEnd={dropNote}
					onDragStart={(e) => {
						
						setDragging({
							key,
							x: (e.pageX - parentRef.parentRef.current.offsetLeft) - cards[key].x * parentRef.parentRef.current.offsetWidth,
							y: (e.pageY - parentRef.parentRef.current.offsetTop) - cards[key].y
						})
					}}
				>
					<button className="DeleteBtn" onClick={() => remove(key)}>
						×
					</button>
					<div className="ColorSelector">
						{CORLORS.map((c, i) => (
							<div
								key={c}
								className="ColorCircle"
								onClick={() => {
									console.log(c)
									update(key, { ...cards[key], c: CORLORS[i] });
									setIsSynced(false)
								}}
								style={{ background: c }}
							/>
						))}
					</div>
					{editMode.key === key ? (
						<textarea
							className="EditableText"
							style={{ width: editMode.w, height: editMode.h }}
							onBlur={(e) => {
								// console.log(e)
								setEditMode({ key: "", w: 0, h: 0 })
								setIsSynced(false)
							}}

							onChange={(e) => update(key, { ...cards[key], t: e.target.value })}
							defaultValue={cards[key].t}
							autoFocus
							onFocus={(e) => e.target.select()}
						/>
					) : (
						<pre className="Text" onClick={(e) =>
							setEditMode({
								key,
								w: e.currentTarget.clientWidth,
								h: e.currentTarget.clientHeight,
							})}>{cards[key].t}
						</pre>
					)}
				</div>
			))}

			< div style={style} >
				<button className="AddCard" onClick={() => add()}>
					+ ADD NOTE
				</button>
			</div>

		</div>
	);
};

export default Notes;