import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTrash,
  faEdit,
  faPlay,
  faArrowAltCircleUp,
  faRedo,
  faUndo,
  faAngleDown,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";

import { useSelector, useDispatch } from "react-redux";
import { transcriptChart } from "../../store/search/searchAction";
import { getTranscriptComments } from "../../api/TranscriptComment";
import share from "../../assets/share.png";

const ColorButton = styled(Button)(({ theme }) => ({
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#C7C7C9",
  "&:hover": {
    backgroundColor: "#F7CF6D",
  },
}));

const columns = [
  { id: "timestamp", label: "Timestamp", minWidth: "3rem" },
  { id: "participantName", label: "Participant Name", minWidth: "3rem" },
  { id: "transcript", label: "Transcript", minWidth: "5rem" },
  { id: "comment", label: "Comment", minWidth: "3rem" },
  { id: "share", label: "Share", minWidth: "1rem" },
];

const iconStyle = {
  fontSize: 20,
  color: "grey",
};

export default function TranscriptTable({
  player,
  currData,
  setOpen,
  seekToTimestamp,
  sceneTimestamp,
  setSceneTimestamp,
  scrollToTop,
  tableid,
  movieId,
  setAddTagPopupOpen,
  commentTimestamp,
  setNewTimestamp,
  comments,
  playing,
  openShareViaEmailPopup,
}) {
  const [curTime, setCurTime] = useState(0);
  // console.log(curTime);

  useEffect(async () => {
    // console.log(player.current)
    if (player === null || player.current === null) {
      return;
    }
    // console.log("calculating");

    const intervalId = setInterval(() => {
      // console.log("calculating");

      if (player === null || player.current === null) {
        return;
      }

      const temp = Math.floor(player.current.getCurrentTime());
      setCurTime(Math.floor(temp / 30) * 30);
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [player.current]);

  let bgColor = "";

  const [filter, setFilter] = useState("");

  const dispatch = useDispatch();

  const data = useSelector((state) => {
    return state.searchReducer.transcript;
  });

  // console.log(data);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const hmsToSeconds = (e) => {
    //console.log(e);
    var h = Number(e.split(":")[0]);
    var m = Number(e.split(":")[1]);
    var s = Number(e.split(":")[2]);

    return h * 3600 + m * 60 + s;
  };

  // const [comments, setComments] = useState([]);
  // console.log(comments);
  // useEffect(async () => {
  //   handleGetComment();
  // }, []);

  // const handleGetComment = async () => {
  //   const temp = await getTranscriptComments(movieId);
  //   console.log(temp);
  //   setComments(temp);
  //   console.log(comments);
  // };
  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: "yellow" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  useEffect(() => {
    dispatch(
      transcriptChart(
        data.map((e) => {
          var count = 0;

          if (comments !== [] && filter !== "") {
            const tempComments = comments.filter((field) => {
              // console.log(e)
              return Number(field.resource_id.split("_")[1]) === e.time;
            });
            // console.log(count);
            // console.log(tempComments);
            for (var i = 0; i < tempComments.length; i++) {
              if (tempComments[i].comment.includes(filter)) {
                count = count + 1;
              }
            }
            // console.log(count);
            // console.log(e.sentence.split(filter).length - 1);
            count = e.sentence.split(filter).length - 1 + count;
          }

          // console.log(count);

          // console.log((e.sentence.match(`/${filter}/g`) || []).length);
          if (filter === null) {
            return { label: e.time, value: 0 };
          } else {
            return {
              label: e.time,
              value: count,
            };
          }
        })
      )
    );
  }, [data, filter]);

  const muiTableData = data
    .filter((e) => {
      // console.log(comments);
      if (filter === null) {
        return [];
      }
      if (e.sentence !== null && e.sentence.includes(filter)) {
        return true;
      }
      if (comments === []) {
        return false;
      }
      const tempComments = comments.filter((field) => {
        // console.log(e);
        return Number(field.resource_id.split("_")[1]) === e.time;
      });
      // console.log(tempComments);
      for (var i = 0; i < tempComments.length; i++) {
        if (tempComments[i].comment.includes(filter)) {
          return true;
        }
      }
    })
    .map((fields) => {
      // console.log(fields.comment);
      return {
        timestamp: (
          <Button
            variant="outlined"
            onClick={() => {
              seekToTimestamp(fields.time);
              setSceneTimestamp(fields.time);
              scrollToTop();
            }}
            size="small"
          >
            {secondsToHms(fields.time)}
          </Button>
        ),
        participantName: (
          <span
            style={{
              backgroundColor: bgColor,
              cursor: "pointer",
            }}
            onClick={() => {
              seekToTimestamp(fields.time);
              setSceneTimestamp(fields.time);
              scrollToTop();
            }}
          >
            {fields.participant_name ?? 'ALL'}
          </span>
        ),
        transcript: (
          <span
            style={{
              backgroundColor: bgColor,
              // margin: "2px",
              padding: "4px 10px",
              // borderRadius: "50px",
              cursor: "pointer",
              // color: 'white',
            }}
            onClick={() => {
              seekToTimestamp(fields.time);
              setSceneTimestamp(fields.time);
              scrollToTop();
            }}
          >
            {getHighlightedText(fields.sentence, filter)}
            {/* {fields.sentence} */}
          </span>
        ),
        comment: (
          <div>
            {comments
              .filter((e) => {
                return Number(e.resource_id.split("_")[1]) === fields.time;
              })
              .map((e) => {
                // console.log(e);
                return (
                  <div>
                    <IconButton
                      onClick={() => {
                        currData.current = e;
                        setOpen(true);
                      }}
                      size="small"
                      // sx={{ textAlign: "center" }}
                    >
                      <FontAwesomeIcon style={iconStyle} icon={faEdit} />
                    </IconButton>
                    {getHighlightedText(e.comment, filter)}
                    {/* {e.comment} */}
                  </div>
                );
              })}
            <IconButton
              onClick={() => {
                commentTimestamp.current = fields.time;
                setAddTagPopupOpen(true);
              }}
              size="small"
              // sx={{ textAlign: "center" }}
            >
              <FontAwesomeIcon style={iconStyle} icon={faPlusSquare} />
            </IconButton>
          </div>
        ),
        share: (
          <div
            className="transcripts-detail-table-share-button"
            onClick={() => {
              const filtered_comments = comments.filter((e) => {
                return Number(e.resource_id.split("_")[1]) === fields.time;
              });
              const timeInSeconds = secondsToHms(fields.time);
              const time = fields.time;
              const comments_arr = [];
              filtered_comments.forEach((element) => {
                comments_arr.push(element["comment"]);
              });
              const data = {
                timeInSeconds: timeInSeconds,
                time: time,
                comments: comments_arr,
                transcript: fields.sentence,
              };
              openShareViaEmailPopup(data);
            }}
          >
            <img src={share} alt="share"></img>
          </div>
        ),
      };
    });

  const listRef = useRef([]);

  useEffect(() => {
    if (
      listRef.current[curTime] !== undefined &&
      listRef.current[curTime] !== null
    ) {
      listRef.current[curTime].focus();
    }
  }, [curTime]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "0.25rem",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          id="outlined-basic"
          label="Filter"
          variant="outlined"
          sx={{ marginBottom: "1rem", width: "100%" }}
          size="small"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <p style={{ whiteSpace: "nowrap", marginLeft: "1rem" }}>
          Hit count: {filter !== "" ? muiTableData.length : 0}
        </p>
      </div>
      <Paper
        sx={{
          // width: "100%",
          overflow: "hidden",
          height: "calc(100vh - 3rem - 5rem)",
        }}
      >
        <TableContainer sx={{ maxHeight: "100%", overflowX: "auto" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ overflowX: "scroll" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    // key={column.id}
                    align={column.align}
                    style={{ maxWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {muiTableData
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  // console.log(row)
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      // key={row.code}
                      selected={
                        curTime === hmsToSeconds(row.timestamp.props.children)
                          ? true
                          : false
                      }
                      ref={(ref) =>
                        (listRef.current[
                          hmsToSeconds(row.timestamp.props.children)
                        ] = ref)
                      }
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            // key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.minWidth,
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
