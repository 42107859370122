import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";

import { fetchMeetingMetrics } from "../../../store/meetingMetrics/meetingMetricsEffect";

import { getMovies } from "../../../api/Movies";

import { getUsers } from "../../../api/Users";

import MaterialTable from "material-table";
import Heading from "../../../components/Heading";
import Card from "../../../components/Card";
import { Paper } from "@material-ui/core";
import FilterButtonIcon from "../../../assets/filter_button.svg";
import FilterButtonVector from "../../../assets/FilterButtonVector.svg";

import tableIcons from "../../../assets/MaterialTableIcons";
import {
  dataset,
  options,
  columns,
  detailPanel,
  optionsFilteringEnabled,
} from "./helper";

import Loading from "../../../components/Loading";
import Notes from "../../Notes";
import "./Users.css";

const Movies = () => {
  const dispatch = useDispatch();
  const [usersObj, setUsersObj] = useState([]);
  const [filteringEnabled, setFilteringEnabled] = useState(false);

  useEffect(() => {
    getUsers()
      .then((response) => {
        setUsersObj(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    dispatch(fetchMeetingMetrics());
  }, []);

  const _meetingMetrics = useSelector(
    (state) => state.meetingMetricsReducer.meetingMetrics
  );

  const isMeetingMetricsLoading = useSelector(
    (state) => state.meetingMetricsReducer.loading
  );

  const meetingMetrics =
    _meetingMetrics.metrics && _meetingMetrics.metrics.length != 0
      ? _meetingMetrics.metrics
      : [];

  const users = usersObj.length != 0 ? usersObj.users : [];

  // console.log(usersObj)
  // console.log(users)

  const data = dataset(users, meetingMetrics);

  const inputRef = useRef(null);

  // console.log(inputRef)

  return (
    <div
      className="col-md-10 p-4 mt-5"
      style={{ position: "relative" }}
      onDragOver={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      ref={inputRef}
    >
      {/* <div className="mx-2"> */}
      <div className="user-container-root">
        <div className="user-section-heading-row ">
          <span className="section-title-text">Users List</span>
          <div
            onClick={() => {
              setFilteringEnabled((prev) => !prev);
            }}
            className={
              filteringEnabled
                ? "filter-button-div-enabled"
                : "filter-button-div"
            }
          >
            <img src={FilterButtonVector} width={20} />
          </div>
        </div>
        {
          isMeetingMetricsLoading ? (
            <Loading />
          ) : (
            <MaterialTable
              title=""
              icons={tableIcons}
              columns={columns}
              data={data}
              options={filteringEnabled ? optionsFilteringEnabled : options}
              detailPanel={detailPanel}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
            />
          )}
      </div>

      <Notes parentRef={inputRef} />
    </div>
  );
};

export default Movies;
