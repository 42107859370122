import React, { useState, useEffect } from "react";
import "./NotificationConfig.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import "./ListAlert.css";
import {
  get_alert_config,
  update_alert_config_status,
  delete_alert_config,
} from "../../api/AlertConfig";
import { MdOutlineDeleteSweep } from "react-icons/md";

export default function ListAlert({ account_id }) {
  // console.log(account_id);

  const [alertsUser, setAlertsUser] = useState([]);
  const [alertsIBY, setAlertsIBY] = useState([]);

  const refactorArray = (rawData) => {
    const res = [];
    const alert_id_set = new Set();
    rawData.forEach((data) => {
      alert_id_set.add(data.alert_def_id);
    });
    let uniqueAlertIdList = [...alert_id_set];
    // console.log(
    //   "alert unique array inside reformatDAta function - ",
    //   uniqueAlertIdList
    // );

    uniqueAlertIdList.forEach((alertId) => {
      let currObj;
      currObj = {
        ...getGeneralParametersForAlert(rawData, alertId),
        details: getAlertDetailsArray(rawData, alertId),
      };
      res.push(currObj);
    });
    // console.log("refactored alert array - ", res);
    return res;
  };

  const getGeneralParametersForAlert = (rawData, alert_id) => {
    let res = {
      error: "error in getting general parameters from the alert list",
    };
    let size = rawData.length;
    for (let i = 0; i < size; i++) {
      let data = rawData[i];
      if (data.alert_def_id === alert_id) {
        // console.log(
        //   " true from cheking equality of - ",
        //   data.alert_def_id,
        //   " and - ",
        //   alert_id
        // );
        res = {
          alert_def_id: data.alert_def_id,
          alert_name: data.alert_name,
          alert_description: data.alert_description,
          conditions_rel: data.conditions_rel,
          is_active: data.is_active,

          alert_condition: data.alert_condition,

          role_name: data.role_name,
        };
      }
    }
    return res;
  };

  const getAlertDetailsArray = (rawData, alert_id) => {
    let res = [];
    rawData.forEach((data) => {
      if (data.alert_def_id === alert_id) {
        let resEntry = {
          metric: data.metric,
          value1: data.value_1,
          value2: data.value_2,
          alert_condition: data.alert_condition,
        };
        res.push(resEntry);
      }
    });
    return res;
  };

  const updateAlertStatus = async (alert_def_id, is_active) => {
    // console.log(alert_def_id);
    // console.log(is_active);
    const res = await update_alert_config_status(alert_def_id, is_active);
  };

  //   rawData.forEach((data) => {
  //     if (data.alert_def_id === alert_id) {
  //       console.log(
  //         " true from cheking equality of - ",
  //         data.alert_def_id,
  //         " and - ",
  //         alert_id
  //       );
  //       res =  {
  //         alert_def_id: data.alert_def_id,
  //         alert_name: data.alert_name,
  //         alert_description: data.alert_description,
  //         conditions_rel: data.conditions_rel,
  //         is_active: data.is_active,

  //         alert_condition: data.alert_condition,

  //         role_name: data.role_name,
  //       };
  //     }
  //   });
  //   return res;
  // };

  // const getAlertDetailsArray = (rawData, alert_id) => {
  //   let res = [];
  //   rawData.forEach((data) => {
  //     if (data.alert_def_id === alert_id) {
  //       let resEntry = {
  //         metric: data.metric,
  //         value1: data.value_1,
  //         value2: data.value_2,
  //       };
  //       res.push(resEntry);
  //     }
  //   });
  //   return res;
  // };

  useEffect(async () => {
    handleALL();
  }, []);

  const handleALL = async () => {
    const result = await get_alert_config(account_id);
    // console.log(result);
    var userAlerts = [];
    var adminAlerts = [];

    for (var i = 0; i < result.length; i++) {
      if (result[i]["role_name"] === "ADMIN") {
        adminAlerts.push(result[i]);
      } else {
        userAlerts.push(result[i]);
      }
    }

    setAlertsUser(refactorArray(userAlerts));
    // setAlertsUser(refactorArray(sampleInput));
    setAlertsIBY(refactorArray(adminAlerts));
    // setAlertsIBY(refactorArray(sampleInput));
  };

  const displayAlertConfig = (item) => {
    // console.log(item);
    // "alert_def_id": "66e8a27a-5e92-48a4-b696-26550dbaed74", "alert_name": "gdhfjgkghfd", "alert_description": null, "conditions_rel": "AND", "is_active": null, "role_name": "ADMIN"
    return (
      <div className="display-alert-wrapper">
        <p className="display-alert-heading" style={{ display: "flex" }}>
          <p style={{ margin: 0, width: "100%" }}>
            Alert Name: {item.alert_name}{" "}
          </p>
          <MdOutlineDeleteSweep
            style={{
              fontSize: "1.5rem",
              position: "relative",
              right: "1rem",
              cursor: "pointer",
            }}
            onClick={async () => {
              await delete_alert_config(item.alert_def_id);
              await handleALL();
            }}
          />
        </p>
        <p className="display-alert-heading">
          <p style={{ margin: 0, width: "100%" }}>
            Description: {item.alert_description}{" "}
          </p>
        </p>
        <p className="display-alert-desciption">{item.alert_description} </p>
        <div className="display-alert-desciption">
          Relation Condition: {item.conditions_rel}
          {item.details.map((detail) => {
            return (
              <div className="relation-condition-entry">
                <span>{detail.metric.toUpperCase()} : </span>
                <span className="info-text-small">
                  {detail.alert_condition}
                </span>
                <span className="info-text-small">{detail.value1}</span>
                <span
                  className={
                    detail.alert_condition === "in_between"
                      ? "info-text-small"
                      : "info-text-small make-invisible"
                  }
                >
                  {detail.value2}
                </span>
              </div>
            );
          })}
        </div>
        <p>
          {/* {item.is_active} */}
          {item.is_active === 1 ? (
            <Switch
              defaultChecked
              inputProps={{ "aria-label": "controlled" }}
              onChange={(event) => {
                updateAlertStatus(item.alert_def_id, event.target.checked);
              }}
            />
          ) : (
            <Switch
              inputProps={{ "aria-label": "controlled" }}
              onChange={(event) => {
                updateAlertStatus(item.alert_def_id, event.target.checked);
              }}
            />
          )}
        </p>
      </div>
    );
  };

  return (
    <div
      style={
        account_id !== undefined
          ? { display: "flex", flexDirection: "column-reverse" }
          : null
      }
    >
      <div>
        <p>
          Alerts configured by {account_id !== undefined ? "client" : "you"}
        </p>
        {alertsUser.map((item) => {
          return displayAlertConfig(item);
        })}
      </div>
      <hr />
      <div>
        <p>Alerts configured by IBY</p>
        {alertsIBY.map((item) => {
          return displayAlertConfig(item);
        })}
      </div>
    </div>
  );
}
