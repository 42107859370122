import React, { useState } from "react";
import "./MovieSectionCRUD.css";
import {
  deleteMovieSection,
  updateMovieSection,
} from "../../../api/MovieSections";

export const DeleteMovieSection = () => {
  const params = new URLSearchParams(window.location.search);
  const sectionId = params.get("sectionId");
  const sectionName = params.get("sectionName");

  const deleteHandler = async () => {
    try {
      await deleteMovieSection(sectionId);
      window.history.back();
    } catch (err) {
      console.log("error in deleting section - ", err);
    }
  };

  return (
    <div className="section-crud-root">
      <div className="section-crud-container">
        <span className="section-title-text">Section ID: {sectionId}</span>
        <span className="section-title-text">Section Name: {sectionName}</span>
        <div className="section-crud-delete-div">
          <div className="red-big-button" onClick={deleteHandler}>
            DELETE
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpdateMovieSection = () => {
  const params = new URLSearchParams(window.location.search);
  const sectionId = params.get("sectionId");
  const sectionName = params.get("sectionName");
  const [name, setName] = useState(sectionName);
  const handleUpdate = async (sectionId, sectionName) => {
    try {
      await updateMovieSection(sectionId, sectionName);
      window.history.back();
    } catch (err) {
      console.log("error in updating section - ", err);
    }
  };
  return (
    <div className="section-crud-root">
      <div className="section-crud-container">
        <span className="section-title-text">Section ID: {sectionId}</span>
        <span className="section-title-text">Section Name: {sectionName}</span>
        <div className="section-crud-textfield-div">
          <span>Enter New Section Name:</span>
          <input
            type="text"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          ></input>
        </div>

        <div className="section-crud-delete-div">
          <div
            className="yellow-big-button"
            onClick={() => {
              handleUpdate(sectionId, name);
            }}
          >
            UPDATE
          </div>
        </div>
      </div>
    </div>
  );
};
