import React from "react";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import PowerCharts from "fusioncharts/fusioncharts.powercharts";

import widgets from "fusioncharts/fusioncharts.widgets";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import plotData from "./helpler";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme, widgets);

const HealthyGauge = (props) => {
	const dataset = props.dataset;
	const userId = props.userId;

	const dataSource = plotData(dataset, userId);

	return (
    <ReactFC
      type="angulargauge"
      width="100%"
      height="400"
      dataFormat="JSON"
      dataSource={dataSource}
    />
  );
};

export default HealthyGauge;
