import { combineReducers } from "redux";

import faceEmotionsReducer from "./faceEmotions/faceEmotionsReducer";
import appUsagesReducer from "./appUsages/appUsagesReducer";
import usersReducer from "./users/usersReducer";
import zoomUsersReducer from "./zoomUsers/zoomUsersReducer";
import zoomRecordingsReducer from "./zoomRecordings/zoomRecordingsReducer";
import zoomUserReducer from "./zoomUser/zoomUserReducer";
import zoomMeetingReportReducer from "./zoomMeetingReport/zoomMeetingReportReducer";
import zoomMeetingsReducer from "./zoomMeetings/zoomMeetingsReducer";
import activeScoresReducer from "./activeScores/activeScoresReducer"
import engagementScoresReducer from "./engagementScores/engagementScoresReducer"
import moviesReducer from "./movies/moviesReducer"
import detectedFacesReducer from "./detectedFaces/detectedFacesReducer"
import meetingMetricsReducer from "./meetingMetrics/meetingMetricsReducer"
import searchReducer from "./search/searchReducer";
import adminReducer from "./admin/adminReducer";
import benchmarkReducer from "./benchmark/benchmarkReducer";

export default combineReducers({
  faceEmotionsReducer,
  appUsagesReducer,
  usersReducer,
  zoomUsersReducer,
  zoomRecordingsReducer,
  zoomUserReducer,
  zoomMeetingReportReducer,
  zoomMeetingsReducer,
  activeScoresReducer,
  engagementScoresReducer,
  moviesReducer,
  detectedFacesReducer,
  meetingMetricsReducer,
  searchReducer,
  adminReducer,
  benchmarkReducer,
});
