import React from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const BenchmarkScoreInCard = ({ movieScore, benchmarkScore, numberDecimal }) => {
  const benchmarkDiffScore = Math.round(movieScore - benchmarkScore);

  if (benchmarkDiffScore < 0) {
    return (
      <div className="dashboard-permeeting-highlight-score-benchmark-negative-diff">
        <ArrowDropDownIcon sx={{ color: "#FFF", fontSize: "16px" }} />
        <span className="dashboard-permeeting-highlight-score-benchmark-negative-diff-text">
          {Math.abs(benchmarkDiffScore)}
        </span>
      </div>
    );
  } else if (benchmarkDiffScore > 0) {
    return (
      <div className="dashboard-permeeting-highlight-score-benchmark-positive-diff">
        <ArrowDropUpIcon sx={{ color: "#FFF", fontSize: "16px" }} />
        <span className="dashboard-permeeting-highlight-score-benchmark-positive-diff-text">
        {Math.abs(benchmarkDiffScore)}
        </span>
      </div>
    );
  } else if (benchmarkDiffScore === 0) {
    return (
      <div className="dashboard-permeeting-highlight-score-benchmark-positive-diff">
        <ArrowDropUpIcon sx={{ color: "#FFF", fontSize: "16px" }} />
        <span className="dashboard-permeeting-highlight-score-benchmark-positive-diff-text">
        {Math.abs(benchmarkDiffScore)}
        </span>
      </div>
    );
  } else {
    return null;
  }
};

export default BenchmarkScoreInCard;
