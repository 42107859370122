import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	getUser
} from "../../api/Users";

import {
	getMovie
} from "../../api/Movies";

import {
	getTsFaceEmotionsOfMovieOfParticipant,
} from "../../api/FaceEmotions";

import {
	getTsDetectedFacesOfParticipantInMovie
} from "../../api/DetectedFaces";

import {
	getTsDistacesOfParticipantInMovie
} from "../../api/Distances";

import { getMovieMetricsMean, getMovieMetricsSectionsMean } from "../../api/MovieMetrics";

import Heading from "../../components/Heading";
import Info from "../../components/Info";
import ChartSection from "../../components/ChartsPerMeetingAndUser";

const DashboardPerMeetingAndUser = () => {
  const benchmarkSettings = useSelector((state) => {
    return state.benchmarkReducer;
  });

	const [engagementTs, setEngagemenTs] = useState({
		engagementTs: {},
	});

	const [faceEmotionTs, setFaceEmotionTs] = useState({
		faceEmotionTs: { face_emotions: {} },
	});

	const [user, setUser] = useState({
		user: {},
	});

	const [movie, setMovie] = useState({
		movie: {},
	});

	const [detectedFacesTs, setDetectedFacesTs] = useState({
		detectedFacesTs: {},
	});

	const [distancesTs, setDistancesTs] = useState({
		distancesTs: {},
	});

  const [benchmarkMetrics, setBenchmarkMetrics] = useState({});

	// const zoomMeetingId = decodeURI(window.location.search.substring(15));
	const params = new URLSearchParams(window.location.search);
	const movieId = params.get('movieId');
	const userId = params.get('userId');

	useEffect(() => {
		getUser(userId)
			.then((response) => {
				// console.log(response)
				setUser({
					user: response
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		getMovie(movieId)
			.then((response) => {
				// console.log(response)
				setMovie({
					movie: response
				});

			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		getTsFaceEmotionsOfMovieOfParticipant(movieId, userId)
			.then((response) => {
				// console.log(response)
				setFaceEmotionTs({
					faceEmotionTs: response
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		getTsDetectedFacesOfParticipantInMovie(movieId, userId)
			.then((response) => {
				// console.log(response)
				setDetectedFacesTs({
					detectedFacesTs: response
				});

			})
			.catch((err) => {
				console.log(err);
			});
	}, []);


	useEffect(() => {
		getTsDistacesOfParticipantInMovie(movieId, userId)
			.then((response) => {
				// console.log(response)
				setDistancesTs({
					distancesTs: response
				});

			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

  useEffect(() => {
    const fetchMovieMetricsMean = async () => {
      try {
        const result = await getMovieMetricsMean();
        setBenchmarkMetrics(result);
      } catch (e) {}
    }
    const fetchMovieMetricsSectionMean = async (sectionId) => {
      try {
        const result = await getMovieMetricsSectionsMean(sectionId);
        setBenchmarkMetrics(result);
      } catch (e) {}
    }
    if (benchmarkSettings.isSetting && benchmarkSettings.type === "ALL") {
      fetchMovieMetricsMean();
    } else if (benchmarkSettings.isSetting && benchmarkSettings.type === "SECTION") {
      fetchMovieMetricsSectionMean(benchmarkSettings.sectionId);
    }
  }, [benchmarkSettings]);

	return (
		<div className='col-md-10 p-4'>
			<Heading heading='h5'>
				IBY analyzes online communication every seconds.
			</Heading>
			<Info movie={movie.movie} user={user.user}/>
			<ChartSection
				faceEmotionTs={faceEmotionTs}
				engagementTs={engagementTs}
				detectedFacesTs={detectedFacesTs}
				distancesTs={distancesTs}
        isBenchmarkSetting={benchmarkSettings.isSetting}
        benchmarkMetrics={benchmarkMetrics}
				movie={movie}
				user={user.user}
			/>
		</div>
	);
};

export default DashboardPerMeetingAndUser;
