const plotData = (dataset, userId) => {
	let dateFormat = require("dateformat");
	let scoreList = [];
	let labelList = [];

	let movies = {}
	let topics = {}

	// if ("healthy_scores" in scores && scores.healthy_scores.length != 0) {
	// 	for (let i = 0; i < scores.healthy_scores.length; i++) {

	// 		let score = {};
	// 		let label = {};

	// 		label.label = scores.healthy_scores[i]["topic"]
	// 		score.value = scores.healthy_scores[i]["healthy"]
	// 		score.link =  "n-" + window.location.href.split("/").slice(0, 3).join("/") 
	// 		+ "/users/movies/dashboard?movieId=" + scores.healthy_scores[i]["movie_id"] + "&userId=" + userId;
			
	// 		scoreList.push(score);
	// 		labelList.push(label);
	// 	}
	// }

	for (let i = 0; i < dataset.length; i++) {
		const d = dataset[i];

		if (d.user_id != userId) {
			continue
		}

		if (d.movie_id in movies){
			movies[d.movie_id].push(d.healthy);
		} else{
			movies[d.movie_id] = [d.healthy];
			topics[d.movie_id] = d.topic;
		}

	}
	
	Object.keys(movies).forEach(key => {
		let score = {};
		let label = {};

		label.label = topics[key];
		score.value = average(movies[key]);
		score.link =  "n-" + window.location.href.split("/").slice(0, 3).join("/") 
		+ "/users/movies/dashboard?movieId=" + key + "&userId=" + userId;
		
		scoreList.push(score);
		labelList.push(label);
	});

	const dataSource = {
		chart: {
			// subcaption: "Engagement Score",
			yaxisname: "Score",
			numvisibleplot: "70",
			labeldisplay: "auto",
			theme: "fusion",
		},
		categories: [
			{
				category: labelList,
			},
		],
		dataset: [
			{
				// seriesname: "Healthy score",
				data: scoreList,
			},
		],
	};

	return dataSource;
};

export default plotData;

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 100) / 100;
};
