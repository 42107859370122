const plotData = (data, userObj) => {
  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");

  let users = new Set();
  let users_emotion = {};

  let users_ratio = {};

  // console.log(userObj)

  let users_name = {};
  let thumbnail = {};

  if ("users" in userObj) {
    userObj.users.forEach((a) => {
      thumbnail[a["user_id"]] = a["url"];

      if (a["first_name"] === null && a["last_name"] === null) {
        return;
      }
      users_name[a["user_id"]] = a["first_name"] + a["last_name"];
    });
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].movie_id != movieId || data[i].user_id == "all") {
      continue;
    }

    users.add(data[i].user_id);
  }

  users.forEach((a) => {
    users_emotion[a] = {};
    users_emotion[a]["angry"] = 0;
    users_emotion[a]["disgust"] = 0;
    users_emotion[a]["fear"] = 0;
    users_emotion[a]["happy"] = 0;
    users_emotion[a]["neutral"] = 0;
    users_emotion[a]["sad"] = 0;
    users_emotion[a]["surprise"] = 0;
  });
  // for (let i = 0; i < users.length; i++) {
  //   users_emotion[users[i]]={};
  // }
  for (let i = 0; i < data.length; i++) {
    if (data[i].movie_id != movieId || data[i].user_id == "all") {
      continue;
    }

    users_emotion[data[i].user_id]["angry"] += data[i].angry;
    users_emotion[data[i].user_id]["disgust"] += data[i].disgust;
    users_emotion[data[i].user_id]["fear"] += data[i].fear;
    users_emotion[data[i].user_id]["happy"] += data[i].happy;
    users_emotion[data[i].user_id]["neutral"] += data[i].neutral;
    users_emotion[data[i].user_id]["sad"] += data[i].sad;
    users_emotion[data[i].user_id]["surprise"] += data[i].surprise;

    users_ratio[data[i].user_id] = data[i].ratio;
  }

  // console.log(users_emotion)

  let rows = parseInt(users.size / 4);
  let cols = 4;
  if (users.size % 4 != 0) {
    rows += 1;
  }
  let dataset = [];
  let cnt = 0;
  let r = 1;
  let c = 1;
  users.forEach((u) => {
    let data = {};
    let emotion = "happy";
    if (
      users_emotion[u]["angry"] > users_emotion[u]["disgust"] &&
      users_emotion[u]["angry"] > users_emotion[u]["fear"] &&
      users_emotion[u]["angry"] > users_emotion[u]["happy"] &&
      users_emotion[u]["angry"] > users_emotion[u]["neutral"] &&
      users_emotion[u]["angry"] > users_emotion[u]["sad"] &&
      users_emotion[u]["angry"] > users_emotion[u]["surprise"]
    ) {
      emotion = "angry";
    } else if (
      users_emotion[u]["disgust"] > users_emotion[u]["angry"] &&
      users_emotion[u]["disgust"] > users_emotion[u]["fear"] &&
      users_emotion[u]["disgust"] > users_emotion[u]["happy"] &&
      users_emotion[u]["disgust"] > users_emotion[u]["neutral"] &&
      users_emotion[u]["disgust"] > users_emotion[u]["sad"] &&
      users_emotion[u]["disgust"] > users_emotion[u]["surprise"]
    ) {
      emotion = "disgust";
    } else if (
      users_emotion[u]["fear"] > users_emotion[u]["disgust"] &&
      users_emotion[u]["fear"] > users_emotion[u]["angry"] &&
      users_emotion[u]["fear"] > users_emotion[u]["happy"] &&
      users_emotion[u]["fear"] > users_emotion[u]["neutral"] &&
      users_emotion[u]["fear"] > users_emotion[u]["sad"] &&
      users_emotion[u]["fear"] > users_emotion[u]["surprise"]
    ) {
      emotion = "fear";
    } else if (
      users_emotion[u]["happy"] > users_emotion[u]["disgust"] &&
      users_emotion[u]["happy"] > users_emotion[u]["fear"] &&
      users_emotion[u]["happy"] > users_emotion[u]["angry"] &&
      users_emotion[u]["happy"] > users_emotion[u]["neutral"] &&
      users_emotion[u]["happy"] > users_emotion[u]["sad"] &&
      users_emotion[u]["happy"] > users_emotion[u]["surprise"]
    ) {
      emotion = "happy";
    } else if (
      users_emotion[u]["neutral"] > users_emotion[u]["disgust"] &&
      users_emotion[u]["neutral"] > users_emotion[u]["fear"] &&
      users_emotion[u]["neutral"] > users_emotion[u]["happy"] &&
      users_emotion[u]["neutral"] > users_emotion[u]["angry"] &&
      users_emotion[u]["neutral"] > users_emotion[u]["sad"] &&
      users_emotion[u]["neutral"] > users_emotion[u]["surprise"]
    ) {
      emotion = "neutral";
    } else if (
      users_emotion[u]["sad"] > users_emotion[u]["disgust"] &&
      users_emotion[u]["sad"] > users_emotion[u]["fear"] &&
      users_emotion[u]["sad"] > users_emotion[u]["happy"] &&
      users_emotion[u]["sad"] > users_emotion[u]["neutral"] &&
      users_emotion[u]["sad"] > users_emotion[u]["angry"] &&
      users_emotion[u]["sad"] > users_emotion[u]["surprise"]
    ) {
      emotion = "sad";
    } else if (
      users_emotion[u]["surprise"] > users_emotion[u]["disgust"] &&
      users_emotion[u]["surprise"] > users_emotion[u]["fear"] &&
      users_emotion[u]["surprise"] > users_emotion[u]["happy"] &&
      users_emotion[u]["surprise"] > users_emotion[u]["neutral"] &&
      users_emotion[u]["surprise"] > users_emotion[u]["sad"] &&
      users_emotion[u]["surprise"] > users_emotion[u]["angry"]
    ) {
      emotion = "surprise";
    }

    ++cnt;

    data = {
      rowid: r.toString(),
      columnid: (c++).toString(),
      displayvalue: u in users_name ? users_name[u] : "User" + cnt,

      //ratioの値がない場合は、alphaを0に設定している
      alpha:
        u in users_ratio
          ? users_ratio[u] * 100 * users.size > 100
            ? 100
            : users_ratio[u] * 100 * users.size
          : 0,

      value: thumbnail[u],
      brLabel: u,
      colorrangelabel: emotion,
      link:
        "n-" +
        window.location.href.split("/").slice(0, 3).join("/") +
        "/users/movies/dashboard?userId=" +
        u +
        "&movieId=" +
        movieId,
    };

    if (cnt % 4 == 0) {
      r++;
      c = 1;
    }

    dataset.push(data);
  });

  let rows_data = [];
  let cols_data = [];
  for (let i = 0; i < rows; i++) {
    let d = {
      id: (i + 1).toString(),
    };
    rows_data.push(d);
  }

  for (let i = 0; i < cols; i++) {
    let d = {
      id: (i + 1).toString(),
    };
    cols_data.push(d);
  }

  const dataSource = {
    colorrange: {
      gradient: "0",
      color: [
        {
          code: "#f48f57",
          minvalue: "0",
          maxvalue: "50",
          label: "happy",
        },
        {
          code: "#ffcd89",
          minvalue: "0",
          maxvalue: "50",
          label: "neutral",
        },
        {
          code: "#e66b9d",
          minvalue: "50",
          maxvalue: "70",
          label: "surprise",
        },
        {
          code: "#934e9f",
          minvalue: "70",
          maxvalue: "85",
          label: "disgust",
        },
        {
          code: "#45379b",
          minvalue: "70",
          maxvalue: "85",
          label: "angry",
        },
        {
          code: "#1d5fbf",
          minvalue: "70",
          maxvalue: "85",
          label: "sad",
        },
        {
          code: "#2097b7",
          minvalue: "70",
          maxvalue: "85",
          label: "fear",
        },
      ],
    },
    dataset: [
      {
        data: dataset,
      },
    ],
    columns: {
      column: cols_data,
    },
    rows: {
      row: rows_data,
    },
    chart: {
      animation: "1",
      theme: "fusion",
      caption: "Users",
      subcaption: " Emotion Map",
      showLabels: "0",
      showvalues: "1",
      mapbycategory: "1",
      valueFontColor: "#ffffff",
      plottooltext: "<img src=$value />",
    },
  };
  return dataSource;
};

export default plotData;
