import React from "react";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import Loading from "../../Loading";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const AudioFeatures = (props) => {
  const audioFeaturesUrl = props.audioFeaturesUrl;
  const isLoaded = props.isLoaded;

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Audio Features</span>
      <div className="row">
        <div className="bar-histogram-chart-column-card">
        {
          isLoaded === "Not Loaded"
          ? <Loading />
          :
            <div className="audio-features-chart-div">
              <img src={audioFeaturesUrl?.url_pitch} alt="Voice Pitch" width={520} />
              <img src={audioFeaturesUrl?.url_volume} alt="Voice Volume" width={520} />
            </div>
        }
        </div>
      </div>
    </section>
  );
};

export default AudioFeatures;