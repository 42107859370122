import React from "react";
import "./Dashboard.css";
import { useState } from "react";
const DashboardPerSection = () => {
  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("sectionId");
  // const type = params.get("type");
  const [selectedMenu, setSelectedMenu] = useState(0);
  return (
    <div className="section-dashboard-root">
      <div className="section-dashboard-selector-root">
        <div
          className={
            selectedMenu === 0
              ? "section-dashboard-selector-element-selected"
              : "section-dashboard-selector-element"
          }
          onClick={() => {
            setSelectedMenu(0);
          }}
        >
          <span>Overview</span>
        </div>
        <div
          className={
            selectedMenu === 1
              ? "section-dashboard-selector-element-selected"
              : "section-dashboard-selector-element"
          }
          onClick={() => {
            setSelectedMenu(1);
          }}
        >
          <span>Individual</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardPerSection;
