import React, { useEffect, useState } from "react";
import "./EmailSettings.css";

import { Switch } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import { getEmailSettings, updateEmailSettings } from "../../api/EmailSettings";
import Loading from "../../components/Loading";

const EmailSettings = () => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [email, setEmail] = useState("");
  //const [previousEmail, setPreviousEmail] = useState("");
  const [dailyHighlightsActive, setDailyHighlightsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const enableInputBox = () => {
    setIsEditEnabled((o) => !o);
  };

  const disableInputBox = () => {
    getEmailSettings()
      .then((res) => {
        setEmail(res.email_id);
      })
      .catch((err) => {
        console.log(err);
      });

    setIsEditEnabled(false);
  };

  const updateEmail = () => {
    setIsEditEnabled(false);
    updateEmailSettings(email, dailyHighlightsActive)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const updateDailyHighlightsChange = (event) => {
    setDailyHighlightsActive(event.target.checked);
    const res = event.target.checked ? 1 : 0;
    updateEmailSettings(email, res)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmailSettings()
      .then((res) => {
        setEmail(res.email_id);
        setDailyHighlightsActive(res.is_daily_highlights_active ? true : false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <div className="email-settings-loading">
      <Loading color="#f7cf6d" size={40} />
    </div>
  ) : (
    <div className="email-settings">
      <div className="email-settings-heading">Email Settings</div>
      <div className="email-settings-email">
        <div>
          <div className="email-settings-email-heading">Enter Email</div>
          <div className="email-settings-email-sub-heading">
            The email address will be used to send emails
          </div>
        </div>
        <div>
          <input
            className={
              isEditEnabled
                ? "email-settings-email-input-enabled"
                : "email-settings-email-input"
            }
            type="email"
            placeholder="Enter your Email Address"
            value={email}
            onChange={updateEmailChange}
            disabled={isEditEnabled ? "" : "disabled"}
          />
          {isEditEnabled ? (
            <div className="email-settings-email-done-buttons">
              <CancelIcon
                className="email-settings-email-cancel-button"
                onClick={disableInputBox}
              />
              <DoneIcon
                className="email-settings-email-done-button"
                onClick={updateEmail}
              />
            </div>
          ) : (
            <div className="email-settings-email-done-buttons">
              <EditIcon
                className="email-settings-email-edit-button"
                onClick={enableInputBox}
              />
            </div>
          )}
        </div>
      </div>
      <div className="email-settings-daily-highlights">
        <div className="email-settings-daily-highlights-show">
          <div>
            <div className="email-settings-daily-highlights-heading">
              Your Daily highlights
            </div>
            <div className="email-settings-daily-highlights-sub-heading">
              The report will be send to your email
            </div>
          </div>
          <div className="email-settings-daily-highlights-toggle">
            <div>No</div>
            <Switch
              checked={dailyHighlightsActive}
              onChange={updateDailyHighlightsChange}
            />
            <div>Yes</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSettings;
