import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import S3Video from "../S3Video";
import { ArrowUpSquare } from "react-bootstrap-icons";

const iconStyle = {
	fontSize: 25,
	color: "grey",
};

const actionIconStyle = {
	fontSize: 18,
	color: "grey",
};

// const tagStyle = {
// 	borderRadius: "10px",
// 	border: "2px solid #e2c9b4",
// 	backgroundColor: "#e2c9b4",
// 	color: "#71625a",
// 	padding: "2px",
// };

const cellStyle = {
	good: {
		color: "#62B58F",
		fontWeight: "bold",
		fontSize: 36
	},

	normal: {
		fontSize: 24
	},

	bad: {
		color: "#F2726F",
		fontWeight: "bold",
		fontSize: 36
	},

}

export const columns = [
	{
		title: "ID",
		field: "movieId",
		render: (rowData) => {
			return (
				rowData.movieId.slice(0, 8) + "..."
			);
		}
	},
	{
		title: "TOPIC",
		field: "topic",
		defaultSort: 'asc',
		headerStyle: {
			minWidth: 300,
		},
	},
	{ title: "UPLOAD TIME", field: "uploadTime", type: "date", format: 'yyyy/MM/dd' },
	{
		title: "ACTIVE",
		field: "active",
		type: "numeric",
		cellStyle: (rowData) => {
			if (rowData >= 10 && rowData < 20) {
				return cellStyle.normal
			} else if (rowData >= 20) {
				return cellStyle.good
			} else {
				return cellStyle.bad
			}
		},
	},
	{
		title: "ENGAGEMENT",
		field: "engagement",
		type: "numeric",
		cellStyle: (rowData) => {
			if (rowData >= 0.25 && rowData < 0.75) {
				return cellStyle.normal
			} else if (rowData >= 0.75) {
				return cellStyle.good
			} else {
				return cellStyle.bad
			}
		}
	},
	{
		title: "HEALTHY",
		field: "healthy",
		type: "numeric",
		cellStyle: (rowData) => {
			if (rowData >= 2 && rowData < 3.0) {
				return cellStyle.normal
			} else if (rowData >= 3) {
				return cellStyle.good
			} else {
				return cellStyle.bad
			}
		}
	},
	{
		title: "TEXT NEGAPOSI",
		field: "textNegaposi",
		type: "numeric",
		cellStyle: (rowData) => {
			if (rowData >= 0 && rowData < 0.5) {
				return cellStyle.normal
			} else if (rowData >= 0.5) {
				return cellStyle.good
			} else {
				return cellStyle.bad
			}
		}
	},
	{
		title: "SPEECH SPEED",
		field: "speechSpeed",
		type: "numeric",
		cellStyle: (rowData) => {
			if (rowData >= 170 && rowData < 250) {
				return cellStyle.normal
			} else if (rowData >= 250) {
				return cellStyle.good
			} else {
				return cellStyle.bad
			}
		}
	},
	{
		title: "DASHBOAD",
		field: "movieId",
		cellStyle: { textAlign: 'center' },
		render: (rowData) => {
			return (
				<Link
					to={
						"/movies/dashboard?movieId=" + rowData.movieId
					}>
					<FontAwesomeIcon
						style={iconStyle}
						icon={faChartLine}
					/>
				</Link>
			)
		},
	},
	{
		title: "ACTIONS",
		field: "movieId",
		cellStyle: { textAlign: 'center' },
		render: (rowData) => {
			return (
				<div className='row'>
					<Link
						className='col-md-6'
						to={
							"/movies/update?movieId=" + rowData.movieId
						}>
						<FontAwesomeIcon
							style={actionIconStyle}
							icon={faEdit}
						/>
					</Link>
					<Link
						className='col-md-6'
						to={
							"/movies/delete?movieId=" + rowData.movieId
						}>
						<FontAwesomeIcon
							style={actionIconStyle}
							icon={faTrash}
						/>
					</Link>
				</div>
			)
		},
	},
];

export const options = {
	headerStyle: {
		fontSize: 16,
		fontWeight: "bold",
	},
	filtering: true,
	selection: true,
	pageSize: 10,
}

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Number(Math.round(((sum) / arr.length) * 10) / 10);
};

export const dataset = (_movies, data) => {

	// console.log(data)

	let movies = {};

	for (let i = 0; i < _movies.length; i++) {

		const id = _movies[i]["movie_id"];
		movies[id] = {}
		movies[id]["topic"] = _movies[i]["topic"];
		movies[id]["upload_time"] = _movies[i]["upload_time"];
		movies[id]["url"] = _movies[i]["url"];

		movies[id]["active"] = [];
		movies[id]["engagement"] = [];
		movies[id]["healthy"] = [];

		movies[id]["text_negaposi"] = [];
		movies[id]["speech_speed"] = [];
	}

	for (let i = 0; i < data.length; i++) {

		if (data[i].movie_id in movies == false) {
			continue
		}

		movies[data[i].movie_id]["active"].push(data[i].active);
		movies[data[i].movie_id]["engagement"].push((data[i].ratio_distance === null) ? data[i].ratio : data[i].ratio_distance);
		movies[data[i].movie_id]["healthy"].push(data[i].healthy);

		if (data[i].text_negaposi !== null) {
			movies[data[i].movie_id]["text_negaposi"].push(data[i].text_negaposi);
		}

		if (data[i].speech_speed !== null) {
			movies[data[i].movie_id]["speech_speed"].push(data[i].speech_speed);
		}

	}

	const dataset = []
	for (var key in movies) {
		const row = {}
		row["movieId"] = key
		row["topic"] = movies[key]["topic"]
		row["uploadTime"] = movies[key]["upload_time"]
		row["imageUrl"] = movies[key]["url"]
		row["active"] = average(movies[key]["active"])
		row["engagement"] = average(movies[key]["engagement"])
		row["healthy"] = average(movies[key]["healthy"])

		// participant id is only all, So average is not needed
		row["textNegaposi"] = average(movies[key]["text_negaposi"])
		row["speechSpeed"] = average(movies[key]["speech_speed"])

		dataset.push(row)
	}

	// console.log(dataset)

	return dataset;
};

export const detailPanel = [
	{
		tooltip: 'Show Highlights',
		render: rowData => {
			return (
				<div className='card text-center my-3'>
					<div className='card-body'>
						<div className="row">
							<div className="col-md-4">
								<S3Video movieId={rowData.movieId} type="happy" />
							</div>
							<div className="col-md-4">
								<S3Video movieId={rowData.movieId} type="neutral" />
							</div>
							<div className="col-md-4">
								<S3Video movieId={rowData.movieId} type="negative" />
							</div>
						</div>
					</div>
				</div>
			)
		},
	}
]
