import React from "react";
import { calculateSummary } from "../ActiveScores/ScoreCard/helper";
import { calculateSummary as calculateSummaryEngagement } from "../DetectedFaces/ScoreCard/helper";
import { calculateSummary as calculateSummaryHealth } from "../HealthyScores/ScoreCard/helper";
import "./index.css";
const UserScoreCards = ({ dataset, userId }) => {
  const summary = calculateSummary(dataset, userId);
  const summary_engagement = calculateSummaryEngagement(dataset, userId);
  const summary_health = calculateSummaryHealth(dataset, userId);
  console.log("summery - ", summary);
  const weeklyChange = summary.thisWeek - summary.lastWeek;
  const weeklyChangeEngagement =
    summary_engagement.thisWeek - summary_engagement.lastWeek;
  const weeklyChangeHealth =
    summary_engagement.thisWeek - summary_health.lastWeek;

  return (
    <div className="user-score-cards">
      <div className="user-analytics-overview-card">
        <span>Active Score</span>
        <div className="analytics-overview-card-row">
          <div className="user-big-number-column">
            <span className="info-text-small">This month</span>
            <span className="bold-number-text">{summary.lastMonth}</span>
          </div>
          <div
            className={
              weeklyChange > 0 ? "user-coloredbox-green" : "user-coloredbox-red"
            }
          >
            <span className="info-text-small-white">Weekly change</span>
            <span className="white-number-value">{weeklyChange}</span>
          </div>
        </div>
      </div>
      <div className="user-analytics-overview-card">
        <span>Engagement Score</span>
        <div className="analytics-overview-card-row">
          <div className="user-big-number-column">
            <span className="info-text-small">This month</span>
            <span className="bold-number-text">
              {summary_engagement.lastMonth}
            </span>
          </div>
          <div
            className={
              weeklyChangeEngagement > 0
                ? "user-coloredbox-green"
                : "user-coloredbox-red"
            }
          >
            <span className="info-text-small-white">Weekly change</span>
            <span className="white-number-value">{weeklyChange}</span>
          </div>
        </div>
      </div>
      <div className="user-analytics-overview-card">
        <span>Health Score</span>
        <div className="analytics-overview-card-row">
          <div className="user-big-number-column">
            <span className="info-text-small">This month</span>
            <span className="bold-number-text">{summary_health.lastMonth}</span>
          </div>
          <div
            className={
              weeklyChangeHealth > 0
                ? "user-coloredbox-green"
                : "user-coloredbox-red"
            }
          >
            <span className="info-text-small-white">Weekly change</span>
            <span className="white-number-value">{weeklyChange}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserScoreCards;
