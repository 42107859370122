import React, { useState, useEffect }from "react";
import DistanceGauge from "./DistanceGauge";
import DistanceHeatMap from "./DistanceHeatMap";

import Loading from "../../Loading";

const UserDistance = (props) => {
  const [userId, setUserId] = useState("all");

  const dataset = props.dataset;
  const users = props.users;
  const isLoaded = props.isLoaded;

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  // User distance from screen

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">User distance from screen</span>
      <div className="bar-histogram-chart-column-card">
        {
          isLoaded === "Not Loaded"
          ? <Loading />
          :
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <DistanceHeatMap
                    dataset={dataset}
                    users={users}
                    setUserId={setUserId}
                  />
                </div>
                <div className="col-md-6">
                  <DistanceGauge dataset={dataset} userId={userId} />
                </div>
              </div>
            </div>
        }
      </div>
    </section>
  );
};

export default UserDistance;
