import React from "react";

import FaceEmotions from "./FaceEmotions";

// import ValenceScore from "./_ValenceScore";
import ValenceScore from "./Valences";

import UserEmotionsSummary from "./UserEmotionsSummary";
import UserEmotions from "./UserEmotions";
// import UserEngagements from "./UserEngagements";
import UserHealthy from "./UserHealthy";
import Engagements from "./Engagements";
import Highlights from "./Highlights";
import Movies from "./Movies";
import SpeechRallies from "./SpeechRallies";
import SpeechSpeeds from "./SpeechSpeeds";
import AudioFeatures from "./AudioFeatures";
import TranscriptSentiments from "./TranscriptSentiments";
import UserDetectedFaces from "./UserDetectedFaces";
import DetectedFaces from "./DetectedFaces";
import UserDistance from "./UserDistance";
import UserDetectedFaceDistance from "./UserDetectedFaceDistance";
import { default as HealthPlotData } from "./UserHealthy/HealthyGauge/helpler";
import { default as ActivePlotData } from "./Activite/ActiveGauge/helpler";
import { default as EngagementPlotData } from "./UserDetectedFaceDistance/DetectedFaceDistanceGauge/helpler";
import { default as EmotionsPlotData } from "./UserEmotions/EmotionDonut/helpler";
import BenchmarkScoreInCard from "./BenchmarkScoreInCard";
import { getAudioFeatures } from "../../api/AudioFeatures";
import { getVoiceEmotions } from "../../api/VoiceEmotions";
import { useState, useEffect } from "react";
import VoiceEmotions from "./VoiceEmotions";

import Loading from "../Loading";

import share from "../../assets/share.png";
import Popup from "reactjs-popup";
// Step 2 - Include the react-fusioncharts component
// import ReactFC from "react-fusioncharts";

// Step 3 - Include the fusioncharts library
// import FusionCharts from "fusioncharts";

// Step 4 - Include the chart type
// import Pie2D from "fusioncharts/fusioncharts.charts";
// import Doughnut2D from "fusioncharts/fusioncharts.charts";
// import Column2D from "fusioncharts/fusioncharts.charts";

// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import "./index.css";
let movieId = null;

const ChartSection = ({
  movie,
  faceEmotionTs,
  users,
  zoomTimelines,
  audioFeaturesUrl,
  voiceEmotions,
  transcriptSentiments,
  detectedFaces,
  detectedFacesTs,
  meetingMetrics,
  hostAvgSpeakDuration,
  hostSpeakRatio,
  isBenchmarkSetting,
  benchmarkMetrics,
  setShareOpen,
  isLoaded,
}) => {
  console.log(isLoaded["tag"], isLoaded["comment"]);
  const lauchTranscriptDetails = () => {
    const link =
      window.location.href.split("/").slice(0, 3).join("/") +
      "/movies/transcript_details?movieId=" +
      movie.movie.movies?.movie_id;
    window.open(link);
    // console.log("movie id - ", movie.movie.movies?.movie_id);

    // console.log("meeting metrics - ", meetingMetrics);
    // getTranscriptScore();
  };
  const [healthScore, setHealthScore] = useState({});
  const [activeScore, setActiveScore] = useState({});
  const [engagementScore, setEngagementScore] = useState({});
  const [emotionsScore, setEmotionsScore] = useState(null);
  const [transcriptScore, setTranscriptScore] = useState(null);
  const dummyMovieID = "000055be-eb2c-4cd3-b751-c1c67b053025";
  // const metrics =
  // meetingMetrics.metrics && meetingMetrics.metrics.length != 0
  //   ? meetingMetrics.metrics
  //   : [];
  // let movieId = movie.movie.movies?.movie_id && movie.movie.movies?.movie_id;
  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");
  // if (
  //   movie.movie.movies?.movie_id !== null &&
  //   movieId !== movie.movie.movies?.movie_id
  // ) {
  //   movieId = movie.movie.movies?.movie_id;
  // }
  // console.log(
  //   "transcript sentiments. trans - ",

  //   transcriptSentiments.transcriptSentiments.transcript_sentiment
  // );
  // console.log("transcript sentiments - ", transcriptSentiments);

  //USEFFECT USING MOVIE ID EXTRACTED FROM THE URL
  // useEffect(() => {
  //   if (movieId != null) {
  //     // if (movieId) {
  //     console.log("api call for voice and audio has movieID - ", movieId);
  //     getAudioFeatures(movieId)
  //       .then((response) => {
  //         console.log("audio features response for", movieId, " - ", response);
  //         const res = {
  //           voice_pitch: response.audio_features.url_pitch,
  //           voice_volume: response.audio_features.url_volume,
  //         };
  //         setAudioFeaturesUrl(res);
  //       })
  //       .catch((err) => {
  //         console.log("error in audio features - ", err);
  //       });

  //     getVoiceEmotions(movieId)
  //       .then((response) => {
  //         console.log("voice emotions response - ", response);
  //         setVoiceEmotions(response.voice_emotions);
  //       })
  //       .catch((error) => {
  //         console.log("error in voice emotions api call - ", error);
  //       });
  //   }
  // }, []);

  const getTranscriptScore = () => {
    // IT IS THE AVERAGE OF THE SPEECH SPEED OF THE ENTIRE VIDEO .transcriptSentiments.transcript_sentiment[0].sr
    let totalsum = 0;
    transcriptSentiments?.transcriptSentiments?.transcript_sentiment?.forEach(
      (element) => {
        totalsum += element.sr;
      }
    );
    console.log("total sum of transcript score - ", totalsum);
    let size =
      transcriptSentiments.transcriptSentiments.transcript_sentiment.length;
    setTranscriptScore(totalsum / size);
  };
  useEffect(() => {
    // console.log("transcript sentiments in use effect - ", transcriptSentiments);
    if (
      transcriptSentiments?.transcriptSentiments?.transcript_sentiment != null
    )
      getTranscriptScore();
  }, [transcriptSentiments]);

  useEffect(() => {
    const activePlotData = ActivePlotData(meetingMetrics, "all");
    setActiveScore(activePlotData?.dials?.dial[0]?.value);
  }, [meetingMetrics]);
  // useEffect(() => {
  //   // const healthResponse = plotData(meetingMetrics, users.users);
  //   if (isNaN(engagementResponse?.dials?.dial[0]?.value)) {
  //     setEngagementResponse(EngagementPlotData(meetingMetrics, "all"));
  //   }
  //   // console.log("engagement Response - ", engagementResponse);
  // }, [engagementResponse]);
  useEffect(() => {
    const engagementPlotData = EngagementPlotData(meetingMetrics, "all");
    setEngagementScore(engagementPlotData?.dials?.dial[0]?.value);
  }, [meetingMetrics]);
  // useEffect(() => {
  //   // const healthResponse = plotData(meetingMetrics, users.users);
  //   if (
  //     emotionsResponse == null ||
  //     emotionsResponse?.category[0].category[0].value === "0"
  //   ) {
  //     setEmotionsResponse(EmotionsPlotData(meetingMetrics, "all"));
  //   }
  //   // console.log("emotions Response - ", emotionsResponse);
  // }, [emotionsResponse]);

  useEffect(() => {
    const emotionsPlotData = EmotionsPlotData(meetingMetrics, "all");
    setEmotionsScore(emotionsPlotData?.dials?.dial[0]?.value);
  }, [meetingMetrics]);
  // useEffect(() => {
  //   // const healthResponse = plotData(meetingMetrics, users.users);
  //   console.log("health useEffect ran");
  //   if (isNaN(healthResponse?.dials?.dial[0]?.value)) {
  //     setHealthResponse(plotData(meetingMetrics, "all"));
  //   }
  //   // console.log("health Response - ", healthResponse);
  // }, [healthResponse]);

  useEffect(() => {
    const healthPlotData = HealthPlotData(meetingMetrics, "all");
    setHealthScore(healthPlotData?.dials?.dial[0]?.value);
  }, [meetingMetrics]);

  // Adding the chart and theme as dependency to the core fusioncharts
  // ReactFC.fcRoot(FusionCharts, Doughnut2D, FusionTheme);

  // STEP 2 - Chart Data

  return (
    <section className="chartSection">
      <div className="container">
        <div className="row mt-5">
          <div className="dashboard-permeeting-info-summary-container">
            <div className="col-md-2 mb-4">
              <Movies movie={movie.movie} />
            </div>
            {/* <div className="col-md-9 mb-5">
              <Highlights />
            </div> */}
            <div className="dashboard-permeeting-highlights-container">
              <div className="dashboard-permeeting-highlights-score-row">
                <div className="dashboard-permeeting-highlight-score-card">
                  <span className="dashboard-permeeting-highlight-score-title">Active</span>
                  {
                    isLoaded.meetingMetrics === "Not Loaded"
                    ? <Loading />
                    :
                      <div className="dashboard-permeeting-highlight-score">
                        <span className="dashboard-permeeting-highlight-score-text">
                          {parseFloat(activeScore).toFixed(2)}
                        </span>
                        {
                          isBenchmarkSetting && (
                            <BenchmarkScoreInCard
                              movieScore={activeScore}
                              benchmarkScore={benchmarkMetrics.active}
                              numberDecimal={2}
                            />
                          )
                        }
                      </div>
                  }
                </div>
                <div className="dashboard-permeeting-highlight-score-card">
                  <span className="dashboard-permeeting-highlight-score-title">Engagement</span>
                  {
                    isLoaded.meetingMetrics === "Not Loaded"
                    ? <Loading />
                    :
                      <div className="dashboard-permeeting-highlight-score">
                        <span className="dashboard-permeeting-highlight-score-text">
                          {parseFloat(engagementScore).toFixed(2)}
                        </span>
                        {
                          isBenchmarkSetting && (
                            <BenchmarkScoreInCard
                              movieScore={engagementScore}
                              benchmarkScore={benchmarkMetrics.engagement}
                              numberDecimal={2}
                            />
                          )
                        }
                      </div>
                  }
                </div>
                <div className="dashboard-permeeting-highlight-score-card">
                  <span className="dashboard-permeeting-highlight-score-title">Health</span>
                  {
                    isLoaded.meetingMetrics === "Not Loaded"
                    ? <Loading />
                    :
                      <div className="dashboard-permeeting-highlight-score">
                        <span className="dashboard-permeeting-highlight-score-text">
                          {parseFloat(healthScore).toFixed(2)}
                        </span>
                        {
                          isBenchmarkSetting && (
                            <BenchmarkScoreInCard
                              movieScore={healthScore}
                              benchmarkScore={benchmarkMetrics.health}
                              numberDecimal={2}
                            />
                          )
                        }
                      </div>
                  }
                </div>
                <div className="dashboard-permeeting-highlight-score-card">
                  <span className="dashboard-permeeting-highlight-score-title">Speak Speed</span>
                  {
                    isLoaded.meetingMetrics === "Not Loaded"
                    ? <Loading />
                    :
                      <div className="dashboard-permeeting-highlight-score">
                        <span className="dashboard-permeeting-highlight-score-text">
                          {parseFloat(transcriptScore).toFixed(1)}
                        </span>
                        {
                          isBenchmarkSetting && (
                            <BenchmarkScoreInCard
                              movieScore={transcriptScore}
                              benchmarkScore={benchmarkMetrics.transcript}
                              numberDecimal={1}
                            />
                          )
                        }
                        <span
                          className="info-text-small"
                          onClick={lauchTranscriptDetails}
                        >
                          See Details
                        </span>
                      </div>
                  }
                </div>
                <div className="dashboard-permeeting-highlight-score-card">
                  <span className="dashboard-permeeting-highlight-score-title">Speak Ratio</span>
                  {
                    isLoaded.hostSpeakFeatures === "Not Loaded"
                    ? <Loading />
                    :
                      <div className="dashboard-permeeting-highlight-score">
                        <span className="dashboard-permeeting-highlight-score-text">
                          {parseFloat(hostSpeakRatio).toFixed(2)}
                        </span>
                        {
                          isBenchmarkSetting && (
                            <BenchmarkScoreInCard
                              movieScore={hostSpeakRatio}
                              benchmarkScore={benchmarkMetrics.hostSpeakRatio}
                              numberDecimal={2}
                            />
                          )
                        }
                        <span
                          className="info-text-small"
                          onClick={lauchTranscriptDetails}
                        >
                          See Details
                        </span>
                      </div>
                  }
                </div>
                <div className="dashboard-permeeting-highlight-score-card">
                  <span className="dashboard-permeeting-highlight-score-title">Speak Duration</span>
                  {
                    isLoaded.hostSpeakFeatures === "Not Loaded"
                    ? <Loading />
                    :
                      <div className="dashboard-permeeting-highlight-score">
                        <span className="dashboard-permeeting-highlight-score-text">
                          {parseFloat(hostAvgSpeakDuration).toFixed(2)}
                        </span>
                        {
                          isBenchmarkSetting && (
                            <BenchmarkScoreInCard
                              movieScore={hostAvgSpeakDuration}
                              benchmarkScore={benchmarkMetrics.hostSpeakDuration}
                              numberDecimal={2}
                            />
                          )
                        }
                        <span
                          className="info-text-small"
                          onClick={lauchTranscriptDetails}
                        >
                          See Details
                        </span>
                      </div>
                  }
                </div>
              </div>
              <UserEmotionsSummary
                dataset={meetingMetrics}
                isLoaded={isLoaded.meetingMetrics}
                isBenchmarkSetting={isBenchmarkSetting}
                benchmarkMetrics={benchmarkMetrics}
              />
            </div>
            {isLoaded["tag"] === "Loaded" &&
            isLoaded["comment"] === "Loaded" ? (
              <div className="dashboard-per-meeting-share">
                <img
                  src={share}
                  alt="share"
                  onClick={() => {
                    setShareOpen((o) => !o);
                  }}
                />
              </div>
            ) : (
              <div></div>
            )}
            </div>

          <div className="mb-5">
            <UserEmotions
              dataset={meetingMetrics}
              users={users.users}
              isLoaded={isLoaded.meetingMetrics}
            />
          </div>

          {/* <div className='mb-5'>
						<UserEngagements dataset={engagementAvg.engagementAvg} users={users.users} />
					</div> */}
          {/* USER ENGAGEMENT  */}
          <div className="mb-5">
            <UserDetectedFaceDistance
              dataset={meetingMetrics}
              users={users.users}
              isLoaded={isLoaded.meetingMetrics}
            />
          </div>
          {/* <div className='mb-5'>
						<UserDetectedFaces dataset={meetingMetrics} users={users.users} />
					</div> */}
          <div className="mb-5">
            <UserDistance
              dataset={meetingMetrics}
              users={users.users}
              isLoaded={isLoaded.detectedFacesTs}
            />
          </div>
          <div className="mb-5">
            <UserHealthy
              dataset={meetingMetrics}
              users={users.users}
              isLoaded={isLoaded.detectedFacesTs}
            />
          </div>
          <div className="mb-5">
            {/* THIS IS THE FACIAL EMOTIONS CHART */}
            <FaceEmotions />
          </div>
          {/* <div className='mb-5'>
						<Engagements dataset={engagementTs.engagementTs} />
					</div> */}
          <div className="mb-5">
            <DetectedFaces
              dataset={detectedFacesTs.detectedFacesTs}
              movie={movie.movie}
              isLoaded={isLoaded.detectedFacesTs}
            />
          </div>
          <div className="mb-5">
            <SpeechRallies
              dataset={zoomTimelines.zoomTimelines}
              isLoaded={isLoaded.zoomTimelines}
            />
          </div>
          <div className="mb-5">
            <AudioFeatures
              audioFeaturesUrl={audioFeaturesUrl.audioFeaturesUrl.audio_features}
              isLoaded={isLoaded.audioFeaturesUrl}
            />
          </div>
          <div className="mb-5">
            <VoiceEmotions
              dataset={voiceEmotions.voiceEmotions.voice_emotions}
              transcriptDataset={transcriptSentiments.transcriptSentiments}
              isLoaded={isLoaded.voiceEmotions}
            />
          </div>
          <div className="mb-5">
            <SpeechSpeeds
              dataset={transcriptSentiments.transcriptSentiments}
              isLoaded={isLoaded.transcriptSentiments}
            />
          </div>
          <div className="mb-5">
            <TranscriptSentiments
              dataset={transcriptSentiments.transcriptSentiments}
              isLoaded={isLoaded.transcriptSentiments}
            />
          </div>
          {/* <div className='mb-5'>
						<ValenceScore dataset={faceEmotionTs.faceEmotionTs} />
					</div> */}
        </div>
      </div>
    </section>
  );
};

export default ChartSection;
