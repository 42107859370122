function timeConvert(seconds) {
	const hour = Math.floor(seconds / 3600);
	const minute = Math.floor((seconds - 3600 * hour) / 60);
	const sec = seconds - 3600 * hour - 60 * minute

	return `${hour}:${minute}:${sec}`;
}

const plotData = (detectedFacesTs, movie) => {

	const params = new URLSearchParams(window.location.search);
	const movieId = params.get("movieId");

	const timeList = []
	const detectedFacesList = []

	
	if ("detected_faces" in detectedFacesTs && detectedFacesTs.detected_faces.length != 0 && "movies" in movie && "duration" in movie.movies) {
		// console.log(detectedFacesTs);
		let cur = 0;
		for (let i = 0; i < detectedFacesTs.detected_faces.length; i++) {
			const df = detectedFacesTs.detected_faces[i]

			for (let j = cur; j < df.time; j++) {
				timeList.push({ label: timeConvert(j) })
				detectedFacesList.push({
					value: 0,
					link: "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/player?movieId="
						+ movieId + "&time=" + j,
				});
			}

			cur = df.time + 1;

			timeList.push({ label: timeConvert(df.time) })
			detectedFacesList.push({
				value: df.count,
				link: "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/player?movieId="
					+ movieId + "&time=" + df.time,
			});
		};

		// console.log(cur)
		for (let i = cur; i < movie.movies.duration; i++) {
			timeList.push({ label: timeConvert(i) })
			detectedFacesList.push({
				value: 0,
				link: "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/player?movieId="
					+ movieId + "&time=" + i,
			});
		}
		
	}

	// console.log(timeList);
	// console.log(detectedFacesList);

	const dataSource = {
		chart: {
			// subcaption: "Engagement Score",
			// yaxisname: "Score",
			// numvisibleplot: "70",
			// labeldisplay: "auto",
			// theme: "fusion",
			// caption: "Analysing Subsidies by Youth Population",
			// subcaption: "By province",
			// yaxisname: "Population Count",
			syaxisname: "Subsidies % of Income",
			labeldisplay: "rotate",
			snumbersuffix: "%",
			scrollheight: "10",
			numvisibleplot: "10",
			drawcrossline: "1",
			theme: "fusion",
			anchorRadius: "3",
			lineThickness: "1",
			lineAlpha: "100"
		},
		categories: [
			{
				category: timeList,
			},
		],
		dataset: [
			{
				seriesname: "Engagement(Detected faces)",
				data: detectedFacesList,
			},
		],
	};

	return dataSource;
};

export default plotData;
