import React from "react";
import { generateDataSource, generateDataSource2 } from "./helper";
import "./Histogram.css";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
charts(FusionCharts);

const Histogram = ({
  selectedParams,
  metrics1,
  metrics2,
  section1name,
  section2name,
}) => {
  const dataSource = generateDataSource2(
    metrics1,
    metrics2,
    selectedParams[0],
    section1name,
    section2name
  );
  console.log("datasource for histogram - ", dataSource);

  return (
    <ReactFusioncharts
      type="mscolumn2d"
      width="40%"
      height="80%"
      dataFormat="JSON"
      dataSource={dataSource}
    />
  );
};

export default Histogram;
