import React, { useState, useEffect } from "react";
import EmotionDonut from "./EmotionDonut";
import UserHeatMap from "./UserHeatMap";

import Loading from "../../Loading";

const UserEmotions = (props) => {

	const [userId, setUserId] = useState("all");

	const dataset = props.dataset;
	const users = props.users;
  const isLoaded = props.isLoaded;

	// console.log(props)

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
    <section className="bar-chart-section">
      <span className="section-title-text">User Emotions</span>
      <div className="bar-histogram-chart-column-card">
        {
          isLoaded === "Not Loaded"
          ? <Loading />
          :
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <UserHeatMap
                    dataset={dataset}
                    users={users}
                    setUserId={setUserId}
                  />
                </div>
                <div className="col-md-6">
                  <EmotionDonut dataset={dataset} userId={userId} />
                </div>
              </div>
            </div>
        }
      </div>
    </section>
  );
};

export default UserEmotions;
