import React, { useState, useEffect } from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";

ReactFC.fcRoot(FusionCharts, TimeSeries);

const schema = [
  {
    name: "Time",
    type: "date",
    format: "%s",
    column: "Time",
    index: 0,
  },
  {
    name: "Score",
    type: "number",
    column: "Score",
    index: 1,
  },
  {
    name: "_row_id",
    type: "string",
  },
];

const dataSource = {
  chart: {},
  caption: {
    text: "Score",
  },
  subcaption: {
    // text: "",
  },
  yaxis: [
    {
      // plot: {
      //   value: "Score",
      // },
      // format: {
      //   prefix: "",
      // },
      // title: "",
      min: "0",
      // max: "0",
      // enabled: "0",
      label: {display: "none"}
    },
  ],
  navigator: {
    enabled: 0,
  },
  extensions: {
    standardRangeSelector: {
      enabled: "0",
    },
    customRangeSelector: {
      enabled: "0",
    },
  },
  legend: {
    enabled: "0",
    // item: [
    //   {
    //     value: "Series A", // name of visible legend item
    //   },
    //   {
    //     value: "Series B",
    //   },
    // ],
  },
  xAxis: {
    outputTimeFormat: {
      //year: "",
      // month: "%b'%y (%q)",
      // day: "%d/%m (%a)",
      //hour: "",
      //minute: "",
      second: "%s",
      //millisecond: ""
    },
    style: {
      // "tick-mark": { },
      // "tick-mark-major": { },
      // "tick-mark-minor": { },
      label: {},
      // "label-major": { display: "none" },
      // "label-minor": { display: "none" },
      "label-context": { display: "none" },
    },
  },
};

export default function TagVisualisationTest({ raw }) {
  const [data, setData] = useState([]);
  console.log(raw);

  useEffect(async () => {
    var chartData = [];
    console.log(raw)
    console.log(raw.length)
    var count = 1;
    for(var i = 0; i <= raw.length; i = i + 1){
      console.log(i)
      if(i === raw.length){
        console.log(chartData.length)
        setData(chartData);
        console.log(data);
      }
      else if(i === raw.length - 1 || raw[i].resource_id.split("_")[1] !== raw[i + 1].resource_id.split("_")[1]){
        chartData.push([raw[i].resource_id.split("_")[1], count, i]);
        count = 1;
      }
      else{
        count = count + 1;
      }
    }
  }, [raw])

  const [chart, setChart] = useState({
    timeseriesDs: {
      type: "timeseries",
      renderAt: "container",
      width: "600",
      height: "400",
      dataSource,
    },
  });

  useEffect(() => {
    // const jsonify = (res) => res.json();
    // const data = await fetch(
    //   "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/line-chart-with-time-axis-data.json"
    // ).then(jsonify);
    // const schema = await fetch(
    //   "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/line-chart-with-time-axis-schema.json"
    // ).then(jsonify);

    console.log(data);
    console.log(schema);

    const fusionTable = new FusionCharts.DataStore().createDataTable(
      data,
      schema
    );

    const timeseriesDs = Object.assign({}, chart.timeseriesDs);
    timeseriesDs.dataSource.data = fusionTable;

    console.log(timeseriesDs);

    setChart({
      timeseriesDs,
    });
  }, [data]);

  return (
    <div>
      {chart.timeseriesDs.dataSource.data ? (
        <ReactFC {...chart.timeseriesDs} />
      ) : (
        "loading"
      )}
    </div>
  );
}
