import React, { useState, useEffect } from "react";

import CompareFaceEmotions from "./CompareFaceEmotions";
import CompareTextNegaPosi from "./CompareTextNegaPosi";
import CompareSpeechSpeed from "./CompareSpeechSpeed";

import ChartSelect from "./ChartSelect";

const Charts = (props) => {

    const movieInfoList = props.movieInfoList;
    const [selectedChartType, setsSlectedChartType] = useState("Face emotions");

    const selectedChart = () => {
        if (selectedChartType == "faceEmotions") {
            return <CompareFaceEmotions movieInfoList={movieInfoList} />
        }
        else if (selectedChartType == "textSentiments") {
            return <CompareTextNegaPosi movieInfoList={movieInfoList} />
        }
        else if (selectedChartType == "speechSpeed") {
            return <CompareSpeechSpeed movieInfoList={movieInfoList} />
        }
        else {
            return <div>Select movies</div>
        }
    }

    return (
        <div className='card text-center my-3'>
            <div className='card-body'>
                {/* <div >{selectedChartType}</div> */}
                <ChartSelect setsSlectedChartType={setsSlectedChartType} />
                {selectedChart()}
            </div>
        </div>
    );
};

export default Charts;
