import React from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const EmotionLine = (props) => {
	const faceEmotions = props.dataset;
	const dataSource = plotData(faceEmotions);

	// console.log(dataSource)
	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};


	return (
		<div className='card text-center'>
			<div className='pt-3' style={style}>
				Valence Score
			</div>
			<div className='card-body'>
				<ReactFC
					type="msline"
					width="100%"
					height="500"
					dataFormat="JSON"
					dataSource={dataSource}
				/>
			</div>
		</div>
	);
};

export default EmotionLine;
