import React, { useRef, useEffect, useState } from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import Dropdown from "react-bootstrap/Dropdown";

import { CSVLink } from "react-csv";

import { getTsFaceEmotionsOfMovie } from "../../../api/FaceEmotions";
import Loading from "../../Loading";

import "./app.css";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const EmotionLine = (props) => {
  const [faceEmotionTs, setFaceEmotionTs] = useState({
    faceEmotionTs: {},
    isLoaded: true,
  });

  const [aggregateType, setAggregateType] = useState("mean");

  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");

  useEffect(() => {
    getTsFaceEmotionsOfMovie(movieId, aggregateType)
      .then((response) => {
        // console.log(response)
        setFaceEmotionTs({
          faceEmotionTs: response,
          isLoaded: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // console.log(aggregateType);
    getTsFaceEmotionsOfMovie(movieId, aggregateType)
      .then((response) => {
        // console.log(response)
        setFaceEmotionTs({
          faceEmotionTs: response,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [aggregateType]);

  const fetchDoneRef = useRef();

  const faceEmotions = faceEmotionTs.faceEmotionTs;
  const dataSource = plotData(faceEmotions);

  // console.log(dataSource)
  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  const iconStyle = {
    fontSize: 25,
    color: "grey",
    cursor: "pointer",
  };

  const handleSelect = (e) => {
    if (e == "download") {
      fetchDoneRef.current.link.click();
    } else if (e == "mean") {
      setAggregateType("mean");
    } else if (e == "sum") {
      setAggregateType("sum");
    } else if (e == "std") {
      setAggregateType("std");
    }
  };

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Facial Emotion Score</span>

      <div class="row">
        <div className="bar-histogram-chart-column-card">
        {
          faceEmotionTs.isLoaded
          ? <Loading />
          :
            <div>
              <div className="pt-3 header-item" style={style}>
                <div className="header-title">
                  {aggregateType}
                </div>
                <div className="header-icon">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="mean">Mean</Dropdown.Item>
                      <Dropdown.Item eventKey="sum">Sum</Dropdown.Item>
                      <Dropdown.Item eventKey="std">Std</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="download">
                        Download csv
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="card-body">
                <ReactFC
                  type="msline"
                  width="100%"
                  height="500"
                  dataFormat="JSON"
                  dataSource={dataSource}
                />
              </div>
              {"face_emotions" in faceEmotions &&
                faceEmotions.face_emotions.length && (
                  <CSVLink
                    data={faceEmotions.face_emotions}
                    filename={"emotion_" + aggregateType + ".csv"} //ファイル名を指定
                    ref={fetchDoneRef} // refを指定
                    target="_blank"
                  />
              )}
            </div>
        }
        </div>
      </div>
    </section>
  );
};

export default EmotionLine;
