import React from "react";

import { dataset } from "../../MovieListV2/Tables/helper";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import S3Video from "../../MovieListV2/S3Video";
import {
  faChartLine,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

const iconStyle = {
  fontSize: 25,
  color: "grey",
};

const actionIconStyle = {
  fontSize: 18,
  color: "grey",
};

// const tagStyle = {
// 	borderRadius: "10px",
// 	border: "2px solid #e2c9b4",
// 	backgroundColor: "#e2c9b4",
// 	color: "#71625a",
// 	padding: "2px",
// };

const cellStyle = {
  good: {
    color: "#62B58F",
    fontWeight: "bold",
    fontSize: 36,
  },

  normal: {
    fontSize: 24,
  },

  bad: {
    color: "#F2726F",
    fontWeight: "bold",
    fontSize: 36,
  },
};

// SECTION DATASET FORMAT -
// 0:
// active: 19.6
// engagement: 0.3
// healthy: 4.15
// imageUrl: ""
// movie_list: (2) ['D5rRjWvJ', 'Jwrnv4pD']
// section_id: "section_test_001"
// section_name: "test section"
// speechSpeed: 139.7
// textNegaposi: 0.7000000000000001

export const columns = [
  {
    title: "Section ID",
    field: "section_id",
    render: (rowData) => {
      return rowData.section_id.slice(0, 8) + "...";
    },
  },
  {
    title: "Section Name",
    field: "section_name",
    defaultSort: "asc",
    headerStyle: {
      minWidth: 300,
    },
  },

  {
    title: "ACTIVE",
    field: "active",
    type: "numeric",
    render: (rowData) => {
      return rowData.active.toFixed(1);
    },
    cellStyle: (rowData) => {
      if (rowData >= 10 && rowData < 20) {
        return cellStyle.normal;
      } else if (rowData >= 20) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  {
    title: "ENGAGEMENT",
    field: "engagement",
    type: "numeric",
    render: (rowData) => {
      return rowData.engagement.toFixed(1);
    },
    cellStyle: (rowData) => {
      if (rowData >= 0.25 && rowData < 0.75) {
        return cellStyle.normal;
      } else if (rowData >= 0.75) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  {
    title: "HEALTHY",
    field: "healthy",
    type: "numeric",
    render: (rowData) => {
      return rowData.healthy.toFixed(1);
    },
    cellStyle: (rowData) => {
      if (rowData >= 2 && rowData < 3.0) {
        return cellStyle.normal;
      } else if (rowData >= 3) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  {
    title: "TEXT NEGAPOSI",
    field: "textNegaposi",
    type: "numeric",
    render: (rowData) => {
      return rowData.textNegaposi.toFixed(1);
    },
    cellStyle: (rowData) => {
      if (rowData >= 0 && rowData < 0.5) {
        return cellStyle.normal;
      } else if (rowData >= 0.5) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  {
    title: "SPEECH SPEED",
    field: "speechSpeed",
    type: "numeric",
    render: (rowData) => {
      return rowData.speechSpeed.toFixed(1);
    },
    cellStyle: (rowData) => {
      if (rowData >= 170 && rowData < 250) {
        return cellStyle.normal;
      } else if (rowData >= 250) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  // {
  //   title: "DASHBOAD",
  //   field: "section_id",
  //   cellStyle: { textAlign: "center" },
  //   render: (rowData) => {
  //     return (
  //       <Link to={"/section/dashboard?movieId=" + rowData.section_id}>
  //         <FontAwesomeIcon style={iconStyle} icon={faChartLine} />
  //       </Link>
  //     );
  //   },
  // },
  {
    title: "ACTIONS",
    field: "section_id",
    cellStyle: { textAlign: "center" },
    render: (rowData) => {
      return (
        <div className="row">
          <Link
            className="col-md-6"
            to={
              "/movies/updateSection?sectionId=" +
              rowData.section_id +
              "&sectionName=" +
              rowData.section_name
            }
          >
            <FontAwesomeIcon style={actionIconStyle} icon={faEdit} />
          </Link>
          <Link
            className="col-md-6"
            to={
              "/movies/deleteSection?sectionId=" +
              rowData.section_id +
              "&sectionName=" +
              rowData.section_name
            }
          >
            <FontAwesomeIcon style={actionIconStyle} icon={faTrash} />
          </Link>
        </div>
      );
    },
  },
];

export const detailPanel = [
  {
    tooltip: "Show Highlights",
    render: (rowData) => {
      let no_of_movies = rowData.movie_list.length;
      let random_movie1 = Math.floor(Math.random() * no_of_movies);
      let random_movie3 = Math.floor(Math.random() * no_of_movies);
      let random_movie2 = Math.floor(Math.random() * no_of_movies);
      return (
        <div className="card text-center my-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <S3Video
                  movieId={rowData.movie_list[random_movie1]}
                  // type="happy"
                  type="customized"
                />
              </div>
              <div className="col-md-4">
                <S3Video
                  movieId={rowData.movie_list[random_movie2]}
                  // type="neutral"
                  type="customized"
                />
              </div>
              <div className="col-md-4">
                <S3Video
                  movieId={rowData.movie_list[random_movie3]}
                  // type="negative"
                  type="customized"
                />
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

export const options = {
  headerStyle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  filtering: true,
  selection: true,
  pageSize: 10,
};

export const getSectionDataset = (sectionList, individualMovieData) => {
  // let individualMovieData = [];
  // if (individualMovieData.length === 0)
  // individualMovieData = dataset(movies, metrics);
  console.log(
    "from getSectionDataset func - ",
    sectionList,
    individualMovieData
  );
  const res = [];
  sectionList.forEach((section) => {
    let currObj = {
      section_id: section.section_id,
      section_name: section.section_name,
      movie_list: section.movie_list,
      ...getMovieListAverage(section.movie_list, individualMovieData),
    };
    res.push(currObj);
  });
  // console.log("section dataset - ", res);
  return res;
};

const getMovieListAverage = (movieList, individualMovieData) => {
  // active: 12.6;
  // engagement: 0.3;
  // healthy: 1.3;
  // imageUrl: undefined;
  // movieId: "9rEvXvaq";
  // speechSpeed: 112.6;
  // tableData: {
  //   id: 100;
  // }
  // textNegaposi: 0.6;
  // topic: "59g4xm1D_19_D3_0";
  // uploadTime: "2022-06-21 10:26:06";
  console.log("movieList in get Movie average - ", movieList);
  console.log("individual movie data in getMOvieAvg - ", individualMovieData);
  let sumActive = 0;
  let sumEngagement = 0;
  let sumHealthy = 0;
  let imageUrl = "";
  let sumSpeechSpeed = 0;
  let sumTextNegaposi = 0;

  let count = 0;
  individualMovieData.forEach((movieData) => {
    if (movieList.includes(movieData.movieId)) {
      // console.log(
      //   " movieList included - ",
      //   movieData.movieId,
      //   " active - ",
      //   movieData.active
      // );
      sumActive += movieData.active;
      sumEngagement += movieData.engagement;
      sumHealthy += movieData.healthy;
      sumSpeechSpeed += movieData.speechSpeed;
      sumTextNegaposi += movieData.textNegaposi;
      count++;
    }
  });
  const res = {
    active: sumActive / count,
    textNegaposi: sumTextNegaposi / count,
    healthy: sumHealthy / count,
    speechSpeed: sumSpeechSpeed / count,
    engagement: sumEngagement / count,
    imageUrl: imageUrl,
  };
  console.log("movielist avg method in helper - ", res);

  return res;
};
