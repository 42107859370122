const initialState = {
	loading: false,
	faceEmotions: [],
	error: "",
};

const faceEmotionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_FACE_EMOTIONS_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_FACE_EMOTIONS_SUCCESS":
			return {
				loading: false,
				faceEmotions: action.payload,
				error: "",
			};
		case "FETCH_FACE_EMOTIONS_FAILURE":
			return {
				loading: false,
				faceEmotions: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default faceEmotionsReducer;
