import React from "react";
import LoadingImg from "../../assets/loading.gif";
import "./index.css";

const Loading = (props) => {
  return (
    <div className="loading-container">
     <img src={LoadingImg} alt="Loading..." className="loading-image" />
    </div>
  )

};

export default Loading;
