import moment from "moment-timezone";

const plotData = (dataset, userId) => {
  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");
  // console.log("movieId and userID from healthyguage helper- ", movieId, userId);
  let users = new Set();
  let healthy = 0;

  if (userId == "all") {
    let sum = 0;
    let count = 0;
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].movie_id != movieId || dataset[i].user_id == "all") {
        continue;
      }

      sum += dataset[i].healthy;
      count += 1;
    }

    healthy = sum / count;
  } else {
    let sum = 0;
    let count = 0;
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].movie_id != movieId || dataset[i].user_id == "all") {
        continue;
      }

      if (dataset[i].user_id == userId) {
        sum += dataset[i].healthy;
        count += 1;
      }
    }
    healthy = sum / count;
  }

  const dataSource = {
    chart: {
      caption: "Score",
      // subcaption: "over all",
      // numbersuffix: "%",
      gaugefillmix: "{dark-20},{light+70},{dark-10}",
      theme: "fusion",
    },
    colorrange: {
      color: [
        {
          minvalue: "0",
          maxvalue: "1.0",
          label: "low",
          code: "#F2726F",
        },
        {
          minvalue: "1.0",
          maxvalue: "3.0",
          label: "middle",
          code: "#FFC533",
        },
        {
          minvalue: "3.0",
          maxvalue: "5.0",
          label: "High",
          code: "#62B58F",
        },
      ],
    },
    // pointers: {
    //   pointer: [
    //     {
    //       value: healthy,
    //     },
    //   ],
    // },
    dials: {
      dial: [
        {
          value: healthy,
        },
      ],
    },
    trendpoints: {
      point: [
        {
          startValue: healthy,
          useMarker: "1",
          markerColor: "#000000",
          markerBorderColor: "#666666",
          markerRadius: "10",
          // markerTooltext: "Previous year's average was 62",
          // displayValue: "Last Year",
          color: "#0075c2",
          thickness: "0",
          alpha: "100",
        },
      ],
    },
  };
  // console.log("datasource from inside healthyguage helper - ", dataSource);

  return dataSource;
};
export default plotData;
