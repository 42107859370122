function timeConvert(seconds) {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - 3600 * hour) / 60);
  let sec = seconds - 3600 * hour - 60 * minute;
  sec = Math.floor(sec);
  return `${hour}:${minute}:${sec}`;
}

const generateDatasource = (rawData, transcriptSentiments) => {
  // console.log("Raw Data to generate voice emotions datasource - ", rawData);

  const timeList = [];
  if (
    "transcript_sentiment" in transcriptSentiments &&
    transcriptSentiments.transcript_sentiment.length != 0
  ) {
    for (let i = 0; i < transcriptSentiments.transcript_sentiment.length; i++) {
      const sentiment = transcriptSentiments.transcript_sentiment[i];

      timeList.push({ label: timeConvert(sentiment.st) });
    }
  }

  const res = {
    chart: {
      // caption: "Reach of Social Media Platforms amoung youth",
      // yaxisname: "% of youth on this platform",
      // subcaption: "2012-2016",
      xAxisName: "Time",
      labeldisplay: "rotate",

      showhovereffect: "1",
      numbersuffix: "",
      drawcrossline: "1",
      // plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
      theme: "fusion",
    },
    // categories: [
    //   {
    //     category: getLabelArray(rawData, "time"),
    //   },
    // ],
    categories: [
      {
        category: timeList,
      },
    ],
    dataset: [
      {
        seriesname: "Happy",
        data: getEmotionArray(rawData, "happy"),
      },
      {
        seriesname: "Sad",
        data: getEmotionArray(rawData, "sad"),
      },
      {
        seriesname: "Angry",
        data: getEmotionArray(rawData, "angry"),
      },
      {
        seriesname: "Disgust",
        data: getEmotionArray(rawData, "disgust"),
      },
      {
        seriesname: "Surprise",
        data: getEmotionArray(rawData, "surprise"),
      },
      {
        seriesname: "Calm",
        data: getEmotionArray(rawData, "calm"),
      },
      {
        seriesname: "Fear",
        data: getEmotionArray(rawData, "fear"),
      },
      {
        seriesname: "Neutral",
        data: getEmotionArray(rawData, "neutral"),
      },
    ],
  };

  const genRes = {
    chart: {
      caption: "Reach of Social Media Platforms amoung youth",
      yaxisname: "% of youth on this platform",
      subcaption: "2012-2016",
      showhovereffect: "1",
      numbersuffix: "%",
      drawcrossline: "1",
      plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
      theme: "fusion",
    },
    categories: [
      {
        category: [
          {
            label: 0,
          },
          {
            label: 3,
          },
          {
            label: 6,
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: "Happy",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Sad",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Angry",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Disgust",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Surprise",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Calm",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Fear",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Neutral",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
    ],
  };
  // console.log("raw data in generateDatasourse - ", rawData);
  // getEmotionArray(rawData, "happy");
  // getEmotionArray(rawData, "angry");
  // getEmotionArray(rawData, "sad");

  // console.log("generate datasource - ", res);
  return res;
};

const getEmotionArray = (rawData, emotion) => {
  let res = [];
  // rawData.forEach((data) => {
  //   res.push({ value: data.happy });
  // });

  let length = rawData.length;
  // length = 3;
  for (let i = 0; i < length; i++) {
    res.push({ value: rawData[i][emotion] });
  }

  // console.log(emotion, " array - ", res);
  return res;
};
const getLabelArray = (rawData, emotion) => {
  let res = [];
  // rawData.forEach((data) => {
  //   res.push({ value: data.happy });
  // });

  let length = rawData.length;
  // length = 3;

  for (let i = 0; i < length; i++) {
    res.push({ label: rawData[i][emotion] });
  }

  // console.log(emotion, " array - ", res);
  return res;
};

export default generateDatasource;

// 100:
// angry: 0
// calm: 20
// disgust: 10
// fear: 0
// happy: 0
// neutral: 59
// sad: 9
// surprise: 1
// time: 300
