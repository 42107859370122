import React from "react";
import Heading from "../../../components/Heading";
import DownloadDetails from "./DownloadDetails";
import "./Download.css";
import DownloadMascot from "../../../assets/download_mascot.svg";

const Download = () => {
  return (
    <div className="download-root">
      <img src={DownloadMascot} />
      <div className="download-options-root">
        <span className="section-title-text">Download CSV file</span>
        <DownloadDetails />
      </div>
    </div>
  );
};

export default Download;
