import React from "react";
import "./index.css";
import { useState } from "react";
import { Notifications } from "@material-ui/icons";
import Settings from "../Settings";
import NotificationConfig from "../Notifications/NotificationConfig";
// import AiTags from "./AiTags";
import EmailSettings from "../EmailSettings";
import BenchmarkSettings from "../BenchmarkSettings";

const SettingsV2 = () => {
  const [selectedPage, setSelectedPage] = useState(0);
  // 0 - Account
  // 1 - Notifications
  // 2 - AI Tags

  const displayData = () => {
    if (selectedPage === 0) {
      return <Settings />;
    } else if (selectedPage === 1) {
      return <NotificationConfig />;
    } else if (selectedPage === 2) {
      return <EmailSettings />;
    } else if (selectedPage === 3) {
      return <BenchmarkSettings />;
    }
  };
  return (
    <div className="settingsv2-root">
      <div className="settingsv2-title-container ">
        <span className="section-title-text">Settings</span>
      </div>
      <div className="settingsv2-contents-row">
        <div className="settingsv2-options-column">
          <div
            onClick={() => {
              setSelectedPage(0);
            }}
            className={
              selectedPage === 0
                ? "settingsv2-options-button-active"
                : "settingsv2-options-button"
            }
          >
            Account
          </div>
          <div
            onClick={() => {
              setSelectedPage(1);
            }}
            className={
              selectedPage === 1
                ? "settingsv2-options-button-active"
                : "settingsv2-options-button"
            }
          >
            Notifications
          </div>
          <div
            onClick={() => {
              setSelectedPage(2);
            }}
            className={
              selectedPage === 2
                ? "settingsv2-options-button-active"
                : "settingsv2-options-button"
            }
          >
            Emails
          </div>
          <div
            onClick={() => {
              setSelectedPage(3);
            }}
            className={
              selectedPage === 3
                ? "settingsv2-options-button-active"
                : "settingsv2-options-button"
            }
          >
            Benchmark
          </div>
        </div>
        <div className="settingsv2-content">{displayData()}</div>
      </div>
    </div>
  );
};

export default SettingsV2;
