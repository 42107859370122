import React from "react";
import "./CompareSection.css";
import Checkbox from "@mui/material/Checkbox";
import { API } from "aws-amplify";
import { selectedIndexPropType } from "react-tabs/lib/helpers/propTypes";
import {
  getSectionMovieMetrics,
  getSectionDetails,
} from "../../../../api/MovieSections";
import { useEffect } from "react";
import { useState } from "react";
import CompareSectionCharts from "./CompareSectionCharts/CompareSectionCharts";
// select section_members.movie_id, summary_results.angry, summary_results.fear from section_members join summary_results where section_id = '336b0957-2c86-11ed-b86c-af76d2b39c24' and section_members.movie_id = summary_results.movie_id;
const CompareSection = () => {
  const params = new URLSearchParams(window.location.search);
  const firstSectionID = params.get("firstSection");
  const secondSectionID = params.get("secondSection");
  const [section1metrics, setSection1metrics] = useState([]);
  const [section2metrics, setSection2metrics] = useState([]);
  const [activeSelected, setActiveSelected] = useState(false);
  const [healthSelected, setHealthSelected] = useState(false);
  const [engagementSelected, setEngagementSelected] = useState(false);
  const [textNegaPosiSelected, setTextNegaPosiSelected] = useState(false);
  const [speechSpeedSelected, setSpeechSpeedSelected] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [sectionDetails, setSectionDetails] = useState([]);
  const [section1name, setSection1name] = useState("");
  const [section2name, setSection2name] = useState("");

  const fetchSectionDetails = async () => {
    let response = await getSectionDetails();
    console.log("section details obtained - ", response);
    setSectionDetails(response);
  };
  const getNameOfSection = (sectionDetails, sectionId) => {
    console.log(
      "sectiondetails in get name of section - ",
      sectionDetails,
      sectionId
    );
    let name = "No Name";
    sectionDetails.forEach((data) => {
      console.log("checking data -", data.section_id, sectionId);
      if (data.section_id === sectionId) name = data.section_name;
    });
    return name;
  };

  useEffect(() => {
    if (sectionDetails !== null) {
      setSection1name(getNameOfSection(sectionDetails, firstSectionID));
      setSection2name(getNameOfSection(sectionDetails, secondSectionID));
    }
  }, [sectionDetails, firstSectionID, secondSectionID]);

  const fetchSectionMovieMetrics = async (firstSectionID, secondSectionID) => {
    let response1 = await getSectionMovieMetrics(firstSectionID);
    let response2 = await getSectionMovieMetrics(secondSectionID);
    console.log(
      "section movie metrics for ",
      firstSectionID,
      " - ",
      response1,
      " , ",
      secondSectionID,
      " - ",
      response2
    );
    setSection1metrics(response1);
    setSection2metrics(response2);
  };

  const computeSelectedArray = (
    activeSelected,
    healthSelected,
    engagementSelected,
    textNegaPosiSelected,
    speechSpeedSelected
  ) => {
    let res = [];
    activeSelected && res.push("activity_score");
    healthSelected && res.push("healthy_sore");
    engagementSelected && res.push("engagement_score");
    textNegaPosiSelected && res.push("text_negative_m");
    speechSpeedSelected && res.push("speech_speed_m");
    console.log("selected array - ", res);
    return res;
  };

  const getSelectionComment = (selectedArray) => {
    let len = selectedArray.length;
    if (len < 1) {
      return "Select parameters to reveal chart";
    }
    if (len > 2) {
      return "Select either 1 or 2 parameters to compare";
    } else return null;
  };

  useEffect(() => {
    fetchSectionMovieMetrics(firstSectionID, secondSectionID);
  }, [firstSectionID, secondSectionID]);

  useEffect(() => {
    fetchSectionDetails();
  }, []);

  useEffect(() => {
    setSelectedArray(
      computeSelectedArray(
        activeSelected,
        healthSelected,
        engagementSelected,
        textNegaPosiSelected,
        speechSpeedSelected
      )
    );
  }, [
    activeSelected,
    healthSelected,
    engagementSelected,
    textNegaPosiSelected,
    speechSpeedSelected,
  ]);

  return (
    <div className="compare-section-root">
      <div className="compare-section-options-part ">
        <div className="section-info-box">
          <span>Section ID : {firstSectionID}</span>
          <span>Section Name : {section1name}</span>
        </div>
        <div className="section-info-box">
          <span>Section ID : {secondSectionID}</span>
          <span>Section Name : {section2name}</span>
        </div>
        <div className="compare-section-checkbox">
          <div className="compare-section-checkbox-item">
            <Checkbox
              checked={activeSelected}
              onChange={(event) => {
                setActiveSelected(event.target.checked);
              }}
            />
            <span>Active Score</span>
          </div>
          <div className="compare-section-checkbox-item">
            <Checkbox
              checked={healthSelected}
              onChange={(event) => {
                setHealthSelected(event.target.checked);
              }}
            />
            <span>Health Score</span>
          </div>
          <div className="compare-section-checkbox-item">
            <Checkbox
              checked={engagementSelected}
              onChange={(event) => {
                setEngagementSelected(event.target.checked);
              }}
            />
            <span>Engagement Score</span>
          </div>
          <div className="compare-section-checkbox-item">
            <Checkbox
              checked={textNegaPosiSelected}
              onChange={(event) => {
                setTextNegaPosiSelected(event.target.checked);
              }}
            />
            <span>Text NegaPosi</span>
          </div>
          <div className="compare-section-checkbox-item">
            <Checkbox
              checked={speechSpeedSelected}
              onChange={(event) => {
                setSpeechSpeedSelected(event.target.checked);
              }}
            />
            <span>Speech Speed</span>
          </div>
        </div>
      </div>
      <div className="compare-section-chart-part ">
        {(selectedArray.length === 1 || selectedArray.length === 2) &&
        section2metrics.length !== 0 &&
        section1metrics.length !== 0 ? (
          <CompareSectionCharts
            metrics1={section1metrics}
            metrics2={section2metrics}
            selectedParams={selectedArray}
            sectionDetails={sectionDetails}
            section1name={section1name}
            section2name={section2name}
          />
        ) : (
          <span>{getSelectionComment(selectedArray)}</span>
        )}
      </div>
    </div>
  );
};

export default CompareSection;
// CREATE THE API FOR GETTING SECTION MOVIE METRICS
// GET THE SECTION MOVIE METRICS FROM THE API
// CREATE A STATE WHICH STORES WHICH OF THE CHECKBOX IS SELECTED
// CREATE LOGIC FOR SHIFTING THE GRAPHS ACCORDING TO THE SELECTED CHECKBOX
// WRITE FUNCTION TO GENERATE DATASET FOR THE GRAPHS
