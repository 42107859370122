import { Auth, API } from "aws-amplify";

export async function getZoomJWTSettings() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "ZoomJWTSettingsAPI";
	const path = "?account_id=" + accountId;

	return await API.get(apiName, path);

}

export async function updateZoomJWTSettings(zoomSettings) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "ZoomJWTSettingsAPI";
	const path = "";
	
	zoomSettings["accountId"] = accountId;
	const myInit = { 
        body: zoomSettings, 
    };
	return await API.post(apiName, path, myInit);
}

export async function getZoomS2SOAuthSettings() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "ZoomS2SOAuthSettingsAPI";
	const path = "?account_id=" + accountId;

	return await API.get(apiName, path);

}

export async function updateZoomS2SOAuthSettings(zoomSettings) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "ZoomS2SOAuthSettingsAPI";
	const path = "";
	
	zoomSettings["account_id"] = accountId;
	const myInit = { 
        body: zoomSettings, 
    };
	return await API.post(apiName, path, myInit);
}
