import React from "react";

import { calculatePercentageEmotionScores, calculatePercentageBenchmarkEmotionScores } from "./helpler";
import EmotionPieGauge from "../../EmotionPieGauge";

const UserEmotionsSummary = (props) => {
	const dataset = props.dataset;
  const isLoaded = props.isLoaded;
  const isBenchmarkSetting = props.isBenchmarkSetting;
  const benchmarkMetrics = props.benchmarkMetrics;

  const percentageEmotionScores = calculatePercentageEmotionScores(dataset);
  let percentageBenchmarkEmotionScores = {};
  if (isBenchmarkSetting) {
    percentageBenchmarkEmotionScores = calculatePercentageBenchmarkEmotionScores(benchmarkMetrics);
  }

  return (
    <div className="dashboard-emotions-score-summary-row">
      <EmotionPieGauge
        emotionName="Neutral"
        color="#ffcd89"
        isLoaded={isLoaded}
        percentageScore={percentageEmotionScores.neutral}
        isBenchmarkSetting={isBenchmarkSetting}
        percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
      />
      <EmotionPieGauge
        emotionName="Happy"
        color="#f48f57"
        isLoaded={isLoaded}
        percentageScore={percentageEmotionScores.happy}
        isBenchmarkSetting={isBenchmarkSetting}
        percentageBenchmarkScore={percentageBenchmarkEmotionScores.happy}
      />
      <EmotionPieGauge
        emotionName="Suprise"
        color="#e66b9d"
        isLoaded={isLoaded}
        percentageScore={percentageEmotionScores.suprise}
        isBenchmarkSetting={isBenchmarkSetting}
        percentageBenchmarkScore={percentageBenchmarkEmotionScores.surprise}
      />
      <EmotionPieGauge
        emotionName="Disgust"
        color="#934e9f"
        isLoaded={isLoaded}
        percentageScore={percentageEmotionScores.disgust}
        isBenchmarkSetting={isBenchmarkSetting}
        percentageBenchmarkScore={percentageBenchmarkEmotionScores.disgust}
      />
      <EmotionPieGauge
        emotionName="Angry"
        color="#45379b"
        isLoaded={isLoaded}
        percentageScore={percentageEmotionScores.angry}
        isBenchmarkSetting={isBenchmarkSetting}
        percentageBenchmarkScore={percentageBenchmarkEmotionScores.angry}
      />
      <EmotionPieGauge
        emotionName="Sad"
        color="#1d5fbf"
        isLoaded={isLoaded}
        percentageScore={percentageEmotionScores.sad}
        isBenchmarkSetting={isBenchmarkSetting}
        percentageBenchmarkScore={percentageBenchmarkEmotionScores.sad}
      />
      <EmotionPieGauge
        emotionName="Fear"
        color="#2097b7"
        isLoaded={isLoaded}
        percentageScore={percentageEmotionScores.fear}
        isBenchmarkSetting={isBenchmarkSetting}
        percentageBenchmarkScore={percentageBenchmarkEmotionScores.fear}
      />
    </div>
  );
};

export default UserEmotionsSummary;
