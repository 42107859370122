import React, { useEffect, useState } from "react";

import Heading from "../../components/Heading";
import Info from "../../components/Info";
import ChartSection from "../../components/ChartsPerUser";

import { getUser } from "../../api/Users";

import { getMeetingMetrics } from "../../api/MeetingMetrics";
import "./Dashboard.css";

const DashboardPerUser = () => {
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");

  const [meetingMetrics, setMeetingMetrics] = useState({});
  const [user, setUser] = useState({
    user: {},
  });

  useEffect(() => {
    getMeetingMetrics()
      .then((response) => {
        // console.log(response)
        setMeetingMetrics(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getUser(userId)
      .then((response) => {
        console.log("user response - ", response);
        // FORMAT -
        // users:
        // first_name: null;
        // last_name: null;
        // url: "https://dashboard-cloud-participant-images-bucket.s3.amazonaws.com/7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa/002a79dd-62ca-493d-834d-2cdbb0c50c86/thumbnail.png?AWSAccessKeyId=ASIAXKNSHYQG6JY54MHJ&Signature=iofUey7Wjj%2FPHcvV6g9qCmJAozQ%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEOD%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLW5vcnRoZWFzdC0xIkgwRgIhAJ00ijp8ybw9167UaPUnsMIykVduISuqLLM8i4UFcrIOAiEA0HtGuwJJlLuj7dEpdZ7StCJWr3RT1ABPAyUdKQJhBnIqpQIIKhABGgw1MDM0MjE4NDY1NDEiDLIk4HaPC7byYtTkkCqCAgJKFAV0mOvMaYiBj0RNgIxRreVAOevdEtcx%2FgxdwABeTMmc1L0oV4Z1qzQGaZ%2FZ%2FOAEdBnG5yQ%2BYqL1oFPj4efiVPjqHMRQF%2Fb6VNWb3ZcuUt4kZtC6btJpCDyH9lr4ynvwc%2FtIXgFN8%2FM62FQ9TefuDHSNQRDnTW7eA5Rnq0bhE7xxX1qlpPC%2FJLa9aWpsKob%2FspW%2BzdiBzmL7mI5yTbuTXkoe8ZlBKHpt7YjDtR0sxB0G0SpfIuDPg%2F3o%2BLNubfV0lnVkjtfoFGKOP%2FIxaLAo6VyHMa8qtwlCk9EyGVzFJ%2FIlQDjxKAdu47B%2BemDy7j9D82DYrbDJmdhcXWzmD24kZDDE996WBjqZAWtEQJ%2BOXKCFTDaNkr4l0KFU39vRY0gWbWKaDubtbVK1ggAq9EqYhUl71QxNnnCHiW%2BNrow2RqLdvA64%2FXuXBzYhxrUtEmmiRTcUmYmt3tbbj3ct3rE5WJC%2Fn98N3Zu3Lb0PYzGTfkCeCwQL3PdpUOw3z5UqlKnu3K7g1ixGTIR57TYFmNxcUbWEIurnRnSLtDyVkO6fiAMNsw%3D%3D&Expires=1658306087";
        // user_id: "002a79dd-62ca-493d-834d-2cdbb0c50c86";
        setUser({
          user: response,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const metrics =
    meetingMetrics.metrics && meetingMetrics.metrics.length != 0
      ? meetingMetrics.metrics
      : [];

  return (
    <div className="col-md-10 p-4">
      <Heading heading="h5">
        IBY analyzes online communication every seconds.
      </Heading>
      <Info user={user.user} />
      <ChartSection userId={userId} meetingMetrics={metrics} />
    </div>
  );
};

export default DashboardPerUser;
