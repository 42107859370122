import React, { useState, useEffect } from "react";
import FaceEmotions from "./FaceEmotions";


const Charts = (props) => {

    const movieInfoList = props.movieInfoList;

    const col = (movieInfoList.length <= 3) ? ("col-md-"+String(12/movieInfoList.length)) : ("col-md-3");

    const rows = movieInfoList.map((movieInfo, index) =>
        
            <div className={col} key={movieInfo.movieId}>
                <FaceEmotions movieInfo={movieInfo} />
            </div>
        
    );

    return (
        <div>
            <div className="row" >
            {rows}
            </div>
        </div>

    );
};

export default Charts;
