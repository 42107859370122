import { Auth, API } from "aws-amplify";

export async function getMovie(movieId, accountId = "") {
  if (accountId === "" || accountId === "SCENE") {
    accountId = (await Auth.currentUserInfo()).attributes.sub;
  }

  const apiName = "MoviesAPI";
  const path = "?account_id=" + accountId + "&movie_id=" + movieId;

  return await API.get(apiName, path);
}

export async function getMoviesWithUrl() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MoviesAPI";
  const path = "?account_id=" + accountId + "&type=with_url";

  return await API.get(apiName, path);
}

export async function getMovies() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MoviesAPI";
  const path = "?account_id=" + accountId;

  return await API.get(apiName, path);
}

export async function getMoviesWithOffset(offset, perPage) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MoviesAPI";
  const path =
    "?account_id=" +
    accountId +
    "&page_number=" +
    offset +
    "&page_size=" +
    perPage;

  return await API.get(apiName, path);
}

export async function createUploadUrl(fileName, fileType, topicName) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MoviesAPI";
  const path = "/upload";

  const body = {};
  body["account_id"] = accountId;
  body["file_name"] = fileName;
  body["file_type"] = fileType;
  body["topic_name"] = topicName;

  const myInit = {
    body: body,
  };

  return await API.post(apiName, path, myInit);
}

export async function deleteMovie(movieId) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MoviesAPI";
  const path = "?account_id=" + accountId + "&movie_id=" + movieId;

  return await API.del(apiName, path);
}

export async function updateMovie(movieId, data) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MoviesAPI";
  const path = "";

  const body = {};
  body["account_id"] = accountId;
  body["movie_id"] = movieId;
  body["topic"] = data.topic;

  const myInit = {
    body: body,
  };

  return await API.put(apiName, path, myInit);
}
