import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getRole,
  getOrganisationList,
  getFeatures,
  updateFeature,
} from "../../api/Admin";
import {
  fetchAdminAccess,
  fetchOrganisationList,
} from "../../store/admin/adminAction";

import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

import ListAlert from "../Notifications/ListAlert";
import CreateAlert from "../Notifications/CreateAlert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTrash,
  faEdit,
  faPlay,
  faArrowAltCircleUp,
  faRedo,
  faUndo,
  faAngleDown,
  faPlusSquare,
  faBell,
} from "@fortawesome/free-solid-svg-icons";

import Popup from "reactjs-popup";
import Loading from "../../assets/loading.gif";

const iconStyle = {
  fontSize: 20,
  color: "grey",
};

const columns = [
  { id: "name", label: "Organisation Name", minWidth: "4rem" },
  { id: "email", label: "Email id", minWidth: "5rem" },
  { id: "edit", label: "Edit", minWidth: "2rem" },
  { id: "alert", label: "Alert", minWidth: "2rem" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function SubscriptionHelper() {
  const [filter, setFilter] = useState("");

  const dispatch = useDispatch();
  const data = useSelector((store) => {
    return store.adminReducer.organisationList;
  });
  // console.log(data);

  useEffect(async () => {
    const result = await getOrganisationList();
    // console.log(result);
    dispatch(fetchOrganisationList(result));
  }, []);

  const muiTableData = data
    .filter((e) => {
      if (filter === "") {
        return [];
      }
      if (e.name !== null && e.name.includes(filter)) {
        return true;
      }
      if (e.email !== null && e.email.includes(filter)) {
        return true;
      }
    })
    .map((fields) => {
      return {
        name: <p>{fields.name}</p>,
        email: <p>{fields.email}</p>,
        edit: (
          <div>
            <IconButton
              onClick={() => {
                currData.current = fields;
                fetchFeaturesHelper(fields.account_id);
                setOpen(true);
              }}
              size="small"
            >
              <FontAwesomeIcon style={iconStyle} icon={faEdit} />
            </IconButton>
          </div>
        ),
        alert: (
          <div>
            <IconButton
              onClick={() => {
                setCurrentAlertDetails(fields);
                // fetchFeaturesHelper(fields.account_id);
                setOpenAlert(true);
              }}
              size="small"
            >
              <FontAwesomeIcon style={iconStyle} icon={faBell} />
            </IconButton>
          </div>
        ),
      };
    });

  const [open, setOpen] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const currData = useRef(null);
  const currFeatures = useRef([]);

  const fetchFeaturesHelper = async (check_account_id) => {
    setPopupLoading(true);
    const temp = await getFeatures(check_account_id);
    console.log(temp);
    currFeatures.current = temp.map((e) => {
      return { ...e, updatedValue: temp.active };
    });
    console.log(currFeatures.current);
    setPopupLoading(false);
  };

  const updateFeaturehelper = async ({
    feature_id,
    active,
    change_account_id,
  }) => {
    console.log(active);
    setPopupLoading(true);
    const result = await updateFeature(feature_id, active, change_account_id);
    const temp = await getFeatures(change_account_id);
    console.log(temp);
    currFeatures.current = temp.map((e) => {
      return { ...e, updatedValue: temp.active };
    });
    setPopupLoading(false);
  };

  const HighlightPopup = () => {
    return (
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={() => {
          setOpen(false);
        }}
      >
        {popupLoading === true || currData.current === null ? (
          <img src={Loading} />
        ) : (
          <div
            style={{
              margin: "2rem",
              // borderRadius: "10rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2rem",
                top: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              X
            </div>
            <div
              style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
            >
              Edit
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                justifyContent: "space-between",
              }}
            >
              Organisation Name{": "}
              {/* <FormControl sx={{ m: 1, width: "60%" }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={currData.current.name}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  size="small"
                  disabled
                />
              </FormControl> */}
              <p style={{ margin: 0, fontWeight: 300 }}>
                {currData.current.name}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                justifyContent: "space-between",
              }}
            >
              Email id{": "}
              <p style={{ margin: 0, fontWeight: 300 }}>
                {currData.current.email}
              </p>
              {/* <FormControl sx={{ m: 1, width: "60%" }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={currData.current.email}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  size="small"
                  disabled
                />
              </FormControl> */}
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <p style={{ fontWeight: "bold", marginTop: "1rem" }}>
                Analysis Options
              </p>
              {currFeatures.current.map((feature) => {
                var enabled = feature.active;
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    {feature.analysis_name}{" "}
                    <Switch
                      checked={enabled}
                      inputProps={{ "aria-label": "controlled" }}
                      onClick={() => {
                        updateFeaturehelper({
                          feature_id: feature.analysis_id,
                          active: !enabled,
                          change_account_id: currData.current.account_id,
                        });
                      }}
                    />
                    {/* <Button
                      variant="outlined"
                      onClick={() => {
                        updateFeaturehelper({
                          feature_id: feature.analysis_id,
                          active: !enabled,
                          change_account_id: currData.current.account_id,
                        });
                      }}
                      size="small"
                    >
                      Toggle
                    </Button> */}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Popup>
    );
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [currentAlertDetails, setCurrentAlertDetails] = useState(null);

  const AlertPopup = () => {
    return (
      <Modal
        open={openAlert}
        onClose={() => {
          setOpenAlert(false);
          setCurrentAlertDetails(null);
        }}
        // aria-labelledby="parent-modal-title"
        // aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            padding: "2rem",
            // borderRadius: "10rem",
            height: "80vh",
            width: "50vw",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: "2rem",
              top: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenAlert(false);
              setCurrentAlertDetails(null);
            }}
          >
            X
          </div>
          <p>
            Add Alert Definitions for{" "}
            {currentAlertDetails !== null ? currentAlertDetails.name : null}
          </p>
          <CreateAlert
            account_id={
              currentAlertDetails !== null
                ? currentAlertDetails.account_id
                : undefined
            }
          />
          <hr />
          <ListAlert
            account_id={
              currentAlertDetails !== null
                ? currentAlertDetails.account_id
                : undefined
            }
          />
        </Box>
      </Modal>
    );
  };

  return (
    <div
      className="col-md-10 p-4"
      style={{
        display: "flex",
        flexDirection: "column",
        // width: "100%",
        // margin: "0.25rem",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          id="outlined-basic"
          label="Filter"
          variant="outlined"
          sx={{ marginBottom: "1rem", width: "100%" }}
          size="small"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
      </div>
      {HighlightPopup()}
      {AlertPopup()}
      <Paper
        sx={{
          // width: "100%",
          overflow: "hidden",
          height: "calc(100vh - 3rem - 5rem)",
        }}
      >
        <TableContainer sx={{ maxHeight: "100%", overflowX: "auto" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ overflowX: "scroll" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    // key={column.id}
                    align={column.align}
                    style={{ maxWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {muiTableData
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  // console.log(row)
                  // console.log(row.timestamp.props.children[1].props.children);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      // key={row.code}
                      // selected={
                      //   curTime === row.timestamp.props.children ? true : false
                      // }
                      // ref={(ref) =>
                      //   (listRef.current[row.timestamp.props.children] = ref)
                      // }
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            // key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.minWidth,
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
