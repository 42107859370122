const initialState = {
	loading: false,
	zoomMeetingReport: {},
	error: "",
};

const zoomMeetingReportReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_ZOOM_MEETING_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_ZOOM_MEETING_SUCCESS":
			return {
				loading: false,
				zoomMeetingReport: action.payload,
				error: "",
			};
		case "FETCH_ZOOM_MEETING_FAILURE":
			return {
				loading: false,
				zoomMeetingReport: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default zoomMeetingReportReducer;
