import { Auth, API } from "aws-amplify";

export async function getShareAccounts() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ShareDashboardAPI";
  const path = "?account_id=" + accountId;

  return await API.get(apiName, path);
}

export async function deleteShareAccounts(obj) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ShareDashboardAPI";
  const path = "";
  const body = {
    body: {
      sender_id: accountId,
      receiver_id: obj["receiver_id"],
      scope: obj["scope"],
      resource_id: obj["resource_id"],
    },
  };

  return await API.del(apiName, path, body);
}

export async function createShareAccounts(obj) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ShareDashboardAPI";
  const path = "";
  const body = {
    body: {
      email_id: obj["email_id"],
      resource_id: obj["resource_id"],
      account_id: accountId,
      scope: obj["scope"],
    },
  };

  return await API.post(apiName, path, body);
}

export async function getSharedOrganizationList() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ShareDashboardAPI";
  const path = "/organizations?account_id=" + accountId;

  return await API.get(apiName, path);
}

export async function getSharedSearchTagsResult(
  sender_id,
  resource_type,
  tag,
  page,
  limit,
  speaker,
  speakerManagement,
  movie_id
) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ShareDashboardAPI";
  const path =
    "/tags/search" +
    "?account_id=" +
    sender_id +
    "&resource_type=" +
    resource_type +
    "&tag=" +
    tag.join(",") +
    "&speaker=" +
    speaker +
    "&page=" +
    page +
    "&limit=" +
    limit +
    "&section_id=" +
    speakerManagement +
    "&receiver_account_id=" +
    accountId +
    "&movie_id=" +
    movie_id;

  return await API.get(apiName, path);
}

export async function getSharedSeachTagsSuggestions(sender_id) {
  const apiName = "GetTagSuggestions";
  const path = "/suggestion?account_id=" + sender_id;

  return await API.get(apiName, path);
}
