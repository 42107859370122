import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import Card from "../../components/Card";

import TextField from "@mui/material/TextField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import { createUploadUrl } from "../../api/Movies";
import UploadMascot from "../../assets/upload_mascot.svg";
import "./index.css";

const style = {
  width: "100%",
  height: 400,
  padding: 10,
  border: "1px dotted #888",
  alignItems: "center",
};

const iconStyle = {
  fontSize: 50,
  color: "grey",
};

const Upload = () => {
  // const [progress, setProgress] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploading, setUploading] = useState(false)
  const [topicNameObj, setTopicName] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log('acceptedFiles:', acceptedFiles.length);

    const progress_array = [];
    const progress_obj = {};
    for (let i = 0; i < acceptedFiles.length; ++i) {
      // progress_array.push(0);
      // setProgress(progress_array);
      // setProgress([...progress, 90]);
      progress_obj[acceptedFiles[i].name] = 0;
    }

    // setProgress(progress_array);
    setProgress(progress_obj);

    // console.log(progress)
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop });

  const files = acceptedFiles.map((file, index) => {
    // console.log(progress);
    // console.log(acceptedFiles);

    return (
      <div class="file-list-container">
        <div class="file-progress-entry" key={file.path}>
          <div class="file-name-and-size">
            {file.path} - {file.size} bytes
          </div>
          <div class="upload-pregress-bar progress mb-4 mt-1">
            {/* <div class="progress-bar" role="progressbar" style={{ width: `${progress[index]}%` }} aria-valuenow={`"${progress[index]}""`} aria-valuemin="0" aria-valuemax="100"> */}
            <div
              class="progress-bar"
              role="progressbar"
              style={{ width: `${progress[file.name]}%` }}
              aria-valuenow={`"${progress[file.name]}""`}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {progress[file.name]}%
            </div>
            {/* <div class="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div> */}
          </div>
        </div>
        <div class="topic-name-entry">
          <TextField
            id={`topic-name-text-field-${index}`}
            name={file.name}
            fileName={file.name}
            fullWidth
            label="Topic Name"
            placeholder="type here..."
            variant="outlined"
            size="normal"
            style={{width: "300px"}}
            onChange={(e) => {
              const fileName = e.target.name;
              const topicName = e.target.value;
              setTopicName(state => {
                state[fileName] = topicName;
                return state;
              })
            }}
            />
        </div>
      </div>
      );
  });

  const uploadFiles = () => {
    const options = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };

    for (let i = 0; i < acceptedFiles.length; ++i) {
      const file = acceptedFiles[i];
      const topicName = (file.name in topicNameObj && topicNameObj[file.name]) ? topicNameObj[file.name] : file.name;
      // console.log(acceptedFiles[i])

      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          // setProgress(
          // 	progress.map((value, index) => {
          // 		console.log([index, value, percentCompleted])
          // 		return (index === i ? percentCompleted : value)
          // 	}
          // 	)
          // );
          setProgress((prev) => ({
            ...prev,
            [file.name]: percentCompleted,
          }));
        },
        headers: {
          "Content-Type": file.type,
        },
      };

      // console.log(config)

      createUploadUrl(file.name, file.type, topicName).then((res) => {
        setUploading(true);
        axios.put(res.upload_url, file, config)
          .finally(() => {
            setUploading(false);
          });
      });
    }
  };

  return (
    <div className="upload-root">
      <img src={UploadMascot} width={400} />
      <div className="upload-options-container">
        <span className="section-title-text">Upload Your Movies</span>
        <div {...getRootProps()} className="upload-box-container">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div className="center-div">
              <FontAwesomeIcon style={iconStyle} icon={faUpload} />

              <div className="my-4">
                <p className="info-text-small">
                  Drag and drop some files here, or click to select files
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="">
          <div className="h4 fw-bold">Files</div>
          {files}
        </div>

        {acceptedFiles.length != 0 ? (
          <div class="text-center mt-4">
            <button
              class="btn btn-primary mt-3"
              onClick={() => uploadFiles()}
              disabled={uploading}
            >
              {uploading && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Upload Files
            </button>
          </div>
        ) : (
          <div>No file is selected</div>
        )}
      </div>
    </div>
  );
};

export default Upload;
