import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Analytics = () => {
	

	return (
		<div className='col-md-10 p-4'>
		<Tabs
		>
		<TabList>
		  <Tab>Title 1</Tab>
		  <Tab>Title 2</Tab>
		</TabList>
	
		<TabPanel>
		  <h2>Any content 1</h2>
		</TabPanel>
		<TabPanel>
		  <h2>Any content 2</h2>
		</TabPanel>
	  </Tabs>
	  </div>

	);
};

export default Analytics;
