function timeConvert(seconds) {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - 3600 * hour) / 60);
  let sec = seconds - 3600 * hour - 60 * minute;
  sec = Math.floor(sec);
  return `${hour}:${minute}:${sec}`;
}

const plotData = (transcriptSentiments) => {
  let users = new Set();
  const speedList = [];
  const timeList = [];

  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");

  if (
    "transcript_sentiment" in transcriptSentiments &&
    transcriptSentiments.transcript_sentiment.length != 0
  ) {
    for (let i = 0; i < transcriptSentiments.transcript_sentiment.length; i++) {
      const sentiment = transcriptSentiments.transcript_sentiment[i];

      const link =
        "n-" +
        window.location.href.split("/").slice(0, 3).join("/") +
        "/movies/player?movieId=" +
        movieId +
        "&time=" +
        sentiment.st;

      speedList.push({ value: sentiment.sr, link: link });
      timeList.push({ label: timeConvert(sentiment.st) });
    }
  }

  // console.log(timeList)

  const dataSource = {
    chart: {
      xAxisName: "Time",
      labeldisplay: "rotate",
      scrollheight: "10",
      numvisibleplot: "10",
      drawcrossline: "1",
      theme: "fusion",
      anchorRadius: "3",
      lineThickness: "1",
      lineAlpha: "100",
      showToolTipShadow: "1",
    },
    categories: [
      {
        category: timeList,
      },
    ],
    dataset: [
      {
        seriesname: "Speech speed",
        data: speedList,
      },
    ],
  };

  return dataSource;
};

export default plotData;
