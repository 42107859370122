import React from "react";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import plotData from "./helper";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const ActiveScore = (props) => {
  const dataset = props.dataset;

  const dataSource = plotData(dataset);

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  return (
    <div className="bar-histogram-chart-column-card">
      <div className="card-body">
        <ReactFC
          type="column2d"
          width="100%"
          height="400"
          dataFormat="JSON"
          dataSource={dataSource}
          fcEvent-dataPlotClick={(eventObj) => {
            // console.log(eventObj);
            // window.open("/users/" + eventObj.data.brLabel);
          }}
        />
      </div>
    </div>
  );
};

export default ActiveScore;
