import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player/lazy";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ArrowClockwise,
} from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faRedo,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

import HistoryTable from "./HistoryTable";
import CustomPlayHelper from "./CustomPlayHelper";

import { QUICKVIEW_TABLE } from "../../utils/schema";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { getHighlightOfMovie } from "../../api/Highlights";
import {
  getSearchHighlights,
  deleteTag,
  addTag,
  updateTagFunction,
} from "../../api/Search";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

import Loading from "../../components/Loading";
import TagVisualisation from "../../components/Charts/TagVisualisation/TagVisualisation";
import TagVisualisationTest from "../../components/Charts/TagVisualisation/TagVisualisationTest";

import { getMovie } from "../../api/Movies";

import "./QuickView.css";

import { fetchHighlightsDataRequest, fetchHighlightsDataSuccess } from "../../store/search/searchAction";
import { useSelector, useDispatch } from "react-redux";

const showSecond = true;
const str = showSecond ? "HH:mm:ss" : "HH:mm";

const iconStyle = {
  fontSize: 20,
  color: "grey",
};

const iconStyleAlt = {
  fontSize: 15,
  color: "grey",
  marginLeft: "1rem",
  cursor: "pointer",
};

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F7CF6D",
  "&:hover": {
    backgroundColor: "#C7C7C9",
  },
}));

const getDetails = () => {
  const res = window.location.search.toString().split("?")[1];
  let company_name = "";
  let sender_id = "";
  if (res) {
    company_name = res.split("&")[0].split("=")[1];
    sender_id = res.split("&")[1].split("=")[1];
  }

  return { company_name, sender_id };
};

export default function Highlight() {
  const [details, setDetails] = useState(getDetails());

  const [playing, setPlaying] = useState(false);
  const [tagType, setTagType] = useState("ai");
  // const [highlightsData, setHighlightsData] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  const movieId = params.get("movieId");
  const [sceneTimestamp, setSceneTimestamp] = useState(params.get("timestamp"));
  const [twoTables, setTwoTables] = useState(false);
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filter2, setFilter2] = useState([]);
  const [video, setVideo] = useState(undefined);
  const [duration, setDuration] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();


  const highlightsData = useSelector((state) => {
    return state.searchReducer.highlights;
  });

  console.log(filter);

  useEffect(async () => {
    // console.log(details);
    const temp = await getMovie(movieId, details.sender_id);
    setVideo(temp.movies.url);
    updateAllTags();
  }, []);

  const updateAllTags = async () => {
    dispatch(fetchHighlightsDataRequest());
    let temp2 = await getSearchHighlights(type, movieId, details.sender_id);
    // console.log(temp2);
    const temp3 = {
      tags_type: temp2.tags_type.sort(),
      tags: temp2.tags.sort(
        (a, b) => a.resource_id.split("_")[1] - b.resource_id.split("_")[1]
      ),
      tag_visualization: temp2.tag_visualization.sort(
        (a, b) => a.resource_id.split("_")[1] - b.resource_id.split("_")[1]
      ),
      tag_visualization_types: temp2.tag_visualization_types.map((e) => {
        return e.tag;
      }),
      manual_tags: temp2.manual_tags.sort(
        (a, b) => a.resource_id.split("_")[1] - b.resource_id.split("_")[1]
      ),
      manual_tags_type: temp2.manual_tags_type.sort(),
    };
    dispatch(fetchHighlightsDataSuccess(temp3));
    // setHighlightsData(temp3);
  };

  const handleTagTypeChange = (event) => {
    setTagType(event.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = useState(false);

  const currData = useRef(null);

  const [rate, setRate] = useState(1);
  const player = useRef(null);

  const rateUp = () => {
    if (rate < 4) {
      setRate(rate + 1);
    }
  };

  const rateDown = () => {
    if (rate > 1) {
      setRate(rate - 1);
    }
  };

  const rateReset = () => {
    setRate(1);
  };

  const closeModal = () => {
    setOpen(false);
    setUpdateTag("");
    setUpdateNote("");
  };

  const deleteTagHelper = async (tag_id) => {
    setPopupLoading(true);
    await deleteTag(tag_id, details.sender_id);
    closeModal();
    setPopupLoading(false);
    await updateAllTags();
  };

  const UpdateTagHelper = async (tag_id) => {
    setPopupLoading(true);
    await updateTagFunction(tag_id, updateTag, updateNote, details.sender_id);
    setUpdateTag("");
    setUpdateNote("");
    await updateAllTags();
    closeModal();
    setPopupLoading(false);
  };

  const [popupLoading, setPopupLoading] = useState(false);

  const [newTimestamp, setNewTimestamp] = useState({
    hh: "00",
    mm: "00",
    ss: "00",
  });
  const [newTag, setNewTag] = useState("");
  const [newNote, setNewNote] = useState("");

  const [updateTag, setUpdateTag] = useState("");
  const [updateNote, setUpdateNote] = useState("");

  const HighlightPopup = () => {
    // console.log(currData);
    return (
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        {popupLoading === true || currData.current === null ? (
          <Loading />
        ) : (
          <div
            style={{
              margin: "3rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2rem",
                top: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                closeModal();
              }}
            >
              X
            </div>
            <p>Timestamp: {currData.current.resource_id.split("_")[1]}</p>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <div>
                <div style={{ marginBottom: "1rem" }}>
                  <input
                    type="text"
                    placeholder="Tag"
                    value={updateTag}
                    onChange={(e) => {
                      setUpdateTag(e.target.value);
                    }}
                  ></input>
                  <span style={{ paddingLeft: "3rem" }}>
                    Current tag: {currData.current.tag}
                  </span>
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <input
                    type="text"
                    placeholder="Note"
                    value={updateNote}
                    onChange={(e) => {
                      setUpdateNote(e.target.value);
                    }}
                  ></input>
                  <span style={{ paddingLeft: "3rem" }}>
                    Current comment: {currData.current.comment}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => deleteTagHelper(currData.current.tag_id)}
                style={{
                  backgroundColor: "red",
                  border: "0px",
                  color: "white",
                }}
              >
                Delete
              </button>
              <button
                onClick={() => {
                  UpdateTagHelper(currData.current.tag_id);
                }}
                style={{
                  backgroundColor: "green",
                  border: "0px",
                  color: "white",
                }}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const handleAddTag = async () => {
    const addTemp = await addTag(
      type,
      +newTimestamp.hh * 60 * 60 + +newTimestamp.mm * 60 + +newTimestamp.ss,
      movieId,
      newTag,
      newNote,
      details.sender_id
    );

    // console.log(addTemp.message);
    await updateAllTags();
    setAddTagPopupOpen(false);
    setNewTimestamp({
      hh: "00",
      mm: "00",
      ss: "00",
    });
    setNewTag("");
    setNewNote("");
  };

  const seekToTimestamp = (time) => {
    // console.log(player.current);
    player.current.seekTo(time, "seconds");
  };

  const seekForward = () => {
    // console.log(player.current);
    const time = player.current.getCurrentTime();
    player.current.seekTo(time + 10, "seconds");
  };

  const seekBackward = () => {
    // console.log(player.current);
    const time = player.current.getCurrentTime();
    player.current.seekTo(time - 10, "seconds");
  };

  const [addTagPopupOpen, setAddTagPopupOpen] = useState(false);

  const addTagHelper = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "2rem",
            top: "1rem",
            cursor: "pointer",
          }}
          onClick={() => {
            setAddTagPopupOpen(false);
          }}
        >
          X
        </div>
        Add Tag
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            justifyContent: "space-between",
            alignItems: "center",
            width: "60%",
          }}
        >
          <TextField
            size="small"
            variant="outlined"
            label="HH"
            value={newTimestamp.hh}
            onChange={(e) => {
              setNewTimestamp({ ...newTimestamp, hh: e.target.value });
            }}
            sx={{ marginTop: "1rem", width: "28%" }}
          ></TextField>
          <TextField
            size="small"
            variant="outlined"
            label="mm"
            value={newTimestamp.mm}
            onChange={(e) => {
              setNewTimestamp({ ...newTimestamp, mm: e.target.value });
            }}
            sx={{ marginTop: "1rem", width: "28%" }}
          ></TextField>
          <TextField
            size="small"
            variant="outlined"
            label="ss"
            value={newTimestamp.ss}
            onChange={(e) => {
              setNewTimestamp({ ...newTimestamp, ss: e.target.value });
            }}
            sx={{ marginTop: "1rem", width: "28%" }}
          ></TextField>
        </div>
        {/* <TextField
          variant="outlined"
          label="Timestamp"
          value={newTimestamp}
          onChange={(e) => {
            setNewTimestamp(e.target.value);
          }}
          size="small"
          sx={{ marginTop: "1rem", width: "60%" }}
        ></TextField> */}
        <TextField
          size="small"
          variant="outlined"
          label="Tag"
          value={newTag}
          onChange={(e) => {
            setNewTag(e.target.value);
          }}
          sx={{ marginTop: "1rem", width: "60%" }}
        ></TextField>
        <TextField
          size="small"
          variant="outlined"
          label="Note"
          value={newNote}
          onChange={(e) => {
            setNewNote(e.target.value);
          }}
          sx={{ marginTop: "1rem", width: "60%" }}
          multiline
          rows={4}
        ></TextField>
        <Button
          onClick={() => handleAddTag()}
          variant="contained"
          disableElevation
          sx={{ marginTop: "1rem" }}
        >
          Add Tag
        </Button>
      </div>
    );
  };

  const addTagPopup = () => {
    // console.log(currData);
    return (
      <Popup
        open={addTagPopupOpen}
        closeOnDocumentClick
        onClose={() => setAddTagPopupOpen(false)}
      >
        <div
          style={{
            margin: "3rem",
            display: "flex",
          }}
        >
          {addTagHelper()}
        </div>
      </Popup>
    );
  };

  const playTimestamps = [
    ...new Set(
      useSelector((s) => {
        let chartArray = [];
        if (s.searchReducer.chart1 !== []) {
          chartArray.push(...s.searchReducer.chart1);
        }
        if (s.searchReducer.chart2 !== []) {
          chartArray.push(...s.searchReducer.chart2);
        }
        return chartArray;
      }).map((fields) => Number(fields.resource_id.split("_")[1]))
    ),
  ];

  // console.log(playTimestamps);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [customPlay, setCustomPlay] = useState(false);

  useEffect(async () => {
    console.log(customPlay);

    if (customPlay === false) {
      return;
    }

    console.log(customPlay);

    // console.log(playTimestamps);
    let idx = 0;
    for (let i = 0; i < playTimestamps.length; i++) {
      if (playTimestamps[i] === sceneTimestamp) {
        idx = i;
        break;
      }
    }

    for (let i = idx; i < playTimestamps.length; i++) {
      console.log(customPlay);

      setSceneTimestamp(playTimestamps[i]);
      seekToTimestamp(playTimestamps[i]);
      setPlaying(true);
      await wait(5000);
    }
  }, [customPlay]);

  // useEffect(() => {
  //   CustomPlayHelper(
  //     customPlay,
  //     playTimestamps,
  //     sceneTimestamp,
  //     setSceneTimestamp,
  //     seekToTimestamp,
  //     setPlaying,
  //     wait
  //   );
  // }, [customPlay, playTimestamps]);

  const handlePLayClick = async () => {
    setCustomPlay(true);

    // console.log(customPlay);

    // console.log(playTimestamps);
    let idx = 0;
    for (let i = 0; i < playTimestamps.length; i++) {
      if (playTimestamps[i] === sceneTimestamp) {
        idx = i;
        break;
      }
    }

    for (let i = idx; i < playTimestamps.length; i++) {
      // console.log(customPlay);

      setSceneTimestamp(playTimestamps[i]);
      seekToTimestamp(playTimestamps[i]);
      setPlaying(true);
      await wait(5000);
    }
  };

  return (
    <div
      className="col-md-10 p-4"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <HighlightPopup />
      {addTagPopup()}
      <div
        className="quickview-flex"
        style={{ justifyContent: "space-between" }}
      >
        <div className="height-100 col-md-4">
          <div>
            <h4>QuickView</h4>
            <p>Movie id: {movieId}</p>
          </div>
          <div>
            {video === undefined ? (
              <Loading />
            ) : (
              <ReactPlayer
                url={video}
                controls={true}
                playbackRate={rate}
                ref={player}
                // onReady={() => {
                //   player.current.seekTo(sceneTimestamp);
                // }}
                // If "onReady", infinity loop happed.
                onDuration={(e) => {
                  player.current.seekTo(sceneTimestamp);
                  setDuration(e);
                }}
                width="100%"
                // height="80%"
                playing={playing}
                // onPlay={() => setPlaying(true)}
                // onPause={() => setPlaying(false)}
              />
            )}

            {
              highlightsData.isLoading ? (
                <Loading />
              ) : (
                <TagVisualisation
                  duration={parseInt(duration)}
                  seekToTimestamp={seekToTimestamp}
                  player={player}
                  chartid="1"
                  />
              )
            }
            {
              !highlightsData.isLoading && twoTables ? (
                <TagVisualisation
                  duration={parseInt(duration)}
                  seekToTimestamp={seekToTimestamp}
                  player={player}
                  chartid="2"
                />
              ) : null
            }

            <ChevronDoubleLeft onClick={rateDown} />
            <ArrowClockwise onClick={rateReset} />
            <ChevronDoubleRight onClick={rateUp} />
            <span>{rate}</span>
            <span>x</span>

            <span
              onClick={() => {
                seekBackward();
              }}
            >
              <FontAwesomeIcon style={iconStyleAlt} icon={faUndo} />
            </span>
            <span
              onClick={() => {
                seekForward();
              }}
            >
              <FontAwesomeIcon style={iconStyleAlt} icon={faRedo} />
            </span>
            {customPlay === true ? (
              <button
                onClick={() => {
                  // handlePLayClick();
                  // setCustomPlay(false);
                  window.location.reload(false);
                }}
              >
                <FontAwesomeIcon style={iconStyleAlt} icon={faPause} />
              </button>
            ) : (
              <button
                onClick={() => {
                  // handlePLayClick();
                  setCustomPlay(true);
                }}
              >
                <FontAwesomeIcon style={iconStyleAlt} icon={faPlay} />
              </button>
            )}
          </div>
        </div>
        <div className="comments-box col-md-8 tables-wrapper">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ColorButton
              variant="contained"
              disableElevation
              sx={{ marginBottom: "1rem" }}
              onClick={() => {
                // console.log("add tag button clicked");
                setAddTagPopupOpen(true);
              }}
            >
              + Add tag
            </ColorButton>
            <ColorButton
              variant="contained"
              disableElevation
              sx={{ marginBottom: "1rem" }}
              onClick={() => {
                setTwoTables(!twoTables);
              }}
            >
              {twoTables === true ? "Remove table" : "Add table"}
            </ColorButton>
          </div>
            {
              highlightsData.isLoading ? (
                <Loading />
              ) : (
                <div className="two-tables">
                  <HistoryTable
                    player={player}
                    filter={filter}
                    setFilter={setFilter}
                    currData={currData}
                    setOpen={setOpen}
                    seekToTimestamp={seekToTimestamp}
                    sceneTimestamp={sceneTimestamp}
                    setSceneTimestamp={setSceneTimestamp}
                    scrollToTop={scrollToTop}
                    tableid="1"
                  />
                {
                  !highlightsData.isLoading && twoTables ? (
                    <HistoryTable
                      player={player}
                      filter={filter2}
                      setFilter={setFilter2}
                      currData={currData}
                      setOpen={setOpen}
                      seekToTimestamp={seekToTimestamp}
                      sceneTimestamp={sceneTimestamp}
                      setSceneTimestamp={setSceneTimestamp}
                      scrollToTop={scrollToTop}
                      tableid="2"
                    />
                    ) : null
                  }
                </div>
              )
            }
        </div>
      </div>
    </div>
  );
}

// create and update timestamp
