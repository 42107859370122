import React, { useState, useRef, useEffect } from "react";
import Heading from "../../components/Heading";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import { SEARCH_TABLE } from "../../utils/schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import {
  faChartLine,
  faTrash,
  faEdit,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import "./Search.css";


import Filter from "./Filter";

// import Autocomplete from "react-autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import {
  fetchSearchResult,
  fetchTagSuggestions,
} from "../../store/search/searchEffect";
import { resetSearchResult } from "../../store/search/searchAction";
import { useSelector, useDispatch } from "react-redux";

import { getHighlightOfMovie } from "../../api/Highlights";

import Popup from "reactjs-popup";

const ColorButton = styled(Button)(({ theme }) => ({
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#F7CF6D",
  "&:hover": {
    backgroundColor: "#f7af05",
  },
  width: 200,
}));

const iconStyle = {
  fontSize: 20,
  color: "grey",
};

const getDetails = () => {
  const res = window.location.search.toString().split("?")[1];
  let company_name = "";
  let sender_id = "";
  if (res) {
    company_name = res.split("&")[0].split("=")[1];
    sender_id = res.split("&")[1].split("=")[1];
  }

  return { company_name, sender_id };
};

export const Search = ({ showHeading }) => {
  const [details, setDetails] = useState(getDetails());

  const dispatch = useDispatch();

  const table = useSelector((state) => {
    return state.searchReducer.table;
  });
  const loading = useSelector((state) => {
    return state.searchReducer.loading;
  });
  const resource_type = useSelector((state) => {
    return state.searchReducer.resource_type;
  });
  const store = useSelector((state) => {
    return state.searchReducer;
  });
  console.log(store);

  const tagSuggestions = useSelector((state) => {
    return state.searchReducer.tags;
  });

  useEffect(() => {
    dispatch(fetchTagSuggestions(details));
  }, []);

  console.log(table);

  const [resourceType, setResourceType] = useState("VIDEO");
  const [tagKey, setTagKey] = useState([]);
  const [speaker, setSpeaker] = useState("");
  const [speakerManagement, setSpeakerManagement] = useState("");
  const [movie_id, setMovie_id] = useState("");

  console.log(tagKey);
  console.log(resourceType);

  const [open, setOpen] = useState(false);
  const [popupClipUrl, setPopupClipUrl] = useState(null);
  const [popupLoading, setPopupLoading] = useState(true);
  const closeModal = () => {
    setOpen(false);
    setPopupLoading(true);
    setPopupClipUrl(null);
  };

  console.log(popupClipUrl);

  const processHighlight = async (props) => {
    setOpen(true);
    console.log(props);
    setPopupClipUrl(
      await getHighlightOfMovie(props.movieId, "customized", details.sender_id)
      // await getHighlightOfMovie(props.movieId, props.resource_type)
    );
    setPopupLoading(false);
  };

  const HighlightPopup = () => {
    if (popupLoading === true) {
      return (
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <Loading />
        </Popup>
      );
    } else {
      return (
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <ReactPlayer
            url={popupClipUrl.highlights.url}
            controls={true}
            width="100%"
            // height="100%"
          />
        </Popup>
      );
    }
  };

  let tableData = "";
  console.log(table);
  if (table.data.length) {
    tableData = table.data.map((fields) => {
      let tag;
      if (fields.tag) {
        const responseTagNum = fields.tag.split(',').length;
        if (fields.tag_num <= responseTagNum) {
          tag = fields.tag;
        } else {
          tag = `${fields.tag} +${fields.tag_num-responseTagNum}`;
        }
      } else {
        tag = "";
      }
      return (
        <tr key={fields.resource_id ?? fields.movie_id}>
          <td>{fields.movie_id}</td>
          <td>{fields.user_id}</td>
          <td>{fields.date}</td>
          <td>{fields.title}</td>
          <td>{tag}</td>
          <td>
            {details.company_name ? (
              <div></div>
            ) : (
              <Link
                to={
                  "/movies/dashboard?movieId=" +
                  fields.movie_id +
                  "&type=" +
                  resource_type
                }
              >
                <FontAwesomeIcon style={iconStyle} icon={faChartLine} />
              </Link>
            )}
          </td>
          <td>
            {/* highlight */}
            <button
              style={{ backgroundColor: "white", border: "0px" }}
              onClick={() => {
                console.log(fields.movie_id);
                console.log(resource_type);
                const movieId = fields.movie_id;
                processHighlight({ movieId, resource_type });
              }}
            >
              <FontAwesomeIcon style={iconStyle} icon={faPlay} />
            </button>
          </td>
          <td>
            {/* quickview */}
            <Link
              to={
                details.company_name
                  ? "/share/search/quickView?company_name=" +
                    details.company_name +
                    "&sender_id=" +
                    details.sender_id +
                    "&movieId=" +
                    fields.movie_id +
                    "&type=" +
                    "SCENE" +
                    "&timestamp=" +
                    (resource_type === "SCENE" ? fields.resource_id.split("_")[1] : "0")
                  : "/search/quickView?movieId=" +
                    fields.movie_id +
                    "&type=" +
                    "SCENE" +
                    "&timestamp=" +
                    (resource_type === "SCENE" ? fields.resource_id.split("_")[1] : "0")
              }
            >
              <FontAwesomeIcon style={iconStyle} icon={faPlay} />
            </Link>
          </td>
        </tr>
      );
    });
  }

  const [pageNumber, setPageNumber] = useState(1);

  const matchStateToTerm = (state, value) => {
    return state.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  };

  const sortStates = (a, b, value) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();
    const valueLower = value.toLowerCase();
    const queryPosA = aLower.indexOf(valueLower);
    const queryPosB = bLower.indexOf(valueLower);
    if (queryPosA !== queryPosB) {
      return queryPosA - queryPosB;
    }
    return aLower < bLower ? -1 : 1;
  };

  return (
    <div className="p-4">
      <HighlightPopup />
      {/* <Card align="left"> */}
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "60%" }}>
          <Stack spacing={3}>
            {showHeading ? (
              <Heading heading="h5" align="left">
                Tag Search
              </Heading>
            ) : (
              <div></div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              Resource Type
              <span className="resource-wrapper">
                <span
                  className={
                    resourceType === "VIDEO"
                      ? "resource-selected resource-types"
                      : "resource-types"
                  }
                  onClick={() => {
                    setResourceType("VIDEO");
                    setTagKey([]);
                  }}
                >
                  Video
                </span>
                <span
                  className={
                    resourceType === "SCENE"
                      ? "resource-selected resource-types"
                      : "resource-types"
                  }
                  onClick={() => {
                    setResourceType("SCENE");
                    setTagKey([]);
                  }}
                >
                  Scene
                </span>
              </span>
            </div>
            <Autocomplete
              loading
              multiple
              limitTags={4}
              disableCloseOnSelect
              disablePortal
              id="combo-box-demo"
              options={
                tagSuggestions[
                  resourceType === "VIDEO" ? "video_tags" : "scene_tags"
                ]
              }
              onChange={(event, value) => {
                setTagKey(value);
              }}
              sx={{ width: "80%" }}
              renderInput={(params) => (
                <TextField {...params} label="Tag Key" fullWidth />
              )}
              value={tagKey}
              size="small"
            />
            {/* <div
              className="form-group mt-3 col-11"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label>Speaker</label>
              <input
                type="text"
                className="form-control mt-1 md-5"
                id="speakerInput"
                placeholder=""
                value={speaker}
                onChange={(e) => setSpeaker(e.target.value)}
              />
            </div> */}
            <ColorButton
              // type="submit"
              variant="contained"
              // className="btn btn-primary mt-4 col-3"
              disableElevation
              onClick={() => {
                console.log("on click called");
                setPageNumber(1);
                dispatch(
                  fetchSearchResult(
                    resourceType,
                    tagKey,
                    pageNumber,
                    20,
                    speaker,
                    speakerManagement,
                    details,
                    movie_id
                  )
                );
              }}
            >
              Search
            </ColorButton>
          </Stack>
        </div>
        <div style={{ width: "40%", color: "black" }}>
          {/* <p className="heading-color">All tags</p>
          <div
            style={{
              overflowY: "scroll",
              marginTop: "1vh",
              height: "35vh",
            }}
          >
            {tagSuggestions[
              resourceType === "VIDEO" ? "video_tags" : "scene_tags"
            ].map((suggestion) => {
              return (
                <div
                  className="tag-suggestion"
                  onClick={() => {
                    setTagKey(suggestion);
                  }}
                >
                  {suggestion}
                </div>
              );
            })}
          </div> */}
          <Filter
            setSpeakerManagement={setSpeakerManagement}
            setSpeaker={setSpeaker}
            setMovie_id={setMovie_id}
          />
        </div>
      </div>
      {/* </Card> */}
      <div className="mt-5"></div>
      <div>
        <Heading heading="h5" align="left">
          Search Results
        </Heading>
        <p></p>
      </div>
      {/* <Card> */}
      {loading === false ? (
        <Table schema={SEARCH_TABLE} data={tableData} />
      ) : (
        <Loading />
      )}
      {/* </Card> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          alignItems: "center",
        }}
      >
        {pageNumber === 1 ? null : (
          <button
            onClick={() => {
              const curtemp = pageNumber - 1;
              dispatch(
                fetchSearchResult(
                  resourceType,
                  tagKey,
                  curtemp,
                  20,
                  speaker,
                  speakerManagement,
                  details,
                  movie_id
                )
              );
              setPageNumber(pageNumber - 1);
            }}
          >
            {"<"}
          </button>
        )}

        <p style={{ margin: "0 1rem" }}>{pageNumber}</p>
        <button
          onClick={() => {
            const curtemp = pageNumber + 1;
            dispatch(
              fetchSearchResult(
                resourceType,
                tagKey,
                curtemp,
                20,
                speaker,
                speakerManagement,
                details,
                movie_id
              )
            );
            setPageNumber(pageNumber + 1);
          }}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export const SearchContainer = () => {
  return (
    <div style={{ width: "80%" }}>
      <Search showHeading={true} />
    </div>
  );
};
