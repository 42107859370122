import React, { useState, useEffect } from "react";
import "./NotificationConfig.css";
import CreateAlert from "./CreateAlert";
import ListAlert from "./ListAlert";

export default function NotificationConfig() {
  const [settingSection, setSettingSection] = useState("general");

  return (
    <div>
      <div className="notification-config-sections">
        <p
          className={
            settingSection === "general"
              ? "notification-section-selector selected"
              : "notification-section-selector"
          }
          onClick={() => {
            setSettingSection("general");
          }}
        >
          All alert conditions
        </p>
        <p
          className={
            settingSection === "advanced"
              ? "notification-section-selector selected"
              : "notification-section-selector"
          }
          onClick={() => {
            setSettingSection("advanced");
          }}
        >
          Create alert condition
        </p>
      </div>
      <div className="config-body">
        {settingSection === "advanced" ? <CreateAlert /> : <ListAlert />}
      </div>
    </div>
  );
}
