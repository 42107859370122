import React, { useState, useEffect } from "react";
import HealthyGauge from "./HealthyGauge";
import UserHealthyHeatMap from "./UserHealthyHeatMap";

import { getAvgHealthyScoresGroupByParticipant } from "../../../api/HealthyScores";

const UserHealthy = (props) => {
  const [userId, setUserId] = useState("all");
  const [healthyScores, sethealthyScores] = useState({});

  const dataset = props.dataset;
  const users = props.users;

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  // useEffect(() => {
  // 	getAvgHealthyScoresGroupByParticipant()
  // 		.then((response) => {
  // 			// console.log(response)
  // 			sethealthyScores(response);
  // 		})
  // 		.catch((err) => {
  // 			console.log(err);
  // 		});
  // }, []);

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">User Health Score</span>
      <div className="bar-histogram-chart-column-card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <UserHealthyHeatMap
                dataset={dataset}
                users={users}
                setUserId={setUserId}
              />
            </div>
            <div className="col-md-6">
              <HealthyGauge dataset={dataset} userId={userId} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserHealthy;
