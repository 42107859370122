import React from "react";
import ApexCharts from "react-apexcharts"

import Loading from "../Loading";

const EmotionPieGauge = ({
  emotionName,
  color,
  isLoaded,
  percentageScore,
  isBenchmarkSetting,
  percentageBenchmarkScore
}) => {
  const COLOR = {
    BLACK: "#000",
    GRAY: "#888",
    RED: "#EC8A87",
    GREEN: "#80BFA2",
  };

  let dataLabelsOptions;
  let benchmarkColor;
  let benchmarkFormat;
  if (isBenchmarkSetting) {
    const benchmarkDiffScore = Math.round(percentageScore - percentageBenchmarkScore);
    if (benchmarkDiffScore < 0) {
      benchmarkColor = COLOR.RED;
      benchmarkFormat = `▼ ${Math.abs(benchmarkDiffScore)}%`;
    } else if (benchmarkDiffScore > 0) {
      benchmarkColor = COLOR.GREEN;
      benchmarkFormat = `▲ ${Math.abs(benchmarkDiffScore)}%`;
    } else if (benchmarkDiffScore === 0) {
      benchmarkColor = COLOR.GREEN;
      benchmarkFormat = `▲ ${Math.abs(benchmarkDiffScore)}%`;
    } else {
      benchmarkFormat = '';
    }
    dataLabelsOptions = {
      name: {
        show: true,
        offsetY: 20,
        color: benchmarkColor,
        fontSize: '12px',
        formatter: function () {
          return [benchmarkFormat];
        }
      },
      value: {
        show: true,
        formatter: (value) => `${Math.round(value)}%`,
        color: COLOR.BLACK,
        fontSize: '18px',
        offsetY: -16,
      },
    }
  } else {
    dataLabelsOptions = {
      name: {
        show: false,
      },
      value: {
        show: true,
        formatter: (value) => `${Math.round(value)}%`,
        color: COLOR.BLACK,
        fontSize: '18px',
        offsetY: 7,
      },
    }
  }

  let options = {
    series: [percentageScore],
    colors: [color],
    chart: {
      height: 150,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        // ApecCharts has a bag. Below code is not run well.
        // When value is zero, radial bar is completetly colored as 100%.
        // startAngle: -270,
        // endAngle: 90,
        startAngle: 0,
        endAngle: 360,

        hollow: {
          size: '60%',
          background: "transparent",
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          show: true,
          startAngle: 0,
          endAngle: 360,
          background: '#EEE',
          strokeWidth: '97%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
        dataLabels: dataLabelsOptions,
      },
    },
  };
  return (
    <div className="dashboard-permeeting-emotions-card">
      {
        isLoaded === "Not Loaded"
          ? <Loading />
          : (
            <div className="dashboard-permeeting-emotions-entry">
              <span className="dashboard-permeeting-highlight-score-title">{emotionName}</span>
              <ApexCharts options={options} series={options.series} type="radialBar" height={150} />
            </div>
        )
      }
    </div>
  );
};

export default EmotionPieGauge;
