import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTrash,
  faEdit,
  faPlay,
  faArrowAltCircleUp,
  faRedo,
  faUndo,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

import { chart1, chart2 } from "../../store/search/searchAction";


const ColorButton = styled(Button)(({ theme }) => ({
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#C7C7C9",
  "&:hover": {
    backgroundColor: "#F7CF6D",
  },
}));

const columns = [
  { id: "timestamp", label: "Timestamp", minWidth: "4rem" },
  { id: "tag", label: "Tag", minWidth: "5rem" },
  { id: "value", label: "Value", minWidth: "3rem" },
  // { id: "tagType", label: "Tag type" },
  {
    id: "note",
    label: "Note",
    minWidth: "3rem",
  },
  // {
  //   id: "update",
  //   label: "Update",
  //   align: "right",
  // },
];

const iconStyle = {
  fontSize: 20,
  color: "grey",
};

export default function HistoryTable({
  player,
  filter,
  setFilter,
  currData,
  setOpen,
  seekToTimestamp,
  sceneTimestamp,
  setSceneTimestamp,
  scrollToTop,
  tableid,
}) {
  const [curTime, setCurTime] = useState(0);
  console.log(curTime);
  useEffect(async () => {
    if (player === null || player.current === null) {
      return;
    }
    console.log("calculating");

    const intervalId = setInterval(() => {
      if (player === null || player.current === null) {
        return;
      }

      const temp = Math.floor(player.current.getCurrentTime());
      setCurTime(secondsToHms(temp));
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [player.current]);

  let bgColor = "#83D0FF";
  if (tableid === "2") {
    bgColor = "#80BFA2";
  }

  const dispatch = useDispatch();
  const [tagType, setTagType] = useState("ai");
  const highlightsData = useSelector((state) => {
    return state.searchReducer.highlights;
  });

  const data = useSelector((state) => {
    if (tagType === "ai") {
      return highlightsData.tags;
    } else if (tagType === "raw") {
      return highlightsData.tag_visualization;
    } else if (tagType === "manual") {
      return highlightsData.manual_tags;
    }
  });

  const options = useSelector((state) => {
    if (tagType === "ai") {
      return highlightsData.tags_type;
    } else if (tagType === "raw") {
      return highlightsData.tag_visualization_types;
    } else if (tagType === "manual") {
      return highlightsData.manual_tags_type;
    }
  });

  const tagTypeSelect = () => {
    return (
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-label">Tag type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tagType}
          label="Tag type"
          onChange={handleTagTypeChange}
          size="small"
        >
          <MenuItem value={"ai"}>ai</MenuItem>
          <MenuItem value={"raw"}>raw</MenuItem>
          <MenuItem value={"manual"}>manual</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const handleTagTypeChange = (event) => {
    setTagType(event.target.value);
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  useEffect(() => {
    if (tableid === "1") {
      dispatch(
        chart1(
          data.filter((e) => {
            // if (filter === null) {
            //   return [];
            // } else {
            //   return e.tag === filter;
            // }
            if (filter.length === 0) {
              return true;
            }
            for (let i = 0; i < filter.length; i++) {
              if (filter[i] === e.tag) {
                return true;
              }
            }
            return false;
          })
        )
      );
    } else if (tableid === "2") {
      dispatch(
        chart2(
          data.filter((e) => {
            // if (filter === null) {
            //   return [];
            // } else {
            //   return e.tag === filter;
            // }
            if (filter.length === 0) {
              return true;
            }
            for (let i = 0; i < filter.length; i++) {
              if (filter[i] === e.tag) {
                return true;
              }
            }
            return false;
          })
        )
      );
    }
  }, [data, tagType, filter]);

  const muiTableData = data
    .filter((e) => {
      // if (filter === null) {
      //   return [];
      // } else {
      //   return e.tag === filter;
      // }
      if (filter.length === 0) {
        return true;
      }
      for (let i = 0; i < filter.length; i++) {
        if (filter[i] === e.tag) {
          return true;
        }
      }
      return false;
    })
    .map((fields) => {
      return {
        timestamp: (
          <>
            <IconButton
              onClick={() => {
                currData.current = fields;
                setOpen(true);
              }}
              size="small"
              // sx={{ textAlign: "center" }}
            >
              <FontAwesomeIcon style={iconStyle} icon={faEdit} />
            </IconButton>
            <Button
              variant="outlined"
              onClick={() => {
                seekToTimestamp(fields.resource_id.split("_")[1]);
                setSceneTimestamp(fields.resource_id.split("_")[1]);
                scrollToTop();
              }}
              size="small"
            >
              {secondsToHms(fields.resource_id.split("_")[1])}
            </Button>
          </>
        ),
        tag: (
          <span
            style={{
              backgroundColor: bgColor,
              // margin: "2px",
              padding: "4px 10px",
              // borderRadius: "50px",
              cursor: "pointer",
              // color: 'white',
            }}
            onClick={() => {
              seekToTimestamp(fields.resource_id.split("_")[1]);
              setSceneTimestamp(fields.resource_id.split("_")[1]);
              scrollToTop();
            }}
          >
            {fields.tag}
          </span>
        ),
        value: <p>{fields.value}</p>,
        note: fields.comment,
      };
    });

  const scrollToRow = (row) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const listRef = useRef([]);
  // console.log(listRef);

  // useEffect(() => {
  //   // console.log(sceneTimestamp);
  //   // console.log(secondsToHms(sceneTimestamp));
  //   // console.log(listRef.current[secondsToHms(sceneTimestamp)]);
  //   if (
  //     listRef.current[secondsToHms(sceneTimestamp)] !== undefined &&
  //     listRef.current[secondsToHms(sceneTimestamp)] !== null
  //   ) {
  //     listRef.current[secondsToHms(sceneTimestamp)].focus();
  //   }
  // }, [sceneTimestamp]);
  useEffect(() => {
    if (
      listRef.current[curTime] !== undefined &&
      listRef.current[curTime] !== null
    ) {
      listRef.current[curTime].focus();
    }
  }, [curTime]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "0.25rem",
      }}
    >
      <div className="space-between comment-heading">
        {tagTypeSelect()}
        <Autocomplete
          loading
          multiple
          limitTags={2}
          disableCloseOnSelect
          disablePortal
          id="combo-box-demo"
          options={options}
          onChange={(event, newValue) => {
            setFilter(newValue);
            // console.log(newValue);
          }}
          sx={{ minWidth: 250 }}
          renderInput={(params) => <TextField {...params} label="Filter" />}
          size="small"
        />
      </div>
      <Paper
        sx={{
          // width: "100%",
          overflow: "hidden",
          height: "calc(100vh - 3rem - 5rem)",
        }}
      >
        <TableContainer sx={{ maxHeight: "100%", overflowX: "auto" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ overflowX: "scroll" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    // key={column.id}
                    align={column.align}
                    style={{ maxWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {muiTableData
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  // console.log(i)
                  // console.log(row.timestamp.props.children[1].props.children);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      // key={row.code}
                      // selected={
                      //   secondsToHms(
                      //     sceneTimestamp !== null ? sceneTimestamp : null
                      //   ) === row.timestamp.props.children[1].props.children
                      //     ? true
                      //     : false
                      // }
                      selected={
                        curTime ===
                        row.timestamp.props.children[1].props.children
                          ? true
                          : false
                      }
                      ref={(ref) =>
                        (listRef.current[
                          row.timestamp.props.children[1].props.children
                        ] = ref)
                      }
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            // key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.minWidth,
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
