import { Auth, API } from "aws-amplify";

export async function getAllNotes() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "NotesAPI";
	const path = "?account_id=" + accountId;
	return await API.get(apiName, path);
}

export async function getNotesOfThePage(pathName) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "NotesAPI";
	const path = "?account_id=" + accountId + "&page=" + pathName;
	return await API.get(apiName, path);
}

export async function syncNotes(cards, pathName, movieId, userId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "NotesAPI";
	const path = "?account_id=" + accountId + "&path_name=" + pathName + "&movie_id=" + movieId + "&participant_id=" + userId;
	const myInit = {
		body: {
			notes: cards
		}, 
	};
	return await API.post(apiName, path, myInit)
}

export async function deleteNote(noteId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "NotesAPI";
	const path = "?account_id=" + accountId + "&note_id=" + noteId;
	return await API.del(apiName, path)
}