import { Auth, API } from "aws-amplify";

// For all
export async function getMeetingMetrics(movieId) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MeetingMetricsAPI_V2";
  const path = "?account_id=" + accountId;
  const link = await API.get(apiName, path);

  return fetch(link)
    .then((response) => {
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then(async (blob) => {
      const data = JSON.parse(await blob.text());
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function getMeetingMetricsDetails() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MeetingMetricsAPI_V2";
  const path = "?account_id=" + accountId + "&type=detail";

  const link = await API.get(apiName, path);

  return fetch(link)
    .then((response) => {
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then(async (blob) => {
      const data = JSON.parse(await blob.text());
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
}

// export async function getMeetingMetrics(movieId) {
//   const accountId = (await Auth.currentUserInfo()).attributes.sub;
//   const apiName = "MeetingMetricsAPI";
//   const path = "?account_id=" + accountId;
//   return await API.get(apiName, path);
// }

// export async function getMeetingMetricsDetails() {
//   const accountId = (await Auth.currentUserInfo()).attributes.sub;
//   const apiName = "MeetingMetricsAPI";
//   const path = "?account_id=" + accountId + "&type=detail";

//   return await API.get(apiName, path);
// }
