export const fetchMeetingMetricsRequest = () => {
	return {
	  type: "FETCH_MEETING_METRICS_REQUEST"
	}
  }
  
  export const fetchMeetingMetricsSuccess = data => {
	return {
	  type: "FETCH_MEETING_METRICS_SUCCESS",
	  payload: data
	}
  }
  
  export const fetchMeetingMetricsFailure = error => {
	return {
	  type: "FETCH_MEETING_METRICS_FAILURE",
	  payload: error
	}
  }
