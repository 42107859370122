import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
	createAccount
} from "../../api/Accounts";

import Card from "../../components/Card";
import Logo from "../../assets/logo.png";

const schema = yup.object({
	email: yup
		.string()
		.required('Please enter a email address')
		.email('Please enter a properly formatted email address'),
	fullName: yup.string().required('Please enter a full name'),
	password: yup
		.string()
		.required('Please enter a password')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/,
			"Weak password"
		),
	confirmPassword: yup.string()
		.required('Please enter a confirm password')
		.oneOf([yup.ref('password')], 'Passwords must match'),
	company: yup
		.string()
		.required('Please enter a company name')
})

export const SginUp = () => {

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid } } = useForm({
			mode: 'onBlur',
			reValidateMode: 'onChange',
			resolver: yupResolver(schema)
		});

	const onSubmit = (data) => {
		// console.log(data);

		createAccount(data)
			.then((response) => {
				console.log(response)
				alert("Successfully created!");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Style for logo
	const logStyle = {
		width: "153px",
		height: "105px",
		objectFit: "cover",
		objectPosition: "50% 50%",
	};

	return (
		<div className='row justify-content-center'>
			<div className='col-md-5'>
				<img
					className='my-4 mx-auto d-block'
					src={Logo}
					alt="I'm beside you"
					style={logStyle}
				/>
				<Card>
					<div className="mb-5">
						<h4>SIGN UP</h4>
					</div>
					<div className="text-start">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row justify-content-center my-4">
								<div class="col-md-10">
									<label class="form-label ms-2">Full name</label>
									<input
										className="form-control"
										type='text'
										{...register("fullName")}
									/>
									<div className="text-danger ms-2 h6">
										{errors.fullName?.message}
									</div>
								</div>
							</div>
							<div className="row justify-content-center my-4">
								<div class="col-md-10">
									<label class="form-label ms-2">Email</label>
									<input
										className="form-control"
										type='email'
										{...register("email")}
									/>
									<div className="text-danger ms-2 h6">
										{errors.email?.message}
									</div>
								</div>
							</div>
							<div className="row justify-content-center my-4">
								<div class="col-md-10">
									<label class="form-label ms-2">Password</label>
									<input
										className="form-control"
										type='password'
										{...register("password")}
									/>
									<div class="form-text ms-2">Must contain 8 characters, one uppercase, one lowercase, one number and one special case character.</div>
									<div className="text-danger ms-2 h6">
										{errors.password?.message}
									</div>
								</div>
							</div>
							<div className="row justify-content-center my-4">
								<div class="col-md-10">
									<label class="form-label ms-2">Confirm Password</label>
									<input
										className="form-control"
										type='password'
										{...register("confirmPassword")}

									/>
									<div className="text-danger ms-2 h6">
										{errors.confirmPassword?.message}
									</div>
								</div>
							</div>
							<div className="row justify-content-center my-4">
								<div class="col-md-10">
									<label class="form-label ms-2">Company name</label>
									<input
										className="form-control"
										type='text'
										{...register("company")}
									/>
									<div className="text-danger ms-2 h6">
										{errors.company?.message}
									</div>
								</div>
							</div>
							<div className="text-center mt-5">
								<button
									type="submit"
									className="btn btn-primary btn-lg w-25"
									disabled={!isValid}
								>
									SUBMIT
								</button>
							</div>
						</form>
					</div>
				</Card>
			</div>
		</div>
	);
};

export default SginUp;
