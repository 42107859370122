import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

import Card from "../../components/Card";

import { v4 as uuidv4 } from 'uuid';

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
	getMovie, deleteMovie, updateMovie
} from "../../api/Movies";

const schema = yup.object({
	topic: yup
		.string()
		.required('Please enter a topic')

})

export const UpdateMovie = () => {

	const [movie, setMovie] = useState({
		movieId: "",
		topic: "",
		uploadDate: "",
	});

	const [updating, setUpdating] = useState(false)

	const {
		handleSubmit, control, register, reset, formState: { errors, isValid }
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema)
	});

	useEffect(() => {
		const movieInfo = {}
		const params = new URLSearchParams(window.location.search);
		const movieId = params.get('movieId');

		movieInfo["movieId"] = movieId;

		getMovie(movieId)
			.then((response) => {
				movieInfo["topic"] = response.movies.topic;
				movieInfo["uploadDate"] = response.movies.upload_time;
				setMovie(movieInfo);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		reset(movie);
	}, [movie]);

	const onSubmit = (data) => {
		// console.log(data)
		setUpdating(true)
		updateMovie(movie.movieId, data)
			.then((response) => {
				console.log(response)
				alert("Successfully updated!");
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setUpdating(false)
			});
	};

	return (
		<div className='col-md-10 p-4 mt-5'>
			<div className='row justify-content-center'>
				<div className='col-md-6'>
					<Card>
						<h3>Update movie</h3>
						<div className="text-start">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row my-4">
									<label class="form-label ms-2">ID</label>
									<div class="col-md-12">
										<input
											className="form-control"
											type='text'
											{...register("movieId")}
											disabled="disabled"
										/>
										{/* <div className="text-danger ms-2 h6">
											{errors.movieId?.message}
										</div> */}
									</div>
								</div>

								<div className="row my-4">
									<label class="form-label ms-2">TOPIC</label>
									<div class="col-md-12">
										<input
											className="form-control"
											type='text'
											{...register("topic")}
										/>
										<div className="text-danger ms-2 h6">
											{errors.topic?.message}
										</div>
									</div>
								</div>

								<div className="row my-4">
									<label class="form-label ms-2">UPLOAD DATE</label>
									<div class="col-md-12">
										<input
											className="form-control"
											type='text'
											{...register("uploadDate")}
											disabled="disabled"
										/>
										{/* <div className="text-danger ms-2 h6">
											{errors.uploadDate?.message}
										</div> */}
									</div>
								</div>


								<div className="text-center mt-5">
									<button
										type="submit"
										class="btn btn-primary btn-lg w-25"
										disabled={!isValid || updating}
									>
										{updating && <span className="spinner-border spinner-border-sm mr-1"></span>}
										UPDATE
									</button>
								</div>

							</form>
						</div>
					</Card>
				</div>
			</div>
		</div>

	);
};

export default UpdateMovie;
