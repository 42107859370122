// Step 1 - Include react
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Step 2 - Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Step 3 - Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Step 4 - Include the chart type
import Line2D from "fusioncharts/fusioncharts.charts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";

// Step 5 - Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
// import { info } from "node-sass";

// Step 6 - Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Line2D, FusionTheme);

// Create a JSON object to store the chart configurations

export default function TranscriptVisualisation({
  duration,
  seekToTimestamp,
  chartid,
  movieId,
}) {
  let bgColor = "#83D0FF";
  if (chartid === "2") {
    bgColor = "#80BFA2";
  }

  let lineColor = "#127fcb";
  if (chartid === "2") {
    lineColor = "#023020";
  }

  const raw = useSelector((state) => {
    return state.searchReducer.transcriptChart;
  });
  console.log(raw);

  const [data, setData] = useState([]);

  useEffect(async () => {
    var chartData = [];
    for (var i = 0; i < raw.length; i = i + 1) {
      // if (
      //   i === raw.length - 1 ||
      //   raw[i].resource_id.split("_")[1] !==
      //     raw[i + 1].resource_id.split("_")[1]
      // ) {
      //   chartData.push({
      //     label: secondsToHms(raw[i].resource_id.split("_")[1]),
      //     value: count,
      //     anchorRadius: "4",
      //     anchorBorderThickness: "2",
      //     anchorBorderColor: lineColor,
      //     anchorBgColor: bgColor,
      //     color: lineColor,
      //   });
      //   count = 1;
      // } else {
      //   count = count + 1;
      // }
      // console.log(raw[i])
      if (raw[i].value === 0) {
        chartData.push({
          label: secondsToHms(raw[i].label),
          value: raw[i].value,
          anchorRadius: "0",
          anchorBorderThickness: "2",
          anchorBorderColor: lineColor,
          anchorBgColor: bgColor,
          color: lineColor,
        });
      } else {
        chartData.push({
          label: secondsToHms(raw[i].label),
          value: raw[i].value,
          anchorRadius: "4",
          anchorBorderThickness: "2",
          anchorBorderColor: lineColor,
          anchorBgColor: bgColor,
          color: lineColor,
        });
      }
    }

    setData(chartData);
  }, [raw, duration]);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const hmsToSeconds = (e) => {
    // console.log(e);
    var h = Number(e.split(":")[0]);
    var m = Number(e.split(":")[1]);
    var s = Number(e.split(":")[2]);

    return h * 3600 + m * 60 + s;
  };

  // console.log(data);

  const chartConfigs = {
    type: "line",
    width: "100%",
    height: "100",
    dataFormat: "json",
    dataSource: {
      chart: {
        showYAxisValues: "0",
        showXAxisValues: "0",
        theme: "fusion",
        plotToolText: `Timestamp: $label <br> Count: $dataValue`,
        // xAxisMinValue: "0",
        // xAxisMaxValue: duration,
      },
      data: data,
    },
    events: {
      dataPlotClick: function (e) {
        seekToTimestamp(hmsToSeconds(e.data.id));
      },
    },
  };

  return (
    <div>
      <ReactFC {...chartConfigs} />
    </div>
  );
}
