import React, { useState, useEffect } from "react";
import HealthyScoreColumn from "./HealthyScoreColumn";
import ScoreCard from "./ScoreCard";

const HealthyScores = (props) => {
  const dataset = props.dataset;
  const userId = props.userId;

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Healthy Score</span>
      <div className="row">
        <div className="col-md-12">
          <HealthyScoreColumn dataset={dataset} userId={userId} />
        </div>
        {/* <div className='col-md-4'>
				<ScoreCard dataset={dataset}  userId={userId}/>
			</div> */}
      </div>
    </section>
  );
};

export default HealthyScores;
