import { Auth, API } from "aws-amplify";

export async function getRole() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "GetRoleAPI";
  const path = "?account_id=" + accountId;
  return await API.get(apiName, path);
}

export async function getOrganisationList() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "GetOrganisationListAPI";
  const path = "?account_id=" + accountId;
  return await API.get(apiName, path);
}

export async function getFeatures(check_account_id) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  if (check_account_id === true) {
    check_account_id = accountId;
  }
  const apiName = "GetFeaturesAPI";
  const path =
    "?account_id=" + accountId + "&check_account_id=" + check_account_id;
  return await API.get(apiName, path);
}

export async function updateFeature(feature_id, active, change_account_id) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;

  const apiName = "UpdateFeatureAPI";
  const path = "";
  let myInit = {};

  myInit = {
    body: {
      account_id: accountId,
      feature_id: feature_id,
      active: active,
      change_account_id: change_account_id,
    },
  };

  console.log(myInit.body);
  return await API.post(apiName, path, myInit);
}
