import { Auth, API } from "aws-amplify";

export async function getHostSpeakFeatures(movie_id) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ParticipantSpeakFeaturesAPI";
  const path = `?account_id=${accountId}&movie_id=${movie_id}`;
  const response = await API.get(apiName, path);

  const hostFeatures = response.filter(feature => feature.is_host);
  let result = {}
  if (hostFeatures.length === 1) {
    result = {
      zoomUserId: hostFeatures[0].zoom_user_id,
      avgSpeakDuration: hostFeatures[0].avg_speak_duration,
      speakRatio: hostFeatures[0].speak_ratio,
    }
  }
  return result
}

export async function getParticipantSpeakFeatures(movie_id) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ParticipantSpeakFeaturesAPI";
  const path = `?account_id=${accountId}&movie_id=${movie_id}`;
  const response = await API.get(apiName, path);

  const result = response.map(feature => ({
    isHost: response.is_host,
    zoomUserId: response.zoom_user_id,
    avgSpeakDuration: response.avg_speak_duration,
    speakRatio: response.speak_ratio,
  }));

  return result
}
