import React from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme, TimeSeries);
charts(FusionCharts);

const EmotionBarLine = (props) => {
  console.log(props.dataset);
  const faceEmotions = props.dataset;
  const dataSource = plotData(faceEmotions);

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Facial Emotion Score</span>
      <div className="bar-histogram-chart-column-card">
        <div className="card-body">
          <ReactFC
            type="timeseries"
            width="100%"
            height="600"
            dataFormat="JSON"
            renderAt="container"
            dataSource={dataSource}
          />
        </div>
      </div>
    </section>
  );
};

export default EmotionBarLine;
