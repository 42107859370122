import React, { useState, useEffect } from "react";

import Select from 'react-select';

const chartOptions = [
    { value: 'faceEmotions', label: 'Face emotions' },
    { value: 'textSentiments', label: 'Text negaposi' },
    { value: 'speechSpeed', label: 'Speech speed' },
]

const ChartSelect = (props) => {

    return (
        <div className="row justify-content-end">
            <div class="col-4 py-2">
                <Select
                    options={chartOptions}
                    onChange={(value) => {
                        props.setsSlectedChartType(value.value);
                    }}
                />
            </div>
        </div>
    );
};

export default ChartSelect;