import React, { useState, useEffect } from "react";
import Highlight from "./Highlight";

import { getRandomHighlightsOfMovie } from "../../../api/Highlights";
import "./index.css";

const Highlights = (props) => {
  const [highlights, setHighlights] = useState({});

  useEffect(() => {
    getRandomHighlightsOfMovie("happy", 4)
      .then((response) => {
        console.log(response);
        setHighlights(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  let highlightList = highlights.highlights
    ? highlights.highlights.map((highlight) => {
        return (
          <div className="highlight-video-card">
            <Highlight url={highlight.url} topic={highlight.topic} />
          </div>
        );
      })
    : "";

  return (
    <div className="highlights-root">
      <span className="section-title-text">Pickup highlights</span>

      <div className="highlight-cards-row">{highlightList}</div>
    </div>
  );
};

export default Highlights;
