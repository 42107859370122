import React, { useEffect, useState, useRef } from "react";

import ReactPlayer from "react-player";
import { getHighlightOfMovie } from "../../../../api/Highlights";
import "./index.css";

import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ArrowClockwise,
} from "react-bootstrap-icons";

const Highlight = (props) => {
  const player = useRef(null);

  const url = props.url;
  const topic = props.topic;
  const [rate, setRate] = useState(1);

  const rateUp = () => {
    if (rate < 4) {
      setRate(rate + 1);
    }
  };

  const rateDown = () => {
    if (rate > 1) {
      setRate(rate - 1);
    }
  };

  const rateReset = () => {
    setRate(1);
  };

  return (
    <div className="highlight-card-column">
      <div>{topic}</div>
      <ReactPlayer
        url={url}
        controls={true}
        playbackRate={rate}
        ref={player}
        width="100%"
        height="100%"
      />
      <div className="highlight-video-controls">
        <span>Playback Speed </span>
        <ChevronDoubleLeft onClick={rateDown} />
        {/* <ArrowClockwise onClick={rateReset} /> */}
        <span>{rate}</span>
        <span>x</span>
        <ChevronDoubleRight onClick={rateUp} />
      </div>
    </div>
  );
};

export default Highlight;
