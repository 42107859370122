import React, { useState, useEffect } from "react";

import { getMovie } from "../../../../api/Movies";

import { getLatestHighlightOfParticipant } from "../../../../api/Highlights";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const S3Video = (props) => {
  const userId = props.userId;
  const type = props.type;
  const [url, setUrl] = useState("");

  console.log(props);

  useEffect(() => {
    getLatestHighlightOfParticipant(userId, type)
      .then((response) => {
        console.log(response);
        setUrl(response.highlights.url);
      })
      .catch((err) => {
        setUrl("");
        console.log(err);
      });
  }, []);

  const vidoDeom =
    url != "" ? (
      <video controls width="240" height="240" src={url} />
    ) : (
      <div>No data</div>
    );

  return (
    <div>
      <div className="py-2" style={{ fontWeight: "bold", textAlign: "center" }}>
        {capitalizeFirstLetter(type)} hightlight
      </div>
      {vidoDeom}
    </div>
  );
};

export default S3Video;
