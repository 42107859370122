import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from "../../components/Card";

import { getZoomJWTSettings, updateZoomJWTSettings, getZoomS2SOAuthSettings, updateZoomS2SOAuthSettings } from "../../api/ZoomSettings";

const schema = yup.object({
  emails: yup
    .array()
    .of(
      yup.object().shape({
        email: yup
          .string()
          .required("Please enter a email address")
          .email("Please enter a properly formatted email address"),
      })
    )
    .min(1, "Minimum of 1 field"),
  zoom_account_id: yup.string().required("Please enter a account id"),
  zoom_client_id: yup.string().required("Please enter a client id"),
  zoom_client_secret: yup.string().required("Please enter a client secret"),
});

export const Settings = () => {
  const [zoomSettings, setZoomSettings] = useState({
    zoom_account_id: "",
    zoom_client_id: "",
    zoom_client_secret: "",
    emails: [""],
  });

  const [zoomJWTSettings, setZoomJWTSettings] = useState({
    zoom_api_key: "",
    zoom_api_secret: "",
  });


  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: zoomSettings,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
  });

  console.log(zoomSettings)
  console.log(zoomJWTSettings)

  useEffect(async () => {
    try{
      const response = await getZoomJWTSettings();
      setZoomJWTSettings(response.zoom_settings);
    } catch (err) {
        console.log(err);
      }
  }, []);

  useEffect(async () => {
    try{
      const response = await getZoomS2SOAuthSettings();
      setZoomSettings(response.zoom_settings);
    } catch (err) {
        console.log(err);
      }
  }, []);

  useEffect(() => {
    reset(zoomSettings);
  }, [zoomSettings]);

  const onSubmit = (data) => {
    // console.log(data);
    // console.log('is not Empty');
    setZoomSettings(data);

    updateZoomS2SOAuthSettings(data)
      .then((response) => {
        // console.log(response)
        alert("Successfully updated!");
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 400:
          case 404:
            alert(err.response?.data);
          default:
            console.log(err.response);
        }
      });
  };

  return (
    <div className="p-4 mt-1" style={{ height: "calc(100% - 1rem)" }}>
      {/* <div className=" ">
        <div className="col-md-5"> */}
      {/* <Card> */}
      <h4>Zoom settings</h4>

      <div className="text-start">
        <form onSubmit={handleSubmit(onSubmit)}>

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="zoom-s2s-oauth-settings-accordion-summary"
          >
            <div>Server-to-Server OAuth</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row my-4">
              <label class="form-labell">Account ID</label>
              <div class="col-md-12">
                <input
                  className="form-control"
                  type="text"
                  {...register("zoom_account_id")}
                />
                <div class="form-text ms-2">
                  *If you didn't issue account id, client id and client secret yet, please follow{" "}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1DmIXUjIb2rKC_p0y3AyGMIOUfmouB4Fu/edit#gid=694143529"
                    target="_blank"
                  >
                    this procedure
                  </a>{" "}
                  to issue them.
                </div>
                <div className="text-danger ms-2 h6">
                  {errors.zoom_account_id?.message}
                </div>
              </div>
            </div>
            <div className="row my-4">
              <label class="form-label ms-2">Client ID</label>
              <div class="col-md-12">
                <input
                  className="form-control"
                  type="text"
                  {...register("zoom_client_id")}
                />
                <div className="text-danger ms-2 h6">
                  {errors.zoom_client_id?.message}
                </div>
              </div>
            </div>
            <div className="row my-4">
              <label class="form-label ms-2">Client Secret</label>
              <div class="col-md-12">
                <input
                  className="form-control"
                  type="text"
                  {...register("zoom_client_secret")}
                />
                <div className="text-danger ms-2 h6">
                  {errors.zoom_client_secret?.message}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="zoom-jwt-settings-accordion-summary"
          >
            <div>JWT (The API key and secret cannot be edited, since JWT will be deprecated June 1)</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row my-4">
              <label class="form-label ms-2">Api key</label>
              <div class="col-md-12">
                <input
                  className="form-control"
                  type="text"
                  value={zoomJWTSettings?.zoom_api_key}
                  disabled
                />
              </div>
            </div>
            <div className="row my-4">
              <label class="form-label ms-2">Api secret</label>
              <div class="col-md-12">
                <input
                  className="form-control"
                  type="text"
                  value={zoomJWTSettings?.zoom_api_secret}
                  disabled
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

          <div className="row mb-2">
            <div class="col-md-4  ms-2">Email</div>
            <div class="col-md-2"></div>
            <div class="form-text ms-2">
              Please provide email addresses(at least 1) of the Zoom user who
              wants to analyze the cloud recording.
            </div>
            <div className="text-danger ms-2 h6">{errors.emails?.message}</div>
          </div>
          {fields.map((field, index) => (
            <div className="row mb-3">
              <div class="col-md-10">
                <input
                  className="form-control"
                  type="email"
                  {...register(`emails.${index}.email`)}
                />
                <div className="text-danger ms-2 h6">
                  {errors.emails && errors.emails[index]?.email?.message}
                </div>
              </div>

              <div class="col-md-2">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}

          <section>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => append({ email: "" })}
            >
              Add
            </button>
          </section>

          <div className="text-center mt-4" style={{ paddingBottom: "1rem" }}>
            <button
              type="submit"
              class="btn btn-primary btn-lg w-25"
              disabled={!isValid}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* </Card> */}
      {/* </div>
      </div> */}
    </div>
  );
};

export default Settings;
