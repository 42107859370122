const plotData = (dataset, userId) => {
  let neutralScore = 0;
  let happyScore = 0;
  let sadScore = 0;
  let angryScore = 0;
  let fearScore = 0;
  let disgustScore = 0;
  let surpriseScore = 0;

  let users = new Set();
  let users_emotion = {};
  // console.log(faceEmotions);

  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");

  if (userId == "all") {
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].movie_id != movieId || dataset[i].user_id == "all") {
        continue;
      }

      neutralScore += dataset[i].neutral;
      happyScore += dataset[i].happy;
      sadScore += dataset[i].sad;
      angryScore += dataset[i].angry;
      fearScore += dataset[i].fear;
      disgustScore += dataset[i].disgust;
      surpriseScore += dataset[i].surprise;
    }
  } else {
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].movie_id != movieId || dataset[i].user_id == "all") {
        continue;
      }

      if (dataset[i].user_id == userId) {
        neutralScore += dataset[i].neutral;
        happyScore += dataset[i].happy;
        sadScore += dataset[i].sad;
        angryScore += dataset[i].angry;
        fearScore += dataset[i].fear;
        disgustScore += dataset[i].disgust;
        surpriseScore += dataset[i].surprise;
      }
    }
  }

  const dataSource = {
    chart: {
      caption: "Emotional Overview",
      subcaption: "Overall",
      showplotborder: "1",
      showLabels: "1",
      animation: "1",
      hoverfillcolor: "#efe3d8",
      numberprefix: "$",
      plottooltext: "<b>$label</b>  <b>$value</b>",
      theme: "fusion",
    },
    category: [
      {
        label: "Emotion",
        tooltext: "Please hover over a sub-category to see details",
        color: "#ffffff",
        value: (
          happyScore +
          sadScore +
          fearScore +
          angryScore +
          disgustScore +
          neutralScore +
          surpriseScore
        ).toString(),
        category: [
          {
            label: "Positive",
            color: "#ff884d",
            value: (happyScore + surpriseScore).toString(),
            category: [
              {
                label: "Happy",
                color: "#f48f57",
                value: happyScore.toString(),
              },
              {
                label: "Surprise",
                color: "#e66b9d",
                value: surpriseScore.toString(),
              },
            ],
          },

          {
            label: "Negative",
            color: "#5375d4",
            value: (
              sadScore +
              fearScore +
              angryScore +
              disgustScore
            ).toString(),
            category: [
              {
                label: "Disgust",
                color: "#934e9f",
                value: disgustScore.toString(),
              },
              {
                label: "Angry",
                color: "#45379b",
                value: angryScore.toString(),
              },
              {
                label: "Sad",
                color: "#1d5fbf",
                value: sadScore.toString(),
              },
              {
                label: "Fear",
                color: "#2097b7",
                value: fearScore.toString(),
              },
            ],
          },
          {
            label: "Neutral",
            color: "#ffcd89",
            value: neutralScore.toString(),
            category: [
              {
                label: "Neutral",
                color: "#ffcd89",
                value: neutralScore.toString(),
              },
            ],
          },
        ],
      },
    ],
  };
  // console.log("Emotion donut datasource - ", dataSource);
  return dataSource;
};

export default plotData;
