import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

import Card from "../../components/Card";

import { v4 as uuidv4 } from 'uuid';

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
	getMovie, deleteMovie
} from "../../api/Movies";

// const schema = yup.object({
// 	alert_name: yup.string().required('Please enter a api key'),
// 	alerts: yup.array().of(
// 		yup.object().shape({
// 			metric: yup
// 				.string()
// 				.required('Please select a metric'),
// 			condition: yup
// 				.string()
// 				.required('Please select a condition'),
// 			value: yup
// 				.number()
// 				.typeError('you must specify a number')
// 				.min(-100, 'Min value 0.')
// 				.max(100, 'Max value 30.')

// 		})
// 	).min(1, "Minimum of 1 field"),

// })

export const UpdateAlert = () => {

	const [movie, setMovie] = useState({
		movieId : "",
		topic : "",
		uploadDate: "",
	});

	const [deleting, setDeleting] = useState(false)

	const {
		handleSubmit, control, register, reset, formState: { errors, isValid }
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		// resolver: yupResolver(schema)
	});

	useEffect(() => {
		const movieInfo = {}
		const params = new URLSearchParams(window.location.search);
		const movieId = params.get('movieId');

		movieInfo["movieId"] = movieId;
		
		getMovie(movieId)
			.then((response) => {
				movieInfo["topic"] = response.movies.topic;
				movieInfo["uploadDate"] = response.movies.upload_time;
				setMovie(movieInfo);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		reset(movie);
	}, [movie]);

	const onSubmit = (data) => {
		setDeleting(true);
		deleteMovie(movie.movieId)
			.then((response) => {
				console.log(response)
				alert("Successfully deleted!");
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setDeleting(false);
			});
	};

	return (
		<div className='col-md-10 p-4 mt-5'>
			<div className='row justify-content-center'>
				<div className='col-md-6'>
					<Card>
						<h3>Delete movie</h3>
						<div className="text-start">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row my-4">
									<label class="form-label ms-2">ID</label>
									<div class="col-md-12">
										<input
											className="form-control"
											type='text'
											{...register("movieId")}
											disabled="disabled"
										/>
										{/* <div className="text-danger ms-2 h6">
											{errors.movieId?.message}
										</div> */}
									</div>
								</div>

								<div className="row my-4">
									<label class="form-label ms-2">TOPIC</label>
									<div class="col-md-12">
										<input
											className="form-control"
											type='text'
											{...register("topic")}
											disabled="disabled"
										/>
										{/* <div className="text-danger ms-2 h6">
											{errors.topic?.message}
										</div> */}
									</div>
								</div>
								
								<div className="row my-4">
									<label class="form-label ms-2">UPLOAD DATE</label>
									<div class="col-md-12">
										<input
											className="form-control"
											type='text'
											{...register("uploadDate")}
											disabled="disabled"
										/>
										{/* <div className="text-danger ms-2 h6">
											{errors.uploadDate?.message}
										</div> */}
									</div>
								</div>
								

								<div className="text-center mt-5">
									<button
										type="submit"
										class="btn btn-primary btn-lg w-25"
										disabled={deleting}
									>
										{deleting && <span className="spinner-border spinner-border-sm mr-1"></span>}
										DELETE
									</button>
								</div>

							</form>
						</div>
					</Card>
				</div>
			</div>
		</div>

	);
};

export default UpdateAlert;
