import React, { useEffect, useState } from "react";
import "./Notifications.css";
import { FaInfo, FaInfoCircle } from "react-icons/fa";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { BiInfoCircle } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { FiRefreshCcw } from "react-icons/fi";
import {
  getNotifications,
  notificationsCheckedUpdate,
} from "../../api/Notifications";
import Loading from "../../assets/loading.gif";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { BsCheckSquare, BsCheckSquareFill } from "react-icons/bs";

const refreshTime = 120;

export default function Notifications() {
  const [time, setTime] = useState(0);
  const [checked, setChecked] = useState(0);

  const [data, setData] = useState([]);
  console.log(data);

  const [loading, setLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);

  const removeNotificationHelper = (id, tick) => {
    if (checked !== 0) {
      setData(
        data.filter((e) => {
          return e.alert_result_id !== id;
        })
      );
    } else {
      setData(
        data.map((e) => {
          if (e.alert_result_id === id) {
            return { ...e, checked: tick };
          }
          return e;
        })
      );
    }
  };

  const notification_table = (e) => {
    console.log(e);
    return (
      <div key={e.notification_id} className="notification">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            // marginTop: "10px",
          }}
        >
          <span className="text-title" style={{ color: "#EC8A87" }}>
            {/* <span
              className="icons-wrapper"
              style={{ backgroundColor: "#EC8A87" }}
            >
              <BsExclamationTriangleFill
                style={{ color: "white", fontSize: "20" }}
              />
            </span> */}
            {e.severity === 1 ? (
              <div
                className="icons-wrapper"
                style={{ backgroundColor: "#EC8A87" }}
              >
                <BsExclamationTriangleFill
                  style={{ color: "white", fontSize: "20" }}
                />
              </div>
            ) : e.severity === 2 ? (
              <div
                className="icons-wrapper"
                style={{ backgroundColor: "#5F6CE1" }}
              >
                <FaInfo style={{ color: "white", fontSize: "20" }} />
              </div>
            ) : e.severity === 3 ? (
              <div
                className="icons-wrapper"
                style={{ backgroundColor: "#F7CF6D" }}
              >
                <TiTick style={{ color: "black", fontSize: "20" }} />
              </div>
            ) : (
              <div
                className="icons-wrapper"
                style={{ backgroundColor: "green" }}
              >
                <MdOutlineNotificationsNone
                  style={{ color: "white", fontSize: "20" }}
                />
              </div>
            )}
            {e.alert_name}
            {/* <BiInfoCircle style={{ fontSize: "16", color: "black", marginLeft: '1rem' }} /> */}
          </span>
          {e.checked === 0 ? (
            <BsCheckSquare
              style={{ color: "black", fontSize: "20" }}
              className="checked-tick"
              onClick={async () => {
                await notificationsCheckedUpdate(e.alert_result_id, 1);
                removeNotificationHelper(e.alert_result_id, 1);
              }}
            />
          ) : (
            <BsCheckSquareFill
              style={{ color: "green", fontSize: "20" }}
              className="checked-tick"
              onClick={async () => {
                await notificationsCheckedUpdate(e.alert_result_id, 0);
                removeNotificationHelper(e.alert_result_id, 0);
              }}
            />
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="text-sub-title">{e.alert_description}</span>
          <span>
            <span>{e.resource_type}: </span>
            <span>{e.resource_id}</span>
          </span>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="text-alt">Alert score: {e.alert_score}</span>
          <span>{e["alert_results.update_date"]}</span>
        </div>
      </div>
    );
  };

  // {"alert_def_id": "b698ae24-a3f7-4d94-a744-ec6c0e92209e", "alert_name": "Sample", "account_id": "add8516b-f3af-4799-97fe-f700ed88eede", "update_date": "2022-02-15 08:35:50", "alert_result_id": "038b9dd4-ddcb-4a8f-bae0-806721de968c", "alert_results.alert_def_id": "b698ae24-a3f7-4d94-a744-ec6c0e92209e", "alert_detail_def_id": "426bceaf-6e53-4c73-b558-ca4764b913f6", "movie_id": "63eb90c1-a937-4624-80b1-6fab7aa6a66f", "alert_score": 3.52743, "alert_results.update_date": "2022-02-22 07:21:57", "is_reported": 0}

  const wait = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchData = async () => {
    setLoading(true);
    const temp = await getNotifications(pageNumber, checked);
    console.log(temp);
    setData(temp);
    setLoading(false);
  };

  // useEffect(async () => {
  //   //check data
  //   if (time === 0) {
  //     await wait(1000);
  //     await fetchData();
  //     setTime(refreshTime);
  //   } else {
  //     await wait(1000);
  //     setTime(time - 1);
  //   }
  // }, [time]);

  useEffect(async () => {
    await fetchData();
  }, [checked]);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  if (loading === true) {
    return <img src={Loading} />;
  }

  return (
    <div className="col-md-10 p-4">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <p style={{ fontSize: "2rem" }}>Notifications</p>
        <p>
          {/* Refreshing in... {secondsToHms(time)}{" "} */}
          {/* <FiRefreshCcw
            style={{
              color: "black",
              fontSize: "20",
              marginLeft: "1rem",
              cursor: "pointer",
            }}
            onClick={() => setTime(0)}
          /> */}
        </p>
        <div style={{ display: "flex" }}>
          <p
            className={checked === 0 ? "checked-tab-active" : "checked-tab"}
            onClick={() => setChecked(0)}
          >
            All
          </p>
          <p
            className={checked === 1 ? "checked-tab-active" : "checked-tab"}
            onClick={() => setChecked(1)}
          >
            Checked
          </p>
          <p
            className={checked === 2 ? "checked-tab-active" : "checked-tab"}
            onClick={() => setChecked(2)}
          >
            Unchecked
          </p>
        </div>
      </div>
      <div className="Notifications-table">
        {data.map((notification) => {
          return notification_table(notification);
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          alignItems: "center",
        }}
      >
        {pageNumber === 1 ? null : (
          <button
            onClick={() => {
              setPageNumber(pageNumber - 1);
              fetchData();
            }}
          >
            {"<"}
          </button>
        )}

        <p style={{ margin: "0 1rem" }}>{pageNumber}</p>
        <button
          onClick={() => {
            setPageNumber(pageNumber + 1);
            fetchData();
          }}
        >
          {">"}
        </button>
      </div>
    </div>
  );
}

// set page number
