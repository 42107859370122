// Routes
const HOME = "/";
const USERS = "/users"; //User List
const USER_ID = "/users/:userId"; // Dashboard per user

const MOVIES = "/movies"; //Movie List
const MOVIE_DASHBOARD = "/movies/dashboard"; //Dashboard of a Movie
const SECTION_DASHBOARD = "/section/dashboard";
const COMPARE_SECTION = "/section/compare";
const TRANSCRIPT_DETAILS = "/movies/transcript_details";
const USER_DASHBORD = "/users/dashboard"; //Dasboard of a user
const USER_MOVIE_DASHBOARD = "/users/movies/dashboard"; //Dasboard of a user in a movie
const MOVIE_PLAYER = "/movies/player"; //Dasboard of a user in a movie
const ANALYTICS = "/labs/analytics"; //NOT implemented yet
const DOWNLOAD = "/labs/download"; //Dasboard of a user in a movie

const MOVIES2 = "/movies2"; //Movie List
const USERS2 = "/users2"; //Movie List

const CREATE_ALERT = "/alerts/create"; //alert definition create
const ALERT_DEFINITION_LIST = "/alerts/list"; //alert definition List
const UPDATE_ALERT = "/alerts/update"; //alert definition create

const SETTINGS = "/settings"; //Settings;

const SIGN_UP = "/signup"; //alert definition create

const UPLOAD = "/upload"; //Dasboard of a user in a movie
const DELETE_MOVIE = "/movies/delete";
const UPDATE_MOVIE = "/movies/update";

const DELETE_MOVIE_SECTION = "/movies/deleteSection";
const UPDATE_MOVIE_SECTION = "/movies/updateSection";

const SEARCH = "/search";
const QUICKVIEW = "/search/quickView";
const DATAMANAGEMENT = "/datamanagement";
const SETTINGS_V2 = "/settings_v2";

const SHARED_DASHBOARD = "/shared_dashboard";
const SHARED_DASHBOARD_HOME = "/share";
const SHARED_DASHBOARD_SEARCH = "/share/search";
const SHARED_DASHBOARD_QUICKVIEW = "/share/search/quickview";

const SUBSCRIPTIONS = "/subscriptions";
const NOTIFICATIONS = "/notifications";

const Routes = {
  home: HOME,
  users: USERS,
  userId: USER_ID,

  movies: MOVIES,
  movieDashboard: MOVIE_DASHBOARD,
  transcript_details: TRANSCRIPT_DETAILS,
  userDashboard: USER_DASHBORD,
  sectionDashboard: SECTION_DASHBOARD,
  compareSection: COMPARE_SECTION,
  userMovieDashboard: USER_MOVIE_DASHBOARD,
  player: MOVIE_PLAYER,
  analytics: ANALYTICS,
  downlaod: DOWNLOAD,

  movies2: MOVIES2,
  users2: USERS2,

  createAlert: CREATE_ALERT,
  alertList: ALERT_DEFINITION_LIST,
  updateAlert: UPDATE_ALERT,

  signUp: SIGN_UP,
  settings: SETTINGS,

  upload: UPLOAD,

  deleteMovie: DELETE_MOVIE,
  updateMovie: UPDATE_MOVIE,

  deleteMovieSection: DELETE_MOVIE_SECTION,
  updateMovieSection: UPDATE_MOVIE_SECTION,

  search: SEARCH,
  quickView: QUICKVIEW,
  datamanagement: DATAMANAGEMENT,
  settings_v2: SETTINGS_V2,

  shared_dahsboard: SHARED_DASHBOARD,
  shared_dahsboard_home: SHARED_DASHBOARD_HOME,
  shared_dahsboard_search: SHARED_DASHBOARD_SEARCH,
  shared_dashboard_quickview: SHARED_DASHBOARD_QUICKVIEW,

  subscriptions: SUBSCRIPTIONS,
  notifications: NOTIFICATIONS,
};

export default Routes;
