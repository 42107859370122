const initialState = {
	loading: false,
	zoomRecordings: [],
	error: "",
};

const zoomRecordingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_ZOOM_RECORDINGS_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_ZOOM_RECORDINGS_SUCCESS":
			return {
				loading: false,
				zoomRecordings: action.payload,
				error: "",
			};
		case "FETCH_ZOOM_RECORDINGS_FAILURE":
			return {
				loading: false,
				zoomRecordings: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default zoomRecordingsReducer;
