const initialState = {
	loading: false,
	zoomUser: {},
	error: "",
};

const zoomUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_ZOOM_USER_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_ZOOM_USER_SUCCESS":
			return {
				loading: false,
				zoomUser: action.payload,
				error: "",
			};
		case "FETCH_ZOOM_USER_FAILURE":
			return {
				loading: false,
				zoomUser: "",
				error: action.payload,
			};
		default:
			return state;
	}
};

export default zoomUserReducer;
