import { Auth, API } from "aws-amplify";

export async function createAccount(account) {
	const apiName = "AccountAPI";
	const path = "";
	
	const myInit = { 
        body: account, 
    };
	return await API.post(apiName, path, myInit);
}

export async function getAccount() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "AccountAPI";
	const path = "?account_id=" + accountId;
	return await API.get(apiName, path);
}
