// DO NOT EDIT this file , unless you know what you are doing

const aws = {
  Auth: {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_cpSuCTxph",
    userPoolWebClientId: "4p47q1dikmhf4bf6ur73gbinne",
    identityPoolId: "ap-northeast-1:17ccd846-2aad-4458-9701-1aa9fb93d374",
    // userPoolId: "ap-northeast-1_D6xD13T0j",
    // userPoolWebClientId: "43pginmu4ulj9lom8e12m5fr8v",
    // identityPoolId: "ap-northeast-1:17ccd846-2aad-4458-9701-1aa9fb93d374",
  },
  API: {
    endpoints: [
      {
        name: "FaceEmotionsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/face_emotions",
        region: "ap-northeast-1",
      },
      {
        name: "ActiveScoresAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/active_scores",
        region: "ap-northeast-1",
      },
      {
        name: "HealthyScoresAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/healthy_scores",
        region: "ap-northeast-1",
      },
      {
        name: "EngagementScoresAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/engagement_scores",
        region: "ap-northeast-1",
      },
      {
        name: "MoviesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/movies",
        region: "ap-northeast-1",
      },
      {
        name: "UsersAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/users",
        region: "ap-northeast-1",
      },
      {
        name: "HighlightsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/highlights",
        region: "ap-northeast-1",
      },
      {
        name: "ZoomTimelinesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/zoom/timelines",
        region: "ap-northeast-1",
      },
      {
        name: "TranscriptCommentAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/transcripts/tags",
        region: "ap-northeast-1",
      },
      {
        name: "TranscriptsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/transcripts",
        region: "ap-northeast-1",
      },
      {
        name: "TranscriptSentimentsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/transcripts/sentiments",
        region: "ap-northeast-1",
      },
      {
        name: "DetectedFacesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/detected_faces",
        region: "ap-northeast-1",
      },
      {
        name: "MeetingMetricsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/metrics/meetings",
        region: "ap-northeast-1",
      },
      {
        name: "MeetingMetricsAPI_V2",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/metrics/meetings_v2",
        region: "ap-northeast-1",
      },
      {
        name: "DistancesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/distances",
        region: "ap-northeast-1",
      },
      {
        name: "NotesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/notes",
        region: "ap-northeast-1",
      },
      {
        name: "AlertsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/alerts",
        region: "ap-northeast-1",
      },
      {
        name: "ZoomJWTSettingsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/settings/zoom",
        region: "ap-northeast-1",
      },
      {
        name: "ZoomS2SOAuthSettingsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/settings/zoom/s2s_oauth",
        region: "ap-northeast-1",
      },
      {
        name: "AccountAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/accounts",
        region: "ap-northeast-1",
      },
      {
        name: "ThumbnailAPI", // DOSE NOT work. Need to debug
        endpoint:
          "https://aytwb6gj7f.execute-api.ap-northeast-1.amazonaws.com/v1",
        region: "ap-northeast-1",
      },
      {
        name: "SearchAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/tags/search",
        region: "ap-northeast-1",
      },
      {
        name: "SearchHighlightsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/tags",
        region: "ap-northeast-1",
      },
      {
        name: "DeleteTagAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/tags",
        region: "ap-northeast-1",
      },
      {
        name: "AddTagAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/tags",
        region: "ap-northeast-1",
      },
      {
        name: "UpdateTagAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/tags",
        region: "ap-northeast-1",
      },
      {
        name: "GetTagSuggestions",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/tags",
        region: "ap-northeast-1",
      },
      {
        name: "WordCloudAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/word_cloud",
        region: "ap-northeast-1",
      },
      {
        name: "ShareDashboardAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/share",
        region: "ap-northeast-1",
      },
      {
        name: "AudioFeaturesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/audio_features",
        region: "ap-northeast-1",
      },
      {
        name: "VoiceEmotionsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/voice_emotions",
        region: "ap-northeast-1",
      },
      {
        name: "MovieSectionsApi",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/sections",
        region: "ap-northeast-1",
      },
      {
        name: "SectionMeetingMetricsApi",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/sections/movieMetrics",
        region: "ap-northeast-1",
      },
      {
        name: "SectionDetailsApi",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/sections/details",
        region: "ap-northeast-1",
      },
      {
        name: "DeleteSectionApi",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/sections/delete",
        region: "ap-northeast-1",
      },
      {
        name: "UpdateSectionApi",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/sections/update",
        region: "ap-northeast-1",
      },
      {
        name: "MovieSectionAddMovies",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/sections/addMovies",
        region: "ap-northeast-1",
      },
      {
        name: "GetRoleAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/role",
        region: "ap-northeast-1",
      },
      {
        name: "GetOrganisationListAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/all_accounts",
        region: "ap-northeast-1",
      },
      {
        name: "GetFeaturesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/features",
        region: "ap-northeast-1",
      },
      {
        name: "UpdateFeatureAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/features",
        region: "ap-northeast-1",
      },
      {
        name: "GetNotifications",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/notifications",
        region: "ap-northeast-1",
      },
      {
        name: "AlertConfig",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/alert_config",
        region: "ap-northeast-1",
      },
      {
        name: "TranscriptKeywords",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/transcript_keywords",
        region: "ap-northeast-1",
      },
      {
        name: "MovieComment",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/comment_movie",
        region: "ap-northeast-1",
      },
      {
        name: "ShareViaEmail",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/share/email",
        region: "ap-northeast-1",
      },
      {
        name: "EmailSettings",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/settings/email",
        region: "ap-northeast-1",
      },
      {
        name: "SectionsListAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/sections/list",
        region: "ap-northeast-1",
      },
      {
        name: "BenchmarkSettingsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/settings/benchmark",
        region: "ap-northeast-1",
      },
      {
        name: "MovieMetricsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/movie_metrics",
        region: "ap-northeast-1",
      },
      {
        name: "MovieMetricsSectionsAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/movie_metrics/sections",
        region: "ap-northeast-1",
      },
      {
        name: "ParticipantSpeakFeaturesAPI",
        endpoint:
          "https://17cyzblv3e.execute-api.ap-northeast-1.amazonaws.com/v1/participant_speak_features",
        region: "ap-northeast-1",
      },
    ],
  },
};

export default aws;
