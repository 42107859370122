const initialState = {
  loading: false,
  appUsages: [],
  error: ''
}

const appUsagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_APP_USAGES_REQUEST":
      return {
        ...state,
        loading: true
      }
    case "FETCH_APP_USAGES_SUCCESS":
      return {
        loading: false,
        appUsages: action.payload,
        error: ''
      }
    case "FETCH_APP_USAGES_FAILURE":
      return {
        loading: false,
        appUsages: [],
        error: action.payload
      }
    default: return state
  }
}

export default appUsagesReducer
