import "./index.css";
import React from "react";
import { useState } from "react";
import Upload from "../Upload";
import Download from "../Labs/Download/Download";

const DataManagement = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  // 1 = Download data, 2 = Upload Data

  return (
    <div className="datamanagement-root">
      <div className="switch-div-root">
        <div
          onClick={() => {
            setSelectedPage(1);
          }}
          className={selectedPage === 1 ? "switch-div-selected" : "switch-div"}
        >
          <span>Upload</span>
        </div>
        <div
          onClick={() => {
            setSelectedPage(2);
          }}
          className={selectedPage === 2 ? "switch-div-selected" : "switch-div"}
        >
          <span>Download</span>
        </div>
      </div>
      {selectedPage === 1 ? <Upload /> : <Download />}
    </div>
  );
};

export default DataManagement;
