import React, { useEffect, useState } from "react";
import PlayerDetail from "../components/PlayerDetail";
import Info from "../components/Info";
import {
	getMovie
} from "../api/Movies";

const Player = () => {

	const params = new URLSearchParams(window.location.search);
	const movieId = params.get('movieId');
	
	const [movie, setMovie] = useState({
		movie: {},
	});

	useEffect(() => {
		getMovie(movieId)
			.then((response) => {
				// console.log(response)
				setMovie({
					movie: response
				});

			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div className='col-md-10 mt-3'>
			
				<Info movie={movie.movie} />
				<div className='container'>
					<PlayerDetail />
				</div>
			
		</div>
	);
};

export default Player;
