import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import "./index.css";
import generateDatasource from "./helper";
import Loading from "../../Loading";

// Adding the chart and theme as dependency to the core fusioncharts
charts(FusionCharts);

// const dataSource = {
//   chart: {},
//   caption: {
//     text: "Sales Analysis",
//   },
//   subcaption: {
//     text: "Grocery & Footwear",
//   },
//   series: "Type",
//   yaxis: [
//     {
//       plot: "Sales Value",
//       title: "Sale Value",
//       format: {
//         prefix: "$",
//       },
//     },
//   ],
// };

const VoiceEmotions = (props) => {
  const dataset = props.dataset;
  const transcriptDataset = props.transcriptDataset;
  const isLoaded = props.isLoaded;

  // const dataSource = plotData(dataset);
  // const dataSource = dataset;
  const [dataSource, setDataSource] = useState(null);
  let generatedData = null;
  if (dataset != null) {
    generatedData = generateDatasource(dataset, transcriptDataset);
    // console.log("generatedData inside dataset not null - ", generatedData);
  }
  useEffect(() => {
    // console.log("voice emotions dataset - ", dataset);
    // console.log("voice emotions datasource - ", dataSource);
    if (generatedData != null) {
      setDataSource(generatedData);
      // console.log("voice emotions datasource inside not null - ", dataSource);
    }
  }, [dataset]);

  if (dataSource != null) {
    // console.log("voice emotions datasource not null - ", dataSource);
  }

  const dummyDataSource = {
    chart: {
      caption: "Reach of Social Media Platforms amoung youth",
      yaxisname: "% of youth on this platform",
      subcaption: "2012-2016",
      showhovereffect: "1",
      numbersuffix: "%",
      drawcrossline: "1",
      plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
      theme: "fusion",
    },
    categories: [
      {
        category: [
          {
            label: "2012",
          },
          {
            label: "2013",
          },
          {
            label: "2014",
          },
          {
            label: "2015",
          },
          {
            label: "2016",
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: "Facebook",
        data: [
          {
            value: "62",
          },
          {
            value: "64",
          },
          {
            value: "64",
          },
          {
            value: "66",
          },
          {
            value: "78",
          },
        ],
      },
      {
        seriesname: "Instagram",
        data: [
          {
            value: "16",
          },
          {
            value: "28",
          },
          {
            value: "34",
          },
          {
            value: "42",
          },
          {
            value: "54",
          },
        ],
      },
      {
        seriesname: "LinkedIn",
        data: [
          {
            value: "20",
          },
          {
            value: "22",
          },
          {
            value: "27",
          },
          {
            value: "22",
          },
          {
            value: "29",
          },
        ],
      },
      {
        seriesname: "Twitter",
        data: [
          {
            value: "18",
          },
          {
            value: "19",
          },
          {
            value: "21",
          },
          {
            value: "21",
          },
          {
            value: "24",
          },
        ],
      },
    ],
  };

  const genRes = {
    chart: {
      caption: "Reach of Social Media Platforms amoung youth",
      yaxisname: "% of youth on this platform",
      subcaption: "2012-2016",
      showhovereffect: "1",
      numbersuffix: "%",
      drawcrossline: "1",
      plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
      theme: "fusion",
    },
    categories: [
      {
        category: [
          {
            label: 0,
          },
          {
            label: 3,
          },
          {
            label: 6,
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: "Happy",
        data: [
          {
            value: 0,
          },
          {
            value: 5,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Sad",
        data: [
          {
            value: 7,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Angry",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 9,
          },
        ],
      },
      {
        seriesname: "Disgust",
        data: [
          {
            value: 0,
          },
          {
            value: 2,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Surprise",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 4,
          },
        ],
      },
      {
        seriesname: "Calm",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Fear",
        data: [
          {
            value: 8,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
      {
        seriesname: "Neutral",
        data: [
          {
            value: 0,
          },
          {
            value: 0,
          },
          {
            value: 0,
          },
        ],
      },
    ],
  };

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Voice Emotions</span>
      <div className="row">
        <div className="bar-histogram-chart-column-card">
        {
          isLoaded === "Not Loaded"
          ? <Loading />
          :
            <div className="card-body">
              <ReactFC
                type="msline"
                width="100%"
                height="600"
                dataFormat="JSON"
                dataSource={dataSource}
              />
            </div>
        }
        </div>
      </div>
    </section>
  );
};

export default VoiceEmotions;
