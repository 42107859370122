const plotData = (data, userObj) => {
	let users = {}

	let users_name = {}
	let thumbnail = {}

	let users_ratio = {};

	// console.log(userObj)
	
	if ("users" in userObj) {
		userObj.users.forEach((a) => {

			users[a["user_id"]] = []

			thumbnail[a["user_id"]] = a["url"];

			if (a["first_name"] === null && a["last_name"] === null){
				return;
			}
			users_name[a["user_id"]] = a["first_name"] + a["last_name"];
			
		})
	}
	
	for (let i = 0; i < data.length; i++) {
		const d = data[i];

		if (d.user_id in users) {
			users[d.user_id].push(d.healthy);
		} else {
			// users[d.user_id] = [d.healthy];
			continue;
		}

		users_ratio[data[i].user_id] = data[i].ratio;
	}

	var usersCount = Object.keys(users).length;

	let rows = parseInt(usersCount / 4);
	let cols = 4;
	if (users.size % 4 != 0) {
		rows += 1;
	}
	let dataset = [];
	let cnt = 0;
	let r = 1;
	let c = 1;

	Object.keys(users).forEach(u => {
		let data = {};
		let healthy = "low";

		const score = average(users[u])

		if (score > 3) {
			healthy = "high";
		} else if (score > 1 &&  score <= 3) {
			healthy = "middle";
		} 

		++cnt;

		data = {
			rowid: r.toString(),
			columnid: (c++).toString(),
			displayvalue: u in users_name ? users_name[u] : "User" + cnt,
			alpha: u in users_ratio ? users_ratio[u] * 100 * usersCount > 100 ? 100 : users_ratio[u] * 100 * usersCount : 0,
			value: thumbnail[u],
			brLabel: u,
			colorrangelabel: healthy,
			link: "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/users/dashboard?userId=" + u,
		};

		if (cnt % 4 == 0) {
			r++;
			c = 1;
		}

		dataset.push(data);
	});

	let rows_data = [];
	let cols_data = [];
	for (let i = 0; i < rows; i++) {
		let d = {
			id: (i + 1).toString(),
		};
		rows_data.push(d);
	}

	for (let i = 0; i < cols; i++) {
		let d = {
			id: (i + 1).toString(),
		};
		cols_data.push(d);
	}

	const dataSource = {
		colorrange: {
			gradient: "0",
			color: [
				{
					code: "#F2726F",
					minvalue: "0",
					maxvalue: "1",
					label: "low",
				},
				{
					code: "#FFC533",
					minvalue: "1",
					maxvalue: "3",
					label: "middle",
				},
				{
					code: "#62B58F",
					minvalue: "3",
					maxvalue: "5",
					label: "high",
				}
			],
		},
		dataset: [
			{
				data: dataset,
			},
		],
		columns: {
			column: cols_data,
		},
		rows: {
			row: rows_data,
		},
		chart: {
			animation: "1",
			theme: "fusion",
			caption: "Users",
			// subcaption: " Emotion Map",
			showLabels: "0",
			showvalues: "1",
			mapbycategory: "1",
			valueFontColor: "#000000",
			plottooltext:
			"<img src=$value />",
		},
	};
	return dataSource;
};

export default plotData;

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 100) / 100;
};

