const initialState = {
	loading: false,
	activeScores: [],
	error: "",
};

const activeScoresReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_ACTIVE_SCORES_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_ACTIVE_SCORES_SUCCESS":
			return {
				loading: false,
				activeScores: action.payload,
				error: "",
			};
		case "FETCH_ACTIVE_SCORES_FAILURE":
			return {
				loading: false,
				activeScores: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default activeScoresReducer;
