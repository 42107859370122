import React, { useState, useEffect } from "react";
import ActiveScoreColumn from "./ActiveScoreColumn";
import ScoreCard from "./ScoreCard";
import ActiveScoreHistgram from "./ActiveScoreHistgram";
import "./ActiveScores.css";

const ActiveScores = (props) => {
  const dataset = props.dataset;

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Active Score</span>
      <div className="row">
        <div className="col-md-8">
          <ActiveScoreColumn dataset={dataset} />
        </div>
        <div className="col-md-4">
          <ActiveScoreHistgram dataset={dataset} />
          {/* <ScoreCard dataset={dataset} /> */}
        </div>
      </div>
    </section>
  );
};

export default ActiveScores;
