import { Auth, API } from "aws-amplify";

export async function getMovieMetricsMean() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MovieMetricsAPI";
  const path = `/mean?account_id=${accountId}`;
  const response = await API.get(apiName, path);
  return {
    active: response.active,
    engagement: response.engagement,
    health: response.health,
    transcript: response.transcript,
    hostSpeakDuration: response.host_speak_duration,
    hostSpeakRatio: response.host_speak_ratio,
    neutral: response.neutral,
    happy: response.happy,
    surprise: response.surprise,
    disgust: response.disgust,
    angry: response.angry,
    sad: response.sad,
    fear: response.fear,
  }
}

export async function getMovieMetricsSectionsMean(section_id) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "MovieMetricsSectionsAPI";
  const path = `/${section_id}/mean?account_id=${accountId}`;
  const response = await API.get(apiName, path);
  return {
    active: response.active,
    engagement: response.engagement,
    health: response.health,
    transcript: response.transcript,
    hostSpeakDuration: response.host_speak_duration,
    hostSpeakRatio: response.host_speak_ratio,
    neutral: response.neutral,
    happy: response.happy,
    surprise: response.surprise,
    disgust: response.disgust,
    angry: response.angry,
    sad: response.sad,
    fear: response.fear,
  }
}
