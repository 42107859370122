import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";

import TableV1 from "../../components/Table";
import { MOVIE_TAG_TABLE, MOVIE_COMMENT_TABLE } from "../../utils/schema";
import Heading from "../../components/Heading";
import Info from "../../components/Info";
import ChartSection from "../../components/ChartsPerMeeting";

import { getMeetingMetrics } from "../../api/MeetingMetrics";
import { getAudioFeatures } from "../../api/AudioFeatures";
import { getVoiceEmotions } from "../../api/VoiceEmotions";
import { getMovieMetricsMean ,getMovieMetricsSectionsMean } from "../../api/MovieMetrics";
import { getHostSpeakFeatures } from "../../api/ParticipantSpeakFeatures.js";

import Popup from "reactjs-popup";
import Loading from "../../assets/loading.gif";
import "./Dashboard.css";
import { FaPen } from "react-icons/fa";
import {
  getSearchHighlights,
  deleteTag,
  addTag,
  updateTagFunction,
} from "../../api/Search";
import {
  getMovieComment,
  deleteMovieComment,
  addMovieComment,
} from "../../api/Comment";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// import {
// 	getAvgFaceEmotionsOfMovieGroupByParticipant,
// 	getTsFaceEmotionsOfMovie,
// } from "../../api/FaceEmotions";

import { getTsEngagementScoresOfMovie } from "../../api/EngagementScores";

import { getUsersInMovie } from "../../api/Users";

import { getZoomTimeline } from "../../api/ZoomTimelines";

import { getTranscriptSentiment } from "../../api/TranscriptSentiments";

import { getMovie } from "../../api/Movies";

import { getTsDetectedFacesOfMovie } from "../../api/DetectedFaces";

import Notes from "../Notes";
import { shareViaEmail } from "../../api/ShareViaEmail";

const TagsPopup = (props) => {
  if (props.popupLoading === true) {
    return (
      <Popup open={props.open} closeOnDocumentClick onClose={props.closeModal}>
        <img src={Loading} />
      </Popup>
    );
  } else {
    return (
      <Popup open={props.open} closeOnDocumentClick onClose={props.closeModal}>
        <div
          style={{ padding: "1rem", maxHeight: "80vh", overflowY: "scroll" }}
        >
          <p>Add new tag</p>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
              label="Tag"
              variant="outlined"
              size="small"
              value={props.newTag}
              onChange={(e) => {
                props.setNewTag(e.target.value);
                // setNewTag(e.target.value);
              }}
            />
            <Button variant="contained" onClick={() => props.handleAddTag()}>
              Add Tag
            </Button>
          </div>
          <TableV1 schema={MOVIE_TAG_TABLE} data={props.tableData} />
        </div>
      </Popup>
    );
  }
};

const CommentPopup = (props) => {
  if (props.popupLoadingComment === true) {
    return (
      <Popup
        open={props.openComment}
        closeOnDocumentClick
        onClose={() => {
          props.closeModalComment();
        }}
      >
        <img src={Loading} />
      </Popup>
    );
  } else {
    return (
      <Popup
        open={props.openComment}
        closeOnDocumentClick
        onClose={() => {
          props.closeModalComment();
        }}
      >
        <div
          style={{
            padding: "1rem",
            width: "100%",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}
        >
          <p>Add new Comment</p>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
              label="Comment"
              variant="outlined"
              size="small"
              value={props.newComment}
              // multiline
              // rows={4}
              onChange={(e) => {
                console.log(e.target.value);
                props.setNewComment(e.target.value);
              }}
              sx={{ width: "600px" }}
            />
            {/* <input
              type="text"
              placeholder="Tag"
              value={newTag}
              onChange={(e) => {
                setNewTag(e.target.value);
              }}
            ></input> */}
            <Button
              sx={{ width: "200px" }}
              variant="contained"
              onClick={() => props.handleAddComment()}
            >
              Add Comment
            </Button>
          </div>
          {/* <Table schema={MOVIE_COMMENT_TABLE} data={tableDataComment} /> */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Comment</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.comment.map((row) => (
                  <TableRow
                    key={row.comment_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell> */}
                    <TableCell
                      sx={{ wordWrap: "break-word", maxWidth: "550px" }}
                    >
                      {row.comment}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          props.deleteCommentHelper(row.comment_id)
                        }
                        variant="outlined"
                        // style={{ backgroundColor: "red", border: "0px" }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    {/* <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Popup>
    );
  }
};

const DashboardPerMeeting = () => {
  // const [faceEmotionTs, setFaceEmotionTs] = useState({
  // 	faceEmotionTs: {},
  // });

  const benchmarkSettings = useSelector((state) => {
    return state.benchmarkReducer;
  });

  const [meetingMetrics, setMeetingMetrics] = useState({});

  const [hostSpeakFeatures, setHostSpeakFeatures] = useState({});

  const [engagementTs, setEngagemenTs] = useState({
    engagementTs: {},
  });

  const [detectedFacesTs, setDetectedFacesTs] = useState({
    detectedFacesTs: {},
  });

  const [users, setUsers] = useState({
    users: {},
  });

  const [zoomTimelines, setZoomTimelines] = useState({
    zoomTimelines: {},
  });

  const [audioFeaturesUrl, setAudioFeaturesUrl] = useState({
    audioFeaturesUrl: {}
  });

  const [voiceEmotions, setVoiceEmotions] = useState({
    voiceEmotions: {}
  });


  const [transcriptSentiments, setTranscriptSentiments] = useState({
    transcriptSentiments: {},
  });

  const [benchmarkMetrics, setBenchmarkMetrics] = useState({});

  const [movie, setMovie] = useState({
    movie: {},
  });
  //   duration: 8461
  // file_name: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa_9X403QO9.mp4"
  // movie_id: "9X403QO9"
  // topic: null
  // upload_time: "2022-06-19 18:11:51"
  // url: "https://dashboard-cloud-movi

  const [tag, setTag] = useState([]);
  const [comment, setComment] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState({
    movieInfo: "Not Loaded",
    tag: "Not Loaded",
    comment: "Not Loaded",
    meetingMetrics: "Not Loaded",
    hostSpeakFeatures: "Not Loaded",
    detectedFacesTs: "Not Loaded",
    zoomTimelines: "Not Loaded",
    audioFeaturesUrl: "Not Loaded",
    voiceEmotions: "Not Loaded",
    transcriptSentiments: "Not Loaded",
  });
  // tags -  array of tags. each element is tag and tag.tag is the name of tag

  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");
  const type = params.get("type");

  // useEffect(() => {
  // 	getTsFaceEmotionsOfMovie(movieId)
  // 		.then((response) => {
  // 			// console.log(response)
  // 			setFaceEmotionTs({
  // 				faceEmotionTs: response
  // 			});
  // 		})
  // 		.catch((err) => {
  // 			console.log(err);
  // 		});
  // }, []);

  useEffect(() => {
    getMeetingMetrics()
      .then((response) => {
        // console.log(response)
        setMeetingMetrics(response);
        setIsDataLoaded((prevState) => ({
          ...prevState,
          meetingMetrics: "Loaded",
        }));
      })
      .catch((error) => {
        // console.log(error);
      });
    // console.log("movie -", movie);
    // console.log("printing movie ", movie.movie.movies?.movie_id);
  }, []);

  useEffect(() => {
    const fetchHostSpeakFeatures = async (movieId) => {
      try {
        const result = await getHostSpeakFeatures(movieId);
        setHostSpeakFeatures(result);
        setIsDataLoaded((prevState) => ({
          ...prevState,
          hostSpeakFeatures: "Loaded",
        }));
      } catch (e) {}
    }
    fetchHostSpeakFeatures(movieId);
  }, []);

  useEffect(() => {
    getTsEngagementScoresOfMovie(movieId)
      .then((response) => {
        // console.log(response)
        setEngagemenTs({
          engagementTs: response,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    // getUsers()
    getUsersInMovie(movieId)
      .then((response) => {
        // console.log(response)
        setUsers({
          users: response,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    getZoomTimeline(movieId)
      .then((response) => {
        // console.log(response)
        setZoomTimelines({
          zoomTimelines: response,
        });
        setIsDataLoaded((prevState) => ({
          ...prevState,
          zoomTimelines: "Loaded",
        }));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    getAudioFeatures(movieId)
      .then((response) => {
        setAudioFeaturesUrl({
            audioFeaturesUrl: response
          });
        setIsDataLoaded((prevState) => ({
          ...prevState,
          audioFeaturesUrl: "Loaded",
        }));
      })
      .catch((err) => {
        console.log("error in audio features - ", err);
      });
  }, [])

  useEffect(() => {
    getVoiceEmotions(movieId)
      .then((response) => {
        console.log("voice emotions response - ", response);
        setVoiceEmotions({
          voiceEmotions: response,
        });
        setIsDataLoaded((prevState) => ({
          ...prevState,
          voiceEmotions: "Loaded",
        }));
      })
      .catch((err) => {
        console.log("error in voice emotions api call - ", err);
      });
  }, [])

  useEffect(() => {
    getTranscriptSentiment(movieId)
      .then((response) => {
        // console.log(response)
        setTranscriptSentiments({
          transcriptSentiments: response,
        });
        setIsDataLoaded((prevState) => ({
          ...prevState,
          transcriptSentiments: "Loaded",
        }));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    const fetchMovieMetricsMean = async () => {
      try {
        const result = await getMovieMetricsMean();
        setBenchmarkMetrics(result);
      } catch (e) {}
    }
    const fetchMovieMetricsSectionMean = async (sectionId) => {
      try {
        const result = await getMovieMetricsSectionsMean(sectionId);
        setBenchmarkMetrics(result);
      } catch (e) {}
    }
    if (benchmarkSettings.isSetting && benchmarkSettings.type === "ALL") {
      fetchMovieMetricsMean();
    } else if (benchmarkSettings.isSetting && benchmarkSettings.type === "SECTION") {
      fetchMovieMetricsSectionMean(benchmarkSettings.sectionId);
    }
  }, [benchmarkSettings]);

  useEffect(() => {
    getMovie(movieId)
      .then((response) => {
        // console.log("movie - ", response);
        setMovie({
          movie: response,
        });
        setIsDataLoaded((prevState) => ({
          ...prevState,
          movieInfo: "Loaded",
        }));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    getTsDetectedFacesOfMovie(movieId)
      .then((response) => {
        // console.log(response)
        setDetectedFacesTs({
          detectedFacesTs: response,
        });
        setIsDataLoaded((prevState) => ({
          ...prevState,
          detectedFacesTs: "Loaded",
        }));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  // todo
  useEffect(() => {
    getSearchHighlights("VIDEO", movieId)
      .then((response) => {
        // console.log("tags - ", response);
        setTag(response);
        setIsDataLoaded((prevState) => ({
          ...prevState,
          tag: "Loaded",
        }));
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const updateMovieTags = async () => {
    getSearchHighlights("VIDEO", movieId)
      .then((response) => {
        // console.log("tags - ", response);
        setTag(response);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const metrics =
    meetingMetrics.metrics && meetingMetrics.metrics.length != 0
      ? meetingMetrics.metrics
      : [];

  // console.log("aaaaaa")

  useEffect(async () => {
    await updateMovieComment();
  }, []);

  const updateMovieComment = async () => {
    getMovieComment("VIDEO", movieId)
      .then((response) => {
        // console.log("comment - ", response);
        setComment(response);
        setIsDataLoaded((prevState) => ({
          ...prevState,
          comment: "Loaded",
        }));
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const [openComment, setOpenComment] = useState(false);
  const [popupLoadingComment, setPopupLoadingComment] = useState(false);

  const openModalComment = () => {
    setOpenComment(true);
  };

  const closeModalComment = () => {
    setOpenComment(false);
  };

  const deleteCommentHelper = async (comment_id) => {
    setPopupLoadingComment(true);
    await deleteMovieComment(comment_id);
    await updateMovieComment();
    setPopupLoadingComment(false);
  };

  const tableDataComment = comment.map((c) => {
    return (
      <tr key={c.comment_id}>
        <td>
          <span
            style={{
              // backgroundColor: "#6D5F57",
              // borderRadius: "4rem",
              padding: "2px 10px",
              // color: "white",
              marginRight: "5px",
              lineHeight: "2rem",
              fontSize: "1rem",
              wordWrap: "break-word",
            }}
          >
            {/* {tag.tag} */}
            {c.comment}
          </span>
        </td>
        <td>
          <Button
            onClick={() => deleteCommentHelper(c.comment_id)}
            variant="outlined"
            // style={{ backgroundColor: "red", border: "0px" }}
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  });

  const [newComment, setNewComment] = useState("");

  const handleAddComment = async () => {
    setPopupLoadingComment(true);
    const addTemp = await addMovieComment("VIDEO", movieId, newComment);
    console.log(addTemp);
    await updateMovieComment();
    setNewComment("");
    setPopupLoadingComment(false);
  };

  const inputRef = useRef();

  const [open, setOpen] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const deleteTagHelper = async (tag_id) => {
    setPopupLoading(true);
    await deleteTag(tag_id);
    await updateMovieTags();
    setPopupLoading(false);
  };

  const tableData = tag.map((tag) => {
    return (
      <tr key={tag.tag_id}>
        <td>
          <span
            style={{
              backgroundColor: "#6D5F57",
              borderRadius: "4rem",
              padding: "2px 10px",
              color: "white",
              marginRight: "5px",
              lineHeight: "2rem",
              fontSize: "1rem",
            }}
          >
            {tag.tag}
          </span>
        </td>
        <td>
          <Button
            onClick={() => deleteTagHelper(tag.tag_id)}
            variant="outlined"
            // style={{ backgroundColor: "red", border: "0px" }}
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  });

  const [newTag, setNewTag] = useState("");

  const handleAddTag = async () => {
    setPopupLoading(true);
    const addTemp = await addTag("VIDEO", 0, movieId, newTag, null);
    // console.log(addTemp.message);
    await updateMovieTags();
    setNewTag("");
    setPopupLoading(false);
  };

  const [shareOpen, setShareOpen] = useState(false);
  const closeShareModal = () => setShareOpen(false);

  const SharePopup = ({ open, closeModal, children }) => {
    return (
      <Popup
        className="dashboard-per-meeting-share-popup"
        open={open}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        {children}
      </Popup>
    );
  };

  const SharePopupDialogBox = ({ open, closeModal }) => {
    return (
      <SharePopup
        open={open}
        closeModal={closeModal}
        children={<SharePopupDialogBoxContent closeModal={closeModal} />}
      />
    );
  };

  const SharePopupDialogBoxContent = ({ closeModal }) => {
    //check all data loaded successfully , then show the share button
    //check email regex then only allow share button to be enabled
    const checkEmailRegex = (email) => {
      const check = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (check) {
        setEnableShareButton(true);
      } else {
        setEnableShareButton(false);
      }
    };

    const [email, setEmail] = useState("");
    const [enableShareButton, setEnableShareButton] = useState(false);
    const [notSubmit, setNotSubmit] = useState(true);
    const [sending, setSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const sendData = async () => {
      const type = "VIDEO";
      // Prepare the data

      const tags = [];
      const comments = [];
      tag.forEach((t) => {
        tags.push(t["tag"]);
      });
      comment.forEach((c) => {
        comments.push(c["comment"]);
      });

      const data = {
        email_id: email,
        movie_id: movieId,
        tags: tags,
        comments: comments,
      };

      setNotSubmit(false);
      setSending(true);

      shareViaEmail("VIDEO", data)
        .then((response) => {
          // If data successfully sent the success screen
          setIsSuccess(true);
          setSending(false);
        })
        .catch((err) => {
          // If failed then failure screen
          console.log(err);
          setIsSuccess(false);
          setSending(false);
        });
    };

    return (
      <div className="share-via-email-dialog-box">
        <div className="share-via-email-dialog-box-heading">
          <div>Share</div>
          <div
            className="share-via-email-dialog-box-heading-close"
            onClick={closeModal}
          >
            X
          </div>
        </div>
        <div className="share-via-email-dialog-box-body">
          {notSubmit ? (
            <div className="share-via-email-dialog-box-body-input">
              <div className="share-via-email-dialog-box-body-subheading">
                You are going to share tags, comments and movie with this email
                address !
              </div>
              <input
                className="share-via-email-dialog-box-body-input"
                type="email"
                value={email}
                onChange={(event) => {
                  checkEmailRegex(event.target.value);
                  setEmail(event.target.value);
                }}
                placeholder="Enter the valid email address you want to share it with"
              />
              {enableShareButton ? (
                <div
                  className="share-via-email-dialog-box-body-button"
                  onClick={sendData}
                >
                  <span>Share</span>
                </div>
              ) : (
                <div className="share-via-email-dialog-box-body-button-disabled">
                  <span>Share</span>
                </div>
              )}
            </div>
          ) : sending ? (
            <img
              className="share-via-email-dialog-box-body-loading"
              src={Loading}
              alt="Loading!"
            />
          ) : isSuccess ? (
            <div>Successfully sent the email! ✅</div>
          ) : (
            <div>Failed to send the email! ❌</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="col-md-10 p-4"
      // For Note start //
      style={{ position: "relative" }}
      onDragOver={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      ref={inputRef}
      // For Note end //
    >
      <SharePopupDialogBox open={shareOpen} closeModal={closeShareModal} />
      <TagsPopup
        newTag={newTag}
        open={open}
        popupLoading={popupLoading}
        closeModal={closeModal}
        handleAddTag={handleAddTag}
        setNewTag={setNewTag}
        tableData={tableData}
      />
      <CommentPopup
        newComment={newComment}
        openComment={openComment}
        popupLoadingComment={popupLoadingComment}
        closeModalComment={closeModalComment}
        handleAddComment={handleAddComment}
        setNewComment={setNewComment}
        comment={comment}
        deleteCommentHelper={deleteCommentHelper}
      />
      <div className="dashboard-permeeting-top-info-row">
        <div className="dashboard-permeeting-info-section dashboard-permeeting-tag-section">
          {/* <span className="info-text-small">Information</span> */}
          {isDataLoaded.movieInfo === "Not Loaded"
            ? "Loading..."
            :
            <div className="dashboard-permeeting-info-container">
              <div className="info-section-entry ">
                <span className="info-text-small">Topic</span>
                <span>
                  {movie.movie.movies?.topic === null
                    ? "No topic"
                    : movie.movie.movies?.topic}
                </span>
              </div>
              <div className="info-section-entry ">
                <span className="info-text-small">Date</span>
                <span>{movie.movie.movies?.upload_time.substring(0, 11)}</span>
              </div>
              <div className="info-section-entry ">
                <span className="info-text-small">ID</span>
                <span>{movie.movie.movies?.movie_id}</span>
              </div>
              <div className="info-section-entry ">
                <span className="info-text-small">Duration</span>
                {/* <span>{movie.movie.movies?.duration / 60} minutes</span> */}
                <span>
                  {new Date(movie.movie.movies?.duration * 1000)
                        .toISOString().substring(11, 19)}
                </span>
              </div>
            </div>
          }
        </div>
        <div className="dashboard-permeeting-tag-section">
          <div className="dashboard-permeeting-tag-section-heading">
            <span className="info-text-small">Tags</span>
            <FaPen onClick={openModal} />
          </div>
            {isDataLoaded.tag === "Not Loaded"
              ? "Loading..."
              :
              <div className="dashboard-permeeting-tag-container">
                {tag.map((currTag) => {
                  console.log(currTag);
                  if (currTag.tag_type === "man") {
                    return (
                      <div className="tag-bubble-container ">
                        <div className="tag-bubble"> {currTag.tag} </div>
                      </div>
                    );
                  } else if (currTag.tag_type === "ai") {
                    if (currTag.tag.split("_")[1] === "corr") {
                      var temp_emotion = currTag.tag.split("_")[0];
                      const temp_value = currTag.value;
                      if (temp_value > 0.2) {
                        temp_emotion = temp_emotion + " increase";
                      } else if (temp_value < -0.2) {
                        temp_emotion = temp_emotion + " decrease";
                      } else {
                        temp_emotion = temp_emotion + " flat";
                      }
                      return (
                        <div className="tag-bubble-container ">
                          <div className="tag-bubble"> {temp_emotion} </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
            }
        </div>
        <div className="dashboard-permeeting-tag-section">
          <div className="dashboard-permeeting-tag-section-heading">
            <span className="info-text-small">Comments</span>
            <FaPen onClick={openModalComment} />
          </div>
          {isDataLoaded.comment === "Not Loaded"
            ? "Loading..."
            :
            <div className="dashboard-per-movie-comment">
              {comment.map((currComment) => (
                <div className="comment-bubble-container">
                  {currComment.comment}
                </div>
              ))}
            </div>
          }
        </div>
      </div>
      {/* <Info movie={movie.movie} tags={tag} setOpen={setOpen} /> */}
      <ChartSection
        movie={movie}
        // faceEmotionAvg={faceEmotionAvg}
        // faceEmotionTs={faceEmotionTs}
        faceEmotionTs
        // engagementAvg={engagementAvg}
        engagementTs={engagementTs}
        detectedFacesTs={detectedFacesTs}
        // healthyAvg={healthyAvg}
        // detectedFaces={detectedFaces}
        users={users}
        zoomTimelines={zoomTimelines}
        audioFeaturesUrl={audioFeaturesUrl}
        transcriptSentiments={transcriptSentiments}
        voiceEmotions={voiceEmotions}
        meetingMetrics={metrics}
        hostAvgSpeakDuration={hostSpeakFeatures.avgSpeakDuration}
        hostSpeakRatio={hostSpeakFeatures.speakRatio}
        isBenchmarkSetting={benchmarkSettings.isSetting}
        benchmarkMetrics={benchmarkMetrics}
        setShareOpen={setShareOpen}
        isLoaded={isDataLoaded}
      />
      <Notes parentRef={inputRef} />
    </div>
  );
};

export default DashboardPerMeeting;
