import React from "react";

const Heading = (props) => {
  const style = {
    fontWeight: 700,
  };
  return props.align === "left" ? (
    <h1 className={`${props.heading} my-2`} style={style}>
      {props.children}
    </h1>
  ) : (
    <h1 className={`${props.heading} text-center my-2 mb-5`} style={style}>
      {props.children}
    </h1>
  );
};

export default Heading;
