import {
  getSearchResult,
  getHighlights,
  getTagSuggestions,
} from "../../api/Search";

import {
  getSharedSeachTagsSuggestions,
  getSharedSearchTagsResult,
} from "../../api/ShareDashboard";

import {
  fetchSearchResultFailure,
  fetchSearchResultRequest,
  fetchSearchResultSuccess,
  fetchTagSuggestionsSuccess,
} from "./searchAction";

export const fetchSearchResult = (
  resource_type,
  tag,
  page,
  limit,
  speaker,
  speakerManagement,
  details,
  movie_id
) => {
  console.log("fetch search result called");
  return (dispatch) => {
    //Initializing Request
    dispatch(
      fetchSearchResultRequest({
        resource_type,
        tag,
        page,
        limit,
        speaker,
        speakerManagement,
      })
    );
    // Calling the api
    if (details.sender_id) {
      getSharedSearchTagsResult(
        details.sender_id,
        resource_type,
        tag,
        page,
        limit,
        speaker,
        speakerManagement,
        movie_id
      )
        .then((response) => {
          // response.data is the emotions
          console.log(response);
          dispatch(fetchSearchResultSuccess(response));
        })
        .catch((error) => {
          // error.message is the error message
          console.log(error);
          dispatch(fetchSearchResultFailure(error.message));
        });
    } else {
      getSearchResult(
        resource_type,
        tag,
        page,
        limit,
        speaker,
        speakerManagement,
        movie_id
      )
        .then((response) => {
          // response.data is the emotions
          console.log(response);
          dispatch(fetchSearchResultSuccess(response));
        })
        .catch((error) => {
          // error.message is the error message
          console.log(error);
          dispatch(fetchSearchResultFailure(error.message));
        });
    }
  };
};

export const fetchTagSuggestions = (details) => {
  console.log("fetch tag suggestions called");
  console.log(details);

  return (dispatch) => {
    //Initializing Request
    // Calling the api
    if (details.sender_id) {
      console.log("sender Id:", details.sender_id);

      getSharedSeachTagsSuggestions(details.sender_id)
        .then((response) => {
          console.log(response);
          response.video_tags.sort();
          response.scene_tags.sort();
          dispatch(fetchTagSuggestionsSuccess(response));
        })
        .catch((error) => {
          // error.message is the error message
          console.log(error);
        });
    } else {
      getTagSuggestions()
        .then((response) => {
          console.log(response);
          response.video_tags.sort();
          response.scene_tags.sort();
          dispatch(fetchTagSuggestionsSuccess(response));
        })
        .catch((error) => {
          // error.message is the error message
          console.log(error);
        });
    }
  };
};
