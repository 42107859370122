import React from "react";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import plotData from "./helper";

import routes from "../../../../utils/routes";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme);

const UserHealthyHeatMap = (props) => {
	const dataset = props.dataset;
	const users = props.users;

	const dataSource = plotData(dataset, users);

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<ReactFC
			type='heatmap'
			width='100%'
			height='500'
			dataFormat='JSON'
			dataSource={dataSource}
			fcEvent-dataPlotRollOver={
				eventObj => {
					props.setUserId(eventObj.data.brLabel)
				}
			}
			fcEvent-dataplotRollOut={
				eventObj => {
					props.setUserId("all")
				}
			}
			// fcEvent-dataPlotClick={
			// 	eventObj => {
			// 		const params = new URLSearchParams(window.location.search);
			// 		const from = params.get("from");
			// 		const to = params.get("to");
			// 		const zoomMeetingId = params.get("zoomMeetingId");
            //         window.open(routes.zoomMeetingDashboardPerUser 
			// 			+ "?zoomMeetingId=" + zoomMeetingId 
			// 			+ "&userId=" + eventObj.data.brLabel 
			// 			+ "&from=" + from 
			// 			+ "&to=" + to);
			// 	}
			// }
		/>
	);
};

export default UserHealthyHeatMap;
