const initialState = {
  loading: false,
  resource_type: "VIDEO",
  tag_key: null,
  table: {
    page: 1,
    data: [],
    limit: 20,
  },
  error: "",
  tags: { scene_tags: [], video_tags: [] },
  customPlay: false,
  chart1: [],
  chart2: [],
  highlights: {
    tags: [],
    tags_type: [],
    tag_visualization: [],
    tag_visualization_types: [],
    manual_tags_type: [],
    manual_tags: [],
    isLoading: false,
  },
  transcript: [],
  transcriptChart: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SEARCH_RESULT_REQUEST":
      return {
        ...state,
        resource_type: action.payload.resource_type,
        tag_key: action.payload.tag,
        loading: true,
        table: {
          ...state.table,
          page: action.payload.page,
          limit: action.payload.limit,
        },
      };
    case "FETCH_SEARCH_RESULT_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        table: {
          ...state.table,
          data: action.payload,
        },
        error: "",
      };
    case "FETCH_SEARCH_RESULT_FAILURE":
      return {
        ...state,
        loading: false,
        table: {
          ...state.table,
          data: initialState.table.data,
        },
        error: action.payload,
      };
    case "FETCH_TAG_SUGGESTIONS_SUCCESS":
      return {
        ...state,
        tags: action.payload,
      };
    case "UPDATE_CUSTOM_PLAY":
      return {
        ...state,
        customPlay: action.payload,
      };
    case "UPDATE_CHART_1":
      return {
        ...state,
        chart1: action.payload,
      };
    case "UPDATE_CHART_2":
      return {
        ...state,
        chart2: action.payload,
      };
    case "FETCH_HIGHLIGHTS_DATA_REQUEST":
      return {
        ...state,
        highlights: {
          ...state.highlights,
          isLoading: true,
        },
      };
    case "FETCH_HIGHLIGHTS_DATA_SUCCESS":
      return {
        ...state,
        highlights: {
          ...action.payload,
          isLoading: false,
        },
      };
    case "FETCH_HIGHLIGHTS_DATA_FAILURE":
      return {
        ...state,
        highlights: {
          ...state.highlights,
          isLoading: false,
        },
      };
    case "UPDATE_TRANSCRIPT_DATA":
      return {
        ...state,
        transcript: action.payload,
      };
    case "UPDATE_TRANSCRIPT_CHART":
      return {
        ...state,
        transcriptChart: action.payload,
      };
    case "RESET_SEARCH_RESULT":
      return initialState;
    default:
      return state;
  }
};

export default searchReducer;
