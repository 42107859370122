import React from "react";
import "./CompareSectionCharts.css";
import ScatterChart from "./ScatterChart/ScatterChart";
import Histogram from "./Histogram/Histogram";

const CompareSectionCharts = ({
  selectedParams,
  metrics1,
  metrics2,
  section1name,
  section2name,
}) => {
  let len = selectedParams.length;
  if (len === 2)
    return (
      <ScatterChart
        metrics1={metrics1}
        metrics2={metrics2}
        selectedParams={selectedParams}
        section1name={section1name}
        section2name={section2name}
      />
    );

  if (len === 1)
    return (
      <Histogram
        metrics1={metrics1}
        metrics2={metrics2}
        selectedParams={selectedParams}
        section1name={section1name}
        section2name={section2name}
      />
    );
  return null;
};

export default CompareSectionCharts;
