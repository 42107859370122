import React, { useEffect, useState, useRef } from "react";
import Card from "./Card";
import Info from "./Info";
import ReactPlayer from 'react-player'
import { getZoomSettings, updateZoomSettings } from "../api/ZoomSettings";

import { getMovie } from "../api/Movies";


import { ChevronDoubleLeft, ChevronDoubleRight, ArrowClockwise } from 'react-bootstrap-icons';

const PlayerDetail = (props) => {

	const player = useRef(null)

	const params = new URLSearchParams(window.location.search);
	const movieId = params.get('movieId');
	const time = params.get('time');

	//Setting Zoomkey and ZoomSecretKey
	const [url, setUrl] = useState("");
	const [topic, setTopic] = useState("");
	const [meetingDate, setMeetingDate] = useState("");
	const [rate, setRate] = useState(1);

	useEffect(() => {
		getMovie(movieId)
			.then((response) => {
				// console.log(response)
				setUrl(response.movies.url);
				setTopic(response.movies.topic);
				setMeetingDate(response.movies.upload_time);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const rateUp = () => {
		if (rate < 4) {
			setRate(rate + 1)
		}
	};

	const rateDown = () => {
		if (rate > 1) {
			setRate(rate - 1)
		}
	};

	const rateReset = () => {
		setRate(1)
	};

	return (
		<div>
			<Info movieId={movieId} meetingDate={meetingDate} topic={topic} />
			<ReactPlayer
				url={url}
				controls={true}
				playbackRate={rate}
				ref={player}
				// onReady={() => {
				// 	player.current.seekTo(time);
				// }}
				// If "onReady", infinity loop happed.
				onDuration={() => {
					player.current.seekTo(time);
				}}
				width="1080px"
				height="640px"
			/>

			<ChevronDoubleLeft onClick={rateDown} />
			<ArrowClockwise onClick={rateReset} />
			<ChevronDoubleRight onClick={rateUp} />
			<span>{rate}</span>
			<span>x</span>

		</div>
	);
};

export default PlayerDetail;
