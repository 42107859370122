import React from "react";

import { calculatePercentageEmotionScores, calculatePercentageBenchmarkEmotionScores } from "./helpler";
import EmotionPieGauge from "../../EmotionPieGauge";


const UserEmotionsSummary = (props) => {
	const dataset = props.dataset;
  const isLoaded = props.isLoaded;
  const isBenchmarkSetting = props.isBenchmarkSetting;
  const benchmarkMetrics = props.benchmarkMetrics;


  const percentageEmotionScores = calculatePercentageEmotionScores(dataset);
  let percentageBenchmarkEmotionScores = {};
  if (isBenchmarkSetting) {
    percentageBenchmarkEmotionScores = calculatePercentageBenchmarkEmotionScores(benchmarkMetrics);
  }

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

  return (
    <div className="card">
			<div class="row">
				<div className='pt-3 header-item' style={style}>
					<div className='header-title'>Facial Emotion Score -- summary</div>
				</div>
			</div>

      <div className="dashboard-emotions-score-summary-row">
        <EmotionPieGauge
          emotionName="Neutral Score"
          color="#ffcd89"
          isLoaded={isLoaded}
          percentageScore={percentageEmotionScores.neutral}
          isBenchmarkSetting={isBenchmarkSetting}
          percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
        />
        <EmotionPieGauge
          emotionName="Happy Score"
          color="#f48f57"
          isLoaded={isLoaded}
          percentageScore={percentageEmotionScores.happy}
          isBenchmarkSetting={isBenchmarkSetting}
          percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
        />
        <EmotionPieGauge
          emotionName="Suprise Score"
          color="#e66b9d"
          isLoaded={isLoaded}
          percentageScore={percentageEmotionScores.suprise}
          isBenchmarkSetting={isBenchmarkSetting}
          percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
        />
        <EmotionPieGauge
          emotionName="Disgust Score"
          color="#934e9f"
          isLoaded={isLoaded}
          percentageScore={percentageEmotionScores.disgust}
          isBenchmarkSetting={isBenchmarkSetting}
          percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
        />
        <EmotionPieGauge
          emotionName="Angry Score"
          color="#45379b"
          isLoaded={isLoaded}
          percentageScore={percentageEmotionScores.angry}
          isBenchmarkSetting={isBenchmarkSetting}
          percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
        />
        <EmotionPieGauge
          emotionName="Sad Score"
          color="#1d5fbf"
          isLoaded={isLoaded}
          percentageScore={percentageEmotionScores.sad}
          isBenchmarkSetting={isBenchmarkSetting}
          percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
        />
        <EmotionPieGauge
          emotionName="Fear Score"
          color="#2097b7"
          isLoaded={isLoaded}
          percentageScore={percentageEmotionScores.fear}
          isBenchmarkSetting={isBenchmarkSetting}
          percentageBenchmarkScore={percentageBenchmarkEmotionScores.neutral}
        />
      </div>
    </div>
  );
};

export default UserEmotionsSummary;
