import React from "react";
import "./index.css";

const AnalyticsOverview = (props) => {
  const trendData = props.trends;
  const activeScore = trendData.mean_curr_active;
  const engagementScore = trendData.mean_curr_engagement;
  const healthyScore = trendData.mean_curr_health;
  const transcriptScore = trendData.mean_curr_transcript;
  const activeScoreChange = trendData.diff_active;
  const engagementScoreChange = trendData.diff_engagement;
  const healthyScoreChange = trendData.diff_health;
  const transcriptScoreChange = trendData.diff_transcript;

  return (
    <section className="analytics-overview-section">
      <span className="section-title-text">Analytics Overview</span>

      <div className="analytics-overview-root">
        <div className="analytics-overview-card">
          <span>Active Score</span>
          <div className="analytics-overview-card-row">
            <span className="big-number-text">{activeScore}</span>
            <div
              className={
                activeScoreChange > 0 ? "coloredbox-green" : "coloredbox-red"
              }
            >
              <span className="white-number-value">{activeScoreChange}</span>
            </div>
          </div>
        </div>
        <div className="analytics-overview-card">
          <span>Health Score</span>
          <div className="analytics-overview-card-row">
            <span className="big-number-text">{healthyScore}</span>
            <div
              className={
                healthyScoreChange > 0 ? "coloredbox-green" : "coloredbox-red"
              }
            >
              <span className="white-number-value">{healthyScoreChange}</span>
            </div>
          </div>
        </div>
        <div className="analytics-overview-card">
          <span>Engagement Score</span>
          <div className="analytics-overview-card-row">
            <span className="big-number-text">{engagementScore}</span>
            <div
              className={
                engagementScoreChange > 0
                  ? "coloredbox-green"
                  : "coloredbox-red"
              }
            >
              <span className="white-number-value">
                {engagementScoreChange}
              </span>
            </div>
          </div>
        </div>
        <div className="analytics-overview-card">
          <span>Transcript Score</span>
          <div className="analytics-overview-card-row">
            <span className="big-number-text">{transcriptScore}</span>
            <div
              className={
                transcriptScoreChange > 0
                  ? "coloredbox-green"
                  : "coloredbox-red"
              }
            >
              <span className="white-number-value">
                {transcriptScoreChange}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AnalyticsOverview;
