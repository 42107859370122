import { Link } from "react-router-dom";

const plotData = (dataset) => {

	const BINS = 30;

	let dateFormat = require("dateformat");
	let scoreList = [];
	let labelList = [];

	let movies = {}
	let topics = {}

	for (let i = 0; i < dataset.length; i++) {
		const d = dataset[i];

		let engagement = 0
		if (d.ratio_distance === null) {
			engagement = d.ratio;
		} else {
			engagement = d.ratio_distance;
		}

		if (d.movie_id in movies){
			movies[d.movie_id].push(engagement);
		} else{
			movies[d.movie_id] = [engagement];
			topics[d.movie_id] = d.topic;
		}

	}

	Object.keys(movies).forEach(key => {
		scoreList.push(average(movies[key]));
	});

	const aryMax = function (a, b) {return Math.max(a, b);}
	const aryMin = function (a, b) {return Math.min(a, b);}
	let max = scoreList.length != 0 ? scoreList.reduce(aryMax) : 0;
	let min = scoreList.length != 0 ? scoreList.reduce(aryMin) : 0;
	
	let interval = Math.ceil((max-min) *100 / BINS) / 100;

	let scoreTimes = {};

	for(let i = 0; i < BINS; i++){
		scoreTimes[i] = 0;
	}
	
	for (let i = 0; i < scoreList.length; i++) {
		for(let j = 0; j < BINS; j++){
			if(scoreList[i] >= interval*j && scoreList[i] < interval*(j+1)){
				scoreTimes[j] += 1;
				break;
			}
		}
	}

	let dataList = []
	for(let i = 0; i < BINS; i++){
		let data = {}
		data["label"] = String(i*interval);
		data["value"] = scoreTimes[i];
		dataList.push(data);
	}

	// console.log(dataList)
	const dataSource = {
    chart: {
      // subcaption: "Active Score",
      yaxisname: "# of meetings",
      // numvisibleplot: "70",
      // labeldisplay: "auto",
      theme: "fusion",
      plotSpacePercent: 0,
      palettecolors: "#F7CF6D",
    },
    data: dataList,
  };

	return dataSource;
};

export default plotData;

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 100) / 100;
};

