import { getMeetingMetrics } from "../../../../api/MeetingMetrics";
import { getMovies } from "../../../../api/Movies";
import { useState, useEffect } from "react";
import { getSectionDataset, columns, options, detailPanel } from "./helper";
import { dataset } from "../../MovieListV2/Tables/helper";
import MaterialTable from "material-table";
import tableIcons from "../../../../assets/MaterialTableIcons";
import Card from "../../../../components/Card";
import "./sectionview.css";
import { Paper } from "@material-ui/core";
import BarLoader from "react-spinners/BarLoader";

const MovieSectionTables = (props) => {
  const [moviesObj, setMoviesObj] = useState([]);
  const [meetingMetrics, setMeetingMetrics] = useState({});
  const [individualMovieData, setIndividualMovieData] = useState([]);
  const [sectionDataset, setSectionDataset] = useState([]);
  useEffect(() => {
    getMovies()
      .then((response) => {
        setMoviesObj(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getMeetingMetrics()
      .then((response) => {
        // console.log(response)
        setMeetingMetrics(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const metrics =
    meetingMetrics.metrics && meetingMetrics.metrics.length != 0
      ? meetingMetrics.metrics
      : [];

  const movies = moviesObj.length != 0 ? moviesObj.movies : [];

  //UPDATED USEEFFECT WITH MOVIEMETRICS IN DEPENDANCY
  // useEffect(() => {
  //   setIndividualMovieData(dataset(movies, meetingMetrics.metrics));
  // }, [meetingMetrics]);

  // useEffect(() => {
  //   setSectionDataset(
  //     getSectionDataset(props.sectionList, individualMovieData)
  //   );
  // }, [individualMovieData]);

  useEffect(() => {
    // OUTPUT OF SECTION DATASET IS ARRAY OF OBJECTS LIKE THIS -
    // 0:
    // active: 19.6
    // engagement: 0.3
    // healthy: 4.15
    // imageUrl: ""
    // movie_list: (2) ['D5rRjWvJ', 'Jwrnv4pD']
    // section_id: "section_test_001"
    // speechSpeed: 139.7
    // textNegaposi: 0.7000000000000001
    setTimeout(function () {
      if (
        individualMovieData.length === 0
        //  ||
        // individualMovieData[0].active === 0
      )
        // setIndividualMovieData(dataset(movies, metrics));
        setIndividualMovieData(dataset(movies, meetingMetrics.metrics));
      else {
        if (
          sectionDataset.length === 0 ||
          isNaN(sectionDataset[0].active) ||
          sectionDataset[0].active === 0
        )
          setSectionDataset(
            getSectionDataset(props.sectionList, individualMovieData)
          );
      }
    }, 500);

    console.log("individual movie data -", individualMovieData);
    console.log("section dataset - ", sectionDataset);
  }, [individualMovieData, sectionDataset, meetingMetrics]);

  // return (
  //   <div>
  //     {sectionDataset.map((sectionData) => {
  //       return <div>{sectionData.active}</div>;
  //     })}
  //   </div>
  // );
  if (
    sectionDataset == null ||
    isNaN(sectionDataset[0]?.active) ||
    sectionDataset[0]?.active === 0
  ) {
    return (
      <div className="sectionview-spinner-page">
        <BarLoader color={"#f7cf6d"} width={160} height={4} />
      </div>
    );
  }
  return (
    <Card>
      <MaterialTable
        title="Section List"
        icons={tableIcons}
        columns={columns}
        data={sectionDataset}
        options={options}
        detailPanel={detailPanel}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        onSelectionChange={(rows) => {
          console.log("selection change rows - ", rows);
          props.rowsCallback(rows);
        }}
        // onSelectionChange={(rows) => {
        //   let curSelectedMovieInfo = [];

        //   for (let i = 0; i < rows.length; ++i) {
        //     const movieInfo = {};
        //     movieInfo["movieId"] = rows[i]["movieId"];
        //     movieInfo["topic"] = rows[i]["topic"];
        //     curSelectedMovieInfo.push(movieInfo);
        //   }

        //   props.setSelectedMovieInfo(curSelectedMovieInfo);
        // }}
      />
    </Card>
  );
};

export default MovieSectionTables;
