import React from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import Loading from "../../Loading";
import routes from "../../../utils/routes";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const SpeechRallies = (props) => {
  const timelines = props.dataset;
  const dataSource = plotData(timelines);
  const isLoaded = props.isLoaded;

  // console.log(dataSource)
  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Speech Rallies</span>

      <div className="row">
        <div className="bar-histogram-chart-column-card">
        {
          isLoaded === "Not Loaded"
          ? <Loading />
          :
            <div className="card-body">
              <ReactFC
                type="scatter"
                width="100%"
                height="500"
                dataFormat="JSON"
                dataSource={dataSource}
              />
            </div>
        }
        </div>
      </div>
    </section>
  );
};

export default SpeechRallies;
