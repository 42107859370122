import {
	getBenchmarkSettings as getBenchmarkApiCall,
	updateBenchmarkSettings as updateBenchmarkApiCall,
	deleteBenchmarkSettings as deleteBenchmarkApiCall
} from "../../api/Benchmark";
import {
	fetchBenchmarkSettingsFailure,
	fetchBenchmarkSettingsSuccess,
	fetchBenchmarkSettingsRequest,
	updateBenchmarkSettingsFailure,
	updateBenchmarkSettingsSuccess,
	updateBenchmarkSettingsRequest,
	deleteBenchmarkSettingsFailure,
	deleteBenchmarkSettingsSuccess,
	deleteBenchmarkSettingsRequest,
} from "./benchmarkAction";

export const fetchBenchmarkSettings = () => {
	return async (dispatch) => {
		//Initializing Request
		dispatch(fetchBenchmarkSettingsRequest());
		//Calling the api
		try {
			const response = await getBenchmarkApiCall();
			dispatch(fetchBenchmarkSettingsSuccess({
				isSetting: response.is_setting,
				type: response.type,
				sectionId: response.section_id,
			}));
		} catch (error) {
			dispatch(fetchBenchmarkSettingsFailure());
		}
	};
};

export const updateBenchmarkSettings = (isSetting, type, sectionId) => {
	return async (dispatch) => {
		//Initializing Request
		dispatch(updateBenchmarkSettingsRequest());
		//Calling the api
		try {
			await updateBenchmarkApiCall(isSetting, type, sectionId);
			dispatch(updateBenchmarkSettingsSuccess({
				isSetting,
				type,
				sectionId,
			}));
		} catch (error) {
			dispatch(updateBenchmarkSettingsFailure());
		}
	};
};

export const deleteBenchmarkSettings = () => {
	return async (dispatch) => {
		//Initializing Request
		dispatch(deleteBenchmarkSettingsRequest());
		//Calling the api
		try {
			await deleteBenchmarkApiCall();
			dispatch(deleteBenchmarkSettingsSuccess());
		} catch (error) {
			dispatch(deleteBenchmarkSettingsFailure());
		}
	};
};
