import moment from "moment-timezone";

const plotData = (dataset, userId) => {
  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");

  let engagement = 0;

  if (userId == "all") {
    let sum = 0;
    let count = 0;
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].movie_id != movieId || dataset[i].user_id == "all") {
        continue;
      }

      let _engagement = 0;
      if (dataset[i].ratio_distance === null) {
        _engagement = dataset[i].ratio;
      } else {
        _engagement = dataset[i].ratio_distance;
      }

      sum += _engagement;
      count += 1;
    }

    engagement = sum / count;
  } else {
    let sum = 0;
    let count = 0;
    for (let i = 0; i < dataset.length; i++) {
      if (dataset[i].movie_id != movieId) {
        continue;
      }

      if (dataset[i].user_id == userId) {
        let _engagement = 0;
        if (dataset[i].ratio_distance === null) {
          _engagement = dataset[i].ratio;
        } else {
          _engagement = dataset[i].ratio_distance;
        }
        sum += _engagement;
        count += 1;
      }
    }
    engagement = sum / count;
  }

  const dataSource = {
    chart: {
      caption: "Score",
      // subcaption: "over all",
      // numbersuffix: "%",
      gaugefillmix: "{dark-20},{light+70},{dark-10}",
      theme: "fusion",
    },
    colorrange: {
      color: [
        {
          minvalue: "0",
          maxvalue: "0.25",
          label: "low",
          code: "#F2726F",
        },
        {
          minvalue: "0.25",
          maxvalue: "0.75",
          label: "middle",
          code: "#FFC533",
        },
        {
          minvalue: "0.75",
          maxvalue: "1.0",
          label: "High",
          code: "#62B58F",
        },
      ],
    },
    // pointers: {
    //   pointer: [
    //     {
    //       value: engagement,
    //     },
    //   ],
    // },
    dials: {
      dial: [
        {
          value: engagement,
        },
      ],
    },
    trendpoints: {
      point: [
        {
          startValue: engagement,
          useMarker: "1",
          markerColor: "#000000",
          markerBorderColor: "#666666",
          markerRadius: "10",
          // markerTooltext: "Previous year's average was 62",
          // displayValue: "Last Year",
          color: "#0075c2",
          thickness: "0",
          alpha: "100",
        },
      ],
    },
  };
  // console.log("datasource from engagement - ", dataSource);
  return dataSource;
};
export default plotData;
