import React, { useState, useEffect, useRef } from "react";
import TranscriptChartVisualisationV3 from "./TranscriptChartVisualisationV3";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  getTranscriptKeywords,
  addTranscriptKeywords,
  deleteTranscriptKeyword,
} from "../../api/Transcripts";

import { useSelector, useDispatch } from "react-redux";

import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";

export default function TranscriptV3viewHelper({
  comments,
  seekToTimestamp,
  keyword,
  index,
  handleUpdateBookmark,
  handleDeleteFromList,
}) {
  const [filter, setFilter] = useState(keyword.keyword);
  const dispatch = useDispatch();
  const data = useSelector((state) => {
    return state.searchReducer.transcript;
  });

  const hmsToSeconds = (e) => {
    // console.log(e);
    var h = Number(e.split(":")[0]);
    var m = Number(e.split(":")[1]);
    var s = Number(e.split(":")[2]);

    return h * 3600 + m * 60 + s;
  };

  const muiTableData = data.filter((e) => {
    // console.log(comments);
    if (filter === null) {
      return [];
    }
    if (e.sentence !== null && e.sentence.includes(filter)) {
      return true;
    }
    if (comments === [] || Array.isArray(comments) === false) {
      return false;
    }
    // console.log(comments);
    const tempComments = comments.filter((field) => {
      // console.log(e);
      return Number(field.resource_id.split("_")[1]) === e.time;
    });
    // console.log(tempComments);
    for (var i = 0; i < tempComments.length; i++) {
      if (tempComments[i].comment.includes(filter)) {
        return true;
      }
    }
  });

  const chartData = data.map((e) => {
    // console.log(e);
    if (filter === "" || filter === null || filter === undefined) {
      return { value: 0, time: e.time };
    }
    // console.log(filter);
    var count = 0;
    if (e.sentence !== null) {
      count = count + e.sentence.split(filter).length - 1;
    }
    if (comments !== [] && Array.isArray(comments) !== false) {
      const tempComments = comments.filter((field) => {
        // console.log(e);
        return Number(field.resource_id.split("_")[1]) === e.time;
      });
      for (var i = 0; i < tempComments.length; i++) {
        count = count + (tempComments[i].comment.match(filter) || []).length;
      }
    }

    console.log(count);
    return { value: count, time: e.time };
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "0.25rem",
      }}
    >
      <div
        style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}
      >
        <TextField
          id="outlined-basic"
          label="Filter"
          variant="outlined"
          sx={{ width: "100%" }}
          size="small"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          disabled={keyword.keyword_id === undefined ? false : true}
        />
        <p
          style={{
            whiteSpace: "nowrap",
            marginLeft: "1rem",
            marginBottom: "0px",
          }}
        >
          Hit count: {filter !== "" ? muiTableData.length : 0}
        </p>
        {keyword.keyword_id === undefined ? (
          <BsBookmark
            onClick={() => {
              handleUpdateBookmark({ keyword: filter }, index, true);
            }}
            style={{
              marginLeft: "1rem",
              fontSize: "30px",
              cursor: "pointer",
              color: "black",
            }}
            onMouseOver={({ target }) => (target.style.color = "grey")}
            onMouseOut={({ target }) => (target.style.color = "black")}
          />
        ) : (
          <BsBookmarkFill
            onClick={() => {
              handleUpdateBookmark(keyword, index, false);
            }}
            style={{
              marginLeft: "1rem",
              fontSize: "30px",
              cursor: "pointer",
              color: "black",
            }}
            onMouseOver={({ target }) => (target.style.color = "grey")}
            onMouseOut={({ target }) => (target.style.color = "black")}
          />
        )}
        <IconButton
          aria-label="delete"
          size="large"
          onClick={() => {
            handleDeleteFromList(index);
          }}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </div>
      <TranscriptChartVisualisationV3
        duration={data.length}
        seekToTimestamp={seekToTimestamp}
        raw={chartData}
      />
    </div>
  );
}
