import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useForm, useFieldArray, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import "./index.css";

import {
  fetchBenchmarkSettings,
  updateBenchmarkSettings,
  deleteBenchmarkSettings,
} from "../../store/benchmark/benchmarkEffect";
import { getSectionsList } from "../../api/SectionsList";

export const BenchmarkSettings = () => {
  const ALL = "ALL";
  const SECTION = "SECTION";

  const dispatch = useDispatch();

  const [sections, setSections] = useState([]);
  const benchmarkSettings = useSelector((state) => {
    return state.benchmarkReducer;
  });

  useEffect(() => {
    const fetchSectionsList = async () => {
      try{
        const result = await getSectionsList();
        setSections(result);
      } catch (e) {}
    }
    fetchSectionsList();
  }, []);

  useEffect(() => {
    dispatch(fetchBenchmarkSettings());
  }, []);

  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      isBenchmarkSetting:benchmarkSettings.isSetting,
      benchmarkSelect: benchmarkSettings.sectionId ? benchmarkSettings.sectionId : ALL,
    },
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const isSetting = data.isBenchmarkSetting;
    const benchmarkSelect = data.benchmarkSelect;
    let type;
    let sectionId;
    if (isSetting) {
      if (benchmarkSelect === ALL) {
        type = ALL;
        sectionId = undefined;
      } else {
        type = SECTION;
        sectionId = benchmarkSelect;
      }
      dispatch(updateBenchmarkSettings(isSetting, type, sectionId));
    } else {
      dispatch(deleteBenchmarkSettings());
    }
  };

  const currentIsBenchmarkSetting = watch("isBenchmarkSetting")

  return (
    <div className="benchmark-settings">
      <div className="benchmark-settings-heading">Benchmark Settings</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="benchmark-settings-container">
          <div className="benchmark-settings-menu-row">
            <div className="benchmark-settings-menu-heading">
              <div className="benchmark-settings-menu-main-heading">
                Benchmark
              </div>
              {/* <div className="benchmark-settings-menu-sub-heading">
                Benchmark
              </div> */}
            </div>
            <div className="benchmark-settings-on-off-setting-toggle">
              <div>No</div>
              <Controller
                control={control}
                name="isBenchmarkSetting"
                render={
                  ({ field: { value, onChange } }) =>
                    <Switch
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                }
              />
              <div>Yes</div>
            </div>
          </div>
          {
            currentIsBenchmarkSetting && (
              <div className="benchmark-settings-menu-row">
                <div className="benchmark-settings-menu-heading">
                  <div className="benchmark-settings-menu-main-heading">
                    Select Benchmark
                  </div>
                  {/* <div className="benchmark-settings-menu-sub-heading">
                    Benchmark
                  </div> */}
                </div>
                <div className="benchmark-settings-benchmark-select">
                  <FormControl sx={{ minWidth: "120px" }}>
                    <InputLabel id="benchmark-select-label">Benchmark</InputLabel>
                    <Controller
                      control={control}
                      name="benchmarkSelect"
                      render={
                        ({ field }) =>
                          <Select {...field}
                            label="Benchmark"
                            labelId="benchmark-select-label"
                          >
                            <MenuItem value={ALL}>ALL</MenuItem>
                            {sections.map((section) => <MenuItem value={section.section_id}>{section.section_name}</MenuItem>)}
                          </Select>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            )
          }
          <div className="benchmark-settings-submit">
            <button
              type="submit"
              className="btn btn-primary btn-lg benchmark-settings-button"
              disabled={benchmarkSettings.updating}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BenchmarkSettings;
