const initialState = {
	loading: false,
	updating: false,
	isSetting: false,
	type: undefined,
	sectionId: undefined,
};

const benchmarkReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_BENCHMARK_SETTINGS_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_BENCHMARK_SETTINGS_SUCCESS":
			return {
				...state,
				loading: false,
				isSetting: action.payload.isSetting,
				type: action.payload.type,
				sectionId: action.payload.sectionId,
			};
		case "FETCH_BENCHMARK_SETTINGS_FAILURE":
			return {
				...state,
				loading: false,
			};
		case "UPDATE_BENCHMARK_SETTINGS_REQUEST":
			return {
				...state,
				updating: true,
			};
		case "UPDATE_BENCHMARK_SETTINGS_SUCCESS":
			return {
				...state,
				updating: false,
				isSetting: action.payload.isSetting,
				type: action.payload.type,
				sectionId: action.payload.sectionId,
			};
		case "UPDATE_BENCHMARK_SETTINGS_FAILURE":
			return {
				...state,
				updating: false,
			};
		case "DELETE_BENCHMARK_SETTINGS_REQUEST":
			return {
				...state,
				updating: true,
			};
		case "DELETE_BENCHMARK_SETTINGS_SUCCESS":
			return {
				...state,
				updating: false,
				isSetting: false,
			};
		case "DELETE_BENCHMARK_SETTINGS_FAILURE":
			return {
				...state,
				updating: false,
			};
		default:
			return state;
	}
};

export default benchmarkReducer;
