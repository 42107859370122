const initialState = {
	loading: false,
	engagementScores: [],
	error: "",
};

const engagementScoresReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_ENGAGEMENT_SCORES_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_ENGAGEMENT_SCORES_SUCCESS":
			return {
				loading: false,
				engagementScores: action.payload,
				error: "",
			};
		case "FETCH_ENGAGEMENT_SCORES_FAILURE":
			return {
				loading: false,
				engagementScores: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default engagementScoresReducer;
