import {
	getMeetingMetrics

} from "../../api/MeetingMetrics";

import {
	fetchMeetingMetricsFailure,
	fetchMeetingMetricsRequest,
	fetchMeetingMetricsSuccess
} from "./meetingMetricsAction";

export const fetchMeetingMetrics = () => {
	return (dispatch) => {
		//Initializing Request
		dispatch(fetchMeetingMetricsRequest());
		//Calling the api
		getMeetingMetrics()
			.then((response) => {
				// response.data is the emotions
				// console.log(response)
				dispatch(fetchMeetingMetricsSuccess(response));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchMeetingMetricsFailure(error.message));
			});
	};
};