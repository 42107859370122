import moment from "moment";

export const calculateSummary = (dataset, userId) => {
	const firstDayOfThisWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
	const lastDayOfThisWeek = moment().endOf("isoWeek").format("YYYY-MM-DD");

	const firstDayOfLastWeek = moment()
		.add("isoWeek", -1)
		.startOf("isoWeek")
		.format("YYYY-MM-DD");
	const lastDayOfLastWeek = moment()
		.add("isoWeek", -1)
		.endOf("isoWeek")
		.format("YYYY-MM-DD");

	const firstDayOfLastMonth = moment()
		.add("month", -1)
		.startOf("month")
		.format("YYYY-MM-DD");
	const lastDayOfLastMonth = moment()
		.add("month", -1)
		.endOf("month")
		.format("YYYY-MM-DD");

	let activeSummary = {};

	let activeInThisWeekList = [];
	let activeInLastWeekList = [];
	let activeInLastMonthList = [];

	for (let i = 0; i < dataset.length; i++) {
		const d = dataset[i]

		if (d.user_id != userId) {
			continue
		}

		let timestamp = moment(d.upload_time);
		let active = d.active;

		if (
			timestamp.isBetween(
				firstDayOfThisWeek,
				lastDayOfThisWeek,
				null,
				"[]"
			)
		) {
			activeInThisWeekList.push(active);
			continue;
		}
		if (
			timestamp.isBetween(
				firstDayOfLastWeek,
				lastDayOfLastWeek,
				null,
				"[]"
			)
		) {
			activeInLastWeekList.push(active);
		}
		if (
			timestamp.isBetween(
				firstDayOfLastMonth,
				lastDayOfLastMonth,
				null,
				"[]"
			)
		) {
			activeInLastMonthList.push(active);
		}
	}

	activeSummary.thisWeek = average(activeInThisWeekList);
	activeSummary.lastWeek = average(activeInLastWeekList);
	activeSummary.lastMonth = average(activeInLastMonthList);


	return activeSummary;
};

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}

	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 100) / 100;
};
