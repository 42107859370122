import { Auth, API } from "aws-amplify";

export async function getSearchResult(
  resource_type,
  tag,
  page,
  limit,
  speaker,
  speakerManagement,
  movie_id
) {
  console.log(page);
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "SearchAPI";
  const path =
    "?account_id=" +
    accountId +
    "&resource_type=" +
    resource_type +
    "&tag=" +
    tag.join(",") +
    "&speaker=" +
    speaker +
    "&page=" +
    page +
    "&limit=" +
    limit +
    "&section_id=" +
    speakerManagement +
    "&movie_id=" +
    movie_id;

  return await API.get(apiName, path);
}

export async function getSearchHighlights(
  resource_type,
  resource_id,
  account_id = ""
) {
  if (account_id === "") {
    account_id = (await Auth.currentUserInfo()).attributes.sub;
  }

  const apiName = "SearchHighlightsAPI";
  const path =
    "?resource_id=" + resource_id + "&resource_type=" + resource_type;
  return await API.get(apiName, path);
}

export async function deleteTag(tag_id, account_id = "") {
  if (account_id === "") {
    account_id = (await Auth.currentUserInfo()).attributes.sub;
  }
  const apiName = "DeleteTagAPI";
  const path = "";
  const myInit = {
    body: {
      tag_id: tag_id,
    },
  };
  return await API.del(apiName, path, myInit);
}

export async function addTag(
  resource_type,
  timestamp,
  movie_id,
  tag,
  comment,
  accountId = ""
) {
  if (accountId === "" || accountId === "SCENE" || accountId === "VIDEO") {
    accountId = (await Auth.currentUserInfo()).attributes.sub;
  }

  const apiName = "AddTagAPI";
  const path = "";
  let myInit = {};

  if (resource_type === "SCENE") {
    myInit = {
      body: {
        resource_type: resource_type,
        timestamp: parseInt(timestamp),
        movie_id: movie_id,
        tag: tag,
        comment: comment,
        account_id: accountId,
      },
    };
  } else if (resource_type === "VIDEO") {
    myInit = {
      body: {
        resource_type: resource_type,
        movie_id: movie_id,
        tag: tag,
        comment: comment,
        account_id: accountId,
      },
    };
  } else if (resource_type === "SCENE_TRANSCRIPT") {
    myInit = {
      body: {
        resource_type: resource_type,
        timestamp: parseInt(timestamp),
        movie_id: movie_id,
        tag: tag,
        comment: comment,
        account_id: accountId,
      },
    };
  }

  console.log(myInit.body);
  return await API.post(apiName, path, myInit);
}

export async function updateTagFunction(tag_id, tag, comment, accountId = "") {
  if (accountId === "") {
    accountId = (await Auth.currentUserInfo()).attributes.sub;
  }
  const apiName = "UpdateTagAPI";
  const path = "";
  const myInit = {
    body: {
      tag_id: tag_id,
      tag: tag,
      comment: comment,
    },
  };
  return await API.put(apiName, path, myInit);
}

export async function getTagSuggestions() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "GetTagSuggestions";
  const path = "/suggestion?account_id=" + accountId;
  return await API.get(apiName, path);
}
