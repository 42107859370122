import moment from 'moment-timezone';

const plotData = (dataset, userId) => {
	let users = new Set();
	let score = 0;

	// if ("healthy_scores" in healthyScores && healthyScores.healthy_scores.length != 0) {
		if (userId == "all") {
	
			let sum = 0;
			for (let i = 0; i < dataset.length; i++) {
				sum += dataset[i].healthy;
			}

			score = sum / dataset.length;

		} else {

			let sum = 0;
			let count = 0;
			for (let i = 0; i < dataset.length; i++) {
				if (dataset[i].user_id == userId) {
					sum += dataset[i].healthy;
					count +=1;
				}
			}
			score = sum / count;
		}

		const dataSource = {
      chart: {
        caption: "Score",
        // subcaption: "over all",
        // numbersuffix: "%",
        gaugefillmix: "{dark-20},{light+70},{dark-10}",
        theme: "fusion",
      },
      colorrange: {
        color: [
          {
            minvalue: "0",
            maxvalue: "1.0",
            label: "low",
            code: "#F2726F",
          },
          {
            minvalue: "1.0",
            maxvalue: "3.0",
            label: "middle",
            code: "#FFC533",
          },
          {
            minvalue: "3.0",
            maxvalue: "5.0",
            label: "High",
            code: "#62B58F",
          },
        ],
      },
      dials: {
        dial: [
          {
            value: score,
          },
        ],
      },
      trendpoints: {
        point: [
          {
            startValue: score,
            useMarker: "1",
            markerColor: "#000000",
            markerBorderColor: "#666666",
            markerRadius: "10",
            // markerTooltext: "Previous year's average was 62",
            // displayValue: "Last Year",
            color: "#0075c2",
            thickness: "0",
            alpha: "100",
          },
        ],
      },
      // pointers: {
      // 	pointer: [
      // 		{
      // 			value: score
      // 		}
      // 	]
      // }
    };

		return dataSource;
	// };
}
export default plotData;
