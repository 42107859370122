import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTrash,
  faEdit,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import "./Info.css";

const iconStyle = {
  fontSize: 20,
  color: "grey",
};

const Info = (props) => {
  console.log(props);

  let meetingDate = "";
  let topic = "";
  let duration = 0;
  let participantsCount = 0;

  let userName = "No name";
  let userId = "";
  let url = "";

  const style = {
    backgroundColor: "#e2c9b5",
    marginRight: "20px",
    borderRadius: "15px",
    width: "100%",
  };

  if (props.user && props.user.users) {
    if (
      props.user.users.first_name === null ||
      props.user.users.last_name === null
    ) {
      userName = "No name";
    } else {
      userName = props.user.users.first_name + " " + props.user.users.last_name;
    }
    url = props.user.users.url;
    userId = props.user.users.user_id;
  }

  const userInfo = props.user ? (
    <section className="user-info-root">
      <div className="user-info-img-container">
        <img src={url} controls width="90" height="90" />
      </div>

      <div className="user-info-card">
        <div className="user-info-item">
          <span className="">Name</span>
          <span className="">{userName}</span>
        </div>
        <div className="user-info-item">
          <span className="">ID</span>
          <span className="">{userId}</span>
        </div>
      </div>

      {/* <div className='d-flex flex-column py-2 px-4' style={style}>
					<span className='small'>ID</span>
					<span className='h5'>{userId}</span>
				</div> */}
    </section>
  ) : null;

  if (props.movie && props.movie.movies) {
    meetingDate = props.movie.movies.upload_time;
    topic = props.movie.movies.topic;
  }

  const movieInfo = props.movie ? (
    <section className="m-4 mb-5">
      <div className="d-flex">
        <div className="d-flex flex-column py-2 px-4" style={style}>
          <span className="small">Meeting Date</span>
          <span className="h5">{meetingDate}</span>
        </div>

        <div className="d-flex flex-column py-2 px-4" style={style}>
          <span className="small">Topic</span>
          <span className="h5">{topic}</span>
        </div>
        {/* THIS IS THE CODE FOR THAT TAG SECTION */}
        {props.tags !== undefined ? (
          <div className="d-flex flex-column py-2 px-4" style={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="small">Tags</span>
              <button
                style={{ backgroundColor: "transparent ", border: "0px" }}
                onClick={() => {
                  props.setOpen(true);
                }}
              >
                <FontAwesomeIcon style={iconStyle} icon={faEdit} />
              </button>
            </div>

            <span className="h5" style={{ flexWrap: "wrap", width: "40vw" }}>
              {props.tags.map((tag) => {
                return (
                  <span
                    style={{
                      backgroundColor: "#6D5F57",
                      borderRadius: "4rem",
                      padding: "2px 10px",
                      color: "white",
                      marginRight: "5px",
                      lineHeight: "2rem",
                      fontSize: "1rem",
                    }}
                  >
                    {tag.tag}
                  </span>
                );
              })}
            </span>
          </div>
        ) : null}
      </div>
    </section>
  ) : null;

  if (props.zoomMeeting && props.zoomMeeting.meetingDate) {
    meetingDate = props.zoomMeeting.start_time.toString().split("T")[0];
    topic = props.zoomMeeting.topic;
    duration = props.zoomMeeting.duration;
    participantsCount = props.zoomMeeting.participants_count;
  }

  const zoomInfo = props.zoomMeeting ? (
    <section className="m-4 mb-5">
      <div className="d-flex">
        <div className="d-flex flex-column py-2 px-4" style={style}>
          <span className="small">Meeting Date</span>
          <span className="h5">{meetingDate}</span>
        </div>

        <div className="d-flex flex-column py-2 px-4" style={style}>
          <span className="small">Topic</span>
          <span className="h5">{topic}</span>
        </div>
        <div className="d-flex flex-column py-2 px-4" style={style}>
          <span className="small">Duration</span>
          <span className="h5">{duration}</span>
        </div>
        <div className="d-flex flex-column py-2 px-4" style={style}>
          <span className="small">Participants Count</span>
          <span className="h5">{participantsCount}</span>
        </div>
      </div>
    </section>
  ) : null;

  let info = userInfo;
  if (props.user && props.movie) {
    info = (
      <div className="d-flex">
        {userInfo}
        {movieInfo}
      </div>
    );
  } else if (props.user) {
    info = userInfo;
  } else if (props.movie) {
    info = movieInfo;
  } else if (props.zoomMeeting) {
    info = zoomInfo;
  }

  return info;
};

export default Info;
