import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";

import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
// import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import aws from "./utils/aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";

import { Provider } from "react-redux";
import store from "./store/rootStore";

import NavigationDrawer from "./components/NavigationDrawer";

import Dashboard from "./containers/Dashbaord/Dashboard";
import DashboardPerUser from "./containers/Dashbaord/DashbaordPerUser";
import DashboardPerMeeting from "./containers/Dashbaord/DashboardPerMeeting";
import DashboardPerMeetingAndUser from "./containers/Dashbaord/DashboardPerMeetingAndUser";
// import User from "./containers/User";

import Movies from "./containers/MovieList/Movies";
import Users from "./containers/UserList/Users";
import Player from "./containers/Player";
import Analytics from "./containers/Labs/Analytics";
import Download from "./containers/Labs/Download/Download";

import Subscriptions from "./containers/Admin/Subscriptions";
import { getRole } from "./api/Admin";
import { fetchAdminAccess } from "./store/admin/adminAction";
import { fetchBenchmarkSettings } from "./store/benchmark/benchmarkEffect";


import MoviesV2 from "./containers/MovieList/MovieListV2/Movies";
import UsersV2 from "./containers/UserList/UserListV2/Users";

import CreateAlert from "./containers/Alerts/CreateAlert";
import AlertList from "./containers/Alerts/AlertList";
import UpdateAlert from "./containers/Alerts/UpdateAlert";
import Notifications from "./containers/Notifications/Notifications";

import Settings from "./containers/Settings";

import SignUp from "./containers/SignUp";

import Upload from "./containers/Upload";

import DeleteMovie from "./containers/DeleteMovie";
import UpdateMovie from "./containers/UpdateMovie";

import { SearchContainer } from "./containers/Search";
import QuickView from "./containers/QuickView";
import DataManagement from "./containers/DataManagement";
import SettingsV2 from "./containers/settings_v2";
import TranscriptDetails from "./containers/Dashbaord/TranscriptDetails";

import Shared_Dashboard from "./containers/shared_dashboard";

import routes from "./utils/routes";
import { Auth } from "aws-amplify";
import { initializeGA, googleAnalyticsId } from "./utils/gaUtil";
import IBYLogoFull from "./assets/IBYlogofull.png";
import LocalOfferIcon from "./assets/local_offerIcon.svg";
import GroupIcon from "./assets/groupIcon.svg";
import DownloadIcon from "./assets/downloadIcon.svg";
import DashbaordIcon from "./assets/dashboardIcon.svg";
import VideoLibraryIcon from "./assets/video_libraryIcon.svg";
import ClearIcon from "./assets/clearIcon.svg";
import { Link } from "react-router-dom";
import NotificationIcon from "./assets/notificationsIcon.svg";
import SettingsIcon from "./assets/settingsIcon.svg";
import LogoutIcon from "./assets/LogoutIcon.svg";
import IBYFullLogo from "./assets/iby-full-logo.png";
import DisplayImage from "./assets/visuals.png";

import { BsInstagram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import DashboardPerSection from "./containers/Dashbaord/DashboardPerSection";
import CompareSection from "./containers/MovieList/MovieListV2/CompareSection/CompareSection";

import {
  DeleteMovieSection,
  UpdateMovieSection,
} from "./containers/MovieList/MovieSections/MovieSectionCRUD";

// Initializing AWS Amplify
Amplify.configure(aws);

async function getAccountId() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  return accountId;
}
const getDetails = () => {
  let company_name = "";
  let sender_id = "";
  const res = window.location.search.toString();
  const reg = new RegExp("/?company_name");
  if (reg.test(res)) {
    company_name = res.split("?")[1].split("&")[0].split("=")[1];
    sender_id = res.split("?")[1].split("&")[1].split("=")[1];
  }

  return { company_name, sender_id };
};

//All routes are defines in "./utils/routes.js"
const PageTemplate = () => {
  const [details, setDetails] = useState(getDetails());
  const [goBack, setGoBack] = useState("");

  const [accountId, setAccountId] = useState("");
  const [location, setLocation] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const role = useSelector((state) => {
    return state.adminReducer.role;
  });
  const router = useRef(null);
  const dispatch = useDispatch();

  useEffect(async () => {
    const result = await getRole();
    console.log(result[0].role_name);
    dispatch(fetchAdminAccess(result[0].role_name));
  }, []);

  useEffect(() => {
    setDetails(getDetails());
  }, [goBack]);

  useEffect(() => {
    initializeGA();
    getAccountId()
      .then((response) => {
        // console.log(response)
        setAccountId(response);
        window.gtag("event", "login", {
          event_category: "engagement",
          event_label: response,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    router.current.history.listen((location) => {
      setLocation(`${location.pathname}${location.search}`);
    });
  }, []);
  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    document.location.reload();
  };

  useEffect(() => {
    window.gtag("config", googleAnalyticsId, {
      page_path: location,
    });
    window.gtag("event", "view_page", {
      event_category: "engagement",
      event_label: accountId,
    });
  }, [location]);

  useEffect(() => {
    dispatch(fetchBenchmarkSettings());
  }, []);

  return (
    <Router ref={router}>
      <div className="app-root-container">
        <div
          className={showSidebar ? "sidebar-active" : "sidebar-active inactive"}
        >
          <div className="sidebar-heading-item">
            <img src={IBYLogoFull} width={150} />
            <img
              src={ClearIcon}
              width={24}
              onClick={() => {
                setShowSidebar(false);
              }}
            />
          </div>
          <hr />
          <div className="sidebar-item">
            <img src={DashbaordIcon} />
            <Link className="Link-tag" to={routes.home}>
              <span className="navigationdrawer-item-text">Dashboard</span>
            </Link>
          </div>
          <div className="sidebar-item">
            <img src={GroupIcon} />
            <Link className="Link-tag" to={routes.users}>
              <span className="navigationdrawer-item-text">Users</span>
            </Link>
          </div>
          <div className="sidebar-item">
            <img src={VideoLibraryIcon} />
            <Link className="Link-tag" to={routes.movies}>
              <span className="navigationdrawer-item-text">All movies</span>
            </Link>
          </div>
          <div className="sidebar-item">
            <img src={DownloadIcon} />
            <Link className="Link-tag" to={routes.upload}>
              <span className="navigationdrawer-item-text">Upload</span>
            </Link>
          </div>
          <div className="sidebar-item">
            <img src={DownloadIcon} />
            <Link className="Link-tag" to={routes.downlaod}>
              <span className="navigationdrawer-item-text">Download</span>
            </Link>
          </div>
          <div className="sidebar-item">
            <img src={LocalOfferIcon} />
            <Link className="Link-tag" to={routes.search}>
              <span className="navigationdrawer-item-text">Search</span>
            </Link>
          </div>
          <hr />
          <div className="sidebar-item">
            <img src={SettingsIcon} />
            <Link className="Link-tag" to={routes.settings}>
              <span className="navigationdrawer-item-text">Settings</span>
            </Link>
          </div>
          {role === "ADMIN" && (
            <div className="sidebar-item">
              <img src={GroupIcon} />
              <Link className="Link-tag" to={routes.subscriptions}>
                <span className="navigationdrawer-item-text">
                  Subscriptions
                </span>
              </Link>
            </div>
          )}
          <div className="sidebar-item">
            <img src={LogoutIcon} />
            <Link className="Link-tag" onClick={signOut}>
              <span className="navigationdrawer-item-text">Log out</span>
            </Link>
          </div>
          <hr />
          <div className="sidebar-item">
            <span className="navigationdrawer-item-text">Contact us</span>
          </div>
          <div className="socials-link-container">
            <BsInstagram
              size={24}
              className="socials-icon-button"
              onClick={() => {
                window.open("https://www.instagram.com/imbesideyouinc/");
              }}
            />
            <FaTwitter
              size={24}
              className="socials-icon-button"
              onClick={() => {
                window.open("https://twitter.com/BesideIm");
              }}
            />
            {/* <FaFacebook /> */}
            <FaLinkedin
              size={24}
              className="socials-icon-button"
              onClick={() => {
                window.open("https://www.linkedin.com/company/imbesideyou/");
              }}
            />
          </div>
        </div>
        <NavigationDrawer
          getDetails={getDetails}
          showSidebar={() => {
            setShowSidebar((prev) => !prev);
          }}
        />
        <div className="sizedbox" />

        <Switch>
          <Route exact path={routes.home} component={Dashboard} />

          <Route exact path={routes.movies} component={MoviesV2} />

          <Route exact path={routes.users} component={UsersV2} />

          <Route
            exact
            path={routes.movieDashboard}
            component={DashboardPerMeeting}
          />
          <Route
            exact
            path={routes.sectionDashboard}
            component={DashboardPerSection}
          />

          <Route
            exact
            path={routes.compareSection}
            component={CompareSection}
          />

          <Route
            exact
            path={routes.transcript_details}
            component={TranscriptDetails}
          />

          <Route exact path={routes.subscriptions} component={Subscriptions} />

          <Route
            exact
            path={routes.userDashboard}
            component={DashboardPerUser}
          />

          <Route
            exact
            path={routes.userMovieDashboard}
            component={DashboardPerMeetingAndUser}
          />

          <Route exact path={routes.player} component={Player} />

          <Route exact path={routes.analytics} component={Analytics} />

          <Route exact path={routes.downlaod} component={Download} />

          <Route exact path={routes.movies2} component={MoviesV2} />

          <Route exact path={routes.users2} component={UsersV2} />

          <Route exact path={routes.createAlert} component={CreateAlert} />

          <Route exact path={routes.alertList} component={AlertList} />

          <Route exact path={routes.updateAlert} component={UpdateAlert} />

          <Route exact path={routes.settings} component={SettingsV2} />

          <Route exact path={routes.upload} component={Upload} />

          <Route exact path={routes.deleteMovie} component={DeleteMovie} />

          <Route exact path={routes.updateMovie} component={UpdateMovie} />
          <Route
            exact
            path={routes.updateMovieSection}
            component={UpdateMovieSection}
          />
          <Route
            exact
            path={routes.deleteMovieSection}
            component={DeleteMovieSection}
          />
          <Route exact path={routes.search} component={SearchContainer} />
          <Route exact path={routes.quickView} component={QuickView} />
          <Route
            exact
            path={routes.datamanagement}
            component={DataManagement}
          />
          <Route exact path={routes.settings_v2} component={SettingsV2} />

          <Route
            exact
            path={routes.datamanagement}
            component={DataManagement}
          />
          <Route
            exact
            path={routes.shared_dahsboard}
            component={Shared_Dashboard}
          />
          <Route
            exact
            path={routes.shared_dahsboard_search}
            component={SearchContainer}
          />
          <Route
            exact
            path={routes.shared_dashboard_quickview}
            component={QuickView}
          />
          <Route exact path={routes.notifications} component={Notifications} />
        </Switch>
      </div>
    </Router>
  );
};

const Login = (props) => {
  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      props.setAuthState(nextAuthState);
      props.setUser(authData);
    });
  }, []);

  return (
    <AmplifyAuthenticator>
      <AmplifySignIn
        headerText="Login"
        slot="sign-in"
        hideSignUp={true}
        formFields={[
          {
            type: "username",
            label: "",
            placeholder: "mail id",
          },
          { type: "password", label: "", placeholder: "password" },
        ]}
        submitButtonText="Login"
      ></AmplifySignIn>
    </AmplifyAuthenticator>
  );
};

const UnAuthPages = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path={routes.signUp} component={SignUp} />
        <Route>
          <div className="login-page">
            <div className="login-page-left">
              <img
                src={IBYFullLogo}
                alt="Logo"
                className="login-page-left-header"
              />
              <Login
                setAuthState={props.setAuthState}
                setUser={props.setUser}
              />
              <div className="login-page-left-footer">
                © 2022 I’mBesideYou. All Rights Reserved
              </div>
            </div>
            <div className="login-page-right">
              <img src={DisplayImage} alt="Logo" />
            </div>
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

const App = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  return (
    <Provider store={store}>
      {authState === AuthState.SignedIn && user ? (
        <PageTemplate />
      ) : (
        <UnAuthPages setAuthState={setAuthState} setUser={setUser} />
      )}
    </Provider>
  );
};

export default App; // withAuthenticator() automatically adds auth facility
