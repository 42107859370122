import React from "react";

import FaceEmotions from "./FaceEmotionsV2";
import ValenceScore from "./ValenceScore";

import ActiveScores from "./ActiveScores";
import EngagementScores from "./EngagementScores";
import HealthyScores from "./HealthyScores";
import DetectedFaces from "./DetectedFaces";
import UserScoreCards from "./UserScoreCards";
// import S3Video from "../../containers/MovieList/MovieListV2/S3Video";
import S3Video from "../../containers/UserList/UserListV2/S3Video";
import "./index.css";

const ChartSection = ({ userId, meetingMetrics }) => {
  // console.log(detectedFaces)

  return (
    <section className="chartSection">
      <div className="user-overview-container detail-panel-video-card">
        <S3Video userId={userId} type="happy" />
        <UserScoreCards dataset={meetingMetrics} userId={userId} />
      </div>
      <div className="container">
        <div className="row mt-5">
          {/* <div className='mb-5'>
						<FaceEmotions dataset={faceEmotions} userId={userId} />
					</div>
					<div className='mb-5'>
						<ValenceScore dataset={faceEmotions} userId={userId} />
					</div> */}

          <div className="mb-5">
            <ActiveScores dataset={meetingMetrics} userId={userId} />
          </div>
          {/* <div className='mb-5'>
						<EngagementScores engagementScores={engagementScores} userId={userId} />
					</div> */}
          <div className="mb-5">
            <DetectedFaces dataset={meetingMetrics} userId={userId} />
          </div>
          <div className="mb-5">
            <HealthyScores dataset={meetingMetrics} userId={userId} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChartSection;
