import { Auth, API } from "aws-amplify";

export async function getRandomHighlightsOfMovie(type, num) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "HighlightsAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + "random" + "&type=" + type + "&num=" + num;

	return await API.get(apiName, path);
}

export async function getHighlightOfMovie(movieId, type, accountId = "") {

	if (accountId === ""){
		accountId = (await Auth.currentUserInfo()).attributes.sub;
	}
	
	const apiName = "HighlightsAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&type=" + type;

	return await API.get(apiName, path);
}

export async function getLatestHighlightOfParticipant(participantId, type) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "HighlightsAPI";
	const path = "?account_id=" + accountId + "&participant_id=" + participantId + "&type=" + type;

	return await API.get(apiName, path);
}

export async function getHighlightOfMovieAndParticipant(movieId, participantId, type) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "HighlightsAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&participant_id=" + participantId + "&type=" + type;

	return await API.get(apiName, path);
}

