import React, { useEffect, useState } from "react";
import "./CompareSectionModal.css";
import { AiOutlineClose } from "react-icons/ai";
import { getMovies } from "../../../../api/Movies";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  createMovieSection,
  addMoviesInSection,
} from "../../../../api/MovieSections";
import ClipLoader from "react-spinners/ClipLoader";

const CompareSectionModal = (props) => {
  const [firstSection, setFirstSection] = useState(null);
  const [secondSection, setSecondSection] = useState(null);

  const generateAutocompleteOptions = (sectionList) => {
    let uniqueSection = new Set();
    sectionList.forEach((section) => {
      uniqueSection.add(section.section_id);
    });
    let uniqueArray = [...uniqueSection];
    let length = uniqueArray.length;
    let res = [];

    for (let i = 0; i < length; i++) {
      let obj = { label: uniqueArray[i], id: uniqueArray[i] };
      res.push(obj);
    }
    console.log("autocomplete options - ", res);
    return res;
  };
  let sectionOptions = generateAutocompleteOptions(props.sectionList);

  if (props.enabled === false) return null;

  return (
    <div className="compare-section-modal-root">
      <div className="compare-section-modal-content">
        <div className="compare-section-modal-topbar">
          <span className="section-title-text compare-section-modal-heading">
            Select Sections to compare
          </span>
          <AiOutlineClose size={36} onClick={props.toggleFunc} />
        </div>
        <div className="compare-section-content">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={sectionOptions}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Section" />}
            onSelect={(event) => {
              console.log("select event - ", event.target.value);
              setFirstSection(event.target.value);
            }}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={sectionOptions}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Section" />}
            onSelect={(event) => {
              console.log("select event - ", event.target.value);
              setSecondSection(event.target.value);
            }}
          />
          <div
            className="yellow-big-button"
            onClick={() => {
              console.log("comparing sections - ", firstSection, secondSection);
              if (firstSection !== null && secondSection !== null) {
                let compareUrl =
                  "/section/compare?firstSection=" +
                  firstSection +
                  "&secondSection=" +
                  secondSection;
                window.open(compareUrl);
              }
            }}
          >
            Compare Section
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareSectionModal;
