// Define Schema here for different types of table

//User
export const USER_TABLE = [
  { id: 1, content: "" },
  { id: 2, content: "ID" },
  { id: 3, content: "NAME" },
  { id: 4, content: "REGISTERED TIME" },
  { id: 5, content: "ACTIVE" },
  { id: 6, content: "ENGAGEMENT" },
  { id: 7, content: "HEALTHY" },
  { id: 8, content: "DASHBOARD" },
];

//Zoom User
export const ZOOM_USERS = [
  { id: 1, content: "ID" },
  { id: 2, content: "EMAIL" },
  { id: 3, content: "FIRST NAME" },
  { id: 4, content: "RECORDING" },
];

//Zoom Recording List
export const ZOOM_RECORDING = [
  { id: 1, content: "ID" },
  { id: 2, content: "TITLE" },
  { id: 3, content: "START TIME" },
  { id: 4, content: "DURATION" },
  { id: 5, content: "JOIN URL" },
  { id: 6, content: "DASHBOARD" },
];

//Movie
export const MOVIE_TABLE = [
  { id: 1, content: "" },
  { id: 2, content: "ID" },
  { id: 3, content: "TOPIC" },
  { id: 4, content: "UPLOAD TIME" },
  { id: 5, content: "ACTIVE" },
  { id: 6, content: "ENGAGEMENT" },
  { id: 7, content: "HEALTHY" },
  { id: 8, content: "DASHBOARD" },
];

//Search
export const SEARCH_TABLE = [
  { id: 1, content: "Movie Id" },
  { id: 2, content: "User Id" },
  { id: 3, content: "Date" },
  { id: 4, content: "Title" },
  { id: 5, content: "Tag" },
  { id: 6, content: "Dashboard" },
  { id: 7, content: "Highlight" },
  { id: 8, content: "Quick View" },
];

//Highlight
export const QUICKVIEW_TABLE = [
  { id: 1, content: "Timestamp" },
  { id: 2, content: "Tag" },
  { id: 3, content: "Note" },
  { id: 4, content: "Update" },
];

// Edit movie tag
export const MOVIE_TAG_TABLE = [
  { id: 1, content: "Tag" },
  { id: 2, content: "Delete" },
];

// Edit movie comment
export const MOVIE_COMMENT_TABLE = [
  { id: 1, content: "Comment" },
  { id: 2, content: "Delete" },
];