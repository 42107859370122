import React, { useEffect, useState } from "react";
import { getMovieSections } from "../../../api/MovieSections";
import MovieSectionTables from "../MovieSections/Tables";
import Charts from "./Charts";
import Tables from "./Tables";
import "./Movies.css";

import { FaAngleDown } from "react-icons/fa";
import getSectionDataset from "../MovieSections/Tables/helper";
import { createDispatchHook } from "react-redux";
import { Compare, EcoTwoTone } from "@material-ui/icons";
import CreateSectionModal from "./CreateSectionModal/CreateSectionModal";
import CompareSectionModal from "./CompareSectionModal/CompareSectionModal";
const Movies = () => {
  const [selectedMovieInfo, setSelectedMovieInfo] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const toggleMenu = () => {
    setSelectedMenu((prev) => (prev + 1) % 2);
  };

  return (
    <div className="movies-root-div">
      <div className="movie-switch-root">
        <div
          onClick={() => setSelectedMenu(0)}
          className={
            selectedMenu === 0
              ? "movie-switch-entry"
              : "movie-switch-entry-inactive"
          }
        >
          <span>Individuals</span>
        </div>
        <div
          onClick={() => setSelectedMenu(1)}
          className={
            selectedMenu === 1
              ? "movie-switch-entry"
              : "movie-switch-entry-inactive"
          }
        >
          <span>Sections</span>
        </div>
      </div>
      {/* <IndividualMovies /> */}
      {selectedMenu === 0 ? <IndividualMovies /> : <MovieSections />}
    </div>
  );
};

const IndividualMovies = () => {
  const [selectedMovieInfo, setSelectedMovieInfo] = useState([]);

  return (
    <div className="col-md-10 p-4 mt-5">
      <Tables setSelectedMovieInfo={setSelectedMovieInfo} />
      <Charts movieInfoList={selectedMovieInfo} />
    </div>
  );
};

const MovieSections = () => {
  const [sectionList, setSectionList] = useState([]);
  const [uniqueSectionList, setUniqueSectionList] = useState([]);
  console.log("get movie section list state - ", sectionList);
  const dummySectionList = [
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "D5rRjWvJ",
      section_id: "section_test_001",
      section_name: "test section",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "Jwrnv4pD",
      section_id: "section_test_001",
      section_name: "test section",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "9X403QO9",
      section_id: "section_test_002",
      section_name: "test section2",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "fe50957f-11a0-4fac-a5cb-e361c8b6091b",
      section_id: "section_test_002",
      section_name: "test section2",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "9X403QO9",
      section_id: "section_test_003",
      section_name: "test section3",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "Jwrnv4pD",
      section_id: "section_test_003",
      section_name: "test section3",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "9X403QO9",
      section_id: "section_test_004",
      section_name: "test section4",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "Jwrnv4pD",
      section_id: "section_test_004",
      section_name: "test section4",
    },
    {
      account_id: "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa",
      movie_id: "fe50957f-11a0-4fac-a5cb-e361c8b6091b",
      section_id: "section_test_004",
      section_name: "test section4",
    },
  ];

  const getMovieArrayForSection = (rawData, sectionID) => {
    let movieArray = [];
    rawData.forEach((entry) => {
      if (entry.section_id === sectionID) {
        movieArray.push(entry.movie_id);
      }
    });

    return movieArray;
  };

  const getSectionNameForSection = (rawData, sectionID) => {
    let res = "No name";
    rawData.forEach((entry) => {
      if (entry.section_id === sectionID) {
        res = entry.section_name;
      }
    });
    return res;
  };

  const reformatData = (rawData) => {
    console.log("reformatting data");
    let res = [];
    let sectionSet = new Set();
    rawData.forEach((data) => {
      sectionSet.add(data.section_id);
    });
    console.log("section set inside reformatDAta function - ", sectionSet);
    let uniqueSectionList = [...sectionSet];
    // console.log(
    //   "movie array for zero element in section set - ",
    //   getMovieArrayForSection(rawData, uniqueSectionList[0])
    // );

    uniqueSectionList.forEach((sectionID) => {
      let currObj = {
        section_id: sectionID,
        movie_list: getMovieArrayForSection(rawData, sectionID),
        section_name: getSectionNameForSection(rawData, sectionID),
      };
      res.push(currObj);
    });

    console.log("reformatted data - ", res);
    return res;
  };
  // REFORMATTED DATA IS OF THE FORM -
  // 0:
  // movie_list: (2) ['D5rRjWvJ', 'Jwrnv4pD']
  // section_id: "section_test_001"
  // section_name: "test section"

  useEffect(
    () => {
      getMovieSections()
        .then((response) => {
          setSectionList(reformatData(response));
          console.log("get movie section response  - ", response);
        })
        .catch((err) => {
          console.log("error in get movie section api call - ", err);
        });
      // if (sectionList.length === 0)
      //   setSectionList(reformatData(dummySectionList));
      // reformatData(sectionList);
    },
    []
    // [sectionList]
  );
  const [expandedSections, setExpandedSections] = useState([]);
  const [expandedSection, setExpandedSection] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  // console.log("section dataset for table - ", getSectionDataset(sectionList));
  const toggleShowModal = () => {
    setShowModal((prev) => !prev);
  };
  const toggleCompareModal = () => {
    setShowCompareModal((prev) => !prev);
  };

  const rowsCallback = (rows) => {
    console.log("rows callback - ", rows);
    setSelectedRows(rows);
  };

  const launchComparePage = (firstSection, secondSection) => {
    if (firstSection !== null && secondSection !== null) {
      let compareUrl =
        "/section/compare?firstSection=" +
        firstSection +
        "&secondSection=" +
        secondSection;
      window.open(compareUrl);
    }
  };
  return (
    <div className="movie-sections-root">
      <CreateSectionModal enabled={showModal} toggleFunc={toggleShowModal} />
      <CompareSectionModal
        enabled={showCompareModal}
        toggleFunc={toggleCompareModal}
        sectionList={sectionList}
      />
      <div>
        <div className="movie-section-top-bar">
          <div
            className="yellow-big-button"
            onClick={() => {
              if (selectedRows.length < 2) toggleCompareModal();
              else
                launchComparePage(
                  selectedRows[0].section_id,
                  selectedRows[1].section_id
                );
            }}
          >
            Compare Section
          </div>
          <div className="black-big-button" onClick={toggleShowModal}>
            Add Section
          </div>
        </div>

        <MovieSectionTables
          sectionList={sectionList}
          rowsCallback={rowsCallback}
        />
      </div>
    </div>
  );
  return (
    <div className="movie-sections-root">
      <MovieSectionTables sectionList={sectionList} />
      {sectionList.map((section) => {
        return (
          <div className="section-list-element-root">
            <div
              className={
                expandedSection === section.section_id
                  ? "section-list-element-active"
                  : "section-list-element"
              }
              onClick={() => {
                setExpandedSection(section.section_id);
              }}
            >
              <span>{section?.section_name}</span>
              <span>{section?.section_id}</span>
              <span>{section?.movie_list.length}</span>
              <FaAngleDown />
            </div>
            <div
              // className={
              //   expandedSections.includes(section?.section_id)
              //     ? "section-list-movie-active"
              //     : "section-list-movie"
              // }
              className={
                expandedSection === section.section_id
                  ? "section-list-movie-active"
                  : "section-list-movie"
              }
            >
              {section.movie_list.map((movie) => {
                {
                  /* LINK EXAMPLE - http://localhost:3000/movies/dashboard?movieId=9X403QO9 */
                }
                const link =
                  window.location.href.split("/").slice(0, 3).join("/") +
                  "/movies/dashboard?movieId=" +
                  movie;
                return (
                  <div
                    className="expanded-movie-element"
                    onClick={() => {
                      window.open(link);
                    }}
                  >
                    <span className="info-text-small">Movie ID: {movie}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Movies;

// 1. MAKE NEW SECTION IN THE DEV ACCOUNT_ID FOR TESTING
// 2. ADD RELAVENT MOVIE LIST IN IT THAT ARE VALID AND PRESENT IN THE DEV ACCOUNT
// 3. WIRE UP THE API AND THEN DISPLAY THE SECTION LIST
// 4. ADD THE OPTIONS FOR CREATING NEW SECTION AND ADDING MOVIES WITH THE APIS THAT ARE CREATED
// 5. EXPLORE INTO DEVELOPING THE INSIDE PAGES THAT COMPARES THE CHARTS etc
