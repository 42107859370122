import React, { useState, useEffect } from "react";

import {
    getMovie
} from "../../../../api/Movies";

import {
    getHighlightOfMovie
} from "../../../../api/Highlights";


const S3Video = (props) => {

    const movieId = props.movieId;
    const type = props.type;
    const [url, setUrl] = useState("");

    
    // useEffect(() => {
    //     getMovie(movieId)
    //         .then((response) => {
    //             setUrl(response.movies.url)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    
    useEffect(() => {
        getHighlightOfMovie(movieId, type)
            .then((response) => {
                setUrl(response.highlights.url)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const vidoDeom = (url != "") ? (
        <video
            controls
            width="320" height="180"
            src={url}
        />

    ) : (
        <div>
            No data
        </div>
    );

    return (
        <div>
            <div className='py-2'
                style={{ fontWeight: "bold" }}>
                {type.toUpperCase()}
            </div>
            {vidoDeom}
        </div>
    );
};

export default S3Video;
