import React, { useState, useEffect } from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import Amplify, { Auth } from "aws-amplify";
import Logo from "../assets/logo.png";
import IBYLogo from "../assets/iby_logo_small.svg";
import HamburgerMenuIcon from "../assets/hamburger_menu_icon.svg";
import { Link } from "react-router-dom";
import routes from "../utils/routes";
import "./NavigationDrawer.css";

import { FaBell } from "react-icons/fa";

import { getAccount } from "../api/Accounts";
import { Tooltip } from "@mui/material";
import FloatingButton from "../assets/floating-btn.png";

import { useDispatch } from "react-redux";
import { resetSearchResult } from "../store/search/searchAction";

const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
  document.location.reload();
};

const NavigationDrawer = (props) => {
  const dispatch = useDispatch();
  const reset = () => {
    dispatch(resetSearchResult());
  };

  //For making the state active when clicked i.e nav-item is highlighted when changed.

  let details = "";

  const route = window.location.pathname.split("/")[1];
  let index;
  switch (route) {
    case "":
      index = 0;
      break;
    case "users":
      index = 1;
      break;

    case "movies":
      index = 2;
      break;

    case "downlaod":
      index = 3;
      break;

    case "upload":
      index = 4;
      break;

    case "search":
      index = 5;
      break;

    case "settings":
      index = 6;
      break;

    case "share":
      details = props.getDetails();
      break;

    default:
  }
  const array = [false, false, false, false, false, false, false, false];
  array[index] = true;
  const [active, setActive] = useState(array);

  const isActive = (index) => {
    const updatedArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    updatedArray[index] = true;
    setActive(updatedArray);
  };

  const [license, setLicense] = useState("");

  useEffect(() => {
    getAccount()
      .then((response) => {
        // console.log(response)
        if (response.account.length != 0) {
          setLicense(response.account[0].iby_license);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="navigationdrawer-root">
      {details ? (
        <div className="share-navigationdrawer-body">
          <div className="share-navigationdrawer-body-heading">
            <div className="navigationdrawer-item">
              <img src={IBYLogo} alt="IBY Company Logo" />
            </div>
            <div className="navigationdrawer-item">{details.company_name}</div>
          </div>
          <div className="share-navigationdrawer-body-content">
            <div className="navigationdrawer-item">
              <span className="navigationdrawer-item-text">Search</span>
            </div>
          </div>
          <Link
            className="Link-tag"
            to={routes.shared_dahsboard}
            onClick={reset}
          >
            <Tooltip title="Shared Dashboard">
              <div className="share-floating-button">
                <img src={FloatingButton} alt="<"></img>
              </div>
            </Tooltip>
          </Link>
        </div>
      ) : (
        <div className="navigationdrawer-body">
          <div className="navigationdrawer-item">
            <img src={IBYLogo} />
          </div>
          <div className="navigationdrawer-item">
            <Link
              className="Link-tag"
              to={routes.home}
              onClick={() => isActive(0)}
            >
              <span
                className={
                  active[0]
                    ? "navigationdrawer-item-text-selected"
                    : "navigationdrawer-item-text"
                }
              >
                Dashboard
              </span>
            </Link>
          </div>
          <div className="navigationdrawer-item">
            <Link
              className="Link-tag"
              to={routes.users}
              onClick={() => isActive(1)}
            >
              <span
                className={
                  active[1]
                    ? "navigationdrawer-item-text-selected"
                    : "navigationdrawer-item-text"
                }
              >
                User
              </span>
            </Link>
          </div>
          <div className="navigationdrawer-item">
            <Link
              className="Link-tag"
              to={routes.movies}
              onClick={() => isActive(2)}
            >
              <span
                className={
                  active[2]
                    ? "navigationdrawer-item-text-selected"
                    : "navigationdrawer-item-text"
                }
              >
                Movie
              </span>
            </Link>
          </div>
          <div className="navigationdrawer-item">
            <Link
              className="Link-tag"
              to={routes.downlaod}
              onClick={() => isActive(3)}
            >
              <span
                className={
                  active[3]
                    ? "navigationdrawer-item-text-selected"
                    : "navigationdrawer-item-text"
                }
              >
                Download
              </span>
            </Link>
          </div>
          <div className="navigationdrawer-item">
            <Link
              className="Link-tag"
              to={routes.upload}
              onClick={() => isActive(4)}
            >
              <span
                className={
                  active[4]
                    ? "navigationdrawer-item-text-selected"
                    : "navigationdrawer-item-text"
                }
              >
                Upload
              </span>
            </Link>
          </div>
          <div className="navigationdrawer-item">
            <Link
              className="Link-tag"
              to={routes.search}
              onClick={() => isActive(5)}
            >
              <span
                className={
                  active[5]
                    ? "navigationdrawer-item-text-selected"
                    : "navigationdrawer-item-text"
                }
              >
                Search
              </span>
            </Link>
          </div>
          <div className="navigationdrawer-item">
            <Link
              className="Link-tag"
              to={routes.notifications}
              onClick={() => isActive(6)}
            >
              <FaBell
                size={20}
                className={
                  active[3]
                    ? "navigationdrawer-item-text-selected socials-icon-button"
                    : "navigationdrawer-item-text socials-icon-button"
                }
              />
            </Link>

            <img src={HamburgerMenuIcon} onClick={props.showSidebar} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationDrawer;
