import React, { useEffect, useState } from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";


import {
	getTsFaceEmotionsOfMovie,
} from "../../../../../../api/FaceEmotions"

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const EmotionLine = (props) => {
	const movieId = props.movieInfo.movieId;

	const [faceEmotionTs, setFaceEmotionTs] = useState({
		faceEmotionTs: {},
	});
	
	useEffect(() => {
		getTsFaceEmotionsOfMovie(movieId)
			.then((response) => {
				// console.log(response)
				setFaceEmotionTs({
					faceEmotionTs: response
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	// console.log(faceEmotionTs.faceEmotionTs)
	const dataSource = plotData(faceEmotionTs.faceEmotionTs, movieId);

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<div className='card text-center'>
			<div className='pt-3' style={style}>
				{props.movieInfo.topic}
			</div>
			<div className='card-body'>
				<ReactFC
					type="msline"
					width="100%"
					height="500"
					dataFormat="JSON"
					dataSource={dataSource}
				/>
			</div>
		</div>
	);
};

export default EmotionLine;
