import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactFC from "react-fusioncharts";

import FusionCharts from "fusioncharts";

import Line2D from "fusioncharts/fusioncharts.charts";

import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Line2D, FusionTheme);

export default function TranscriptChartVisualisationV3({
  seekToTimestamp,
  raw,
}) {
  // console.log(seekToTimestamp);

  let bgColor = "#83D0FF";
  let lineColor = "#127fcb";

  const [data, setData] = useState([]);
  // console.log(data);
  // const raw = muiTableData;

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  useEffect(() => {
    var chartData = [];
    for (var i = 0; i < raw.length; i = i + 1) {
      if (raw[i].value === 0) {
        chartData.push({
          label: secondsToHms(raw[i].time),
          value: raw[i].value,
          anchorRadius: "0",
          anchorBorderThickness: "2",
          anchorBorderColor: lineColor,
          anchorBgColor: bgColor,
          color: lineColor,
        });
      } else {
        chartData.push({
          label: secondsToHms(raw[i].time),
          value: raw[i].value,
          anchorRadius: "4",
          anchorBorderThickness: "2",
          anchorBorderColor: lineColor,
          anchorBgColor: bgColor,
          color: lineColor,
        });
      }
    }

    setData(chartData);
  }, [raw]);

  const hmsToSeconds = (e) => {
    // console.log(e);
    var h = Number(e.split(":")[0]);
    var m = Number(e.split(":")[1]);
    var s = Number(e.split(":")[2]);

    return h * 3600 + m * 60 + s;
  };

  const chartConfigs = {
    type: "line",
    width: "100%",
    height: "100",
    dataFormat: "json",
    dataSource: {
      chart: {
        showYAxisValues: "0",
        showXAxisValues: "0",
        theme: "fusion",
        plotToolText: `Timestamp: $label <br> Count: $dataValue`,
      },
      data: data,
    },
    events: {
      dataPlotClick: function (e) {
        // console.log(e.data);
        seekToTimestamp(hmsToSeconds(e.data.id));
      },
    },
  };

  // console.log(chartConfigs);

  return (
    <div>
      <ReactFC {...chartConfigs} />
    </div>
  );
}
