import { Auth, API } from "aws-amplify";

export async function getMovieSections() {
  const dummyAccountID = "7bf6f6f5-e6ef-41d7-be54-7a2a65dae7aa";
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  console.log("fetching section for accountID - ", accountId);
  const apiName = "MovieSectionsApi";
  const path = "?account_id=" + accountId;
  // const path = "?account_id=" + dummyAccountID;

  return await API.get(apiName, path);
}

export async function createMovieSection(sectionName) {
  const dummyAccountID = "2e5b4cfb-c80c-4305-b327-fed1dfba023a";
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  console.log("creating section for accountID - ", accountId);
  const apiName = "MovieSectionsApi";
  // const path = "?account_id=" + accountId;
  const path = "";
  // const path = "?account_id=" + dummyAccountID;
  const body = {
    section_name: sectionName,
    account_id: accountId,
  };
  const myInit = {
    body: body,
  };

  return await API.post(apiName, path, myInit);
}

export async function deleteMovieSection(sectionId) {
  const dummyAccountID = "2e5b4cfb-c80c-4305-b327-fed1dfba023a";
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  console.log(
    "deleting section form account id  - ",
    accountId,
    "section id - ",
    sectionId
  );
  const apiName = "DeleteSectionApi";
  // const path = "?account_id=" + accountId;
  const path = "";
  // const path = "?account_id=" + dummyAccountID;
  const body = {
    section_id: sectionId,
    account_id: accountId,
  };
  const myInit = {
    body: body,
  };

  return await API.post(apiName, path, myInit);
}
export async function updateMovieSection(sectionId, sectionName) {
  const dummyAccountID = "2e5b4cfb-c80c-4305-b327-fed1dfba023a";
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  console.log(
    "updating section form account id  - ",
    accountId,
    "section id - ",
    sectionId
  );
  const apiName = "UpdateSectionApi";
  // const path = "?account_id=" + accountId;
  const path = "";
  // const path = "?account_id=" + dummyAccountID;
  const body = {
    section_id: sectionId,
    account_id: accountId,
    section_name: sectionName,
  };
  const myInit = {
    body: body,
  };

  return await API.post(apiName, path, myInit);
}

export async function addMoviesInSection(sectionId, movieList) {
  const dummyAccountID = "2e5b4cfb-c80c-4305-b327-fed1dfba023a";
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  console.log("creating section for accountID - ", accountId);
  const apiName = "MovieSectionAddMovies";
  const path = "?account_id=" + accountId;
  // const path = "?account_id=" + dummyAccountID;
  const body = {
    section_id: sectionId,
    account_id: accountId,
    movie_list: movieList,
  };
  const myInit = {
    body: body,
  };

  return await API.post(apiName, path, myInit);
}

export async function getSectionMovieMetrics(sectionId) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  console.log("fetching section movieMetrics for sectionID - ", sectionId);
  const apiName = "SectionMeetingMetricsApi";
  const path = "?section_id=" + sectionId;
  // const path = "?account_id=" + dummyAccountID;
  let res = null;
  try {
    res = await API.get(apiName, path);
  } catch (err) {
    console.log("error in fetching section movie metrics - ", err);
  }
  return res;
}

export async function getSectionDetails() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  console.log("fetching section details for account id - ", accountId);
  const apiName = "SectionDetailsApi";
  const path = "?account_id=" + accountId;
  // const path = "?account_id=" + dummyAccountID;
  let res = null;
  try {
    res = await API.get(apiName, path);
  } catch (err) {
    console.log("error in fetching section movie metrics - ", err);
  }
  return res;
}

//nose account id - 2e5b4cfb-c80c-4305-b327-fed1dfba023a
//pococha account id - 2e5b4cfb-c80c-4305-b327-fed1dfba023a
