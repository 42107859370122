import React from "react";
import "./ScatterChart.css";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { getDataSource, mergeMovieMetrics } from "./helper";

charts(FusionCharts);

const sampleMetrics = [
  {
    movie_id: "9dka85Xq",
    engagement_score: null,
    activity_score: 7.60103,
    healthy_sore: 1.675,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dka85Xq",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: 142.06,
    text_negative_m: 0.833333,
  },
  {
    movie_id: "9dkaEx6q",
    engagement_score: null,
    activity_score: 22.0452,
    healthy_sore: 1.67952,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dkaEx6q",
    engagement_score: null,
    activity_score: 19.309,
    healthy_sore: 1.53846,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dkaEx6q",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: 135.762,
    text_negative_m: 0.817021,
  },
  {
    movie_id: "9dkaMR6q",
    engagement_score: null,
    activity_score: 11.0261,
    healthy_sore: 1.7474,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dkaMR6q",
    engagement_score: null,
    activity_score: 45.4148,
    healthy_sore: 15.0,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dkaMR6q",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: 78.1579,
    text_negative_m: 0.282895,
  },
  {
    movie_id: "9dkaWkKq",
    engagement_score: null,
    activity_score: 51.0797,
    healthy_sore: 1.94591,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dkaWkKq",
    engagement_score: null,
    activity_score: 32.3274,
    healthy_sore: 2.02711,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dkw0OKq",
    engagement_score: null,
    activity_score: 40.1214,
    healthy_sore: 3.50704,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dkw0OKq",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: 137.786,
    text_negative_m: 0.678571,
  },
  {
    movie_id: "9dkw0OKq",
    engagement_score: null,
    activity_score: 22.6231,
    healthy_sore: 2.07944,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dN18WQJ",
    engagement_score: null,
    activity_score: 16.0409,
    healthy_sore: 1.69697,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dN18WQJ",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: 151.818,
    text_negative_m: 0.454545,
  },
  {
    movie_id: "9dN1KreJ",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dN1KreJ",
    engagement_score: null,
    activity_score: 28.2446,
    healthy_sore: 1.64167,
    speech_speed_m: null,
    text_negative_m: null,
  },
  {
    movie_id: "9dN1KreJ",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: 55.6702,
    text_negative_m: 0.249123,
  },
  {
    movie_id: "9dN1KreJ",
    engagement_score: null,
    activity_score: null,
    healthy_sore: null,
    speech_speed_m: null,
    text_negative_m: null,
  },
];

const ScatterChart = ({
  selectedParams,
  metrics1,
  metrics2,
  section1name,
  section2name,
}) => {
  // mergeMovieMetrics(sampleMetrics);
  return (
    <div>
      <ReactFusioncharts
        type="scatter"
        width="36%"
        height="80%"
        dataFormat="JSON"
        dataSource={getDataSource(
          mergeMovieMetrics(metrics1),
          mergeMovieMetrics(metrics2),
          selectedParams,
          section1name,
          section2name
        )}
      />
    </div>
  );
};

export default ScatterChart;
