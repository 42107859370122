import { Auth, API } from "aws-amplify";

export async function getTranscript(movieId) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "TranscriptsAPI";
  const path = "?account_id=" + accountId + "&movie_id=" + movieId;

  return await API.get(apiName, path);
}

export async function getTranscriptKeywords() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "TranscriptKeywords";
  const path = "?account_id=" + accountId;

  return await API.get(apiName, path);
}

export async function addTranscriptKeywords(transcriptKeyword) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;

  const apiName = "TranscriptKeywords";
  const path = "";
  let myInit = {};

  myInit = {
    body: {
      account_id: accountId,
      keyword: transcriptKeyword,
    },
  };

  return await API.post(apiName, path, myInit);
}

export async function deleteTranscriptKeyword(keyword_id) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "TranscriptKeywords";

  const path = "?account_id=" + accountId + "&keyword_id=" + keyword_id;
  return await API.del(apiName, path);
}