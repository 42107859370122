const plotData = (dataset, userId) => {
	let dateFormat = require("dateformat");
	let scoreList = [];
	let labelList = [];

	let movies = {}
	let topics = {}

	for (let i = 0; i < dataset.length; i++) {
		const df = dataset[i];

		if (df.user_id != userId) {
			continue
		}
		if (df.movie_id in movies) {
			movies[df.movie_id].push(df.ratio);
		} else {
			movies[df.movie_id] = [df.ratio];
			topics[df.movie_id] = df.topic;
		}
	}
	
	Object.keys(movies).forEach(key => {
		let score = {};
		let label = {};

		label.label = topics[key];
		score.value = average(movies[key]);
		score.link = "n-" + window.location.href.split("/").slice(0, 3).join("/")
			+ "/users/movies/dashboard?movieId=" + key + "&userId=" + userId;

		scoreList.push(score);
		labelList.push(label);
	});

	const dataSource = {
		chart: {
			// subcaption: "Engagement Score",
			yaxisname: "Score",
			numvisibleplot: "70",
			labeldisplay: "auto",
			theme: "fusion",
		},
		categories: [
			{
				category: labelList,
			},
		],
		dataset: [
			{
				// seriesname: "Engagement score(Detected Faces)",
				data: scoreList,
			},
		],
	};

	return dataSource;
};

export default plotData;

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 100) / 100;
};

