import { Auth, API } from "aws-amplify";

export async function getZoomTimeline(movieId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;

	const apiName = "ZoomTimelinesAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId;

	return await API.get(apiName, path);
}
