// Step 1 - Include react
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Step 2 - Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Step 3 - Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Step 4 - Include the chart type
import Line2D from "fusioncharts/fusioncharts.charts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";

// Step 5 - Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Step 6 - Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Line2D, FusionTheme);

// Create a JSON object to store the chart configurations

export default function TagVisualisation({
  duration,
  seekToTimestamp,
  chartid,
}) {
  // console.log(duration);

  let bgColor = "#83D0FF";
  if (chartid === "2") {
    bgColor = "#80BFA2";
  }

  let lineColor = "#127fcb";
  if (chartid === "2") {
    lineColor = "#023020";
  }

  const raw = useSelector((state) => {
    if (chartid === "1") {
      return state.searchReducer.chart1;
    } else if (chartid === "2") {
      return state.searchReducer.chart2;
    }
  });

  // console.log(raw);
  const [data, setData] = useState([]);

  useEffect(async () => {
    var chartData = [];
    // console.log(raw);
    // console.log(raw.length);
    var count = 1;
    for (var i = 0; i < raw.length; i = i + 1) {
      if (
        i === raw.length - 1 ||
        raw[i].resource_id.split("_")[1] !==
          raw[i + 1].resource_id.split("_")[1]
      ) {
        chartData.push({
          label: secondsToHms(raw[i].resource_id.split("_")[1]),
          value: count,
          // anchorBorderColor: "#cc3333",
          // anchorBgColor: "#ff9900",
          anchorRadius: "4",
          anchorBorderThickness: "2",
          anchorBorderColor: lineColor,
          // anchorSides: "3",
          anchorBgColor: bgColor,
          color: lineColor,
        });
        count = 1;
      } else {
        count = count + 1;
      }
    }

    setData(chartData);
  }, [raw, duration]);

  // useEffect(async () => {
  //   var chartData = [];
  //   console.log(raw);
  //   console.log(raw.length);
  //   var count = 1;
  //   for (var i = 0; i < raw.length; i = i + 1) {
  //     if (
  //       i === raw.length - 1 ||
  //       raw[i].resource_id.split("_")[1] !==
  //         raw[i + 1].resource_id.split("_")[1]
  //     ) {
  //       chartData.push({
  //         label: secondsToHms(raw[i].resource_id.split("_")[1]),
  //         value: count,
  //         // anchorBorderColor: "#cc3333",
  //         // anchorBgColor: "#ff9900",
  //         anchorRadius: "4",
  //         anchorBorderThickness: "2",
  //         anchorBorderColor: lineColor,
  //         // anchorSides: "3",
  //         anchorBgColor: bgColor,
  //         color: lineColor,
  //       });
  //       count = 1;
  //     } else {
  //       count = count + 1;
  //     }
  //   }

  //   console.log(chartData);
  //   var finalChartData = [];
  //   count = 0;

  //   for (var i = 0; i <= duration; i = i + 1) {
  //     if (
  //       count < chartData.length &&
  //       secondsToHms(i) === chartData[count].label
  //     ) {
  //       finalChartData.push(chartData[count]);
  //       count = count + 1;
  //     } else {
  //       finalChartData.push({
  //         label: secondsToHms(i),
  //         value: 0,
  //         drawAnchors: "0",
  //       });
  //     }

  //     if (i === duration) {
  //       setData(finalChartData);
  //     }
  //   }
  // }, [raw, duration]);

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const hmsToSeconds = (e) => {
    // console.log(e);
    var h = Number(e.split(":")[0]);
    var m = Number(e.split(":")[1]);
    var s = Number(e.split(":")[2]);

    return h * 3600 + m * 60 + s;
  };

  // console.log(data);

  const chartConfigs = {
    type: "line", // The chart type
    width: "100%", // Width of the chart
    height: "100", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        // caption: "Countries With Most Oil Reserves [2017-18]", //Set the chart caption
        // subCaption: "In MMbbl = One Million barrels", //Set the chart subcaption
        // xAxisName: "Country", //Set the x-axis name
        // yAxisName: "Reserves (MMbbl)", //Set the y-axis name
        // numberSuffix: "K",
        showYAxisValues: "0",
        showXAxisValues: "0",
        theme: "fusion", //Set the theme for your chart
        // bgColor: "#DDDDDD",
        // bgAlpha: "50",
        plotToolText: `Timestamp: $label <br> Score: $dataValue`,
        xAxisMinValue: "0",
        xAxisMaxValue: duration,
      },
      // Chart Data - from step 2
      data: data,
    },
    events: {
      dataPlotClick: function (e) {
        // console.log(e.data);
        seekToTimestamp(hmsToSeconds(e.data.id));
        // var infoElem = document.getElementById("infolbl");
        // var index = e.data.dataIndex;
        // infoElem.innerHTML =
        //   "The average value from <b>" +
        //   e.data.startText +
        //   "</b> to <b>" +
        //   e.data.endText +
        //   "</b> is <b>" +
        //   Math.round(e.data.binValue * 100) / 100 +
        //   "k</b>";
      },
    },
  };

  return (
    <div>
      <ReactFC {...chartConfigs} />
    </div>
  );
}
