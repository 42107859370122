import { Auth, API } from "aws-amplify";

export async function shareViaEmail(type, data) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "ShareViaEmail";
  const path = "";
  let body = {};
  if (type === "VIDEO") {
    body = {
      body: {
        email_id: data["email_id"],
        type: type,
        movie_id: data["movie_id"],
        tags: data["tags"],
        comments: data["comments"],
        account_id: accountId,
      },
    };
  } else if (type === "TRANSCRIPT") {
    body = {
      body: {
        email_id: data["email_id"],
        type: type,
        movie_id: data["movie_id"],
        transcriptData: data["transcriptData"],
        account_id: accountId,
      },
    };
  }

  return await API.post(apiName, path, body);
}
