import React, { useState, useEffect }from "react";
import DetectedFaceDistanceGauge from "./UserDetectedFaceDistanceGauge";
import DetectedFaceDistanceHeatMap from "./UserDetectedFaceDistanceHeatMap";

const UserDetectedFaces = (props) => {

	const [userId, setUserId] = useState("all");

	const dataset = props.dataset;
	const users = props.users;

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
    <section className="bar-chart-section">
      <span className="section-title-text">User Engagement Score</span>
      <div className="bar-histogram-chart-column-card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <DetectedFaceDistanceHeatMap
                dataset={dataset}
                users={users}
                setUserId={setUserId}
              />
            </div>
            <div className="col-md-6">
              <DetectedFaceDistanceGauge dataset={dataset} userId={userId} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserDetectedFaces;
