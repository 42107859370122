import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const googleAnalyticsId = "UA-202986689-2"

const SCRIPT1_NAME = 'gtag';
const SCRIPT2_NAME = 'gtagScript';

export const initializeGA = () => {

	// gtag.jsをheadタグに埋め込み
	const script1 = document.createElement('script');
	script1.id = SCRIPT1_NAME;
	script1.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
	script1.async = true;
	document.head.appendChild(script1);

	// 実行用scriptをheadタグに埋め込み
	const script2 = document.createElement('script');
	script2.id = SCRIPT2_NAME;
	script2.text = `window.dataLayer = window.dataLayer || [];
	function gtag() { dataLayer.push(arguments); }
	gtag('js', new Date());`;

	// gtag('config', '${googleAnalyticsId}');

	document.head.appendChild(script2);
};

// export const useTracking = () => {
// 	const { listen } = useHistory();
// 	useEffect(() => {
// 		const unlisten = listen((location) => {
// 			if (!window.gtag) return;
// 			console.log(location)
// 			window.gtag('config', googleAnalyticsId, { page_path: location.pathname });
// 		});

// 	}, [listen]);
	
// };