export const generateDataSource = (
  metrics1,
  metrics2,
  selectedParam,
  section1name,
  section2name
) => {
  const categories = [
    {
      category: [
        {
          label: "0-20",
        },
        {
          label: "20-40",
        },
        {
          label: "40-60",
        },
        {
          label: "60-80",
        },
        {
          label: "80-100",
        },
        {
          label: "above 100",
        },
      ],
    },
  ];

  const dataset = [
    {
      seriesname: section1name,
      data: getDatasetData(metrics1, selectedParam),
    },
    {
      seriesname: section2name,
      data: getDatasetData(metrics2, selectedParam),
    },
  ];

  const dataSource = {
    chart: {
      caption: selectedParam,
      subcaption: "2012-2016",
      xaxisname: "Years",
      yaxisname: selectedParam,
      formatnumberscale: "1",

      theme: "fusion",
      drawcrossline: "1",
    },
    categories: categories,
    dataset: dataset,
  };

  return dataSource;
};

const getDatasetData = (metrics, param, stepSize) => {
  let cat1 = 0;
  let cat2 = 0;
  let cat3 = 0;
  let cat4 = 0;
  let cat5 = 0;
  let cat6 = 0;
  let len = metrics.length;
  for (let i = 0; i < len; i++) {
    if (metrics[i][param] === null) continue;
    let curr = metrics[i][param];
    if (curr < stepSize) cat1++;
    else if (curr < stepSize * 2) cat2++;
    else if (curr < stepSize * 3) cat3++;
    else if (curr < stepSize * 4) cat4++;
    else if (curr < stepSize * 5) cat5++;
    else cat6++;
  }
  const res = [
    {
      value: cat1,
    },
    {
      value: cat2,
    },
    {
      value: cat3,
    },
    {
      value: cat4,
    },
    {
      value: cat5,
    },
    {
      value: cat6,
    },
  ];
  console.log("get dataset data - ", res);
  return res;
};

export const generateDataSource2 = (
  metrics1,
  metrics2,
  selectedParam,
  section1name,
  section2name
) => {
  let stepSize = getStepSize(metrics1, metrics2, selectedParam);
  const categories = [
    {
      category: [
        {
          label: "0-" + stepSize.toFixed(1),
        },
        {
          label: stepSize + " - " + stepSize * 2,
        },
        {
          label: (stepSize * 2).toFixed(1) + " - " + (stepSize * 3).toFixed(1),
        },
        {
          label: (stepSize * 3).toFixed(1) + " - " + (stepSize * 4).toFixed(1),
        },
        {
          label: (stepSize * 4).toFixed(1) + " - " + (stepSize * 5).toFixed(1),
        },
        {
          label: "above " + (stepSize * 5).toFixed(1),
        },
      ],
    },
  ];

  const dataset = [
    {
      seriesname: section1name,
      data: getDatasetData(metrics1, selectedParam, stepSize),
    },
    {
      seriesname: section2name,
      data: getDatasetData(metrics2, selectedParam, stepSize),
    },
  ];

  const dataSource = {
    chart: {
      caption: selectedParam,
      subcaption: "2012-2016",
      xaxisname: "Years",
      yaxisname: selectedParam,
      formatnumberscale: "1",

      theme: "fusion",
      drawcrossline: "1",
    },
    categories: categories,
    dataset: dataset,
  };

  return dataSource;
};

const getStepSize = (metrics1, metrics2, param) => {
  let min = 0;
  let total = 0;
  // let size = metrics1.length + metrics2.length;
  let size = 0;
  for (let i = 0; i < metrics1.length; i++) {
    if (metrics1[i][param] !== null) {
      size++;
      total = total + metrics1[i][param];
    }
  }
  for (let i = 0; i < metrics2.length; i++) {
    if (metrics2[i][param] !== null) {
      size++;
      total = total + metrics2[i][param];
    }
  }

  let avg = total / size;
  // let stepSize = Math.ceil(avg / 3);
  let stepSize = avg / 3;
  stepSize = Math.ceil(stepSize * 10) / 10;
  return stepSize;
};
