import { Auth, API } from "aws-amplify";

export async function getAvgFaceEmotions() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?account_id=" + accountId + "&type=avg&group_by=all";
	return await API.get(apiName, path);
}

export async function getAvgFaceEmotionsGroupByParticipant() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?account_id=" + accountId + "&type=avg&group_by=participant";
	return await API.get(apiName, path);
}

export async function getAvgFaceEmotionsOfMovie(movieId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&type=avg";
	return await API.get(apiName, path);
}

export async function getAvgFaceEmotionsOfMovieGroupByParticipant(movieId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&type=avg&group_by=participant";
	return await API.get(apiName, path);
}

export async function getTsFaceEmotionsOfMovie(movieId, aggregateType) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&type=timeseries" + "&agg_type=" + aggregateType;
	return await API.get(apiName, path);
}

export async function getTsFaceEmotionsOfMovieOfParticipant(movieId, participant_id) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId +  "&participant_id=" + participant_id + "&type=timeseries";
	return await API.get(apiName, path);
}

export async function getFaceEmotionsInBetween(from, to) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?accountId=" + accountId + "&from=" + from + "&to=" + to;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}