import React, { useState, useEffect } from "react";
import Movie from "./Movie";
import "./index.css";
import { WindowSharp } from "@mui/icons-material";
import { FaExpand } from "react-icons/fa";

const Movies = ({ movie }) => {
  const params = new URLSearchParams(window.location.search);
  const movieId = params.get("movieId");

  const viewOnFullScreen = () => {
    const fullscreenlink =
      window.location.href.split("/").slice(0, 3).join("/") +
      "/movies/player?movieId=" +
      movieId +
      "&time=0";

    window.open(fullscreenlink);
  };

  // console.log(movie)

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  return (
    <div className="">
      {/* <div className="card-body"> */}
      <Movie movie={movie} />
      {/* </div> */}
    </div>
  );
};

export default Movies;
