const initialState = {
	loading: false,
	meetingMetrics: [],
	error: "",
};

const meetingMetricsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_MEETING_METRICS_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_MEETING_METRICS_SUCCESS":
			return {
				loading: false,
				meetingMetrics: action.payload,
				error: "",
			};
		case "FETCH_MEETING_METRICS_FAILURE":
			return {
				loading: false,
				meetingMetrics: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default meetingMetricsReducer;
