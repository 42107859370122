import { Auth, API } from "aws-amplify";

// For all
export async function getAllAlerts() {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "AlertsAPI";
  const path = "?account_id=" + accountId;
  return await API.get(apiName, path);
}

export async function getAlert(alertId) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "AlertsAPI";
  const path = "?account_id=" + accountId + "&alert_id=" + alertId;
  return await API.get(apiName, path);
}

export async function createAlert(alerts) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "AlertsAPI";
  const path = "";

  alerts["accountId"] = accountId;
  const myInit = {
    body: alerts,
  };
  return await API.post(apiName, path, myInit);
}

export async function updateAlert(alerts) {
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "AlertsAPI";
  const path = "";

  alerts["accountId"] = accountId;
  const myInit = {
    body: alerts,
  };
  return await API.put(apiName, path, myInit);
}
