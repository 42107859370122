import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getRandomUsers } from "../../api/Users";

import { getMeetingMetrics } from "../../api/MeetingMetrics";

import Heading from "../../components/Heading";
import Charts from "../../components/Charts";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import "./Dashboard.css";
import EngagementGauge from "../../components/Charts/UserEngagements/EngagementGauge";

import FloatingButton from "../../assets/floating-btn.png";
import routes from "../../utils/routes";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const Dashboard = () => {
  const role = useSelector((state) => {
    return state.adminReducer.role;
  });
  const [meetingMetrics, setMeetingMetrics] = useState({});
  const [users, setUsers] = useState({});
  const [timeFilter, setTimeFilter] = useState("monthly");
  const getFilteredMetrics = (allData, interval) => {
    // const input = "2022-06-21 01:48:22";
    // const dateformat = new Date(input);
    // console.log("epoc time - ", dateformat.getTime());
    let currDate = new Date();
    let limit;
    if (interval === "monthly")
      limit = currDate.getTime() - 30 * 24 * 60 * 60 * 1000;
    else if (interval === "weekly")
      limit = currDate.getTime() - 7 * 24 * 60 * 60 * 1000;
    else if (interval === "daily")
      limit = currDate.getTime() - 24 * 60 * 60 * 1000;
    else if (interval === "yearly")
      limit = currDate.getTime() - 365 * 24 * 60 * 60 * 1000;
    else if (interval === "three_monthly")
      limit = currDate.getTime() - 3 * 30 * 24 * 60 * 60 * 1000;
    else if (interval === "all_time") limit = 0;

    return allData.filter((data) => {
      let uploadDate = new Date(data.upload_time);
      let uploadDateEpoc = uploadDate.getTime();
      return uploadDateEpoc > limit;
    });
  };

  const getMeanAndDiffScores = (allData, interval) => {
    let currDate = new Date();
    let upperLimit;
    let lowerLimit;
    if (interval === "monthly") {
      upperLimit = currDate.getTime() - 30 * 24 * 60 * 60 * 1000;
      lowerLimit = currDate.getTime() - 2 * 30 * 24 * 60 * 60 * 1000;
    } else if (interval === "weekly") {
      upperLimit = currDate.getTime() - 7 * 24 * 60 * 60 * 1000;
      lowerLimit = currDate.getTime() - 2 * 7 * 24 * 60 * 60 * 1000;
    } else if (interval === "daily") {
      upperLimit = currDate.getTime() - 24 * 60 * 60 * 1000;
      lowerLimit = currDate.getTime() - 2 * 24 * 60 * 60 * 1000;
    } else if (interval === "yearly") {
      upperLimit = currDate.getTime() - 365 * 24 * 60 * 60 * 1000;
      lowerLimit = currDate.getTime() - 2 * 365 * 24 * 60 * 60 * 1000;
    } else if (interval === "three_monthly") {
      upperLimit = currDate.getTime() - 3 * 30 * 24 * 60 * 60 * 1000;
      lowerLimit = currDate.getTime() - 2 * 3 * 30 * 24 * 60 * 60 * 1000;
    } else if (interval === "all_time") {
      upperLimit = 0;
      lowerLimit = 0;
    }
    let dataSize = allData.length;
    // console.log("data size - ", dataSize);
    let currTotalActiveScore = 0;
    let currCount = 0;
    let prevCount = 0;
    let prevTotalActiveScore = 0;
    let currTotalHealthScore = 0;
    let prevTotalHealthScore = 0;
    let currTotalEngagementScore = 0;
    let prevTotalEngagementScore = 0;
    let currTotalTranscriptScore = 0;
    let prevTotalTranscriptScore = 0;
    for (let i = 0; i < dataSize; i++) {
      let currData = allData[i];
      let uploadDate = new Date(currData.upload_time);
      let uploadDateEpoc = uploadDate.getTime();
      if (uploadDateEpoc > upperLimit) {
        currCount++;
        currTotalActiveScore += currData.active;
        currTotalEngagementScore += currData.ratio_distance;
        currTotalHealthScore += currData.healthy;
        currTotalTranscriptScore += currData.speech_speed;
      } else if (uploadDateEpoc < upperLimit && uploadDateEpoc > lowerLimit) {
        prevCount++;
        prevTotalActiveScore += currData.active;
        prevTotalEngagementScore += currData.ratio_distance;
        prevTotalHealthScore += currData.healthy;
        prevTotalTranscriptScore += currData.speech_speed;
      }
    }
    // if (prevCount === 0 || currCount === 0) {
    //   return {
    //     interval: interval,
    //     data_size: dataSize,
    //     curr_count: currCount,
    //     prev_count: prevCount,
    //     mean_curr_active: currTotalActiveScore / currCount,
    //     mean_prev_active: prevTotalActiveScore / prevCount,
    //     diff_active: "X",
    //   };
    // }

    let res = {
      interval: interval,
      data_size: dataSize,
      curr_count: currCount,
      prev_count: prevCount,
      mean_curr_active: parseFloat(currTotalActiveScore / currCount).toFixed(1),
      mean_prev_active: parseFloat(prevTotalActiveScore / prevCount).toFixed(1),
      diff_active: parseFloat(
        currTotalActiveScore / currCount - prevTotalActiveScore / prevCount
      ).toFixed(1),
      mean_curr_health: parseFloat(currTotalHealthScore / currCount).toFixed(1),
      mean_prev_health: parseFloat(prevTotalHealthScore / prevCount).toFixed(1),
      diff_health: parseFloat(
        currTotalHealthScore / currCount - prevTotalHealthScore / prevCount
      ).toFixed(1),
      mean_curr_engagement: parseFloat(
        currTotalEngagementScore / currCount
      ).toFixed(1),
      mean_prev_engagement: parseFloat(
        prevTotalEngagementScore / prevCount
      ).toFixed(1),
      diff_engagement: parseFloat(
        currTotalEngagementScore / currCount -
          prevTotalEngagementScore / prevCount
      ).toFixed(1),
      mean_curr_transcript: parseFloat(
        currTotalTranscriptScore / currCount
      ).toFixed(1),
      mean_prev_transcript: parseFloat(
        prevTotalTranscriptScore / prevCount
      ).toFixed(1),
      diff_transcript: parseFloat(
        currTotalTranscriptScore / currCount -
          prevTotalTranscriptScore / prevCount
      ).toFixed(1),
    };
    return res;
  };

  useEffect(() => {
    getMeetingMetrics()
      .then((response) => {
        // console.log("get meetings metrics response - ", response);
        // console.log(
        //   "filtered response - ",
        //   getFilteredMetrics(response.metrics, "yearly")
        // );
        setMeetingMetrics(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getRandomUsers(20)
      .then((response) => {
        // console.log("get random users response - ", response);
        setUsers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const metrics =
    meetingMetrics.metrics && meetingMetrics.metrics.length != 0
      ? meetingMetrics.metrics
      : [];

  return (
    <div className="col-md-10 p-4">
      <div className="dashboard-headting-div">
        <Heading heading="h5">
          IBY analyzes online communication every seconds.
        </Heading>

        <div className="dropdown-container">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sort by </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={timeFilter}
              label="Age"
              onChange={(event) => {
                console.log("on change - ", event.target.value);
                setTimeFilter(event.target.value);
                // console.log(
                //   "mean and diff  - ",
                //   getMeanAndDiffScores(metrics, timeFilter)
                // );
              }}
            >
              <MenuItem value={"daily"}>Day</MenuItem>
              <MenuItem value={"weekly"}>Week</MenuItem>
              <MenuItem value={"monthly"}>Month</MenuItem>
              <MenuItem value={"three_monthly"}>3 Months</MenuItem>
              <MenuItem value={"yearly"}>Year</MenuItem>
              <MenuItem value={"all_time"}>All time</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <Charts
        users={users}
        meetingMetrics={getFilteredMetrics(metrics, timeFilter)}
        trends={getMeanAndDiffScores(metrics, timeFilter)}
      />
      <Link className="Link-tag" to={routes.shared_dahsboard}>
        <Tooltip title="Shared Dashboard">
          <div className="floating-button">
            <img src={FloatingButton} alt=">"></img>
          </div>
        </Tooltip>
      </Link>
    </div>
  );
};

export default Dashboard;
