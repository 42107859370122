import React, { useState, useEffect } from "react";
import DetectedFaceDistanceColumn from "./DetectedFaceDistanceColumn";
import DetectedFaceDistanceHistgram from "./DetectedFaceDistanceHistgram";
import ScoreCard from "./ScoreCard";

const DetectedFacesScores = (props) => {

	const dataset = props.dataset;

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};


	return (
    <section className="bar-chart-section">
      <span className="section-title-text">Engagement Score</span>
      <div className="row">
        <div className="col-md-8">
          <DetectedFaceDistanceColumn dataset={dataset} />
        </div>
        <div className="col-md-4">
          {/* <ScoreCard dataset={dataset} /> */}
          <DetectedFaceDistanceHistgram dataset={dataset} />
        </div>
      </div>
    </section>
  );
};

export default DetectedFacesScores;
