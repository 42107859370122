export const fetchBenchmarkSettingsRequest = () => {
	return {
		type: "FETCH_BENCHMARK_SETTINGS_REQUEST",
	};
};

export const fetchBenchmarkSettingsSuccess = (benchmarkSettings) => {
	return {
		type: "FETCH_BENCHMARK_SETTINGS_SUCCESS",
		payload: benchmarkSettings,
	};
};

export const fetchBenchmarkSettingsFailure = () => {
	return {
		type: "FETCH_BENCHMARK_SETTINGS_FAILURE",
	};
};

export const updateBenchmarkSettingsRequest = () => {
	return {
		type: "UPDATE_BENCHMARK_SETTINGS_REQUEST",
	};
};

export const updateBenchmarkSettingsSuccess = (benchmarkSettings) => {
	return {
		type: "UPDATE_BENCHMARK_SETTINGS_SUCCESS",
		payload: benchmarkSettings,
	};
};

export const updateBenchmarkSettingsFailure = () => {
	return {
		type: "UPDATE_BENCHMARK_SETTINGS_FAILURE",
	};
};

export const deleteBenchmarkSettingsRequest = () => {
	return {
		type: "DELETE_BENCHMARK_SETTINGS_REQUEST",
	};
};

export const deleteBenchmarkSettingsSuccess = () => {
	return {
		type: "DELETE_BENCHMARK_SETTINGS_SUCCESS",
	};
};

export const deleteBenchmarkSettingsFailure = () => {
	return {
		type: "DELETE_BENCHMARK_SETTINGS_FAILURE",
	};
};
