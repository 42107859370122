import React, { useEffect, useState, useRef } from "react";
import { getTranscript } from "../../api/Transcripts";
import { getWordCloud } from "../../api/WordCloud";
import { getAvgHealthyScores } from "../../api/HealthyScores";
import "./Dashboard.css";

import TranscriptV3view from "./TranscriptV3view";

import ReactPlayer from "react-player/lazy";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ArrowClockwise,
} from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faRedo,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

import TranscriptTable from "./TranscriptTable";
import CustomPlayHelper from "../QuickView/CustomPlayHelper";

import { QUICKVIEW_TABLE } from "../../utils/schema";
import Table from "../../components/Table";
import Card from "../../components/Card";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { getHighlightOfMovie } from "../../api/Highlights";
import {
  getSearchHighlights,
  deleteTag,
  addTag,
  updateTagFunction,
} from "../../api/Search";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import TranscriptVisualisation from "./TranscriptVisualisation";
import { getTranscriptComments } from "../../api/TranscriptComment";
import { getMovie } from "../../api/Movies";
import { getFeatures } from "../../api/Admin";

import Loading from "../../assets/loading.gif";

import "../QuickView/QuickView.css";

import {
  updateTranscript,
} from "../../store/search/searchAction";
import { useSelector, useDispatch } from "react-redux";
import { shareViaEmail } from "../../api/ShareViaEmail";

const iconStyle = {
  fontSize: 20,
  color: "grey",
};

const iconStyleAlt = {
  fontSize: 15,
  color: "grey",
  marginLeft: "1rem",
  cursor: "pointer",
};

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#C7C7C9",
  "&:hover": {
    backgroundColor: "#F7CF6D",
  },
}));

const TranscriptDetails = () => {
  // const params = new URLSearchParams(window.location.search);
  // const movieId = params.get("movieId");
  // const [rawData, setRawData] = useState([]);
  // console.log(rawData);

  const [showDetailView, setShowDetailView] = useState(false);

  const currentFeature = "39b15f18-b4d8-4111-ba79-14a26e94c6df";
  const [enableFeature, setEnableFeature] = useState("disable");
  useEffect(async () => {
    const result = await getFeatures(true);
    // console.log(result);
    var enabled = 0;
    for (var i = 0; i < result.length; i = i + 1) {
      if (
        result[i]["analysis_id"] === currentFeature &&
        result[i]["active"] === 1
      ) {
        setEnableFeature("granted");
        enabled = 1;
        // console.log(enabled)
      }
    }
    if (enabled === 0) {
      setEnableFeature("denied");
    }
  }, []);

  const [playing, setPlaying] = useState(false);
  const [tagType, setTagType] = useState("ai");
  const [rawData, setRawData] = useState([null]);
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  const movieId = params.get("movieId");
  const [sceneTimestamp, setSceneTimestamp] = useState(params.get("timestamp"));
  const [options, setOptions] = useState([]);
  const [video, setVideo] = useState(null);
  const [duration, setDuration] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  // const dummyMovieId = "a2374129-04d1-443a-a102-94987cb4cf0e";
  const [wordCloudImgUrl, setWordCloudImgUrl] = useState(null);
  useEffect(() => {
    getWordCloud(movieId)
      .then((response) => {
        // console.log("wordcloud response - ", response);
        setWordCloudImgUrl(response.word_cloud.url);
      })
      .catch((err) => {
        // console.log("error in wordcloud api call - ", err);
      });

    // getAvgHealthyScores().then((response) => {
    //   console.log("healthy score response - ", response);
    // });
  }, []);

  useEffect(async () => {
    const temp = await getTranscript(movieId);
    // console.log(temp.transcripts);
    setRawData(temp.transcripts);

    dispatch(updateTranscript(temp.transcripts));
  }, []);

  useEffect(async () => {
    const temp = await getMovie(movieId);
    // console.log(temp)
    setVideo(temp.movies.url);
  }, []);

  const updateAllTags = async () => {
    const temp = await getTranscript(movieId);
    // console.log(temp.transcripts);
    setRawData(temp.transcripts);

    await handleGetComment();

    dispatch(updateTranscript(temp.transcripts));
  };

  const [comments, setComments] = useState([]);
  // console.log(comments);
  useEffect(async () => {
    handleGetComment();
  }, []);

  const handleGetComment = async () => {
    const temp = await getTranscriptComments(movieId);
    // console.log(temp);
    setComments(temp);
    // console.log(comments);
  };

  const handleTagTypeChange = (event) => {
    setTagType(event.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = useState(false);

  const currData = useRef(null);

  const [rate, setRate] = useState(1);
  const player = useRef(null);
  // console.log(player);

  const rateUp = () => {
    if (rate < 4) {
      setRate(rate + 1);
    }
  };

  const rateDown = () => {
    if (rate > 1) {
      setRate(rate - 1);
    }
  };

  const rateReset = () => {
    setRate(1);
  };

  const closeModal = () => {
    setOpen(false);
    setUpdateTag("");
    setUpdateNote("");
  };

  const deleteTagHelper = async (tag_id) => {
    setPopupLoading(true);
    await deleteTag(tag_id);
    closeModal();
    setPopupLoading(false);
    await updateAllTags();
  };

  const UpdateTagHelper = async (tag_id) => {
    setPopupLoading(true);
    await updateTagFunction(tag_id, "", updateNote);
    setUpdateTag("");
    setUpdateNote("");
    await updateAllTags();
    closeModal();
    setPopupLoading(false);
  };

  const [popupLoading, setPopupLoading] = useState(false);

  const [newTimestamp, setNewTimestamp] = useState("");
  const [newTag, setNewTag] = useState("");
  const [newNote, setNewNote] = useState("");
  // const newNote = useRef("")

  const [updateTag, setUpdateTag] = useState("");
  const [updateNote, setUpdateNote] = useState("");

  const HighlightPopup = () => {
    return (
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        {/* {console.log(currData.current)} */}
        {popupLoading === true || currData.current === null ? (
          <img src={Loading} />
        ) : (
          <div
            style={{
              margin: "3rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2rem",
                top: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                closeModal();
              }}
            >
              X
            </div>
            <p>
              Timestamp:{" "}
              {secondsToHms(currData.current.resource_id.split("_")[1])}
            </p>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <div style={{ paddingBottom: "1rem" }}>
                Current comment: {currData.current.comment}
              </div>
              <TextField
                id="outlined-basic"
                label="Comment"
                variant="outlined"
                value={updateNote}
                onChange={(e) => {
                  setUpdateNote(e.target.value);
                }}
                sx={{ width: "100%" }}
                size="small"
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => deleteTagHelper(currData.current.tag_id)}
                style={{
                  backgroundColor: "red",
                  border: "0px",
                  color: "white",
                }}
              >
                Delete
              </button>
              <button
                onClick={() => {
                  UpdateTagHelper(currData.current.tag_id);
                }}
                style={{
                  backgroundColor: "green",
                  border: "0px",
                  color: "white",
                }}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  };

  const hmsToSeconds = (e) => {
    console.log(e);
    var h = Number(e.split(":")[0]);
    var m = Number(e.split(":")[1]);
    var s = Number(e.split(":")[2]);

    return h * 3600 + m * 60 + s;
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    return new Date(d * 1000).toISOString().substr(11, 8);
  };

  const handleAddTag = async () => {
    setAddTagPopupOpen(false);
    // console.log(commentTimestamp.current);
    const addTemp = await addTag(
      "SCENE_TRANSCRIPT",
      commentTimestamp.current,
      movieId,
      "",
      newNote
    );
    // console.log(addTemp);
    // console.log(addTemp.message);
    await updateAllTags();
    setNewTimestamp("");
    commentTimestamp.current = 0;
    setNewTag("");
    setNewNote("");
    // newNote.current="";
  };

  const seekToTimestamp = (time) => {
    player.current.seekTo(time, "seconds");
  };

  // console.log(seekToTimestamp);

  const seekForward = () => {
    // console.log(player.current);
    const time = player.current.getCurrentTime();
    player.current.seekTo(time + 10, "seconds");
  };

  const seekBackward = () => {
    // console.log(player.current);
    const time = player.current.getCurrentTime();
    player.current.seekTo(time - 10, "seconds");
  };

  const [addTagPopupOpen, setAddTagPopupOpen] = useState(false);

  const addTagHelper = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "2rem",
            top: "1rem",
            cursor: "pointer",
          }}
          onClick={() => {
            setAddTagPopupOpen(false);
          }}
        >
          X
        </div>
        <p>Add Comment</p>
        {/* <input
          type="number"
          placeholder="Timestamp"
          // value={newTimestamp}
          value={secondsToHms(commentTimestamp.current)}
          onChange={(e) => {
            setNewTimestamp(e.target.value);
          }}
          style={{ marginTop: "1rem" }}
        ></input> */}
        {secondsToHms(commentTimestamp.current)}
        {/* <input
          type="text"
          placeholder="Tag"
          value={newTag}
          onChange={(e) => {
            setNewTag(e.target.value);
          }}
          style={{ marginTop: "1rem" }}
        ></input> */}
        {/* <input
          type="text"
          placeholder="Comment"
          value={newNote}
          onChange={(e) => {
            setNewNote(e.target.value);
          }}
          style={{ marginTop: "1rem" }}
        ></input> */}
        <TextField
          id="outlined-basic"
          label="Comment"
          variant="outlined"
          value={newNote}
          onChange={(e) => {
            setNewNote(e.target.value);
          }}
          sx={{ marginTop: "1rem", width: "100%" }}
          size="small"
        />
        <Button
          onClick={() => handleAddTag()}
          variant="contained"
          disableElevation
          sx={{ marginTop: "1rem" }}
        >
          Add Comment
        </Button>
      </div>
    );
  };

  const commentTimestamp = useRef(0);

  const addTagPopup = () => {
    // console.log(currData);
    return (
      <Popup
        open={addTagPopupOpen}
        closeOnDocumentClick
        onClose={() => setAddTagPopupOpen(false)}
      >
        <div
          style={{
            margin: "3rem",
            display: "flex",
          }}
        >
          {addTagHelper()}
        </div>
      </Popup>
    );
  };

  const [shareViaEmailData, setShareViaEmailData] = useState({});

  const openShareViaEmailPopup = (data) => {
    setShareViaEmailData(data);
    setShareOpen(true);
  };

  const [shareOpen, setShareOpen] = useState(false);
  const closeShareModal = () => setShareOpen(false);

  const SharePopup = ({ open, closeModal, children }) => {
    return (
      <Popup
        className="dashboard-per-meeting-share-popup"
        open={open}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        {children}
      </Popup>
    );
  };

  const SharePopupDialogBox = ({ open, closeModal }) => {
    return (
      <SharePopup
        open={open}
        closeModal={closeModal}
        children={<SharePopupDialogBoxContent closeModal={closeModal} />}
      />
    );
  };

  const SharePopupDialogBoxContent = ({ closeModal }) => {
    //check all data loaded successfully , then show the share button
    //check email regex then only allow share button to be enabled
    const checkEmailRegex = (email) => {
      const check = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (check) {
        setEnableShareButton(true);
      } else {
        setEnableShareButton(false);
      }
    };

    const [email, setEmail] = useState("");
    const [enableShareButton, setEnableShareButton] = useState(false);
    const [notSubmit, setNotSubmit] = useState(true);
    const [sending, setSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const sendData = async () => {
      const type = "TRANSCRIPT";
      // Prepare the data
      const data = {
        email_id: email,
        movie_id: movieId,
        transcriptData: shareViaEmailData,
      };

      setNotSubmit(false);
      setSending(true);

      shareViaEmail("TRANSCRIPT", data)
        .then((response) => {
          // If data successfully sent the success screen
          setIsSuccess(true);
          setSending(false);
        })
        .catch((err) => {
          // If failed then failure screen
          console.log(err);
          setIsSuccess(false);
          setSending(false);
        });
    };

    return (
      <div className="share-via-email-dialog-box">
        <div className="share-via-email-dialog-box-heading">
          <div>Share</div>
          <div
            className="share-via-email-dialog-box-heading-close"
            onClick={closeModal}
          >
            X
          </div>
        </div>
        <div className="share-via-email-dialog-box-body">
          {notSubmit ? (
            <div className="share-via-email-dialog-box-body-input">
              <div className="share-via-email-dialog-box-body-subheading">
                You are going to share transcript and comments at{" "}
                {secondsToHms(shareViaEmailData.time)} of movie with this email
                address !
              </div>
              <input
                className="share-via-email-dialog-box-body-input"
                type="email"
                value={email}
                onChange={(event) => {
                  checkEmailRegex(event.target.value);
                  setEmail(event.target.value);
                }}
                placeholder="Enter the valid email address you want to share it with"
              />
              {enableShareButton ? (
                <div
                  className="share-via-email-dialog-box-body-button"
                  onClick={sendData}
                >
                  <span>Share</span>
                </div>
              ) : (
                <div className="share-via-email-dialog-box-body-button-disabled">
                  <span>Share</span>
                </div>
              )}
            </div>
          ) : sending ? (
            <img
              className="share-via-email-dialog-box-body-loading"
              src={Loading}
              alt="Loading!"
            />
          ) : isSuccess ? (
            <div>Successfully sent the email! ✅</div>
          ) : (
            <div>Failed to send the email! ❌</div>
          )}
        </div>
      </div>
    );
  };

  const playTimestamps = useSelector((s) => {
    return s.searchReducer.transcriptChart.filter((e) => e.value !== 0);
  }).map((fields) => {
    // console.log(fields);
    return fields.label;
  });

  // console.log(playTimestamps);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [customPlay, setCustomPlay] = useState(false);
  const playIndex = useRef(0);

  useEffect(async () => {
    // console.log(customPlay);

    if (customPlay === false) {
      return;
    }

    // console.log(customPlay);

    let idx = 0;
    for (let i = 0; i < playTimestamps.length; i++) {
      if (playTimestamps[i] === sceneTimestamp) {
        // playIndex.current = i;
        idx = i;
        break;
      }
    }

    for (let i = idx; i < playTimestamps.length; i++) {
      // console.log(customPlay);

      setSceneTimestamp(playTimestamps[i]);
      seekToTimestamp(playTimestamps[i]);
      setPlaying(true);
      await wait(30000);
    }
  }, [customPlay]);

  if (enableFeature === "granted") {
    return (
      <div
        className="col-md-10 p-4"
        // className="transcript-details-root "
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {HighlightPopup()}
        {addTagPopup()}
        <SharePopupDialogBox open={shareOpen} closeModal={closeShareModal} />
        <div
          className="quickview-flex"
          // className="transcript-details-video-card "
          style={{ justifyContent: "space-between" }}
        >
          <div className="height-100 col-md-4">
            <div>
              <h4>Transcripts</h4>
              <p>Movie id: {movieId}</p>
            </div>
            <div>
              {video !== null ? (
                <ReactPlayer
                  url={video}
                  controls={true}
                  playbackRate={rate}
                  ref={player}
                  onDuration={(e) => {
                    player.current.seekTo(sceneTimestamp);
                    setDuration(e);
                  }}
                  width="100%"
                  playing={playing}
                />
              ) : (
                <img src={Loading} />
              )}

              {showDetailView === true ? (
                <TranscriptVisualisation
                  duration={parseInt(duration)}
                  seekToTimestamp={seekToTimestamp}
                  player={player}
                  chartid="1"
                  movieId={movieId}
                />
              ) : null}

              <ChevronDoubleLeft onClick={rateDown} />
              <ArrowClockwise onClick={rateReset} />
              <ChevronDoubleRight onClick={rateUp} />
              <span>{rate}</span>
              <span>x</span>

              <span
                onClick={() => {
                  seekBackward();
                }}
              >
                <FontAwesomeIcon style={iconStyleAlt} icon={faUndo} />
              </span>
              <span
                onClick={() => {
                  seekForward();
                }}
              >
                <FontAwesomeIcon style={iconStyleAlt} icon={faRedo} />
              </span>
              {showDetailView === true ? (
                customPlay === true ? (
                  <button
                    onClick={() => {
                      window.location.reload(false);
                      // setCustomPlay(false);
                    }}
                  >
                    <FontAwesomeIcon style={iconStyleAlt} icon={faPause} />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setCustomPlay(true);
                    }}
                  >
                    <FontAwesomeIcon style={iconStyleAlt} icon={faPlay} />
                  </button>
                )
              ) : null}
            </div>
            <span>
              Show detailed View{" "}
              <Switch
                checked={showDetailView}
                onChange={() => {
                  setShowDetailView(!showDetailView);
                }}
              />
            </span>
          </div>
          {showDetailView === true ? (
            <div className="comments-box col-md-8 tables-wrapper">
              <div className="two-tables">
                <TranscriptTable
                  player={player}
                  currData={currData}
                  setOpen={setOpen}
                  seekToTimestamp={seekToTimestamp}
                  sceneTimestamp={sceneTimestamp}
                  setSceneTimestamp={setSceneTimestamp}
                  scrollToTop={scrollToTop}
                  tableid="1"
                  movieId={movieId}
                  setAddTagPopupOpen={setAddTagPopupOpen}
                  commentTimestamp={commentTimestamp}
                  setNewTimestamp={setNewTimestamp}
                  comments={comments}
                  playing={playing}
                  openShareViaEmailPopup={openShareViaEmailPopup}
                />
              </div>
              <div className="transcript-details-wordcloud-card">
                <span style={{ paddingBottom: "0.5rem" }}>Word Cloud</span>
                <img src={wordCloudImgUrl} width={400} />
              </div>
            </div>
          ) : (
            <div className="comments-box col-md-8 tables-wrapper">
              <TranscriptV3view
                player={player}
                currData={currData}
                setOpen={setOpen}
                seekToTimestamp={seekToTimestamp}
                sceneTimestamp={sceneTimestamp}
                setSceneTimestamp={setSceneTimestamp}
                scrollToTop={scrollToTop}
                tableid="1"
                movieId={movieId}
                setAddTagPopupOpen={setAddTagPopupOpen}
                commentTimestamp={commentTimestamp}
                setNewTimestamp={setNewTimestamp}
                comments={comments}
                playing={playing}
                duration={parseInt(duration)}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else if (enableFeature === "denied") {
    return (
      <p>
        Sorry, You dont have access to this feature. Please contact IBY for
        enabling it.
      </p>
    );
  } else {
    return <img src={Loading} />;
  }
};

export default TranscriptDetails;
