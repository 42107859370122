export const calculatePercentageEmotionScores = (dataset) => {
  let neutralScore = 0;
  let happyScore = 0;
  let sadScore = 0;
  let angryScore = 0;
  let fearScore = 0;
  let disgustScore = 0;
  let surpriseScore = 0;
  let totalScore = 0;
  let percentageEmotionScores = {};

  for (let i = 0; i < dataset.length; i++) {
    neutralScore += dataset[i].neutral;
    happyScore += dataset[i].happy;
    sadScore += dataset[i].sad;
    angryScore += dataset[i].angry;
    fearScore += dataset[i].fear;
    disgustScore += dataset[i].disgust;
    surpriseScore += dataset[i].surprise;
  }

  totalScore = neutralScore + happyScore + sadScore + angryScore + fearScore + disgustScore + surpriseScore;

  percentageEmotionScores.neutral = (neutralScore / totalScore) * 100;
  percentageEmotionScores.happy = (happyScore / totalScore) * 100;
  percentageEmotionScores.sad = (sadScore / totalScore) * 100;
  percentageEmotionScores.angry = (angryScore / totalScore) * 100;
  percentageEmotionScores.fear = (fearScore / totalScore) * 100;
  percentageEmotionScores.disgust = (disgustScore / totalScore) * 100;
  percentageEmotionScores.suprise = (surpriseScore / totalScore) * 100;

  return percentageEmotionScores;
};

export const calculatePercentageBenchmarkEmotionScores = (benchmarkMetrics) => {
  let totalScore = 0;
  let percentageBenchmarkEmotionScores = {};

  totalScore = benchmarkMetrics.neutral + benchmarkMetrics.happy + benchmarkMetrics.sad + benchmarkMetrics.angry + benchmarkMetrics.fear + benchmarkMetrics.disgust + benchmarkMetrics.surprise;

  percentageBenchmarkEmotionScores.neutral = (benchmarkMetrics.neutral / totalScore) * 100;
  percentageBenchmarkEmotionScores.happy = (benchmarkMetrics.happy / totalScore) * 100;
  percentageBenchmarkEmotionScores.sad = (benchmarkMetrics.sad / totalScore) * 100;
  percentageBenchmarkEmotionScores.angry = (benchmarkMetrics.angry / totalScore) * 100;
  percentageBenchmarkEmotionScores.fear = (benchmarkMetrics.fear / totalScore) * 100;
  percentageBenchmarkEmotionScores.disgust = (benchmarkMetrics.disgust / totalScore) * 100;
  percentageBenchmarkEmotionScores.surprise = (benchmarkMetrics.surprise / totalScore) * 100;

  return percentageBenchmarkEmotionScores;
};
