import React, { useEffect, useState } from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import Dropdown from "react-bootstrap/Dropdown";

import routes from "../../../utils/routes";
import Loading from "../../Loading";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme);
charts(FusionCharts);

const SpeechSpeeds = (props) => {
  const ALL = 'ALL'

  const originalTranscriptSentimentsDataset = props.dataset;
  const isLoaded = props.isLoaded;

  const participants = {ALL};
  if ('transcript_sentiment' in originalTranscriptSentimentsDataset) {
    originalTranscriptSentimentsDataset.transcript_sentiment.forEach(transcriptSentiment => {
      if (transcriptSentiment.participant_id && !(transcriptSentiment.participant_id in participants))
        participants[transcriptSentiment.participant_id] = transcriptSentiment.participant_name;
    });
  }

  const style = {
    fontWeight: "bold",
    color: "#6d5e57",
  };

  const [dataSource, setDataSource] = useState(plotData(originalTranscriptSentimentsDataset));
  const [participantName, setParticipantName] = useState(ALL);

  useEffect(() => {
    setDataSource(plotData(originalTranscriptSentimentsDataset));
  }, [originalTranscriptSentimentsDataset]);


  const handleSelectParticipant = (selectedParticipantId) => {
    setParticipantName(participants[selectedParticipantId]);
    if (selectedParticipantId === ALL) {
      setDataSource(plotData(originalTranscriptSentimentsDataset));
    }
    else {
      if ('transcript_sentiment' in originalTranscriptSentimentsDataset) {
        const filteredDataset = originalTranscriptSentimentsDataset.transcript_sentiment.filter(transcriptSentiment => 
          selectedParticipantId === transcriptSentiment.participant_id);
        setDataSource(plotData({'transcript_sentiment': filteredDataset}));
      }
    }
  };

  return (
    <section className="bar-chart-section">
      <span className="section-title-text">Speech Speed</span>
      <div className="row">
        <div className="bar-histogram-chart-column-card">
        {
          isLoaded === "Not Loaded"
          ? <Loading />
          :
          <div>
            <div className="pt-3 header-item" style={style}>
              <div className="header-title">
                {participantName}
              </div>
              <div className="header-icon">
                <Dropdown onSelect={handleSelectParticipant}>
                  <Dropdown.Toggle
                    variant="secondary"
                    id="speech-speed-participant-name-toggle"
                  ></Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      Object.entries(participants).map(([participantId, participantName]) => (
                        <Dropdown.Item eventKey={participantId}>{participantName}</Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="card-body">
              <ReactFC
                type="msline"
                width="100%"
                height="500"
                dataFormat="JSON"
                dataSource={dataSource}
              />
            </div>
          </div>
        }
        </div>
      </div>
    </section>
  );
};

export default SpeechSpeeds;
