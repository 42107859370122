import React, { useEffect, useState } from "react";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import { getAvgActiveScoresOfParticipant } from "../../../api/ActiveScores";

import "../../../styles/score.css"

// Adding the chart and theme as dependency to the core fusioncharts
// ReactFC.fcRoot(FusionCharts, FusionTheme);
ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme);
// charts(FusionCharts);

const ActiveScore = (props) => {
	const userId = props.userId;
	const dataset = props.dataset;

	// const [score, setScore] = useState();
	const [fontStyle, setFontStyle] = useState("low-score");

	// useEffect(() => {
	// 	getAvgActiveScoresOfParticipant(userId)
	// 		.then((response) => {
	// 			// console.log(response)
	// 			setScore(response.active_scores[0].active);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// 	// setScore(100)
	// }, []);

	const scores = []
	for (let i = 0; i < dataset.length; i++) {
		const d = dataset[i];

		if (d.user_id == userId){
			scores.push(d.active);
		}
	}

	const score = average(scores);

	useEffect(() => {
		let font_style = "low-score";
		if (score > 10 && score < 20) {
			font_style = "middle-score";
		} else if (score > 20) {
			font_style = "high-score";
		}
		setFontStyle(font_style)
	}, [score]);

	return (
		<div className={fontStyle}>
			{score}
			<span className='h4'>points</span>
		</div>

	);
};

export default ActiveScore;

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 10) / 10;
};