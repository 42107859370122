import { Auth, API } from "aws-amplify";

export async function create_alert_config({
  alert_name,
  account_id,
  conditions_rel,
  alerts,
  alert_description,
  severity,
}) {
  const sender_id = (await Auth.currentUserInfo()).attributes.sub;
  if (account_id === undefined) {
    account_id = sender_id;
  }

  console.log(alert_name);
  console.log(account_id);
  console.log(conditions_rel);
  console.log(alerts);

  const apiName = "AlertConfig";
  const path = "";
  let myInit = {};

  myInit = {
    body: {
      sender_id: sender_id,
      alert_name: alert_name,
      account_id: account_id,
      conditions_rel: conditions_rel,
      alerts: alerts,
      alert_description: alert_description,
      severity: severity,
    },
  };

  console.log(myInit.body);
  return await API.post(apiName, path, myInit);
}

export async function get_alert_config(account_id) {
  console.log(account_id);

  if (account_id === undefined) {
    account_id = (await Auth.currentUserInfo()).attributes.sub;
  }

  const apiName = "AlertConfig";
  const path = "?account_id=" + account_id;

  return await API.get(apiName, path);
}

export async function update_alert_config_status(alert_def_id, is_active) {
  const apiName = "AlertConfig";
  const path = "";
  const myInit = {
    body: {
      alert_def_id: alert_def_id,
      is_active: is_active,
    },
  };

  return await API.put(apiName, path, myInit);
}

export async function delete_alert_config(alert_def_id) {
  const apiName = "AlertConfig";
  const path = "";
  const myInit = {
    body: {
      alert_def_id: alert_def_id
    },
  };
  return await API.del(apiName, path, myInit);
}