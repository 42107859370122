import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

import S3Image from "./S3Image";
import S3Video from "./S3Video";
import "./Users.css";

const iconStyle = {
  fontSize: 25,
  color: "grey",
};

const cellStyle = {
  good: {
    color: "#62B58F",
    fontWeight: "bold",
    fontSize: 36,
  },

  normal: {
    fontSize: 24,
  },

  bad: {
    color: "#F2726F",
    fontWeight: "bold",
    fontSize: 36,
  },
};

export const columns = [
  {
    title: "IMAGE",
    field: "userId",
    render: (rowData) => {
      return <S3Image userId={rowData.userId} />;
    },
  },
  { title: "ID", field: "userId" },
  { title: "NAME", field: "name" },
  {
    title: "REGISTRATION",
    field: "uploadTime",
    type: "date",
    format: "yyyy/MM/dd",
  },
  {
    title: "ACTIVE",
    field: "active",
    type: "numeric",
    cellStyle: (rowData) => {
      if (rowData >= 10 && rowData < 20) {
        return cellStyle.normal;
      } else if (rowData >= 20) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  {
    title: "ENGAGEMENT",
    field: "engagement",
    type: "numeric",
    cellStyle: (rowData) => {
      if (rowData >= 0.25 && rowData < 0.75) {
        return cellStyle.normal;
      } else if (rowData >= 0.75) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  {
    title: "HEALTHY",
    field: "healthy",
    type: "numeric",
    cellStyle: (rowData) => {
      if (rowData >= 2 && rowData < 3.0) {
        return cellStyle.normal;
      } else if (rowData >= 3) {
        return cellStyle.good;
      } else {
        return cellStyle.bad;
      }
    },
  },
  {
    title: "DASHBOAD",
    field: "userId",
    cellStyle: { textAlign: "center" },
    render: (rowData) => {
      return (
        <Link to={"/users/dashboard?userId=" + rowData.userId}>
          <FontAwesomeIcon style={iconStyle} icon={faVideo} />
        </Link>
      );
    },
  },
];

export const options = {
  headerStyle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  filtering: false,
};

export const optionsFilteringEnabled = {
  ...options,
  filtering: true,
};

const average = function (arr) {
  let sum = 0;

  if (arr.length === 0) {
    return 0.0;
  }
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }
  return Number(Math.round((sum / arr.length) * 10) / 10);
};

export const dataset = (_users, data) => {
  let users = {};

  for (let i = 0; i < _users.length; i++) {
    const id = _users[i]["user_id"];
    users[id] = {};
    users[id]["upload_time"] = _users[i]["upload_time"];
    users[id]["first_name"] = _users[i]["first_name"];
    users[id]["last_name"] = _users[i]["last_name"];
    // users[id]["object_key"] = _users[i]["object_key"];

    users[id]["active"] = [];
    users[id]["engagement"] = [];
    users[id]["healthy"] = [];
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].user_id in users == false) {
      continue;
    }

    users[data[i].user_id]["active"].push(data[i].active);
    users[data[i].user_id]["engagement"].push(
      data[i].ratio_distance === null ? data[i].ratio : data[i].ratio_distance
    );
    users[data[i].user_id]["healthy"].push(data[i].healthy);
  }

  const dataset = [];
  for (var key in users) {
    const row = {};
    row["userId"] = key;
    row["name"] =
      users[key].first_name === null && users[key].last_name === null
        ? "No name"
        : users[key].first_name && users[key].last_name;
    row["uploadTime"] = users[key]["upload_time"];
    // row["objectKey"] = users[key]["object_key"]
    row["active"] = average(users[key]["active"]);
    row["engagement"] = average(users[key]["engagement"]);
    row["healthy"] = average(users[key]["healthy"]);
    dataset.push(row);
  }

  // console.log(dataset)

  return dataset;
};

export const detailPanel = [
  {
    tooltip: "Show Highlights",
    render: (rowData) => {
      return (
        <div className="detail-panel-root">
          <S3Video userId={rowData.userId} type="happy" />

          <S3Video userId={rowData.userId} type="neutral" />

          <S3Video userId={rowData.userId} type="negative" />
        </div>
      );
    },
  },
];
