import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

import Card from "../../../components/Card";

import { v4 as uuidv4 } from 'uuid';

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
	getAlert, updateAlert
} from "../../../api/Alerts";

const schema = yup.object({
	alert_name: yup.string().required('Please enter a api key'),
	alerts: yup.array().of(
		yup.object().shape({
			metric: yup
				.string()
				.required('Please select a metric'),
			condition: yup
				.string()
				.required('Please select a condition'),
			value: yup
				.number()
				.typeError('you must specify a number')
				.min(-100, 'Min value 0.')
				.max(100, 'Max value 30.')

		})
	).min(1, "Minimum of 1 field"),

})

export const UpdateAlert = () => {

	const [alerts, setAlerts] = useState({
		alert_name: "",
		alerts: [],
	});
	const {
		handleSubmit, control, register, reset, formState: { errors, isValid }
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema)
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'alerts',
	});

	const params = new URLSearchParams(window.location.search);
	const alertId = params.get('alertId');

	useEffect(() => {
		getAlert(alertId)
			.then((response) => {
				console.log(response)
				setAlerts(response.alert_def);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		reset(alerts);
	}, [alerts]);

	const onSubmit = (data) => {
		console.log(data);
		console.log('is not Empty');
		setAlerts(data);

		updateAlert(data)
			.then((response) => {
				console.log(response)
				alert("Successfully updated!");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className='col-md-10 p-4 mt-5'>
			<div className='row justify-content-center'>
				<div className='col-md-6'>
					<Card>
						<h3>Update alert</h3>
						<div className="text-start">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row my-4">
									<label class="form-label ms-2">ALERT NAME</label>
									<div class="col-md-6">
										<input
											className="form-control"
											id='alertName'
											type='text'
											{...register("alert_name")}
										/>
										<div className="text-danger ms-2 h6">
											{errors.alert_name?.message}
										</div>
									</div>
								</div>
								<div className="row mb-2">
									<div class="col-md-4 ms-1">
										METRIC
									</div>
									<div class="col-md-4">
										CONDITION
									</div>
									<div class="col-md-2">
										VALUE
									</div>
									<div class="col-md-2">

									</div>
								</div>
								{fields.map((field, index) => (
									<div className="row mb-3">
										<input id='detail_id' type="hidden" {...register(`alerts.${index}.alert_detail_def_id`)} />
										<div class="col-md-4">
											<select
												className="form-select"
												id='metric'
												{...register(`alerts.${index}.metric`)}
											>
												<option value='happy_score'>happy score</option>
												<option value='happy_curve'>happy curve</option>
											</select>
											<div className="text-danger ms-2 h6">
												{errors.alerts && errors.alerts[index]?.metric?.message}
											</div>
										</div>
										<div class="col-md-4">
											<select
												className="form-select"
												id='condition'
												{...register(`alerts.${index}.alert_condition`, { required: true })}
											>
												<option value='0'>is equal to</option>
												<option value='1'>is more than</option>
												<option value='2'>is less than</option>
											</select>
											<div className="text-danger ms-2 h6">
												{errors.alerts && errors.alerts[index]?.condition?.message}
											</div>
										</div>

										<div class="col-md-2">
											<input
												className="form-control"
												id='value'
												type='number'
												{...register(`alerts.${index}.value`, { required: true })}
											/>
											<div className="text-danger ms-2 h6">
												{errors.alerts && errors.alerts[index]?.value?.message}
											</div>
										</div>


										<div class="col-md-2">
											<button
												className="btn btn-secondary"
												type="button"
												onClick={() => remove(index)}
											>
												Delete
											</button>

										</div>

									</div>
								))}

								<section>
									<button
										className="btn btn-secondary"
										type="button"
										onClick={() => append({ alert_detail_def_id: uuidv4(), metric: "", condition: "" })}
									>Add
									</button>
								</section>

								<div className="text-center mt-4">
									<button
										type="submit"
										class="btn btn-primary btn-lg w-25"
										disabled={!isValid}
									>
										Submit
									</button>
								</div>

							</form>
						</div>
					</Card>
				</div>
			</div>
		</div>

	);
};

export default UpdateAlert;
