import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const filterOptions = ["Speaker", "Section", "Movie Id"];

export default function MultipleSelectCheckmarks({
  setSpeakerManagement,
  setSpeaker,
  setMovie_id,
}) {
  const [filters, setFilters] = React.useState([]);

  const handleFilterUpdate = ({ filter, value }) => {
    console.log(filter);
    console.log(value);
    if (filter === "Speaker") {
      setSpeaker(value);
    } else if (filter === "Section") {
      setSpeakerManagement(value);
    } else if (filter === "Movie Id") {
      setMovie_id(value);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilters(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <FormControl sx={{ m: 1, width: "80%" }}>
        <InputLabel id="demo-multiple-checkbox-label" size="small">
          Filters
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={filters}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          size="small"
        >
          {filterOptions.map((filter) => (
            <MenuItem key={filter} value={filter}>
              <Checkbox checked={filters.indexOf(filter) > -1} />
              <ListItemText primary={filter} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ marginTop: "1rem", height: "100%", overflowY: "auto" }}>
        {console.log(filters)}
        {filters.map((filter) => {
          return (
            <FormControl sx={{ m: 1, width: "80%" }}>
              <TextField
                id="outlined-basic"
                label={filter}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  handleFilterUpdate({ filter: filter, value: e.target.value });
                }}
              />
            </FormControl>
          );
        })}
      </div>
    </div>
  );
}
