import React, { useEffect, useState } from "react";

import { getMeetingMetrics } from "../../../../api/MeetingMetrics";

import { getMovies } from "../../../../api/Movies";

import MaterialTable from "material-table";

import Card from "../../../../components/Card";
import { Paper } from "@material-ui/core";

import tableIcons from "../../../../assets/MaterialTableIcons";
import { dataset, options, columns, detailPanel } from "./helper";

const Tables = React.memo((props) => {
  const [moviesObj, setMoviesObj] = useState([]);
  const [meetingMetrics, setMeetingMetrics] = useState({});

  useEffect(() => {
    getMovies()
      .then((response) => {
        setMoviesObj(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getMeetingMetrics()
      .then((response) => {
        // console.log(response)
        setMeetingMetrics(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const metrics =
    meetingMetrics.metrics && meetingMetrics.metrics.length != 0
      ? meetingMetrics.metrics
      : [];

  const movies = moviesObj.length != 0 ? moviesObj.movies : [];

  const data = dataset(movies, metrics);
  console.log("data for individual movie  material table - ", data);

  return (
    <Card>
      <div className="mx-2">
        <MaterialTable
          title="Movie List"
          icons={tableIcons}
          columns={columns}
          data={data}
          options={options}
          detailPanel={detailPanel}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onSelectionChange={(rows) => {
            let curSelectedMovieInfo = [];

            for (let i = 0; i < rows.length; ++i) {
              const movieInfo = {};
              movieInfo["movieId"] = rows[i]["movieId"];
              movieInfo["topic"] = rows[i]["topic"];
              curSelectedMovieInfo.push(movieInfo);
            }

            props.setSelectedMovieInfo(curSelectedMovieInfo);
          }}
        />
      </div>
    </Card>
  );
});

export default Tables;
