const initialState = {
	loading: false,
	zoomMeetings: [],
	error: "",
};

const zoomMeetingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_ZOOM_MEETINGS_REQUEST":
			return {
				...state,
				loading: true,
			};
		case "FETCH_ZOOM_MEETINGS_SUCCESS":
			return {
				loading: false,
				zoomMeetings: action.payload,
				error: "",
			};
		case "FETCH_ZOOM_MEETINGS_FAILURE":
			return {
				loading: false,
				zoomMeetings: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default zoomMeetingsReducer;
