import { Auth, API } from "aws-amplify";

export async function getBenchmarkSettings() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "BenchmarkSettingsAPI";
	const path = "?account_id=" + accountId;
	return await API.get(apiName, path);
}

export async function updateBenchmarkSettings(isSetting, type, sectionId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "BenchmarkSettingsAPI";
	const path = "?account_id=" + accountId;

	const body = {}
	body["is_setting"] = isSetting;
	if (type) body["type"] = type;
	if (sectionId) body["section_id"] = sectionId;

  const init = {
    body,
	};
	return await API.post(apiName, path, init);
}

export async function deleteBenchmarkSettings() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "BenchmarkSettingsAPI";
	const path = "?account_id=" + accountId;
	return await API.del(apiName, path);
}
