import { Auth, API } from "aws-amplify";

export async function getEmailSettings() {
  const apiName = "EmailSettings";
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const path = "?account_id=" + accountId;

  return await API.get(apiName, path);
}

export async function updateEmailSettings(
  email_id,
  is_daily_highlights_active
) {
  const apiName = "EmailSettings";
  const path = "";
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const myInit = {
    body: {
      account_id: accountId,
      email_id: email_id,
      is_daily_highlights_active: is_daily_highlights_active,
    },
  };
  return await API.put(apiName, path, myInit);
}
