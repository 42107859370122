import React, { useState, useEffect } from "react";

import {
    getUser
} from "../../../../api/Users";


const S3Image = (props) => {

    const userId = props.userId;
    const [url, setUrl] = useState("");

    useEffect(() => {
        getUser(userId)
            .then((response) => {
                setUrl(response.users.url)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <img
            controls width="120" height="90"
            src={url}
        />

    );
};

export default S3Image;
