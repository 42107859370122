import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import {
  getMeetingMetrics,
  getMeetingMetricsDetails,
} from "../../../api/MeetingMetrics";
import { CSVLink } from "react-csv";
import "./Download.css";

function formatDate(dt) {
  var y = dt.getFullYear();
  var m = ("00" + (dt.getMonth() + 1)).slice(-2);
  var d = ("00" + dt.getDate()).slice(-2);
  return y + "-" + m + "-" + d;
}

const DownloadDetails = (props) => {
  //Setting Zoomkey and ZoomSecretKey
  const [metrics, setMetrics] = useState({
    metrics: [],
  });

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {}, []);

  // Fetch data
  const handleFetch = (event) => {
    event.preventDefault();
    getMeetingMetricsDetails()
      .then((response) => {
        console.log(response);
        setMetrics({
          metrics: response.metrics,
        });
        setIsFetched(true);
        alert("Successfully fetched!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="download-options-container">
      <div className="mb-4">
        <form onSubmit={handleFetch}>
          <div className="form-group">
            <h4 className="info-text-small">1. Fetch data</h4>
          </div>
          <div className="yellow-medium-button">
            <button
              type="submit"
              // className="btn d-block mx-auto text-white text-center px-4 mt-5 login-submit-bg-alpha"
            >
              Fetch Data
            </button>
          </div>
        </form>
      </div>
      <div>
        <div className="mb-1">
          <span className="info-text-small">2. Download data</span>
        </div>
        {isFetched ? (
          <div className="yellow-medium-button">
            <CSVLink
              data={metrics.metrics}
              filename={formatDate(new Date()) + ".csv"}
              // className="btn btn-primary"
              className="no-styling-button"
              target="_blank"
            >
              Download
            </CSVLink>
          </div>
        ) : (
          <div className="yellow-medium-button-disabled">Download</div>
        )}
      </div>
    </div>
  );
};

export default DownloadDetails;
