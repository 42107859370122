import { Auth, API } from "aws-amplify";

export async function getUsers() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path = "?account_id=" + accountId;
	return await API.get(apiName, path);
}

export async function getUsersWithUrl() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path = "?account_id=" + accountId + "&type=with_url";
	return await API.get(apiName, path);
}

export async function getUser(userId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path = "?account_id=" + accountId + "&user_id=" + userId;
	return await API.get(apiName, path);
}

export async function getUsersInMovie(movieId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId;
	return await API.get(apiName, path);
}

export async function getRandomUsers(size) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path = "?account_id=" + accountId + "&size=" + size;
	return await API.get(apiName, path);
}


//end


export async function getRegisteredUsers() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "RegisteredUsersAPI";
	const path = "?account_id=" + accountId;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}

export async function getUsersWithOffset(offset, perPage) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path =
		"?account_id=" +
		accountId +
		"&page_number=" +
		offset +
		"&page_size=" +
		perPage;

	return await API.get(apiName, path);
}
