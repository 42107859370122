const plotData = (dataset) => {
	let dateFormat = require("dateformat");
	let scoreList = [];
	let labelList = [];

	// if ("healthy_scores" in scores && scores.healthy_scores.length != 0) {
	// 	for (let i = 0; i < scores.healthy_scores.length; i++) {

	// 		let score = {};
	// 		let label = {};

	// 		label.label = scores.healthy_scores[i]["topic"]
	// 		score.value = scores.healthy_scores[i]["healthy"]
	// 		score.link =  "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/dashboard?movieId=" + scores.healthy_scores[i]["movie_id"]
			
	// 		scoreList.push(score);
	// 		labelList.push(label);
	// 	}
	// }

	let movies = {}
	let topics = {}

	for (let i = 0; i < dataset.length; i++) {

		if (dataset[i].movie_id in movies){
			movies[dataset[i].movie_id].push(dataset[i].healthy);
		} else{
			movies[dataset[i].movie_id] = [dataset[i].healthy];
			topics[dataset[i].movie_id] = dataset[i].topic;
		}
	}

	Object.keys(movies).forEach(key => {
		let score = {};
		let label = {};

		label.label = topics[key];
		score.value = average(movies[key]);
		score.link =  "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/dashboard?movieId=" + key;
		
		scoreList.push(score);
		labelList.push(label);
	});

	const dataSource = {
    chart: {
      // subcaption: "Active Score",
      yaxisname: "Score",
      numvisibleplot: "70",
      labeldisplay: "auto",
      theme: "fusion",
      palettecolors: "#F7CF6D",
    },
    categories: [
      {
        category: labelList,
      },
    ],
    dataset: [
      {
        // seriesname: "Healthy score",
        data: scoreList,
      },
    ],
  };

	return dataSource;
};

export default plotData;

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 100) / 100;
};

