import { Auth, API } from "aws-amplify";

export async function getNotifications(page, checked) {
  if (page === undefined) {
    page = 1;
  }
  const accountId = (await Auth.currentUserInfo()).attributes.sub;
  const apiName = "GetNotifications";
  const path =
    "?account_id=" + accountId + "&page=" + page + "&checked=" + checked;
  return await API.get(apiName, path);
}

export async function notificationsCheckedUpdate(alert_result_id, checked) {
  const apiName = "GetNotifications";
  const path = "";
  const myInit = {
    body: {
      alert_result_id: alert_result_id,
      checked: checked,
    },
  };
  return await API.put(apiName, path, myInit);
}
