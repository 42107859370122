import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getRole,
  getOrganisationList,
  getFeatures,
  updateFeature,
} from "../../api/Admin";
import {
  fetchAdminAccess,
  fetchOrganisationList,
} from "../../store/admin/adminAction";
import SubscriptionHelper from "./SubscriptionHelper";

export default function Subscriptions() {
  const dispatch = useDispatch();
  const role = useSelector((state) => {
    return state.adminReducer.role;
  });

  useEffect(async () => {
    const result = await getRole();
    console.log(result[0].role_name);
    dispatch(fetchAdminAccess(result[0].role_name));
  }, []);

  if (role === "ADMIN") {
    return <SubscriptionHelper />;
  } else {
    return <p>ACCESS DENIED</p>;
  }
}
