import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const iconStyle = {
	fontSize: 20,
	color: "grey",
};

export const columns = [
	{ title: "ID", field: "alertId" },
	{ title: "NAME", field: "name" },
	{ title: "UPLOAD TIME", field: "uploadTime", type: "date", format: 'yyyy/MM/dd' },
	{
		title: "ACTIONS",
		field: "alertId",
		// cellStyle: { textAlign: 'center' },
		render: (rowData) => {
			return (
				<div className="row">
					<div class="col">
						<Link
							to={
								"/alerts/update?alertId=" + rowData.alertId
							}>
							<FontAwesomeIcon
								style={iconStyle}
								icon={faEdit}
							/>
						</Link>
					</div>
					<div class="col">
						<Link
							to={
								"/alerts?alertId=" + rowData.alertId
							}>
							<FontAwesomeIcon
								style={iconStyle}
								icon={faTrash}
							/>
						</Link>
					</div>
				</div>

			)
		},
	},
];

export const options = {
	headerStyle: {
		fontSize: 16,
		fontWeight: "bold",
	},
	// filtering: true,
}

export const dataset = (_alerts) => {

	const dataset = []

	for (let i = 0; i < _alerts.length; i++) {
		const row = {}
		row["alertId"] = _alerts[i]["id"]
		row["name"] = _alerts[i]["name"]
		row["uploadTime"] = _alerts[i]["upload_time"]

		dataset.push(row)
	}
	return dataset;
};