export const fetchAdminAccess = (request) => {
  return {
    type: "FETCH_ADMIN_ACCESS_REQUEST",
    payload: request,
  };
};

export const fetchOrganisationList = (request) => {
  return {
    type: "FETCH_ORGANISATION_LIST",
    payload: request,
  };
}