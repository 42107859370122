import React from "react";

import FaceEmotions from "./FaceEmotionsV2";
import UserEmotions from "./UserEmotions";
import ValenceScore from "./ValenceScore";
import ActiveScores from "./ActiveScores";
import HealthyScores from "./HealthyScores";
import EngagementScores from "./EngagementScores";
import UserEngagements from "./UserEngagements";
import UserHealthy from "./UserHealthy";
import Highlights from "./Highlights";

import DetectedFaces from "./DetectedFaces";
import DetectedFaceDistance from "./DetectedFaceDistance";

import UserDetectedFaces from "./UserDetectedFaces";

import UserDetectedFaceDistance from "./UserDetectedFaceDistance";
import AnalyticsOverview from "./AnalyticsOverview";
import "./index.css";

const ChartSection = ({ meetingMetrics, users, trends }) => {
  return (
    <section className="chart-section">
      <div className="chart-container">
        {/* <div className="row mt-5"> */}
        <div className="chart-column">
          <div className="mb-5">
            <AnalyticsOverview trends={trends} />
          </div>
          <div className="mb-5">
            <Highlights />
          </div>
          <div className="mb-5">
            <ActiveScores dataset={meetingMetrics} />
          </div>
          <div className="mb-5">
            <UserEmotions dataset={meetingMetrics} users={users} />
          </div>
          {/* <div className='mb-5'>
						<EngagementScores engagementScores={engagementScores} />
					</div> */}
          <div className="mb-5">
            <DetectedFaceDistance dataset={meetingMetrics} />
          </div>

          {/* <div className='mb-5'>
						<UserEngagements dataset={faceEmotions} users={users}/>
					</div> */}
          <div className="mb-5">
            <UserDetectedFaceDistance dataset={meetingMetrics} users={users} />
          </div>
          <div className="mb-5">
            <HealthyScores dataset={meetingMetrics} />
          </div>
          <div className="mb-5">
            <UserHealthy dataset={meetingMetrics} users={users} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChartSection;
