import React from "react";

import UserEmotionSummary from "./UserEmotionsSummary";
import FaceEmotions from "./FaceEmotions";
import Engagements from "../ChartsPerMeeting/Engagements";
import DetectedFaces from "../ChartsPerMeeting/DetectedFaces";
import ValenceScore from "../ChartsPerMeeting/Valences";
// import ValenceScore from "./ValenceScore";
// import UserEmotions from "./UserEmotions";

import Distances from "./Distances";

const ChartSection = ({
	faceEmotionTs,
	engagementTs,
	detectedFacesTs,
	distancesTs,
	isBenchmarkSetting,
	benchmarkMetrics,
	movie,
	user
}) => {
	return (
		<section className='chartSection'>
			<div className='container'>
				<div className='row mt-5'>
					{/* <div className='mb-5'>
						<UserEmotions dataset={faceEmotions} />
					</div> */}
					<div className='mb-5'>
						<UserEmotionSummary
							dataset={faceEmotionTs.faceEmotionTs?.face_emotions}
                isBenchmarkSetting={isBenchmarkSetting}
                benchmarkMetrics={benchmarkMetrics}
						/>
					</div>

					<div className='mb-5'>
						<FaceEmotions faceEmotionTs={faceEmotionTs.faceEmotionTs} userId={user.users?.user_id} />
					</div>

					{/* <div className='mb-5'>
						<ValenceScore dataset={faceEmotionTs.faceEmotionTs} />
					</div> */}

					{/* <div className='mb-5'>
						<Engagements dataset={engagementTs.engagementTs} />
					</div> */}

					<div className='mb-5'>
						<DetectedFaces dataset={detectedFacesTs.detectedFacesTs} movie={movie.movie}/>
					</div>

					<div className='mb-5'>
						<Distances dataset={distancesTs.distancesTs} movie={movie.movie}/>
					</div>


				</div>
			</div>
		</section>
	);
};

export default ChartSection;
