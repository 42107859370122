import { Auth, API } from "aws-amplify";

// For Dashboard per User and Movie
export async function getTsDetectedFacesOfParticipantInMovie(movieId, participantId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "DetectedFacesAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&participant_id=" + participantId + "&type=timeseries";
	return await API.get(apiName, path);
}

// For Dashboard per Movie
export async function getTsDetectedFacesOfMovie(movieId) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "DetectedFacesAPI";
	const path = "?account_id=" + accountId + "&movie_id=" + movieId + "&type=timeseries";
	return await API.get(apiName, path);
}

// For Dashboard, user List, movie, list
export async function getRatioDetectedFacesOfMovieGroupbyParticipant() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "DetectedFacesAPI";
	const path = "?account_id=" + accountId + "&type=avg&group_by=movie_participant";
	return await API.get(apiName, path);
}