const plotData = (dataset) => {
	let dateFormat = require("dateformat");
	let scoreList = [];
	let labelList = [];

	let movies = {}
	let topics = {}

	for (let i = 0; i < dataset.length; i++) {
		const d = dataset[i];

		let engagement = 0
		if (d.ratio_distance === null) {
			engagement = d.ratio;
		} else {
			engagement = d.ratio_distance;
		}

		if (d.movie_id in movies){
			movies[d.movie_id].push(engagement);
		} else{
			movies[d.movie_id] = [engagement];
			topics[d.movie_id] = d.topic;
		}

		

	}
	
	Object.keys(movies).forEach(key => {
		let score = {};
		let label = {};

		label.label = topics[key];
		score.value = average(movies[key]);
		score.link =  "n-" + window.location.href.split("/").slice(0, 3).join("/") + "/movies/dashboard?movieId=" + key;
		
		scoreList.push(score);
		labelList.push(label);
	});

	const dataSource = {
    chart: {
      // subcaption: "Engagement Score",
      yaxisname: "Score",
      numvisibleplot: "70",
      labeldisplay: "auto",
      theme: "fusion",
      palettecolors: "#F7CF6D",
      // yAxisMaxValue: "1",
    },
    categories: [
      {
        category: labelList,
      },
    ],
    dataset: [
      {
        // seriesname: "Engagement score(Detected Faces)",
        data: scoreList,
      },
    ],
  };

	return dataSource;
};

export default plotData;

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum) / arr.length) * 100) / 100;
};

