const SampleDataSource = {
  chart: {
    caption: "Sales of Beer & Ice cream vs Temperature",
    subcaption: "Los Angeles Topanga",
    xaxisname: "Avg Day Temperature",
    yaxisname: "Sales (In USD)",
    xaxisminvalue: "23",
    xaxismaxvalue: "95",
    ynumberprefix: "$",
    yaxisminvalue: "1200",
    xnumbersuffix: "°F",
    theme: "fusion",
    plottooltext:
      "<b>$yDataValue</b> worth <b>$seriesNames</b> were sold,<br>when temperature was <b>$xdataValue</b>",
  },
  categories: [
    {
      category: [
        {
          x: "23",
          label: "23°F",
        },
        {
          x: "32",
          label: "32°F",
        },
        {
          x: "50",
          label: "50°F",
        },
        {
          x: "68",
          label: "68°F",
        },
        {
          x: "80",
          label: "80°F",
        },
        {
          x: "95",
          label: "95°F",
        },
      ],
    },
  ],
  dataset: [
    {
      seriesname: "Ice Cream",
      anchorbgcolor: "5D62B5",
      data: [
        {
          x: "23",
          y: "1560",
        },
        {
          x: "24",
          y: "1500",
        },
        {
          x: "24",
          y: "1680",
        },
        {
          x: "25",
          y: "1780",
        },
        {
          x: "25",
          y: "1620",
        },
        {
          x: "26",
          y: "1810",
        },
        {
          x: "27",
          y: "2310",
        },
        {
          x: "29",
          y: "2620",
        },
        {
          x: "31",
          y: "2500",
        },
        {
          x: "32",
          y: "2410",
        },
        {
          x: "35",
          y: "2880",
        },
        {
          x: "36",
          y: "3910",
        },
        {
          x: "34",
          y: "3960",
        },
        {
          x: "38",
          y: "4080",
        },
        {
          x: "40",
          y: "4190",
        },
        {
          x: "41",
          y: "4170",
        },
        {
          x: "42",
          y: "4280",
        },
        {
          x: "54",
          y: "5180",
        },
        {
          x: "53",
          y: "5770",
        },
        {
          x: "55",
          y: "5900",
        },
        {
          x: "56",
          y: "5940",
        },
        {
          x: "58",
          y: "6090",
        },
        {
          x: "61",
          y: "6086",
        },
        {
          x: "67",
          y: "6100",
        },
        {
          x: "68",
          y: "6200",
        },
        {
          x: "70",
          y: "6360",
        },
        {
          x: "75",
          y: "6450",
        },
        {
          x: "79",
          y: "6650",
        },
        {
          x: "80",
          y: "6710",
        },
        {
          x: "79",
          y: "6975",
        },
        {
          x: "82",
          y: "7000",
        },
        {
          x: "85",
          y: "7150",
        },
        {
          x: "86",
          y: "7160",
        },
        {
          x: "86",
          y: "7200",
        },
        {
          x: "88",
          y: "7230",
        },
        {
          x: "87",
          y: "7210",
        },
        {
          x: "86",
          y: "7480",
        },
        {
          x: "89",
          y: "7540",
        },
        {
          x: "89",
          y: "7400",
        },
        {
          x: "90",
          y: "7500",
        },
        {
          x: "92",
          y: "7640",
        },
      ],
    },
    {
      seriesname: "Beer",
      anchorbgcolor: "#29C3BE",
      data: [
        {
          x: "23",
          y: "3160",
        },
        {
          x: "24",
          y: "3000",
        },
        {
          x: "24",
          y: "3080",
        },
        {
          x: "25",
          y: "3680",
        },
        {
          x: "25",
          y: "3320",
        },
        {
          x: "26",
          y: "3810",
        },
        {
          x: "27",
          y: "5310",
        },
        {
          x: "29",
          y: "3620",
        },
        {
          x: "31",
          y: "4100",
        },
        {
          x: "32",
          y: "3910",
        },
        {
          x: "35",
          y: "4280",
        },
        {
          x: "36",
          y: "4210",
        },
        {
          x: "34",
          y: "4160",
        },
        {
          x: "38",
          y: "4480",
        },
        {
          x: "40",
          y: "4890",
        },
        {
          x: "41",
          y: "4770",
        },
        {
          x: "42",
          y: "4880",
        },
        {
          x: "54",
          y: "4980",
        },
        {
          x: "53",
          y: "4770",
        },
        {
          x: "55",
          y: "4900",
        },
        {
          x: "56",
          y: "4940",
        },
        {
          x: "58",
          y: "4990",
        },
        {
          x: "61",
          y: "5086",
        },
        {
          x: "67",
          y: "5100",
        },
        {
          x: "68",
          y: "4700",
        },
        {
          x: "70",
          y: "5360",
        },
        {
          x: "75",
          y: "5150",
        },
        {
          x: "79",
          y: "5450",
        },
        {
          x: "80",
          y: "5010",
        },
        {
          x: "79",
          y: "4975",
        },
        {
          x: "82",
          y: "5400",
        },
        {
          x: "85",
          y: "5150",
        },
        {
          x: "86",
          y: "5460",
        },
        {
          x: "86",
          y: "5000",
        },
        {
          x: "88",
          y: "5200",
        },
        {
          x: "87",
          y: "5410",
        },
        {
          x: "86",
          y: "5480",
        },
        {
          x: "89",
          y: "5440",
        },
        {
          x: "89",
          y: "5300",
        },
        {
          x: "90",
          y: "5500",
        },
        {
          x: "92",
          y: "5240",
        },
      ],
    },
  ],
};

export const getDataSource = (
  metrics1,
  metrics2,
  selectedParams,
  section1name,
  section2name
) => {
  const dataset = [
    {
      seriesname: section1name,
      anchorbgcolor: "#F7CFFD",
      data: getSeriesData(metrics1, selectedParams),
    },
    {
      seriesname: section2name,
      anchorbgcolor: "#29C3BE",
      data: getSeriesData(metrics2, selectedParams),
    },
  ];
  console.log("dataset - ", dataset);
  let caption = selectedParams[0] + " - vs - " + selectedParams[1];
  const dataSource = {
    chart: {
      caption: caption,
      subcaption: "Comparing Sections",
      xaxisname: selectedParams[0],
      yaxisname: selectedParams[1],

      theme: "fusion",
    },
    dataset: dataset,
  };
  return dataSource;
  return SampleDataSource;
};

const getSeriesData = (metricsArray, selectedParams) => {
  console.log("getting series data for - ", metricsArray, selectedParams);
  let res = [];
  let len = metricsArray.length;
  for (let i = 0; i < len; i++) {
    let currObj = {
      x: metricsArray[i][selectedParams[0]],
      y: metricsArray[i][selectedParams[1]],
    };
    if (
      metricsArray[i][selectedParams[0]] !== null &&
      metricsArray[i][selectedParams[1]] !== null
    )
      res.push(currObj);
  }
  console.log("getSeries data result -  ", res);
  return res;
};

export const mergeMovieMetrics = (metricsArray) => {
  let len = metricsArray.length;
  let prevMovieId = metricsArray[0].movie_id;
  let res = [];
  let currObj = null;
  let currCount = 0;
  let engagement = 0;
  let active = 0;
  let speech = 0;
  let negaposi = 0;
  let health = 0;
  let i = 0;
  while (i < len) {
    let movieId = metricsArray[i].movie_id;
    if (movieId === prevMovieId) {
      currCount++;
      engagement += metricsArray[i].engagement_score;
      active += metricsArray[i].activity_score;
      speech += metricsArray[i].speech_speed_m;
      negaposi += metricsArray[i].text_negative_m;
      health += metricsArray[i].healthy_sore;
      i++;
    } else {
      currObj = {
        movie_id: prevMovieId,
        engagement_score:
          engagement === 0 ? null : engagement / (currCount - 1),
        activity_score: active === 0 ? null : active / (currCount - 1),
        healthy_sore: health === 0 ? null : health / (currCount - 1),
        speech_speed_m: speech === 0 ? null : speech,
        text_negative_m: negaposi === 0 ? null : negaposi,
      };
      prevMovieId = movieId;
      res.push(currObj);
      engagement = 0;
      active = 0;
      speech = 0;
      negaposi = 0;
      health = 0;
      currCount = 0;
    }
  }
  console.log("merged metrics - ", res);
  return res;
};
